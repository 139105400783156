/* eslint-disable */

export function sortDateTimeObjects(dateTimeArray: any[], sortOrder: string) {
  dateTimeArray.sort(function (a: any, b: any) {
    const dateA = new Date(`${a.datePaid} ${a.timePaid}`);
    const dateB = new Date(`${b.datePaid} ${b.timePaid}`);

    let comparison = 0;
    if (dateA > dateB) {
      comparison = 1;
    } else if (dateA < dateB) {
      comparison = -1;
    }

    return sortOrder === 'desc' ? comparison * -1 : comparison;
  });

  return dateTimeArray;
}

export function formatCurrency(amount: string | number) {
  const sanitizedAmount =
    typeof amount === 'string' ? amount.replace(/,/g, '') : amount;
  const parsedAmount = Number(sanitizedAmount);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let formattedValue = formatter.format(parsedAmount);
  if (parsedAmount < 0) {
    formattedValue = `(-${formattedValue.substring(1)})`;
  }
  return formattedValue;
}


export function formatDate(dateString: any) {
  const date = new Date(dateString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export function sortDate(Array: any, order: string) {
  const dateSort = (a: any, b: any) => {
    const dateA: any = new Date(a.activityDate);
    const dateB: any = new Date(b.activityDate);
    if (order == 'asc') {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };
  Array.sort(dateSort);
}

export function sortNumber(order: any, key: any, array: any, setFunction: any) {
  const sortedArray = array.slice();
  if (order === 'asc') {
    handleAscSortNumber(setFunction, key, sortedArray);
  } else {
    handleDscSortNumber(setFunction, key, sortedArray);
  }
}

function handleDscSortNumber(setFunction: any, key: any, sortedArray: any) {
  setFunction(
    sortedArray.sort((a: any, b: any) =>
      (b[key] ?? '').localeCompare(a[key] ?? '')
    )
  );
}

function handleAscSortNumber(setFunction: any, key: any, sortedArray: any) {
  setFunction(
    sortedArray.sort((a: any, b: any) =>
      (a[key] ?? '').localeCompare(b[key] ?? '')
    )
  );
}

export const locationTypes = [
  { label: 'Store', value: 'Store' },
  { label: 'Radius', value: 'Radius' },
];

export function conditionType(refCode: string) {
  switch (refCode) {
    case 'EXCP':
      return 'Exception';

    case 'ATO':
      return 'Auto';

    case 'NEW':
      return 'New';

    case 'ALL':
      return 'All';
  }
}

export function statusTypes(refCode: string) {
  switch (refCode) {
    case 'IDLE':
      return 'Stock Idle';

    case 'OR':
      return 'On Rent';

    case 'CO':
      return 'Charged Off';

    case 'ALL':
      return 'All';
  }
}


export const truncString = (str: any, max: number, add: any) => {
  add = add || '...';
  return typeof str === 'string' && str.length > max
    ? str.substring(0, max) + add
    : str;
};

export const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const viewPDFclick = (b64File: any, docuURL: any) => {
  const urlArray = docuURL.split('.');
  const docuType = urlArray[urlArray.length - 1];
  let contentType = `application/${docuType}`;
  if (docuType === 'html') {
    contentType = 'text/html';
  }
  const blobResult = b64toBlob(b64File, contentType);
  const fileURL = URL.createObjectURL(blobResult);
  window.open(fileURL);
};
