/* eslint-disable prettier/prettier */
/* eslint-disable */

import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  AccessToken = false
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
  };
  if(AccessToken){
    const accessToken =
      window.localStorage.getItem('ac') || (await getAccessToken());
    headers.accessToken = accessToken;
    headers.storeNumber = window.sessionStorage.getItem('storeNumber');
  }

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}

export async function clientCall(
  endpoint: string,
  requestBody: any,
  apiUrl: any,
  needAccessToken = true
) {
  try {
    const jwtToken = await getJWToken();
    
    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber: '00000',
    };

    if (needAccessToken) {
      headers.AccessToken = await getAccessToken();
    }
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    const requestConfig: any = {
      method: requestBody.method,
      headers: {
        ...headers,
      },
    };

    if (requestBody.payload) {
      requestConfig.data = JSON.stringify(requestBody.payload);
    }

    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response
    );
  } catch (e: any) {
    
    return e.response;
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    throw new Error(`An error occurred: ${error}.`);
  }
}

export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
