import { dropdown } from '../../constants/customerSearchInterface';

export function sortObject(
  array: any,
  order: any,
  key: any,
  setState: any,
  sort1: any,
  sort2: any
) {
  let sortedArray = [...array];
  if (order === 'asc') {
    key == 'lastName' ? sort1(false) : sort2(false);
    sortedArray = [...sortedArray].sort((a: any, b: any) =>
      a[key].localeCompare(b[key])
    );
  } else {
    key == 'lastName' ? sort1(true) : sort2(true);
    sortedArray = [...sortedArray].sort((a: any, b: any) =>
      b[key].localeCompare(a[key])
    );
  }
  setState(sortedArray);
}

const inventorySort = (
  order: any,
  key: any,
  setSortOrder: any,
  sortOrder: any,
  sortedArray: any,
  setInventoryDetails: any
) => {
  {
    if (order === 'asc') {
      setSortOrder({ ...sortOrder, itemNumberSortOrder: false });
      sortedArray = [...sortedArray].sort((a, b) => a[key] - b[key]);
    } else {
      setSortOrder({ ...sortOrder, itemNumberSortOrder: true });
      sortedArray = [...sortedArray].sort((a, b) => b[key] - a[key]);
    }
    setInventoryDetails(sortedArray);
  }
};

const paymentActivitySort = (
  order: any,
  key: any,
  setSortOrder: any,
  sortOrder: any,
  sortedArray: any,
  setPaymentDetails: any,
  setActivityDetails: any
) => {
  const getDate = (item: any) =>
    key === 'datePaid'
      ? new Date(`${item.datePaid} ${item.timePaid}`)
      : new Date(item.activityDate);
  if (order === 'asc') {
    key === 'datePaid'
      ? setSortOrder({ ...sortOrder, dateSortOrder: false })
      : setSortOrder({ ...sortOrder, dateActSortOrder: false });

    sortedArray = [...sortedArray].sort((a, b) => {
      return getDate(a).getTime() - getDate(b).getTime();
    });
  } else {
    key === 'datePaid'
      ? setSortOrder({ ...sortOrder, dateSortOrder: true })
      : setSortOrder({ ...sortOrder, dateActSortOrder: true });
    sortedArray = [...sortedArray].sort((a, b) => {
      return getDate(b).getTime() - getDate(a).getTime();
    });
  }
  key === 'datePaid'
    ? setPaymentDetails(sortedArray)
    : setActivityDetails(sortedArray);
};

export function sorting(
  array: any,
  order: any,
  key: any,
  setSortOrder: any,
  sortOrder: any,
  setPaymentDetails: any,
  setActivityDetails: any,
  setInventoryDetails: any
) {
  const sortedArray: any = [...array];
  if (key === 'datePaid' || key === 'activityDate') {
    paymentActivitySort(
      order,
      key,
      setSortOrder,
      sortOrder,
      sortedArray,
      setPaymentDetails,
      setActivityDetails
    );
  } else if (key === 'inventoryNumber') {
    inventorySort(
      order,
      key,
      setSortOrder,
      sortOrder,
      sortedArray,
      setInventoryDetails
    );
  }
}

export const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const viewPDFclick = (b64File: any, docuURL: any) => {
  const urlArray = docuURL.split('.');
  const docuType = urlArray[urlArray.length - 1];
  let contentType = `application/${docuType}`;
  if (docuType === 'html') {
    contentType = 'text/html';
  }
  const blobResult = b64toBlob(b64File, contentType);
  const fileURL = URL.createObjectURL(blobResult);
  window.open(fileURL);
};

export function buildPayload(agreementSearchData: any, offset: any) {
  const {
    agreementNumber,
    lastName,
    firstName,
    storeNumber,
    agreementType,
    status,
    limit,
  } = agreementSearchData;

  if (agreementNumber) {
    return {
      agreementNumber,
      offset,
      limit: limit || null,
    };
  }

  return {
    lastName: lastName.trim() || null,
    firstName: firstName.trim() || null,
    storeNumber: storeNumber || null,
    agreementType: agreementType || null,
    status: status || null,
    offset,
    limit: limit || null,
  };
}

export function formatDate(dateString: any) {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export const getAgrTypeDisplay = (agreementType: string) => {
  switch (agreementType) {
    case 'Rental Agreement (RTO)':
      return 'Rental';
    case 'Rent to Rent - Loaner':
      return 'Loaner';
    case 'Installment Notes':
    case 'INSTALLMENT NOTES':
      return 'Installment Notes';
    case 'Promo':
      return 'Promo';
    default:
      return agreementType;
  }
};

export function formatePayment(total: any) {
  if (parseFloat(total) >= 0) {
    return '$' + parseFloat(total).toFixed(2);
  } else {
    return `($${parseFloat(total).toFixed(2)})`;
  }
}

export function getFormatedDropDown(dropDownData: any) {
  const dropdownResponse = [...dropDownData];
  const refCode = ['E', 'F', 'G', 'H', 'PLAF', 'PLAC', 'DNC', 'NFB'];
  const formatedData: dropdown[] = [];
  formatedData.push({ label: 'All', value: '' });
  dropdownResponse.map((data: any) => {
    if (refCode.includes(data.referenceCode)) {
      formatedData.push({
        label: getLegalHoldReasons(data.description),
        value: data.referenceCode,
      });
    }
  });
  return formatedData;
}

export const getLegalHoldReasons = (reasons: string) => {
  switch (reasons) {
    case 'Discharged Through Bankruptcy Chapter 7':
      return 'Bankruptcy Ch. 7';
    case 'Discharged Through Bankruptcy Chapter 11':
      return 'Bankruptcy Ch. 11';
    case 'Discharged Through Bankruptcy Chapter 12':
      return 'Bankruptcy Ch. 12';
    case 'Discharged/Completed Through Bankruptcy Chapter 13':
      return 'Bankruptcy Ch. 13';
    default:
      return reasons;
  }
};

export function ChangeDateFormat(inputDate: any) {
  const dateParts = inputDate.split('-');
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
}

export function validateAgrData(agreementSearchData: any) {
  const conditions: any[] = [];
  if (agreementSearchData?.storeNumber?.trim()) {
    conditions.push(
      (agreementSearchData?.storeNumber?.trim()?.length ?? 0) > 4
    );
  }

  if (agreementSearchData?.agreementType?.trim()) {
    conditions.push(agreementSearchData?.agreementType?.trim() !== '');
  }

  if (agreementSearchData?.status?.trim()) {
    conditions.push(agreementSearchData?.status?.trim() !== '');
  }

  if (agreementSearchData?.firstName?.trim()) {
    conditions.push((agreementSearchData?.firstName?.trim()?.length ?? 0) > 2);
  }

  if (agreementSearchData?.lastName?.trim()) {
    conditions.push((agreementSearchData?.lastName?.trim()?.length ?? 0) > 2);
  }
  return conditions.length > 0 && conditions.every((condition) => condition);
}

export function validateLegalData(legalHoldSearchObj: any) {
  const conditions: any[] = [];

  if (legalHoldSearchObj?.holdRefCode?.trim()) {
    conditions.push(legalHoldSearchObj?.holdRefCode?.trim() !== '');
  }

  if (legalHoldSearchObj.firstName?.trim()) {
    conditions.push((legalHoldSearchObj.firstName.trim()?.length ?? 0) > 2);
  }

  if (legalHoldSearchObj.lastName?.trim()) {
    conditions.push((legalHoldSearchObj.lastName?.trim()?.length ?? 0) > 2);
  }
  return conditions.length > 0 && conditions.every((condition) => condition);
}
