export function formatePhoneNumber(number: any) {
  if (number.length == 10) {
    number = number.replace(/(\d{1,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  } else {
    number = number.replace(/\D/g, '');
  }
  return number;
}

export function formLocationBody(responseBody: any) {
  if (!responseBody?.response) {
    return [];
  }

  return responseBody.response.map((item: any) => {
    return {
      storeId: item?.storeNumber || null,
      storeDesc: item?.storeName || null,
      address: item?.addressLine1
        ? item?.addressLine2
          ? `${item.addressLine1} ${item.addressLine2}`
          : item.addressLine1
        : null,
      city: item?.city || null,
      state: item?.stateAbb || null,
      zipcode: item?.fullZip || null,
      phone: item?.workPhoneNumber
        ? formatePhoneNumber(item?.workPhoneNumber)
        : null,
      country: item?.countryAbb || null,
      hostBrand: item?.hostBrand || null,
    };
  });
}

export function formPayload(searchPayload: any) {
  return {
    storeNumbers: searchPayload.storeId ? [searchPayload.storeId] : null,
    addressLine1: searchPayload.addressLine ? searchPayload.addressLine : null,
    stateId: searchPayload.state ? parseInt(searchPayload.state) : null,
    city: searchPayload.city ? searchPayload.city : null,
    phoneNumber: searchPayload.phoneNumber
      ? searchPayload.phoneNumber.replace(/\D/g, '')
      : null,
    zip: searchPayload.zipcode ? searchPayload.zipcode : null,
    status: searchPayload.status == '0' ? null : searchPayload.status,
    simsStore: searchPayload.simsStore == '0' ? null : searchPayload.simsStore,
    hostBrand: searchPayload.hostBrand == '' ? null : searchPayload.hostBrand,
    vendorName:
      searchPayload.vendorName == '' ? null : searchPayload.vendorName,
  };
}

export function removeDuplicatePayload(payload: any) {
  return Object.keys(payload).forEach(
    (key: any) => payload[key] === null && delete payload[key]
  );
}

export function formStoreDetails(storeDetails: any) {
  if (!storeDetails?.response) {
    return [];
  }
  storeDetails = storeDetails?.response[0];
  const regionDetails = getManagerDetails('regionalManager', storeDetails);
  const districtDetails = getManagerDetails('districtManager', storeDetails);
  const storeManagerDetails = getManagerDetails('storeManager', storeDetails);
  return {
    general: {
      storeId: storeDetails?.storeNumber,
      storeName: storeDetails?.storeName,
      status: storeDetails?.status,
      openedDate: storeDetails?.storeOpenDate,
      lineOfBusiness: storeDetails?.lob,
      posSystem: storeDetails?.posSystem,
      simsConversion: storeDetails?.simsConversion,
    },
    location: {
      address: storeDetails?.addressLine1
        ? storeDetails?.addressLine2
          ? `${storeDetails.addressLine1} ${storeDetails.addressLine2}`
          : storeDetails.addressLine1
        : null,
      coOrdinates: storeDetails?.latitude
        ? storeDetails?.longitude
          ? `${storeDetails.latitude}, ${storeDetails.longitude}`
          : storeDetails.latitude
        : null,
    },
    contact: {
      phone: formatePhoneNumber(storeDetails.workPhoneNumber),
    },
    hierarchy: {
      region: regionDetails.accountingUnitNumber,
      regionDirector: regionDetails.fullName,
      reginalDirectorEmail: regionDetails.emailAddress,
      district: districtDetails.accountingUnitNumber,
      districtManager: districtDetails.fullName,
      storeManager: storeManagerDetails.fullName,
    },
    storeHours: {
      monday: getWorkingHours(storeDetails?.calendar, 'MON'),
      tuesday: getWorkingHours(storeDetails?.calendar, 'TUE'),
      wednesday: getWorkingHours(storeDetails?.calendar, 'WED'),
      thursday: getWorkingHours(storeDetails?.calendar, 'THU'),
      friday: getWorkingHours(storeDetails?.calendar, 'FRI'),
      saturday: getWorkingHours(storeDetails?.calendar, 'SAT'),
      sunday: getWorkingHours(storeDetails?.calendar, 'SUN'),
    },
  };
}

const getFullName = (firstName: any, lastName: any) =>
  firstName && lastName ? `${firstName} ${lastName}` : firstName || null;

const getManagerDetails = (managerType: any, storeDetails: any) => {
  const manager = storeDetails?.[managerType]?.[0] || {};
  return {
    accountingUnitNumber: manager.accounting_unit_number || null,
    fullName: getFullName(
      manager.app_user_first_name,
      manager.app_user_last_name
    ),
    emailAddress: manager.email_address || null,
  };
};

function getWorkingHours(array: any, dayOfWeek: any) {
  const dayDetails = array.find((day: any) => day.dayOfWeek === dayOfWeek);
  if (dayDetails) {
    return getFormattedTimeRange(dayDetails.startTime, dayDetails.endTime);
  }
  return null;
}

const getFormattedTimeRange = (startTime: any, endTime: any) => {
  if (startTime === '00:00:00' && endTime === '00:00:00') {
    return 'Closed';
  }
  const formatTime = (time: any) => {
    const [hours, minutes] = time.split(':');
    const amOrPm = parseInt(hours) < 12 ? 'AM' : 'PM';
    const formattedHours = parseInt(hours) % 12 || 12;
    return `${formattedHours}:${minutes} ${amOrPm}`;
  };

  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};

export function navigateToStoreLocation(coOrdinates: any) {
  navigator.geolocation.getCurrentPosition((position) => {
    const originCoordinates = `${position.coords.latitude},${position.coords.longitude}`;
    if (coOrdinates) {
      const mapsURL = `https://www.google.com/maps/dir/?api=1&origin=${originCoordinates}&destination=${coOrdinates}`;
      window.open(mapsURL, '_blank');
    }
  });
}

export function getSearchValidation(searchPayload: any, validPhone: any) {
  let initialValidation =
    (searchPayload.storeId.trim().length > 0 &&
      searchPayload.storeId.trim().length == 5) ||
    (searchPayload.phoneNumber.trim().length > 0 && !validPhone) ||
    searchPayload.addressLine.trim() != '' ||
    (searchPayload.zipCode.trim().length > 0 &&
      searchPayload.zipCode.trim().length >= 5) ||
    searchPayload.city.trim() != '' ||
    searchPayload.state != '0' ||
    searchPayload.status != '0' ||
    searchPayload.simsStore != '0' ||
    searchPayload.vendorName != '' ||
    searchPayload.hostBrand != '';

  if (
    searchPayload.storeNumber &&
    searchPayload.storeNumber.trim().length < 5
  ) {
    initialValidation = false;
  }
  if (
    searchPayload.phoneNumber &&
    searchPayload.phoneNumber.trim().length < 10
  ) {
    initialValidation = false;
  }
  if (searchPayload.zipCode && searchPayload.zipCode.trim().length < 5) {
    initialValidation = false;
  }

  return initialValidation;
}

export const formatDate = (dateString: any) => {
  const [year, month, day] = dateString.split('/');

  return `${month}/${day}/${year}`;
};
