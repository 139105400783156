/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const GlobalAdminStyles = () => {
  const useClasses = makeStyles((theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(189, 189, 196)',
        borderRadius: '20px',
        border: '3px solid rgb(206, 204, 201)',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgb(245, 243, 239)',

      },
    },

    w90: {
      width: '90%'
    },

    w1: {
      width: '1.5px'
    },

    w15: {
      width: '15%'
    },

    mt20: {
      marginTop: '20px'
    },

    title: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },

    title1: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(13),
      marginBottom: theme.typography.pxToRem(10),
    },

    title2: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(20),
      marginBottom: theme.typography.pxToRem(10),
    },

    title3: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },

    title4: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontWeight: "bold",
      fontSize: '18px',
      marginBottom: theme.typography.pxToRem(10),
    },

    mR15: {
      marginRight: '15px'
    },

    mt35: {
      marginTop: theme.typography.pxToRem(35),
    },

    mt25: {
      marginTop: theme.typography.pxToRem(25),
    },

    mt10: {
      marginTop: '10px',
    },

    mt30: {
      marginTop: '30px',
    },

    mt40: {
      marginTop: '40px',
    },

    ml10: {
      marginLeft: '10px'
    },

    greyColor: {
      color: 'grey'
    },

    fs12: {
      fontSize: '12px'
    },

    h10: {
      height: '10px'
    },

    mt5: {
      marginTop: "5%",
    },

    mt2: {
      marginTop: "2%",
    },

    cardContent: {
      height: '530px',
      overflow: 'auto'
    },

    cardContentDefault: {
      height: '200px',
    },

    cardLoader: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: '200px'
    },

    confirmationPopup: {
      '& div': {
        '& div': {
          maxWidth: "550px",
          overflow: "hidden",
          paddingBottom: '10px'
        }
      }
    },

    confirmationPopup1: {
      '& div': {
        '& div': {
          maxWidth: "450px",
          overflow: "hidden",
          paddingBottom: '10px'
        }
      }
    },

    employeePopUp: {
      '& div': {
        '& div': {
          maxWidth: "350px",
          overflow: "hidden",
        }
      }
    },

    pinPopUp: {
      '& div': {
        '& div': {
          maxWidth: "290px",
          overflow: "hidden",
        }
      }
    },

    errorColor: {
      color: "red"
    },

    demo: {
      "& .dropdown-content": {
        "& .search": {
          border: "1px solid #e3e3e3",
        },
      },
      "& .options": {
        overflowX: "hidden !important",
      },
    },

    MultiSelect: {
      "& .dropdown-container": {
        "& div": {
          overflow: "hidden !important",
        },
        height: "31px",
      },
      "& .dropdown-heading": {
        height: "31px !important",
      },
      "& .dropdown-content": {
        borderRadius: "8px",
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
        zIndex: "99",
      },
    },

    p10: {
      padding: '10px'
    },

    cursorStyle: {
      cursor: "pointer"
    },

    textBox: {
      '& input': {
        padding: '10px'
      }
    },

    mb5: {
      marginBottom: '5px'
    },

    mb15: {
      marginBottom: '15px'
    },


    mt3: {
      marginTop: theme.typography.pxToRem(25),
    },





















    globalBackgroundColor: {
      backgroundColor: "#ecf0f3"
    },

    whitebackground: {
      backgroundColor: "white"
    },

    removeButtonStyle: {
      backgroundColor: '#f9c0c0',
      color: '#c72323',
      borderRadius: '3px',
      padding: '6%',
      borderColor: '#f9c0c0'
    },

    pageLoader: {
      marginLeft: "200px",
    },





    mt1: {
      marginTop: "5px",
    },

    mt2: {
      marginTop: theme.typography.pxToRem(10)
    },



    mt6: {
      marginTop: "14px"
    },

    mt7: {
      marginTop: "150px"
    },

    mt8: {
      marginTop: "15%"
    },

    mt9: {
      marginTop: theme.typography.pxToRem(8)
    },


    mt10: {
      marginTop: "30px"
    },

    mb0: {
      marginBottom: '0'
    },

    mb1: {
      marginBottom: "1px"
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(150),
    },

    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(10),
    },

    border: {
      borderBottom: "1px #ced4da solid"
    },

    mB1: {
      marginBottom: '35px'
    },

    mB2: {
      marginBottom: '25px'
    },

    mB3: {
      marginBottom: theme.typography.pxToRem(4)
    },
    mL0: {
      marginLeft: '5px'
    },

    mL1: {
      marginLeft: '10px'
    },

    mL2: {
      marginLeft: '50%'
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    mR1: {
      marginRight: '10px'
    },

    width8: {
      width: "8%"
    },

    width0: {
      width: "5%"
    },

    width1: {
      width: "10%"
    },

    width15: {
      width: "15%"
    },

    width2: {
      width: "20%"
    },

    width3: {
      width: "30%"
    },
    width4: {
      width: "70%"
    },
    width5: {
      width: "12.5%"
    },

    width6: {
      width: "20%"
    },

    width100: {
      width: "100%"
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    underline: {
      textDecoration: 'underline',
    },
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    subTitle: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(10),
    },

    centerAlign: {
      textAlign: 'center'
    },

    justifyCenter: {
      alignContent: 'center'
    },

    tabsWidth: {
      '& div': {
        width: '100%'
      }
    },



    card: {
      borderRadius: theme.typography.pxToRem(15),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%'
    },

    card1: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      height: '150px',
    },

    card2: {
      overflow: 'auto',
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      height: '150px',
    },

    card3: {
      overflow: 'auto',
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
      height: '565px',
    },

    height: {
      height: '500px'
    },

    height1: {
      height: '750px'
    },

    height2: {
      height: '100vh'
    },

    height3: {
      height: '400px'
    },


    imgHeight: {
      height: '17px'
    },

    textBlue: {
      color: `${RACCOLOR.NORMAL_BLUE}`
    },

    textDarkSlateBlue: {
      color: "#4A5174"
    },

    popUpPadding: {
      padding: "0% 4%"
    },

    padding0: {
      padding: "10px"
    },

    padding1: {
      padding: "1%"
    },

    padding2: {

      padding: "0%"
    },

    padding3: {
      paddingBottom: "0%"
    },

    padding4: {
      padding: "20px"
    },

    padding5: {
      padding: "15px"
    },

    padding6: {
      padding: '10px 25px'
    },

    padding7: {
      padding: ' 20px 13px 10px 0px'
    },

    pagePadding: {
      padding: "0 1% 0.4% 1%",
      width: '100%'
    },

    pagePadding1: {
      padding: "0 1% 1% 1%",
      paddingTop: theme.typography.pxToRem(24),
      width: '100%'
    },

    ptop: {
      paddingTop: '0px'
    },

    pt1: {
      padding: ' 10px 13px'
    },

    sort: {
      marginLeft: '10px',
      cursor: "pointer",
    },

    sortUp: {
      marginLeft: '10px',
      cursor: "pointer",
      transform: 'rotate(180deg)'
    },

    displayflex: {
      display: 'flex',
      paddingLeft: '10px',
    },

    borderLine: {
      backgroundColor: '#E3E3E3',
      height: '2px',
      width: '100%',
      borderRadius: '5px',
    },

    semiBoldFont: {
      fontFamily: 'OpenSans-semibold',
    },

    Bold: {
      fontWeight: "bold",
    },

    loaderModalCard: {
      '& div': {
        '& div': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
    },

    customModal2: {
      '& div': {
        '& div': {
          maxWidth: '650px',
          maxHeight: '850px'
        },
      },
    },

    customModal1: {
      '& div': {
        '& div': {
          maxWidth: '300px',
        },
      },
    },


    paymentPopup: {
      '& div': {
        '& div': {
          borderRadius: '20px',
          maxWidth: "620px",

        }
      }
    },

    floatLeft: {
      float: 'left',
    },

    floatRight: {
      float: 'right',
    },

    fixed: {
      position: 'fixed'
    },

    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '0.5rem',
    },

    formLabel: {
      marginBottom: '0.2rem',
      color: '#000',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },

    GridLoader: {
      textAlign: 'center',
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: '30px 0px',
    },

    textRight: {
      textAlign: 'right',
    },

    textEnd: {
      textAlign: 'end',
    },


    tooltipcss: {
      marginLeft: '4px',
      marginBottom: '-2px',
      top: '0',
      positon: 'absolute',
    },

    displayBlock: {
      display: 'block'
    },

    pagination: {
      display: "flex",
      listStyle: "none",
      textDecoration: "none",
      fontFamily: 'OpenSans-semibold',
      listStyleType: "none",
      fontSize: theme.typography.pxToRem(14),
      justifyContent: "flex-end",
      cursor: "pointer",
    },

    pageLink: {
      position: "relative",
      display: "block",
      textDecoration: "none",
      padding: "5px 0px",
      width: "26px",
      textAlign: "center",
    },

    paginationPrevNextDisabled: {
      color: "lightgray !important",
      padding: "5px 10px",
      display: "inline-block"
    },

    pageActive: {
      backgroundColor: "#2468FF",
      color: "#fff",
      borderRadius: "50%"
    },

    paginationGrid: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      padding: "20px"
    },

    paginationPrevNext: {
      color: "#000",
      padding: "5px 17px",
      display: "inline-block"
    },

    racpadLink: {
      color: '#2179FE',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
    },

    sticky: {
      position: 'sticky',
    },

    fixTableHeadAgrTrsRec: {
      overflowY: 'auto',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-bold',
        zIndex: 1,
        padding: '0.5rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },

      '& tbody': {
        '& tr': {
          position: 'sticky',
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
        },
      },

      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },

    },

    fixTableHeadAgrTrsRec1: {
      overflowY: 'hidden',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-bold',
        zIndex: 1,
        padding: '0.5rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },

      '& tbody': {
        '& tr': {
          position: 'sticky',
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
        },
      },

      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },

    }
  }));
  const classes = useClasses();
  return classes;
}
