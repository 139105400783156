/* eslint-disable */
import {
  Grid,
  Card,
  CardContent,
  RACTable,
  RACTableRow,
  RACTableCell,
  Typography,
  CircularProgress,
} from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatDate, sortDate } from '../../../../utils/InventoryUtils';
import { NoRecordsCard } from '../../GlobalComponents/NoRecordsCard';
import { getActivities } from '../../../../api/user';
import { ReactComponent as SortDesc } from '../../Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../../Icons/SortUp.svg';
import { useParams } from 'react-router-dom';

export function ActivitiesGrid(props?: any) {
  const viewPortHeight: any = window.innerHeight;
  const routePath: any = useParams();
  const InventoryNumber = routePath.inventoryNumber;
  const { classes, activities, setActivities } = useContext(
    InventoryDetailsContext
  );
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [sort, setSort] = useState<boolean>(true);
  const activityHeading = ['Date', 'Description', 'Reason'];
  const renderActivityHeading = () => (
    <>
      {activityHeading?.map((obj: any, index: any) => {
        if (obj == 'Date') {
          return (
            <RACTableCell key={index}>
              {obj}{' '}
              {activities?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  onClick={() => {
                    sortDate(activities, 'asc');
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              ) : (
                <SortAsc
                  className={`${classes.cursorStyle}`}
                  onClick={() => {
                    sortDate(activities, 'desc');
                    setSort(!sort);
                  }}
                />
              )}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const renderActivityHistory = () => (
    <>
      {activities?.map((obj: any, index: any) => {
        return (
          <RACTableRow className={`${classes.whitebackground}`} key={index}>
            <RACTableCell key={index} style={{ width: '10%' }}>
              <Typography className={`${classes.formValue}`}>
                {obj.activityDate ? formatDate(obj.activityDate) : '-'}
              </Typography>
            </RACTableCell>

            <RACTableCell key={index} style={{ width: '30%' }}>
              <Typography className={`${classes.formValue}`}>
                {obj.description ? obj.description : '-'}
              </Typography>
            </RACTableCell>

            <RACTableCell key={index} style={{ width: '50%' }}>
              {
                <Typography className={`${classes.formValue}`}>
                  {obj.reason ? obj.reason : '-'}
                </Typography>
              }
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const fetchNextData = async () => {
    try {
      setLoader(true);
      const activityResponse = await getActivities(
        InventoryNumber,
        props?.offset + 1
      );

      if (activityResponse && activityResponse.status == 200) {
        props?.offsetFunc(props?.offset + 1);
        if (activityResponse?.data?.activities?.length < 50) {
          setHasMore(false);
        }
        setActivities([...activities, ...activityResponse?.data?.activities]);
      }
      setLoader(false);
    } catch {
      return null;
    }
  };

  const noHistory = () => (
    <>
      <RACTableRow className={`${classes.noRecordHeight}`}>
        <RACTableCell colSpan={activityHeading.length}>
          <NoRecordsCard />
        </RACTableCell>
      </RACTableRow>
    </>
  );
  return (
    <>
      <Grid>
        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <InfiniteScroll
              next={fetchNextData}
              dataLength={activities?.length}
              hasMore={activities?.length == 0 ? false : hasMore}
              height={'auto'}
              loader={
                loader ? (
                  <Grid className={`${classes.infiniteLoader}`}>
                    <CircularProgress />
                  </Grid>
                ) : null
              }
            >
              <RACTable
                renderTableHead={renderActivityHeading}
                renderTableContent={
                  activities?.length == 0 ? noHistory : renderActivityHistory
                }
                stickyHeader={true}
                className={classes.textFixHead}
              />
            </InfiniteScroll>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
