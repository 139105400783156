import {
  CardContent,
  Grid,
  Card,
  Typography,
  makeStyles,
  CircularProgress,
} from '@rentacenter/racstrap';
import { ReactComponent as NoRecordIcon } from '../../../assets/images/No records.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/Error.svg';
import React, { useContext, useEffect, useState } from 'react';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';

const useClasses = makeStyles(() => ({
  centerAlign: {
    textAlign: 'center',
  },
  cardStyle: {
    height: '100%',
    width: '100%',
    borderRadius: '15px',
  },
  cardContentStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  height100: {
    height: '100%',
  },
  height12: {
    height: '12rem',
  },
  marginTop: {
    marginTop: '15px',
  },
}));

/**
 * props used so far: cardType
 * Default Card: Something Went Wrong
 * Date: 20-May-2024
 * Developer: Mohan Kumar.D
 */
export function DynamicCard(props?: any) {
  const classes = useClasses();
  const { resultWidget } = useContext(AppConfigurationContext);
  const [icon, setIcon] = useState<React.ReactNode>(<ErrorIcon />);
  const cardType: string = props?.cardType ? props.cardType : 'ERROR';
  const [message, setMessage] = useState<string>('Something went wrong');
  const inventoryIndicator = props?.value?.origin == 'Inventory' ? true : false;

  useEffect(() => {
    switch (cardType.toUpperCase()) {
      case 'NORECORD': {
        setIcon(<NoRecordIcon />);
        setMessage('No Records Found');
        break;
      }
      case 'BADREQUEST': {
        setIcon(<ErrorIcon />);
        setMessage('Bad Request');
        break;
      }
      case 'LOADER': {
        setIcon(<CircularProgress />);
        setMessage('');
        break;
      }
      case 'ERROR': {
        setIcon(<ErrorIcon />);
        setMessage('Something went wrong');
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultWidget]);

  return (
    <>
      <Grid className={classes.height12}>
        <Grid className={classes.height100}>
          <Card
            className={
              inventoryIndicator
                ? `${classes.cardStyle} ${classes.marginTop}`
                : `${classes.cardStyle}`
            }
          >
            <CardContent className={classes.cardContentStyle}>
              <Grid className={classes.centerAlign}>
                {icon}
                {cardType.toUpperCase() == 'LOADER' ? (
                  <></>
                ) : (
                  <Typography>{message}</Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
