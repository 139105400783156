import { Card, makeStyles, Typography } from '@rentacenter/racstrap';
import React from 'react';
import { CustomerList } from './CustomerList';

export const aleCustomerListTestId = 'aleCustomerListTestId';

const useClasses = makeStyles((theme: any) => ({
  aleListWrapper: {
    marginTop: '1rem',
    marginBottom: theme.typography.pxToRem(120),
  },
  customerList: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRadius: '1rem',
  },
  header: {
    ...theme.typography.h4,
    fontSize: '1.125rem',
    fontFamily: 'OpenSans-bold',
    marginBottom: '.5rem',
  },
  cardBody: {
    padding: '0 0.5rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  legentItemsContainer: {
    height: theme.typography.pxToRem(46 + 8),
    ...theme.typography.h4,
    fontSize: '1.125rem',
    fontFamily: 'OpenSans-bold',
    marginBottom: '.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const AleCustomerList = () => {
  const classes = useClasses();

  return (
    <div className={classes.aleListWrapper}>
      <Typography component="h4" className={classes.header}></Typography>
      <Card
        className={classes.customerList}
        data-testid={aleCustomerListTestId}
      >
        <div className={classes.cardBody}>
          <div className={classes.legentItemsContainer}>ALE CUSTOMER LIST</div>
          <CustomerList />
        </div>
      </Card>
    </div>
  );
};
