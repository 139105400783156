/* eslint-disable */

import { sanitizeURL } from '../utils/utils';

export interface ApiUrls {
  readonly container: string;
  readonly micro: string;
  readonly customers?: string;
  readonly agreement: string;
  readonly storemgmt: string;
  readonly menu: string;
  readonly inventory: string;
  readonly customer: string;
  readonly cashmgmt: string;
  readonly am: string;
  readonly payment: string;
  readonly tax: string;
  readonly legal: string;
  readonly dashboard: string;
  readonly pricing:string;
}

export function apiUrl() {
  const dev = 'https://dev-opsmgmt-racpad.rentacenter.com';
  return dev;
}

export interface AppConfigItem {
  readonly apiUrls: ApiUrls;
  readonly microUrl: string;
  readonly auth: {
    readonly region: string;
    readonly userPoolId: string;
    readonly userPoolWebClientId: string;
    readonly oauth: {
      readonly domain: string;
      readonly scope?: string[];
      readonly redirectSignIn: string;
      readonly redirectSignOut: string;
      readonly responseType: string;
      readonly urlOpener: (url: string) => void;
    };
  };
}
export interface AppConfig extends AppConfigItem {
  readonly appName: string;
}

export function appConfigURL(){
  try{
  const microUrl = {
    localhost: 'http://localhost:3006',
    local: 'https://local-opsmgmt-racpad.rentacenter.com',
    dev: 'https://dev-opsmgmt-racpad.rentacenter.com',
    qa: 'https://qa-opsmgmt-racpad.rentacenter.com',
    uat: 'https://uat-opsmgmt-racpad.rentacenter.com',
    prod: 'https://racpad-opsmgmt.rentacenter.com',
  };
  

  
   const urlOpener = (url: string): void => {
    try{
      const urlToOpen = sanitizeURL(url);
  
      if (urlToOpen) {
        window.open(urlToOpen, '_self');
      }
    }
    catch(e: any){
      console.log(e, 'Catch Error')
      console.log(e.message, 'Error Message');
    }
  };
  
   const localhost: AppConfigItem = {
    apiUrls: {
      container: 'https://local-racpad.rentacenter.com/api',
      micro: 'https://local-opsmgmt-racpad.rentacenter.com/api',
      customers: 'https://local-customers-racpad.rentacenter.com/api',
      agreement: 'https://local-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://local-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://local-menu-racpad.rentacenter.com/api',
      inventory: 'https://local-inventory-racpad.rentacenter.com/api',
      customer: 'https://local-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://local-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://local-am-racpad.rentacenter.com/api',
      payment: 'https://local-payment1-racpad.rentacenter.com/api',
      tax: 'https://local-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://local-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://local-dashboard-racpad.rentacenter.com/api',
      pricing:'https://local-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.localhost,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_FPWut7aB',
      userPoolWebClientId: '608s8dso4aecka8omjpukssq3n',
      oauth: {
        domain: 'auth-local-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.localhost,
        redirectSignOut: microUrl.localhost,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const localhostIE11: AppConfigItem = { ...localhost };
  
   const local: AppConfigItem = {
    apiUrls: {
      container: 'https://local-racpad.rentacenter.com/api',
      micro: 'https://local-opsmgmt-racpad.rentacenter.com/api',
      customers: 'https://local-customers-racpad.rentacenter.com/api',
      agreement: 'https://local-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://local-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://local-menu-racpad.rentacenter.com/api',
      inventory: 'https://local-inventory-racpad.rentacenter.com/api',
      customer: 'https://local-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://local-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://local-am-racpad.rentacenter.com/api',
      payment: 'https://local-payment1-racpad.rentacenter.com/api',
      tax: 'https://local-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://local-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://local-dashboard-racpad.rentacenter.com/api',
      pricing:'https://local-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.local,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_FPWut7aB',
      userPoolWebClientId: '608s8dso4aecka8omjpukssq3n',
      oauth: {
        domain: 'auth-local-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.local,
        redirectSignOut: microUrl.local,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const dev: AppConfigItem = {
    apiUrls: {
      container: 'https://dev-racpad.rentacenter.com/api',
      micro: 'https://dev-opsmgmt-racpad.rentacenter.com/api',
      customers: 'https://dev-customers-racpad.rentacenter.com/api',
      agreement: 'https://dev-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://dev-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://dev-menu-racpad.rentacenter.com/api',
      inventory: 'https://dev-inventory-racpad.rentacenter.com/api',
      customer: 'https://dev-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://dev-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://dev-am-racpad.rentacenter.com/api',
      payment: 'https://dev-payment1-racpad.rentacenter.com/api',
      tax: 'https://dev-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://dev-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://dev-dashboard-racpad.rentacenter.com/api',
      pricing:'https://dev-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.dev,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_8MbOyEYG6',
      userPoolWebClientId: '47r2ihk9fehcpn9t64thdbu2tl',
      oauth: {
        domain: 'auth-qa-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.dev,
        redirectSignOut: microUrl.dev,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const qa: AppConfigItem = {
    apiUrls: {
      container: 'https://qa-racpad.rentacenter.com/api',
      micro: 'https://qa-opsmgmt-racpad.rentacenter.com/api',
      customers: 'https://qa-customers-racpad.rentacenter.com/api',
      agreement: 'https://qa-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://qa-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://qa-menu-racpad.rentacenter.com/api',
      inventory: 'https://qa-inventory-racpad.rentacenter.com/api',
      customer: 'https://qa-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://qa-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://qa-am-racpad.rentacenter.com/api',
      payment: 'https://qa-payment1-racpad.rentacenter.com/api',
      tax: 'https://qa-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://qa-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://qa-dashboard-racpad.rentacenter.com/api',
      pricing:'https://qa-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.qa,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_8MbOyEYG6',
      userPoolWebClientId: '47r2ihk9fehcpn9t64thdbu2tl',
      oauth: {
        domain: 'auth-qa-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.qa,
        redirectSignOut: microUrl.qa,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const uat: AppConfigItem = {
    apiUrls: {
      container: 'https://uat-racpad.rentacenter.com/api',
      micro: 'https://uat-opsmgmt-racpad.rentacenter.com/api',
      customers: 'https://uat-customers-racpad.rentacenter.com/api',
      agreement: 'https://uat-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://uat-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://uat-menu-racpad.rentacenter.com/api',
      inventory: 'https://uat-inventory-racpad.rentacenter.com/api',
      customer: 'https://uat-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://uat-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://uat-am-racpad.rentacenter.com/api',
      payment: 'https://uat-payment1-racpad.rentacenter.com/api',
      tax: 'https://uat-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://uat-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://uat-dashboard-racpad.rentacenter.com/api',
      pricing:'https://uat-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.uat,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_FBmCthpda',
      userPoolWebClientId: '1d8vgr67fr7gli9abpv9j1cb3n',
      oauth: {
        domain: 'auth-uat-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.uat,
        redirectSignOut: microUrl.uat,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const prod: AppConfigItem = {
    apiUrls: {
      container: 'https://racpad.rentacenter.com/api',
      micro: 'https://racpad-opsmgmt.rentacenter.com/api',
      customers: 'https://racpad-customers.rentacenter.com/api',
      agreement: 'https://racpad-agreement-racpad.rentacenter.com/api',
      storemgmt: 'https://racpad-storemgmt-racpad.rentacenter.com/api',
      menu: 'https://racpad-menu-racpad.rentacenter.com/api',
      inventory: 'https://racpad-inventory-racpad.rentacenter.com/api',
      customer: 'https://racpad-customer-racpad.rentacenter.com/api',
      cashmgmt: 'https://racpad-cashmgmt-racpad.rentacenter.com/api',
      am: 'https://racpad-am-racpad.rentacenter.com/api',
      payment: 'https://racpad-payment1-racpad.rentacenter.com/api',
      tax: 'https://racpad-taxmgmt-racpad.rentacenter.com/api',
      legal: 'https://racpad-legalmgmt-racpad.rentacenter.com/api',
      dashboard: 'https://racpad-dashboard-racpad.rentacenter.com/api',
      pricing:'https://racpad-pricing-racpad.rentacenter.com/api'
    },
    microUrl: microUrl.prod,
    auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_so5RR57Of',
      userPoolWebClientId: '50nk6ibf5lru47hesk4j0cfuu4',
      oauth: {
        domain: 'auth-racpad.auth.us-east-1.amazoncognito.com',
        redirectSignIn: microUrl.prod,
        redirectSignOut: microUrl.prod,
        responseType: 'token',
        urlOpener,
      },
    },
  };
  
   const artifact: AppConfigItem = {
    apiUrls: {
      container: '_apiUrls.container',
      micro: '_apiUrls.micro',
      agreement: '_apiUrls.agreement',
      storemgmt: '_apiUrls.storemgmt',
      menu: '_apiUrls.menu',
      inventory: '_apiUrls.inventory',
      customer: '_apiUrls.customer',
      cashmgmt: '_apiUrls.cashmgmt',
      am: '_apiUrls.am',
      payment: '_apiUrls.payment1',
      tax: '_apiUrls.taxmgmt',
      legal: '_apiUrls.legalmgmt',
      dashboard: 'dashboard_app',
      pricing:'_apiUrls.pricing'
    },
    microUrl: '_microUrl',
    auth: {
      region: '_auth.region',
      userPoolId: '_auth.userPoolId',
      userPoolWebClientId: '_auth.userPoolWebClientId',
      oauth: {
        domain: '_auth.oauth.domain',
        redirectSignIn: '_auth.oauth.redirectSignIn',
        redirectSignOut: '_auth.oauth.redirectSignOut',
        responseType: '_auth.oauth.responseType',
        urlOpener,
      },
    },
  };

  let appConfigItem: AppConfigItem;
  
  switch (process.env.REACT_APP_STAGE) {
    case 'localhost':
      appConfigItem = localhost;
      break;
    case 'localhost-ie11':
      appConfigItem = localhostIE11;
      break;
    case 'local':
      appConfigItem = local;
      break;
    case 'dev':
      appConfigItem = dev;
      break;
    case 'qa':
      appConfigItem = qa;
      break;
    case 'uat':
      appConfigItem = uat;
      break;
    case 'prod':
      appConfigItem = prod;
      break;
    case 'artifact':
      appConfigItem = artifact;
      break;
    default:
      appConfigItem = local;
      break;
  }

  const appConfig: AppConfig = {
    appName: 'opsmgmt',
    ...appConfigItem,
  };

  return appConfig;


  }
  catch(e: any){
    console.log(e, 'Error');
    console.log(e.message, 'Error Message');
  }
}

export const appConfig: any = appConfigURL();
