/* eslint-disable prettier/prettier */
/* eslint-disable */
import { useContext } from 'react';
import { AdminContext } from './AdminContext';
import {
  Grid,
  RACButton,
  RACCOLOR,
  RACModalCard,
  Typography,
  makeStyles,
} from '@rentacenter/racstrap';
import PinField from 'react-pin-input';
import {
  fetchConfigReference,
  getCurrentUser,
  getPinDetails,
  getStoreDetails,
  getmenuandstore,
  manageMenu,
} from '../../api/user';
import { handleMenuConfig } from './commonFunction';
import clsx from 'clsx';

const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px',
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonMargin: {
    marginRight: '50px',
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  spinnerModal: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204 204 204 / 0%)',
    background: 'rgb(255 255 255 / 0%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  loaderContainer: {
    position: 'fixed',
    background: '#f7f5f5',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 2000,
    textAlign: 'center',
    margin: '0px 0px',
  },
  loaderContent: {
    display: 'block',
    position: 'fixed',
    zIndex: 9999999,
    top: '40%',
    right: '50%',
  },
}));

export function PinPopUp(props: any) {
  const {
    pinDisplay,
    setPinDisplay,
    setLoader,
    setPageDisplay,
    confirmationPopUp,
    setConfirmationPopUp,
    checkPin,
    setCheckPin,
    checkedRows,
    menuSearchData,
    setMenuSearchData,
    setFilterDisable,
    successDisplay,
    setSuccessDisplay,
    displaySearch,
    setDisplaySearch,
    setMenuSearchContent,
    referenceDetails,
    displaySearchResults,
    setDisplaySearchResults,
    setReferenceDetails,
    assignMenuPageDisplay,
    setAssignMenuPageDisplay,
    setFinalResponse,
    setAssignCheckedRows,
    setCheckedRows,
    finalResponse,
    deletedRows,
    setDeletedRows, disable, setDisable
  } = useContext(AdminContext);

  const classes = useClasses();

  async function validatePin() {
    setConfirmationPopUp({
      ...confirmationPopUp,
      isPinOpen: false,
    });
    setPageDisplay(0);
    setAssignMenuPageDisplay(0);
    setLoader(true);
    const userDetails: any = await getCurrentUser();
    const employeeId = userDetails?.data?.employeeId;
    const response: any = await getPinDetails({
      userId: employeeId,
      encryptedPin: checkPin,
    });

    if (response?.data?.statusCode == 200) {
      setPinDisplay(200)
      setConfirmationPopUp({
        ...confirmationPopUp,
        isPinOpen: false,
      });
      const payload =
        props?.value == 'AssignMenu' ? finalResponse : props?.isRemove ? deletedRows : checkedRows;
      const res: any = await manageMenu({
        menuRequest: payload,
      });

      if (props?.value == 'MenuConfig') {
        if (res?.status == 200 && (res?.data?.appRoleMenuId.length > 0 || res?.data?.menuAssociationId.length > 0)) {
          setConfirmationPopUp({
            ...confirmationPopUp,
            successPopUp: true,
            isPinOpen: false
          })
          setSuccessDisplay(200)
          await fetchUpdatedMenu(menuSearchData);
          (!menuSearchData.employeeId && !menuSearchData.emailId && menuSearchData.hierarchy != 'COUNTRY' && menuSearchData.hierarchy != 'LOB' && menuSearchData.hierarchy != 'ROLE')
            ? await fetchReferenceData(menuSearchData, 'N') : null;
          setLoader(false);
        }
        else {
          setLoader(false);
          setPageDisplay(500);
        }
      } else if (props?.value == 'AssignMenu') {
        setFinalResponse([]);
        setAssignCheckedRows([]);
        if (
          res?.status == 200 &&
          (res?.data?.appRoleMenuId.length > 0 ||
            res?.data?.menuAssociationId.length > 0)
        ) {
          setFinalResponse([]);
          setAssignCheckedRows([]);
          setSuccessDisplay(200);
          setAssignMenuPageDisplay(200);
          setLoader(false);
          setConfirmationPopUp({
            ...confirmationPopUp,
            successPopUp: true,
            isPinOpen: false
          });
        } else {
          setAssignMenuPageDisplay(200);
          setLoader(false);
          setConfirmationPopUp({
            ...confirmationPopUp,
            successPopUp: true,
            isPinOpen: false
          });
          setSuccessDisplay(400);
        }
      }
    } else {
      setDisable({ ...disable, continue: true })
      setConfirmationPopUp({
        ...confirmationPopUp,
        isPinOpen: true,
      });
      setAssignMenuPageDisplay(200);
      setLoader(false);
      setPinDisplay(400);
      setPageDisplay(200);
    }
  }

  async function fetchReferenceData(payload: any, menuRequired: string) {
    try {
      const body = {
        menuRequired: menuRequired,
        association: payload
          ? {
            associationCode: payload.hierarchyValue,
            associationType: payload.hierarchy,
          }
          : null,
      };
      if (payload?.hierarchy == 'STORE') {
        const storeData: any = await getStoreDetails({
          storeNumbers: [payload.hierarchyValue],
        });
        if (storeData?.status == 200 && storeData?.data?.response?.length > 0) {
          const storeResponse = storeData?.data?.response[0];
          setReferenceDetails({
            ...referenceDetails,
            associationCode: storeResponse.storeNumber,
            address:
              storeResponse.addressLine1 + (storeResponse.addressLine2 || ''),
            city: storeResponse.city,
            zipCode: storeResponse.fullZip,
            phone: storeResponse.workPhoneNumber,
            stateName: storeResponse.stateName,
          });
        }
      } else {
        setLoader(true);
        const headerData: any = await fetchConfigReference(body);
        setLoader(false);
        if (
          headerData?.status == 200 &&
          headerData?.data?.adminAssociation.length > 0
        ) {
          const referenceData = headerData?.data?.adminAssociation;
          setReferenceDetails({
            ...referenceDetails,
            associationCode: referenceData.associationCode,
            managerName: referenceData.managerName[0],
            noOfStores: referenceData.noOfStores,
            parentAssociationCode: referenceData.parentAssociationCode,
            countryRefCode: referenceData.countryRefCode,
            stateName: referenceData.stateName,
            companyType: referenceData.companyType,
            companyName: referenceData.companyName,
            lobCode: referenceData.lobCode,
          });
        }
      }
    } catch (e) {
      setLoader(false);
    }
  }

  async function fetchUpdatedMenu(payload: any) {
    try {
      setDisplaySearchResults(true);
      setDisplaySearch(false);
      setFilterDisable(false);
      setMenuSearchContent([]);
      setCheckedRows([]);
      setDeletedRows([]);
      const body = {
        coworkerId: payload.employeeId || null,
        emailId: payload.emailId || null,
        role: payload.hierarchy == 'ROLE' ? payload.hierarchyValue : null,
        menuRequest: ((payload.hierarchyValue || payload.userStore) && payload.hierarchy != 'ROLE') ? {
          association: {
            associationCode: payload.hierarchyValue || payload.userStore,
            associationType: payload.hierarchy || (payload.userStore ? 'STORE' : ''),
            parentAssociationCode: payload.parentHierarchyValue || null,
            parentAssociationType: payload.parentHierarchy || null
          }
        } : null,
        menuRequired: true,
        storesRequired: false
      }
      const fetchedData = await getmenuandstore(body);
      if (
        fetchedData?.status === 200 &&
        fetchedData?.data?.menuConfig.length !== 0 &&
        fetchedData?.data?.menuConfig?.code != 400
      ) {
        setPageDisplay(200);
        handleMenuConfig(
          fetchedData?.data?.menuConfig,
          menuSearchData,
          setMenuSearchContent
        );
      } else if (
        fetchedData?.status === 204 ||
        (fetchedData?.status === 200 &&
          fetchedData?.data?.menuConfig.length === 0)
      ) {
        setPageDisplay(204);
      } else if (fetchedData?.data?.menuConfig?.code == 400) {
        setPageDisplay(204);
      } else {
        setPageDisplay(500);
      }
      setLoader(false);
    } catch (e) {
      setPageDisplay(500);
      setLoader(false);
    }
  }

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={(e) => (e.key == 'Enter' && !disable.continue ? validatePin() : null)}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onChange={(e) => {
                setPinDisplay(200);
                if (e.length == 4) {
                  setDisable({ ...disable, continue: false })
                } else {
                  setDisable({ ...disable, continue: true })
                }
              }}
              onComplete={(e) => {
                setCheckPin(e)
              }}
            />
          </Grid>
          {pinDisplay == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={() => {
              setConfirmationPopUp({
                ...confirmationPopUp,
                isPinOpen: false,
              });
              setPinDisplay(200);
              props?.isRemove ? setDeletedRows([]) : null
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={disable.continue}
            onClick={() => {
              validatePin();
            }}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={props.isPinOpen}
        borderRadius="20px"
        closeIcon={true}
        onClose={() => {
          setConfirmationPopUp({
            ...confirmationPopUp,
            isPinOpen: false,
          });
          setPinDisplay(200);
          props?.isRemove ? setDeletedRows([]) : null
        }}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </>
  );
}
