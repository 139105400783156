import { Card, Grid, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatCurrency, formatDate } from '../../../../utils/InventoryUtils';
import { useHistory } from 'react-router-dom';

export function CurrentAgreements() {
  const history = useHistory();
  const { classes, currentAgreements } = useContext(InventoryDetailsContext);
  return (
    <>
      <Grid style={{ marginBottom: '10px' }}>
        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Typography variant="h5">Current Agreements</Typography>
        </Grid>

        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <Grid container md={12} className={`${classes.cardFirstRow}`}>
              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Agreement #
                </Typography>
                <Typography>
                  {currentAgreements.agreementNumber ? (
                    <a
                      className={classes.racpadLink}
                      onClick={() => {
                        history.push({
                          pathname: `/operations/agreement/details/0000/${currentAgreements?.agreementId}`,
                          search: `?agrType=${currentAgreements.agreementTypeRefCode.toLowerCase()}`,
                        });
                      }}
                    >
                      {currentAgreements.agreementNumber}
                    </a>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Customer
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.customerName
                    ? currentAgreements.customerName
                    : '-'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Open Date
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.openDate
                    ? formatDate(currentAgreements.openDate)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>Rate</Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.rate
                    ? formatCurrency(currentAgreements.rate)
                    : '$0.00'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>Term</Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.term ? currentAgreements.term : '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container md={12} className={`${classes.cardLastRow}`}>
              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Total RTO
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.totalRto
                    ? formatCurrency(currentAgreements.totalRto)
                    : '$0.00'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Total Revenue
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.totalRevenue
                    ? formatCurrency(currentAgreements.totalRevenue)
                    : '$0.00'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  MTD Revenue
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.mtdRevenue
                    ? formatCurrency(currentAgreements.mtdRevenue)
                    : '$0.00'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Agreement RV
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {currentAgreements.agreementRv
                    ? formatCurrency(currentAgreements.agreementRv)
                    : '$0.00'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
