import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACTable,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { LegalHoldContext } from './LegalHoldContext';
import ErrorContent from '../Agreement/ErrorMessageContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GetLegalCustomer } from '../../api/user';

export default function LegalHoldGrid(props: any) {
  const classes = GlobalStyles();
  const {
    displaySearch,
    pageDisplay,
    legalHoldGridContent,
    hasMore,
    loaders,
    setHasMore,
    setLoaders,
    offset,
    setOffset,
    setLegalHoldGridContent,
    legalHoldSearchObj,
  } = useContext(LegalHoldContext);

  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt3} />
  );

  function buildPayload(legalHoldSearchObj: any, offset: any) {
    const { lastName, firstName, limit, holdRefCode } = legalHoldSearchObj;
    return {
      lastName: lastName.trim() || null,
      firstName: firstName.trim() || null,
      holdRefCode: holdRefCode || null,
      offset,
      limit: limit || null,
    };
  }

  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setLoaders({ ...loaders, fetchDataLoader: true });
      const request = buildPayload(legalHoldSearchObj, offset);
      const fetchedData: any = await GetLegalCustomer(request);
      setOffset(offset + 20);
      if (fetchedData.status === 200) {
        setLoaders({ ...loaders, fetchDataLoader: false });
        setLegalHoldGridContent((pre: any) => [
          ...pre,
          ...fetchedData.data.legalholdCustomers,
        ]);
        if (fetchedData.data.legalholdCustomers?.length !== 20) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (e: any) {
      setLoaders({ ...loaders, fetchDataLoader: false });
      setHasMore(false);
    }
  };

  const renderTable = () => (
    <>
      {legalHoldGridContent?.length > 19 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={legalHoldGridContent?.length}
          hasMore={hasMore}
          height={'83vh'}
          loader={
            loaders.fetchDataLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={props.renderLegalHoldTableHeadFn}
            renderTableContent={props.renderLegalHoldTableContentFn}
          ></RACTable>
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={props.renderLegalHoldTableHeadFn}
          renderTableContent={props.renderLegalHoldTableContentFn}
        />
      )}
    </>
  );

  return (
    <Grid container md={12} className={displaySearch ? `${classes.mt5}` : ''}>
      <Card
        className={
          legalHoldGridContent.length > 10 && legalHoldGridContent.length < 20
            ? classes.card3
            : classes.card
        }
      >
        <CardContent>
          <Grid
            className={
              pageDisplay != 200
                ? classes.fixTableHeadAgrTrsRec1
                : classes.fixTableHeadAgrTrsRec
            }
          >
            {pageDisplay === 204
              ? renderErrorContent('No Records Found', 204)
              : pageDisplay === 200
              ? renderTable()
              : pageDisplay == 400
              ? renderErrorContent('Bad Request', 400)
              : pageDisplay == 500
              ? renderErrorContent('Something Went Wrong', 500)
              : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
