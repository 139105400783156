import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatCurrency, formatDate } from '../../../../utils/InventoryUtils';

export function RevenueAndService() {
  const { classes, revenueAndService } = useContext(InventoryDetailsContext);
  return (
    <>
      <Grid style={{ marginBottom: '20px' }}>
        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <Grid container md={12} className={`${classes.cardFirstRow}`}>
              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Revenue Collected
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.totalRevenue
                    ? formatCurrency(revenueAndService.totalRevenue)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  MTD Revenue
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.mtdRevenue
                    ? formatCurrency(revenueAndService.mtdRevenue)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Times On Rent
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.timesOnRent
                    ? revenueAndService.timesOnRent
                    : '0'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Times Transferred
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.timesTransferred
                    ? revenueAndService.timesTransferred
                    : '0'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography className={`${classes.formLabel}`}>
                  Times Missing from Inventory Audit
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.timesMissingFromAudit
                    ? revenueAndService.timesMissingFromAudit
                    : '0'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6" style={{ marginBottom: '5px' }}>
                Service
              </Typography>
            </Grid>
            <Grid container md={12} className={`${classes.cardRow}`}>
              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Last Status Date
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.lastStatusDate
                    ? formatDate(revenueAndService.lastStatusDate)
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Actual Status
                </Typography>
                <Typography
                  className={`${classes.formValue}`}
                  noWrap
                  title={
                    revenueAndService.actualStatus
                      ? revenueAndService.actualStatus
                      : ''
                  }
                >
                  {revenueAndService.actualStatus
                    ? revenueAndService.actualStatus
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Times on Service
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.timesOnService
                    ? revenueAndService.timesOnService
                    : '0'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Service Expense
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {revenueAndService.serviceExpense
                    ? formatCurrency(revenueAndService.serviceExpense)
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
