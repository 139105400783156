/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  CardContent,
  Card,
  CircularProgress,
  Divider,
  Grid,
  RACCheckBox,
  RACSelect,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
  RACToggle,
  RACButton,
  RACTextbox,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import {
  AssignHierarchyType,
  MenuPermissionsHeading,
  getParentHierarchyOptions,
} from '../../constants/constants';
import { AdminContext } from './AdminContext';
import ErrorContent from '../Agreement/ErrorMessageContent';
import { fetchOrganizationDetails } from '../../api/user';
import { MultiSelect } from 'react-multi-select-component';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import ConfirmationPopUp from './ConfirmationPopUp';
import { PinPopUp } from './PinPopUp';
import SuccessPopUp from './SuccessPopUp';
import EmployeePopUp from './EmployeePopUp';
import { booleanToString, formatString } from './commonFunction';

export default function AssignMenu() {
  const {
    confirmationPopUp,
    setConfirmationPopUp,
    setEmployeeId,
    loader,
    setDisplaySearchResults,
    assignHierarchy,
    setAssignHierarchy,
    setFinalResponse,
    assignMenuSearchData,
    setAssignMenuSearchData,
    disable,
    assignCheckedRows,
    setAssignCheckedRows,
    assignParentHierarchyType,
    setAssignParentHierarchyType,
    assignParentHierarchyValue,
    setAssignParentHierarchyValue,
    setDisable,
    setDropDownLoading,
    dropDownLoading,
    assignMenuContent,
    setDisplayAssignMenu,
    assignMenuPageDisplay,
    setAssignMenuContent,
    aSHierarchyValue,
    setAsHierarchyValue,
    setDisplaySearch,
    pageDisplay,
    dropDown,
    setMenuSearchData,
    setErrorMessage
  } = useContext(AdminContext);
  const classes = GlobalAdminStyles();

  const renderAssignMenuTableHeadFn = () => (
    <>
      {MenuPermissionsHeading.map((obj: any, index: any) => {
        if (
          obj?.heading !== 'Hierarchy' &&
          obj?.heading !== 'Hierarchy Value'
        ) {
          return (
            <>
              <RACTableCell key={index}>{obj?.heading}</RACTableCell>
            </>
          );
        }
      })}
    </>
  );

  const renderAssignMenuTableContentFn = () => (
    <>
      <RACTableRow>
        <RACTableCell colSpan={12}>
          <Divider orientation="horizontal" />
        </RACTableCell>
      </RACTableRow>
      {assignMenuContent.map((menuItem: any, i: number) => (
        <React.Fragment key={i}>
          <RACTableRow>
            <RACTableCell className={`${classes.title}`}>
              {menuItem.menuGrouping[0].appName}
            </RACTableCell>
          </RACTableRow>
          {menuItem.menuGrouping.map((menu: any, index: number) => (
            <React.Fragment key={`${i}-${index}`}>
              <RACTableRow>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appComponentName || '-'}
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appSubComponentName || '-'}
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appComponentFunctionName || '-'}
                </RACTableCell>
                <RACTableCell>
                  <RACCheckBox
                    checked={menu.view}
                    onChange={(e: any) => handleCheckBox(e, i, index, 'view')}
                    size="medium"
                  ></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACCheckBox
                    checked={menu.edit}
                    size="medium"
                    onChange={(e: any) => handleCheckBox(e, i, index, 'edit')}
                  ></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACCheckBox
                    checked={menu.approve}
                    onChange={(e: any) =>
                      handleCheckBox(e, i, index, 'approve')
                    }
                    size="medium"
                  ></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACToggle
                    checked={menu.active}
                    toggleColor={'#2279fd'}
                    OnChange={(e: any) => handleCheckBox(e, i, index, 'active')}
                  ></RACToggle>
                </RACTableCell>
              </RACTableRow>
            </React.Fragment>
          ))}
          <RACTableRow>
            <RACTableCell colSpan={12}>
              <Divider orientation="horizontal" />
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      ))}
    </>
  );

  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt40} />
  );

  const handleCheckBox = (e: any, i: any, index: any, key: any) => {
    const updatedMenuSearchContent = [...assignMenuContent];
    updatedMenuSearchContent[i].menuGrouping[index][key] = e.target.checked;

    let updatedRow: any = {};
    const row = updatedMenuSearchContent[i].menuGrouping[index];

    if (key === 'view') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].active = true;
      } else {
        updatedMenuSearchContent[i].menuGrouping[index].edit = false;
        updatedMenuSearchContent[i].menuGrouping[index].approve = false;
        updatedMenuSearchContent[i].menuGrouping[index].active = false;
      }
    }
    if (key === 'edit') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].view = true;
        updatedMenuSearchContent[i].menuGrouping[index].active = true;
      } else {
        updatedMenuSearchContent[i].menuGrouping[index].approve = false;
      }
    }
    if (key === 'active') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].view = true;
      } else {
        updatedMenuSearchContent[i].menuGrouping[index].view = false;
        updatedMenuSearchContent[i].menuGrouping[index].edit = false;
        updatedMenuSearchContent[i].menuGrouping[index].approve = false;
      }
    }
    if (key === 'approve') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].view = true;
        updatedMenuSearchContent[i].menuGrouping[index].edit = true;
        updatedMenuSearchContent[i].menuGrouping[index].active = true;
      }
      updatedRow = {
        appComponentFunction: row.appComponentFunctionName,
        view: e.target.checked ? '1' : booleanToString(row.view),
        edit: e.target.checked ? '1' : booleanToString(row.edit),
        active: e.target.checked ? '1' : booleanToString(row.active),
        approve: e.target.checked ? '1' : '0',
      };
    } else {
      updatedRow = {
        appComponentFunction: row.appComponentFunctionName,
        view:
          key === 'view'
            ? e.target.checked
              ? '1'
              : '0'
            : booleanToString(row.view),
        edit:
          key === 'edit'
            ? e.target.checked
              ? '1'
              : '0'
            : booleanToString(row.edit),
        active:
          key === 'active'
            ? e.target.checked
              ? '1'
              : '0'
            : booleanToString(row.active),
        approve: booleanToString(row.approve),
      };
    }

    const existingIndex = assignCheckedRows.findIndex(
      (row: any) => row.appComponentFunction === updatedRow.appComponentFunction
    );
    if (existingIndex !== -1) {
      if (
        Object.values(updatedRow)
          .slice(1)
          .some((value) => value !== '0')
      ) {
        assignCheckedRows[existingIndex] = updatedRow;
      } else {
        assignCheckedRows.splice(existingIndex, 1);
      }
    } else {
      if (
        Object.values(updatedRow)
          .slice(1)
          .some((value) => value !== '0')
      ) {
        assignCheckedRows.push(updatedRow);
      }
    }

    const updatedContent = [...updatedMenuSearchContent];
    setAssignMenuContent(updatedContent);

    setAssignCheckedRows([...assignCheckedRows]);
  };

  function constructManageMenu() {
    const payload: any = [];
    let array: any = [];
    assignMenuSearchData.hierarchy == 'USER'
      ? (array = assignMenuSearchData.hierarchyValue
        .split(',')
        .map((item: any) => item.trim())
        .filter(Boolean))
      : assignMenuSearchData.parentHierarchy
        ? (array = [assignMenuSearchData.hierarchyValue])
        : (array = assignMenuSearchData.hierarchyValue);
    assignCheckedRows.forEach((row: any) => {
      payload.push({
        ...row,
        employeeId: assignMenuSearchData.hierarchy == 'USER' ? array : null,
        role: assignMenuSearchData.hierarchy == 'ROLE' ? array : null,
        association:
          assignMenuSearchData.hierarchy !== 'USER' &&
            assignMenuSearchData.hierarchy !== 'ROLE'
            ? {
              associationCode: array,
              associationType: assignMenuSearchData.hierarchy,
              parentAssociationCode:
                assignMenuSearchData.parentHierarchyValue || null,
              parentAssociationType:
                assignMenuSearchData.parentHierarchy || null,
            }
            : null,
      });
    });
    setFinalResponse(payload);
  }

  async function dropDownSetter(
    hierarchy: any,
    parentHierarchy: any,
    value: any
  ) {
    const body = {
      type: parentHierarchy,
      filterType: hierarchy,
      filterValue: [value],
    };
    setDisable((prevDisable: any) => ({
      ...prevDisable,
      assignParentHierarchy: false
    }));
    setDropDownLoading((prevDisable: any) => ({
      ...prevDisable,
      aMParentHierarchyValueLoading: true,
    }));
    const response: any = await fetchOrganizationDetails(body);
    setDropDownLoading((prevDisable: any) => ({
      ...prevDisable,
      aMParentHierarchyValueLoading: false,
    }));
    if (
      response?.status == 200 &&
      response?.data?.result &&
      response?.data?.result?.length > 0
    ) {
      const data = response?.data?.result;
      const dataArray: any = [];
      dataArray.push({ label: 'Select', value: '' });
      data.map((item: any) => {
        dataArray.push({
          label: item?.referenceName,
          value: (item?.referenceValue).toString(),
        });
      });
      setAssignParentHierarchyValue(dataArray);
    } else {
      setAssignParentHierarchyValue([{ label: 'Select', value: '' }]);
    }
  }

  function saveDisable() {
    const hierarchyValueIsValid = (typeof assignMenuSearchData?.hierarchyValue === 'string')
      ? !!assignMenuSearchData?.hierarchyValue.trim()
      : !!assignMenuSearchData?.hierarchyValue?.length;
    if (assignMenuSearchData?.hierarchy === 'USER') {
      const userHierarchyValueIsValid = assignMenuSearchData?.hierarchyValue
        ?.split(',').some((val: any) => val?.trim());

      if (assignCheckedRows.length && userHierarchyValueIsValid) {
        return false;
      } else {
        return true;
      }
    }
    if (assignCheckedRows.length) {
      const hasParentHierarchy = !!assignMenuSearchData?.parentHierarchy;
      const hasParentHierarchyValue = !!assignMenuSearchData?.parentHierarchyValue;

      if (
        (hasParentHierarchy && hasParentHierarchyValue && hierarchyValueIsValid) ||
        (!hasParentHierarchy && !hasParentHierarchyValue && hierarchyValueIsValid)
      ) {
        return false;
      }
    }
    return true;
  }

  const handleSingleSelectChange = (selectedValue: any) => {
    const selectedOption = aSHierarchyValue.find((option: any) => option.value === selectedValue);
    if (selectedOption?.value != '') {
      setAssignHierarchy([selectedOption]);
      setAssignMenuSearchData((prevMenuSearchData: any) => ({
        ...prevMenuSearchData,
        hierarchyValue: [selectedValue]
      }));
    }else{
      setAssignHierarchy([]);
    }
  };

  return (
    <>
      <Grid container md={12} style={{ marginTop: '15px' }}>
        {' '}
        {assignMenuPageDisplay == 200 ? (
          <Grid>
            <Typography
              style={{
                marginLeft: '10px',
                marginBottom: '10px',
                fontFamily: 'OpenSans-bold',
                fontSize: '17px',
              }}
            >
              Assign Menu
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        <Card className={classes.card}>
          <CardContent>
            <Grid
              className={
                assignMenuPageDisplay != 200
                  ? classes.fixTableHeadAgrTrsRec1
                  : classes.fixTableHeadAgrTrsRec
              }
            >
              {loader ? (
                <Grid className={`${classes.cardLoader}`}>
                  <CircularProgress />
                </Grid>
              ) : null}
              {assignMenuPageDisplay === 204 ? (
                renderErrorContent('No Records Found', 204)
              ) : assignMenuPageDisplay === 200 ? (
                <>
                  <Grid
                    container
                    md={12}
                    spacing={2}
                    style={{
                      backgroundColor: '#eff4ff',
                      padding: '10px',
                      borderRadius: '10px',
                      margin: '1px',
                      marginBottom: '25px',
                    }}
                  >
                    <Grid container style={{ width: '20%' }}>
                      <Grid item style={{ width: '35%' }}>
                        <Typography>
                          Hierarchy <span style={{ color: 'red' }}>*</span> :
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: '55%' }}>
                        <RACSelect
                          options={AssignHierarchyType}
                          defaultValue={assignMenuSearchData?.hierarchy}
                          onChange={(e) => {
                            setAssignMenuSearchData(
                              (prevMenuSearchData: any) => ({
                                ...prevMenuSearchData,
                                hierarchy: e?.target?.value,
                                parentHierarchy: '',
                                parentHierarchyValue: '',
                                hierarchyValue: '',
                              })
                            );
                            setAssignHierarchy([]);
                            if (e?.target?.value !== '') {
                              setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                assignHierarchyValue: false,
                              }));
                              if (e?.target?.value != 'USER') {
                                switch (e?.target?.value) {
                                  case 'STORE':
                                    setAsHierarchyValue(dropDown.store);
                                    break;
                                  case 'DISTRICT':
                                    setAsHierarchyValue(dropDown.district);
                                    break;
                                  case 'REGION':
                                    setAsHierarchyValue(dropDown.region);
                                    break;
                                  case 'STATE':
                                    setAsHierarchyValue(dropDown.state);
                                    break;
                                  case 'COMPANY':
                                    setAsHierarchyValue(dropDown.company);
                                    break;
                                  case 'COUNTRY':
                                    setAsHierarchyValue(dropDown.country);
                                    break;
                                  case 'LOB':
                                    setAsHierarchyValue(dropDown.lob);
                                    break;
                                  case 'ROLE':
                                    setAsHierarchyValue(dropDown.role);
                                    break;
                                  default:
                                    setAsHierarchyValue([]);
                                }
                              }
                            } else {
                              setAssignMenuSearchData(
                                (prevMenuSearchData: any) => ({
                                  ...prevMenuSearchData,
                                  hierarchyValue: '',
                                  parentHierarchy: '',
                                  parentHierarchyValue: '',
                                })
                              );
                              setAssignHierarchy([]);
                              setAsHierarchyValue([]);
                              setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                employeeId: false,
                                emailId: false,
                                hierarchyValue: true,
                              }));
                            }
                            if (
                              e?.target?.value === 'STATE' ||
                              e?.target?.value === 'COUNTRY'
                            ) {
                              const getParentHierarchy: any =
                                getParentHierarchyOptions(e?.target?.value);
                              setAssignParentHierarchyType(getParentHierarchy);
                              setAssignParentHierarchyValue([
                                { label: 'Select', value: '' },
                              ]);
                              setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                assignParentHierarchy: false
                              }));
                            } else {
                              setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                assignParentHierarchy: true
                              }));
                            }
                          }}
                        ></RACSelect>
                      </Grid>
                    </Grid>
                    <Grid container style={{ width: '30%' }}>
                      <Grid item style={{ width: '35%', textAlign: 'center' }}>
                        <Typography>
                          {formatString(assignMenuSearchData?.hierarchy) ||
                            'Hierarchy Value'}{' '}
                          <span style={{ color: 'red' }}>*</span> :
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: '55%' }}>
                        {assignMenuSearchData?.hierarchy == 'USER' ? (
                          <Grid container style={{ width: '100%' }}>
                            <RACTextbox
                              disabled={disable.assignHierarchyValue}
                              value={assignMenuSearchData.hierarchyValue}
                              OnChange={(e: any) => {
                                const regex = /^[0-9,]+$/;
                                const replace = /[^0-9,]/g;
                                !regex.test(e.target.value)
                                  ? (e.target.value = e.target.value.replace(
                                    replace,
                                    ''
                                  ))
                                  : null;
                                setAssignMenuSearchData(
                                  (prevMenuSearchData: any) => ({
                                    ...prevMenuSearchData,
                                    hierarchyValue: e.target.value,
                                  })
                                );
                              }}
                              onKeyPress={(e) =>
                                e.key == 'Enter'
                                  ? setAssignMenuSearchData(
                                    (prevMenuSearchData: any) => ({
                                      ...prevMenuSearchData,
                                      hierarchyValue:
                                        assignMenuSearchData.hierarchyValue +
                                        ', ',
                                    })
                                  )
                                  : null
                              }
                            />
                          </Grid>
                        ) 
                        : !assignMenuSearchData?.parentHierarchy ? (
                          <MultiSelect
                            className={`${classes.demo} ${classes.MultiSelect}`}
                            onChange={(selectedOptions: any) => {
                              setAssignHierarchy(selectedOptions);
                              const selectedValues = selectedOptions.map(
                                (option: any) => option.value
                              );
                              setAssignMenuSearchData(
                                (prevMenuSearchData: any) => ({
                                  ...prevMenuSearchData,
                                  hierarchyValue: selectedValues,
                                })
                              );
                            }}
                            labelledBy={'Select'}
                            hasSelectAll={false}
                            value={assignHierarchy}
                            isLoading={dropDownLoading.aMHierarchyValueLoading}
                            options={aSHierarchyValue?.filter(
                              (option: any) => option.value !== ''
                            )}
                            disabled={disable.assignHierarchyValue}
                          />
                        ) 
                        : 
                        (
                          <RACSelect
                            defaultValue={assignMenuSearchData?.hierarchyValue}
                            isDisabled={disable.assignHierarchyValue}
                            options={aSHierarchyValue}
                            onChange={(e: any) => {
                              setAssignMenuSearchData(
                                (prevMenuSearchData: any) => ({
                                  ...prevMenuSearchData,
                                  hierarchyValue: e?.target?.value,
                                  parentHierarchyValue: '',
                                })
                              );
                              if (
                                (assignMenuSearchData?.hierarchy == 'STATE' && e?.target?.value) ||
                                (assignMenuSearchData?.hierarchy == 'COUNTRY' && e?.target?.value) ||
                                (typeof (assignMenuSearchData?.hierarchyValue) == 'string' && e?.target?.value)
                              ) {
                                const parentHierarchy =
                                  assignMenuSearchData?.hierarchy == 'STATE'
                                    ? 'COMPANY'
                                    : 'LOB';
                                dropDownSetter(
                                  assignMenuSearchData?.hierarchy,
                                  parentHierarchy,
                                  e?.target?.value
                                );
                              }
                            }}
                          ></RACSelect>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ width: '25%' }}>
                      <Grid item style={{ width: '35%' }}>
                        <Typography>Parent Hierarchy : </Typography>
                      </Grid>
                      <Grid item style={{ width: '45%', marginLeft: '3%' }}>
                        <RACSelect
                          defaultValue={assignMenuSearchData?.parentHierarchy}
                          isDisabled={disable.assignParentHierarchy}
                          options={assignParentHierarchyType}
                          onChange={(e) => {
                            setAssignMenuSearchData(
                              (prevMenuSearchData: any) => ({
                                ...prevMenuSearchData,
                                parentHierarchy: e?.target?.value,
                              })
                            );
                            if (e?.target?.value !== '' &&
                              (typeof (assignMenuSearchData?.hierarchyValue) == 'string' ? assignMenuSearchData?.hierarchyValue
                                : assignMenuSearchData?.hierarchyValue?.length)) {
                              setAssignMenuSearchData(
                                (prevMenuSearchData: any) => ({
                                  ...prevMenuSearchData,
                                  hierarchyValue: assignHierarchy.length ? assignHierarchy[0]?.value : '',
                                })
                              );
                              if (
                                (assignMenuSearchData?.hierarchyValue)
                              ) {
                                const parentHierarchy =
                                  assignMenuSearchData?.hierarchy == 'STATE'
                                    ? 'COMPANY'
                                    : 'LOB';
                                dropDownSetter(
                                  assignMenuSearchData?.hierarchy,
                                  parentHierarchy,
                                  assignHierarchy.length ? assignHierarchy[0]?.value : assignMenuSearchData?.hierarchyValue
                                );
                              }
                            } else {
                              handleSingleSelectChange(assignMenuSearchData?.hierarchyValue)
                              setAssignMenuSearchData(
                                (prevMenuSearchData: any) => ({
                                  ...prevMenuSearchData,
                                  parentHierarchyValue: '',
                                })
                              );
                              setAssignParentHierarchyValue([{ label: 'Select', value: '' }]);
                            }
                          }}
                        ></RACSelect>
                      </Grid>
                    </Grid>
                    <Grid container style={{ width: '25%' }}>
                      <Grid item style={{ width: '50%', textAlign: 'center' }}>
                        <Typography>
                          {formatString(
                            assignMenuSearchData?.parentHierarchy
                          ) || 'Parent Hierarchy Value'}{' '}
                          :{' '}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: '50%' }}>
                        <RACSelect
                          defaultValue={
                            assignMenuSearchData?.parentHierarchyValue
                          }
                          isDisabled={assignMenuSearchData.parentHierarchy&&assignMenuSearchData.hierarchy
                            &&(typeof(assignMenuSearchData?.hierarchyValue) == 'string' ? assignMenuSearchData?.hierarchyValue
                            : assignMenuSearchData?.hierarchyValue?.length) ? false : true}
                          loading={
                            dropDownLoading.aMParentHierarchyValueLoading
                          }
                          options={assignParentHierarchyValue}
                          onChange={(e: any) => {
                            setAssignMenuSearchData(
                              (prevMenuSearchData: any) => ({
                                ...prevMenuSearchData,
                                parentHierarchyValue: e?.target?.value,
                              })
                            );
                          }}
                        ></RACSelect>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.fixTableHeadAgrTrsRec}>
                    <RACTable
                      renderTableHead={renderAssignMenuTableHeadFn}
                      renderTableContent={renderAssignMenuTableContentFn}
                    />
                  </Grid>
                  {confirmationPopUp.isOpen ? (
                    <ConfirmationPopUp
                      isOpen={confirmationPopUp.isOpen}
                      isAssign={true}
                      isSave={false}
                    />
                  ) : null}
                  {confirmationPopUp.isPinOpen ? (
                    <PinPopUp
                      isPinOpen={confirmationPopUp.isPinOpen}
                      value={'AssignMenu'}
                    />
                  ) : null}
                  {confirmationPopUp.employeePopUp ? (
                    <EmployeePopUp isOpen={confirmationPopUp.employeePopUp} />
                  ) : null}
                  {confirmationPopUp.successPopUp ? (
                    <SuccessPopUp isOpen={confirmationPopUp.successPopUp} />
                  ) : null}
                </>
              ) : assignMenuPageDisplay == 400 ? (
                renderErrorContent('Bad Request', 400)
              ) : assignMenuPageDisplay == 500 ? (
                renderErrorContent('Something Went Wrong', 500)
              ) : (
                <></>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={12} className={classes.fixedBottom}>
        <RACButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setMenuSearchData((prevMenuSearchData: any) => ({
              ...prevMenuSearchData,
              employeeId: '',
              emailId: '',
              hierarchy: '',
              hierarchyValue: '',
              parentHierarchy: '',
              parentHierarchyValue: ''
            }));
            setDisable((prevDisable: any) => ({
              ...prevDisable,
              employeeId: false,
              emailId: false,
              hierarchy: false,
              hierarchyValue: true,
              parentHierarchy: true,
            }));
            setErrorMessage('')
            setDisplayAssignMenu(false);
            setDisplaySearch(true)(pageDisplay == 200 || pageDisplay == 204)
              ? setDisplaySearchResults(true)
              : setDisplaySearchResults(false);
          }}
        >
          Cancel
        </RACButton>
        <RACButton
          color="primary"
          variant="contained"
          size="small"
          className={`${classes.floatRight} ${classes.padding0}`}
          disabled={saveDisable()}
          onClick={() => {
            constructManageMenu();
            setConfirmationPopUp({
              ...confirmationPopUp,
              isOpen: true,
            });
          }}
        >
          Save
        </RACButton>
        {assignMenuSearchData?.hierarchy == 'USER' ? (
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.floatRight} ${classes.mR15} ${classes.padding0}`}
            onClick={() => {
              setConfirmationPopUp({
                ...confirmationPopUp,
                employeePopUp: true,
              });
              setEmployeeId('');
            }}
          >
            Copy User Menu
          </RACButton>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
