/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const InventoryStyles = () => {
    const useClasses = makeStyles((theme) => ({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '10px',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(189, 189, 196)',
                borderRadius: '20px',
                border: '3px solid rgb(206, 204, 201)',
            },
            '*::-webkit-scrollbar-track': {
                background: 'rgb(245, 243, 239)',
            },
        },

        iconSize:{
            height:'20px',
            cursor:'pointer'
        },
        paymentPopup: {
            '& div': {
                '& div': {
                    borderRadius: '20px',
                    maxWidth: "620px",

                }
            }
        },

        // formLabel: {
        //     marginBottom: '0.2rem',
        //     color: '#000',
        //     fontFamily: 'OpenSans-semibold',
        //     fontSize: theme.typography.pxToRem(14),
        // },
        alertCardContent: {
            '& div': {
                marginBottom: '1rem',
                justifyContent: "center"
            },
        },

        alertCard: {
            '& div': {
                '& div': {
                    maxWidth: "300px",
                    borderRadius: '18px'
                },

            }
        },
        textCenter: {
            textAlign: 'center'
        },

        w20: {
            width: '20%'
        },
        tabContentContainer: {
            padding: '0.8rem'
        },
        informationCard: {
            boxShadow: 'none',
            borderRadius: '10px',
            padding: '8px',

            '& .MuiCardContent-root': {
                paddingBottom: '8px'
            }
        },
        loaderBackground: {
            textAlign: 'center',
            '& div': {
                '& div': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            },
        },
        ms2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        fixedFooter: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.typography.pxToRem(16),
            backgroundColor: '#ffffff',
            boxShadow: '0 0 3px #d9d9d9',
            marginTop: '1%'
        },

        cardFirstRow: { marginBottom: '20px' },
        cardItems: { margin: '8px 0px' },
        cardLastRow: {
            marginBottom: '4px'
        },
        formLabel: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'OpenSans-semibold',
            letterSpacing: 'normal !important',
            marginBottom: '8px'
        },

        infoCard: {
            '& div': {
                // '& .MuiCardContent-root': {
                //     paddingBottom: '4px'
                // }
            }
        },

        formValue: {
            fontFamily: 'OpenSans-semibold',
            letterSpacing: 'normal !important',
            color: '#4A5174',
            fontSize: '14px',
            // marginTop: '4px'
        },
        // detailsTab: {
        //     '.MuiTab-textColorPrimary.Mui-selected': {
        //         color: 'red'
        //     }
        // },
        //LEgend

        legendSqure: {
            height: '20px',
            width: '20px',
            borderRadius: '5px',
        },

        legendActive: {
            backgroundColor: '#5AE286',
        },

        legendPastDue: {
            backgroundColor: '#FD6A63',
        },

        legendInactive: {
            backgroundColor: '#BBC1CE',
        },
        legendPending: {
            backgroundColor: 'Yellow'
        },

        borderLeftActive: {
            borderLeft: '4px solid #5AE286',
            borderRadius: '4px !important',
        },

        borderLeftPastDue: {
            borderLeft: '4px solid #FD6A63',
            borderRadius: '4px !important',
        },

        borderLeftInactive: {
            borderLeft: '4px solid #BBC1CE',
            borderRadius: '4px',

        },

        mr0: {
            marginRight: theme.typography.pxToRem(0),
        },
        mr1: {
            marginRight: theme.typography.pxToRem(4),
        },
        mr2: {
            marginRight: theme.typography.pxToRem(8),
        },
        mr3: {
            marginRight: theme.typography.pxToRem(16),
        },
        mr4: {
            marginRight: theme.typography.pxToRem(24),
        },
        mr5: {
            marginRight: theme.typography.pxToRem(48),
        },
        //==========================
        errorColor: {
            color: 'red',
        },

        globalBackgroundColor: {
            backgroundColor: '#ecf0f3',
        },

        whitebackground: {
            backgroundColor: 'white',
        },

        pageLoader: {
            marginLeft: '200px',
        },

        title: {
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight: 'bold',
            fontSize: theme.typography.pxToRem(20),
            marginBottom: theme.typography.pxToRem(10),
        },

        mt1: {
            marginTop: '5px',
        },

        mt2: {
            marginTop: theme.typography.pxToRem(10),
        },

        mt3: {
            marginTop: theme.typography.pxToRem(25),
        },

        mt5: {
            marginTop: theme.typography.pxToRem(48),
        },

        mt6: {
            marginTop: '14px',
        },

        mt7: {
            marginTop: '150px',
        },

        mt8: {
            marginTop: '15%',
        },

        mb1: {
            marginBottom: '1px',
        },

        mb2: {
            marginBottom: theme.typography.pxToRem(150),
        },

        mb3: {
            marginBottom: theme.typography.pxToRem(16),
        },

        mb4: {
            marginBottom: theme.typography.pxToRem(10),
        },

        border: {
            borderBottom: '1px #ced4da solid',
        },

        mB1: {
            marginBottom: '35px',
        },

        mB2: {
            marginBottom: '25px',
        },

        mB3: {
            marginBottom: theme.typography.pxToRem(4),
        },
        mL0: {
            marginLeft: '5px',
        },

        mL1: {
            marginLeft: '10px',
        },

        mL2: {
            marginLeft: '50%',
        },

        ms1: {
            marginLeft: theme.typography.pxToRem(4),
        },

        ms2: {
            marginLeft: theme.typography.pxToRem(8),
        },

        mR1: {
            marginRight: '10px',
        },

        width8: {
            width: '8%',
        },

        width0: {
            width: '5%',
        },

        width1: {
            width: '10%',
        },

        width15: {
            width: '15%',
        },

        width2: {
            width: '20%',
        },

        width3: {
            width: '30%',
        },
        width4: {
            width: '70%',
        },
        width5: {
            width: '12.5%',
        },
        width100: {
            width: '100%',
        },
        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },

        mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
        },
        subTitle: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(14),
            marginBottom: theme.typography.pxToRem(10),
        },

        centerAlign: {
            textAlign: 'center',
        },

        justifyCenter: {
            alignContent: 'center',
        },
        tabstest: {

            '& .Mui-selected': {
                '& .MuiTab-wrapper': {
                    color: '#0d6efd'
                },
            },

            '& div': {
                // width: '100%',

                '& .MuiTabs-flexContainer': {
                    backgroundColor: 'white'
                },


            },
            '& #full-width-tabpanel-0': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-1': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-2': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-3': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-4': {
                width: '100%',
                padding: '1rem'
            },

        },

        tabsWidth: {
            backgroundColor: 'white',
            // marginTop: theme.typography.pxToRem(10),
            '& div': {

                width: '100%',

            },

            '& .Mui-selected': {
                '& .MuiTab-wrapper': {
                    color: '#0d6efd'
                },
            }
        },

        cursorStyle: {
            cursor: 'pointer',
        },

        card: {
            borderRadius: theme.typography.pxToRem(15),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },

        card1: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
            height: '150px',
        },

        card2: {
            overflow: 'auto',
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
            height: '150px',
        },

        height: {
            height: '500px',
        },

        height1: {
            height: '750px',
        },

        height2: {
            height: '100vh',
        },

        height3: {
            height: '400px',
        },

        imgHeight: {
            height: '17px',
        },

        textBlue: {
            color: `${RACCOLOR.NORMAL_BLUE}`,
        },

        textDarkSlateBlue: {
            color: 'darkslateblue',
        },

        popUpPadding: {
            padding: '0% 4%',
        },

        padding1: {
            padding: '1%',
        },

        padding2: {
            padding: '0%',
        },

        padding3: {
            paddingBottom: '0%',
        },

        padding4: {
            padding: '20px',
        },

        padding5: {
            padding: '15px',
        },

        pagePadding: {
            padding: '0 1% 1% 1%',
            width: '100%',
        },

        sort: {
            marginLeft: '10px',
            cursor: 'pointer',
        },

        sortUp: {
            marginLeft: '10px',
            cursor: 'pointer',
            transform: 'rotate(180deg)',
        },

        semiBoldFont: {
            fontFamily: 'OpenSans-semibold',
        },

        Bold: {
            fontWeight: 'bold',
        },
        tabContainer: {
            '& .MuiTabs-flexContainer': {
                backgroundColor: 'white'
            }
        },

        loaderModalCard: {
            '& div': {
                '& div': {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
            },
        },

        customModal2: {
            '& div': {
                '& div': {
                    maxWidth: '650px',
                    maxHeight: '850px',
                },
            },
        },

        customModal1: {
            '& div': {
                '& div': {
                    maxWidth: '300px',
                },
            },
        },
        ahActive: {
            borderColor: '#5AE286 !important',
        },

        ahInactive: {
            borderColor: '#BBC1CE !important',
        },

        ahDue: {
            borderColor: '#FD6A63 !important',
        },
        ahPending: {
            borderColor: 'yellow !important'
        },
        racpadAgrLink: {
            borderLeft: '3px solid transparent',
            paddingLeft: '10px',
            borderRadius: '2px'
        },
        statusBadge: {
            padding: '1px 8px',
            marginLeft: '8px',
            color: 'white',
            borderRadius: '10px',
            fontSize: '12px'
        },
        dflex: {
            display: 'flex',
        },
        fontSemiBold: {
            fontFamily: 'OpenSans-semibold',
        },
        textMildGrey: {
            color: '#6c757d',
        },
        statusGreen: {
            backgroundColor: '#03c03c'
        },
        bgAsh: {
            backgroundColor: '#c8d1d3',
        },
        statusWarning: {
            backgroundColor: '#d68b00'
        },
        statusInfo: {
            backgroundColor: '#0bbab6'
        },
        floatLeft: {
            float: 'left',
        },

        floatRight: {
            float: 'right',
        },

        fixed: {
            position: 'fixed',
        },

        fixedBottom: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '0.5rem',
        },



        GridLoader: {
            textAlign: 'center',
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: '30px 0px',
        },

        textRight: {
            textAlign: 'right',
        },

        tooltipcss: {
            marginLeft: '4px',
            marginBottom: '-2px',
            top: '0',
            positon: 'absolute',
        },

        displayBlock: {
            display: 'block',
        },

        pagination: {
            display: 'flex',
            listStyle: 'none',
            textDecoration: 'none',
            fontFamily: 'OpenSans-semibold',
            listStyleType: 'none',
            fontSize: theme.typography.pxToRem(14),
            justifyContent: 'flex-end',
            cursor: 'pointer',
        },

        pageLink: {
            position: 'relative',
            display: 'block',
            textDecoration: 'none',
            padding: '5px 0px',
            width: '26px',
            textAlign: 'center',
        },

        paginationPrevNextDisabled: {
            color: 'lightgray !important',
            padding: '5px 10px',
            display: 'inline-block',
        },

        pageActive: {
            backgroundColor: '#2468FF',
            color: '#fff',
            borderRadius: '50%',
        },

        paginationGrid: {
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            padding: '20px',
        },
        pr0: {
            paddingRight: theme.typography.pxToRem(0),
        },
        pr1: {
            paddingRight: theme.typography.pxToRem(4),
        },
        pr2: {
            paddingRight: theme.typography.pxToRem(8),
        },
        pr3: {
            paddingRight: theme.typography.pxToRem(16),
        },
        pr4: {
            paddingRight: theme.typography.pxToRem(24),
        },
        pr5: {
            paddingRight: theme.typography.pxToRem(48),
        },
        prAuto: {
            paddingRight: 'auto',
        },

        pl0: {
            paddingLeft: theme.typography.pxToRem(0),
        },
        pl1: {
            paddingLeft: theme.typography.pxToRem(4),
        },
        pl2: {
            paddingLeft: theme.typography.pxToRem(8),
        },
        pl3: {
            paddingLeft: theme.typography.pxToRem(16),
        },
        pl4: {
            paddingLeft: theme.typography.pxToRem(24),
        },
        pl5: {
            paddingLeft: theme.typography.pxToRem(48),
        },
        plAuto: {
            paddingLeft: 'auto',
        },

        pt0: {
            paddingTop: theme.typography.pxToRem(0),
        },
        pt1: {
            paddingTop: theme.typography.pxToRem(4),
        },
        pt2: {
            paddingTop: theme.typography.pxToRem(8),
        },
        pt3: {
            paddingTop: theme.typography.pxToRem(16),
        },
        pt4: {
            paddingTop: theme.typography.pxToRem(24),
        },
        pt5: {
            paddingTop: theme.typography.pxToRem(48),
        },
        ptAuto: {
            paddingTop: 'auto',
        },

        pb0: {
            paddingBottom: 'theme.typography.pxToRem(0)',
        },
        pb1: {
            paddingBottom: theme.typography.pxToRem(4),
        },
        pb2: {
            paddingBottom: theme.typography.pxToRem(8),
        },
        pb3: {
            paddingBottom: theme.typography.pxToRem(16),
        },
        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },
        pb5: {
            paddingBottom: theme.typography.pxToRem(48),
        },
        pbAuto: {
            paddingBottom: 'auto',
        },
        p0: {
            padding: '0 !important',
        },
        p1: {
            padding: theme.typography.pxToRem(4),
        },
        p2: {
            padding: theme.typography.pxToRem(8),
        },
        p3: {
            padding: theme.typography.pxToRem(16),
        },
        p4: {
            padding: theme.typography.pxToRem(24),
        },
        p5: {
            padding: theme.typography.pxToRem(48),
        },
        pAuto: {
            padding: 'auto',
        },
        ml0: {
            marginLeft: theme.typography.pxToRem(0),
        },
        ml1: {
            marginLeft: theme.typography.pxToRem(4),
        },
        ml2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        ml3: {
            marginLeft: theme.typography.pxToRem(16),
        },
        ml4: {
            marginLeft: theme.typography.pxToRem(24),
        },
        ml5: {
            marginLeft: theme.typography.pxToRem(48),
        },
        mlAuto: {
            marginLeft: 'auto',
        },

        mt0: {
            marginTop: theme.typography.pxToRem(0),
        },
        mt1: {
            marginTop: theme.typography.pxToRem(4),
        },
        mt2: {
            marginTop: theme.typography.pxToRem(8),
        },
        mt3: {
            marginTop: theme.typography.pxToRem(16),
        },
        mt4: {
            marginTop: theme.typography.pxToRem(24),
        },
        mt5: {
            marginTop: theme.typography.pxToRem(48),
        },
        mtAuto: {
            marginTop: 'auto',
        },
        widthAuto: {
            width: 'auto',
        },

        paginationPrevNext: {
            color: '#000',
            padding: '5px 17px',
            display: 'inline-block',
        },

        racpadLink: {
            color: '#2179FE',
            fontSize: theme.typography.pxToRem(14),
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
        },

        sticky: {
            position: 'sticky',
        },

        textFixHead: {
            '& th': {

                // top: 0,
                zIndex: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-bold',
                padding: '0.5rem 0.2rem',
                fontSize: '15px',
                fontWeight: '400',
                color: '#111111',
            },

            '& tbody': {
                '& tr': {

                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                },
            },
        },

        fixTableHeadAgrTrsRec: {
            '& th': {
                top: 0,
                zIndex: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-bold',
                padding: '0.5rem 0.2rem',
                fontSize: '15px',
                fontWeight: '400',
                color: '#111111',
            },
            '& tbody': {
                '& tr': {

                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                },
            },
        },

        InfiniteGrid: {
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '16px',
            color: 'black',
            margin: '30px 0px'
        },

        cardBorder:{
            borderRadius:'15px'
        },
        marginLastElement:{
            marginBottom:'80px'
        },
        marginCardTop: {
            marginTop: '1rem'
        },
        infiniteLoader: {
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '16px',
            color: 'black',
            margin: '30px 0px',
        },
        noRecordHeight: {
            height: '50px !important'
        },
        headerCard:{
            padding: '0px',
          borderRadius: '0px !important',
          boxShadow: 'none',
        },
        legendGrid:{
            justifyContent: 'flex-end',
        alignItems: 'end',
        display: 'flex',
        },
        searchCard: {
            borderRadius: '15px',
            width: '100%',
            marginTop: theme.typography.pxToRem(8),
            height: 'auto'
          },
    }));
    return useClasses();
};
