import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import { customerDetailsStyle } from './../customerStyles';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import React from 'react';
import { Loader } from '../CommonCompo/Loader';

export default function ReferenceTab(props: any) {
  const classes: any = customerDetailsStyle();

  const referenceHeading: tableHeadings[] = [
    { heading: 'Status' },
    { heading: 'Relation' },
    { heading: 'Name' },
    { heading: 'Do Not Call' },
    { heading: 'Phone #' },
    { heading: 'Time To Call' },
  ];

  const referenceTableBody = props.value.tableBodyOne;

  const referanceTabContent = () => (
    <>
      {referenceTableBody.map((obj: any, index: number) => {
        return (
          <RACTableRow style={{ backgroundColor: 'white' }} key={index}>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.active == 'Y' ? 'Active' : 'In Active'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.relationshipTypeDesc ? obj.relationshipTypeDesc : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.firstName
                ? obj.lastName
                  ? obj.firstName + ' ' + obj.lastName
                  : obj.firstName
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.doNotCall == 'Y' ? 'Yes' : 'No'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.phoneNumber
                ? `(${obj.phoneNumber.slice(0, 3)}) ${obj.phoneNumber.slice(
                    3,
                    6
                  )}-${obj.phoneNumber.slice(6)}`
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '10%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.bestTimeToCallDesc ? obj.bestTimeToCallDesc : '-'}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const referanceTabHeading = () => (
    <>
      {referenceHeading.map((obj: any, index: any) => {
        return (
          <RACTableCell key={index} className={classes.bold}>
            {obj.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01} ${classes.semiBold}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid container md={12}>
              {props.value.status == 200 ? (
                <RACTable
                  renderTableHead={referanceTabHeading}
                  renderTableContent={referanceTabContent}
                  stickyHeader
                  className={classes.fixTableHeadAgrTrsRec}
                />
              ) : props.value.status == 204 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <NoRecordsFound />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    No Records Found
                  </Typography>
                </Grid>
              ) : props.value.status == 400 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Bad Request
                  </Typography>
                </Grid>
              ) : (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Something Went Wrong
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
