/* eslint-disable */
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACTable,
  RACTableCell,
  Typography,
} from '@rentacenter/racstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useContext, useEffect, useState } from 'react';
import { AppConfigurationStyles } from '../../../JsStyles/AppConfigurationStyles';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';
import { AccordionItem } from './AccordionItem';
import { DEFAULT_RULE_LIMIT } from '../../../constants/constants';
import { getOrgRulesSummary } from '../../../api/user';
import { ReactComponent as OverallClosed } from '../../../assets/images/all Accordion.svg';
import { ReactComponent as OverallOpened } from '../../../assets/images/Down all accordions.svg';
import moment from 'moment';

export function ResultWidget() {
  const classes = AppConfigurationStyles();
  const {
    organizationRules,
    request,
    viewMode,
    setRequest,
    setOrganizationRules,
    accordionCount,
    setAccordionCount,
    setResultWidget,
    coreUser
  } = useContext(AppConfigurationContext);
  const [orgRule, setOrgRule] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [closeGlobal, setCloseGlobal] = useState<boolean>(false);
  const [openGlobal, setOpenGlobal] = useState<boolean>(false);
  const header: string[] = [
    'Category',
    'Group',
    'Rule Name',
    'Hierarchy Type',
    'Hierarchy Value',
    'Business Value',
    'Action',
  ];

  const splitFeatureFlags = () => {
    let featureCategories: any[] = [];
    let configCategory: any[] = [];
    let displayRules:any[]=[];
    let responseRules = JSON.parse(JSON.stringify(organizationRules));
    const currentDate=moment().format('YYYY-MM-DD');

    responseRules?.forEach((masterCategory: any, masterIndex: number) => {
      masterCategory.paramCategoryDetails.forEach(
        (category: any, categoryindex: number) => {

          const featureRules = category.ruleDetails.filter(
            (rule: any) =>
              moment(rule.startDate).format('YYYY-MM-DD') <= currentDate &&
              rule.paramKeyType === 'FEATURE_FLAG'
          );

          const configRules = category.ruleDetails.filter(
            (rule: any) =>
              moment(rule.startDate).format('YYYY-MM-DD') <= currentDate &&
              rule.paramKeyType === 'CONFIGURATION' 
          );
          
          // Remove filtered elements from the original array if needed
          const businessRules = category.ruleDetails.filter(
            (rule: any) =>
              moment(rule.startDate).format('YYYY-MM-DD') <= currentDate &&
              rule.paramKeyType != 'FEATURE_FLAG' &&
              rule.paramKeyType != 'CONFIGURATION'
          );
          
          if (featureRules.length) {
            
            featureCategories.push({
              categoryDisplayName: category.categoryDisplayName,
              paramCategoryName: category.paramCategoryName,
              ruleDetails: featureRules,
            });

          }

          if (configRules.length) {
            configCategory.push({
              categoryDisplayName: category.categoryDisplayName,
              paramCategoryName: category.paramCategoryName,
              ruleDetails: configRules,
            });
          }

          if(businessRules.length){
            
            let master= displayRules.find((masterRule:any)=>masterRule.paramCategoryMasterName==masterCategory.paramCategoryMasterName);
            const categoryDetails={
              categoryDisplayName: category.categoryDisplayName,
              paramCategoryName: category.paramCategoryName,
              ruleDetails: businessRules,
            };
            if(master){
              master.paramCategoryDetails.push(categoryDetails);
            }
            else{
              displayRules.push({
                paramCategoryMasterName: masterCategory.paramCategoryMasterName,
                categoryMasterDisplayName: masterCategory.categoryMasterDisplayName,
                paramCategoryDetails:[categoryDetails]
              })
            }
            
          }
        }
      );
    });

    if (configCategory.length && coreUser) {
      
      const existingRule = displayRules.find((categories:any) => categories.paramCategoryMasterName === 'Configurations');
      
      if (existingRule) {
        const existingConfig = existingRule.paramCategoryDetails;
        configCategory = [...configCategory, ...existingConfig];
        displayRules = displayRules.filter((categories:any) => categories.paramCategoryMasterName !== 'Configurations');
      }
    
      displayRules.push({
        paramCategoryMasterName: 'Configurations',
        categoryMasterDisplayName: 'Configurations',
        paramCategoryDetails: configCategory,
      });
    }
     
    if (featureCategories.length && coreUser) {
      const existingRule = displayRules.find((categories:any) => categories.paramCategoryMasterName === 'Feature');
    
      if (existingRule) {
        const existingFeatures = existingRule.paramCategoryDetails;
        featureCategories = [...featureCategories, ...existingFeatures];
        displayRules = displayRules.filter((categories:any) => categories.paramCategoryMasterName !== 'Feature');
      }
    
      displayRules.push({
        paramCategoryMasterName: 'Feature',
        categoryMasterDisplayName: 'Feature',
        paramCategoryDetails: featureCategories,
      });
    }
    
    setOrgRule(displayRules);
    
    if(!displayRules.length){
      setResultWidget(204);
    }
    // responseRules.sort((a: any, b: any) => {
    //   const nameA = a.categoryMasterDisplayName.toLowerCase();
    //   const nameB = b.categoryMasterDisplayName.toLowerCase();

    //   if (nameA < nameB) return -1;
    //   if (nameA > nameB) return 1;
    //   return 0;
    // });
  };

  useEffect(() => {
    splitFeatureFlags();
    if (accordionCount == 0) {
      setCloseGlobal(true);
      setOpenGlobal(false);
    } else if (accordionCount == orgRule.length) {
      setOpenGlobal(true);
      setCloseGlobal(false);
    }
  }, [organizationRules, accordionCount]);

  const fetchNextData = async () => {
    try {
      if (orgRule.length >= DEFAULT_RULE_LIMIT||(!coreUser && orgRule.length>=DEFAULT_RULE_LIMIT-2)) {
        setLoader(true);
        const payload = { ...request };
        payload.limit = request.limit;
        payload.offset = request.offset + DEFAULT_RULE_LIMIT;
        setRequest(payload);
        const infiniteResponse = await getOrgRulesSummary(payload);

        if (
          infiniteResponse &&
          infiniteResponse.status == 200 &&
          infiniteResponse?.data?.organizationRule
        ) {
          if (
            infiniteResponse.data.organizationRule.length < DEFAULT_RULE_LIMIT
          ) {
            setHasMore(false);
          };
          setOrganizationRules([
            ...organizationRules,
            ...infiniteResponse.data.organizationRule,
          ]);
        }
        setLoader(false);
      }
    } catch {
      return null;
    }
  };

  const renderResultHeader = () => (
    <>
      {header.map((heading: string, index: number) => (
        <RACTableCell
        key={index}
          style={{
            backgroundColor: 'white',
            width:
              heading == 'Business Value'
                ? '10%'
                : heading == 'Rule Name'
                ? '20%'
                : '15%',
          }}
        >
          <Typography
            style={
              heading == 'Action'
                ? { textAlign: 'center', fontWeight: '10' }
                : { display: 'flex', alignItems: 'center', fontWeight: '10' }
            }
          >
            {index == 0 && ((!closeGlobal && !openGlobal) || closeGlobal) ? (
              <OverallClosed
                style={{ width: '18px' }}
                onClick={() => {
                  setOpenGlobal(true);
                  setCloseGlobal(false);
                  setAccordionCount(orgRule.length);
                }}
              />
            ) : index == 0 && openGlobal ? (
              <OverallOpened
                style={{ width: '18px' }}
                onClick={() => {
                  setOpenGlobal(false);
                  setCloseGlobal(true);
                  setAccordionCount(0);
                }}
              />
            ) : (
              <></>
            )}
            &ensp; {heading}
          </Typography>
        </RACTableCell>
      ))}
    </>
  );

  const renderResultContent = () => {
    return (
      <>
        {orgRule?.map((rule: any, index: number) => {
          return (
            <AccordionItem
              key={index}
              organizationRule={rule}
              openGlobal={openGlobal}
              closeGlobal={closeGlobal}
              index={index}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <Card style={{ borderRadius: '15px' }}>
        <CardContent className={classes.tableStyle}>
          <InfiniteScroll
            style={{ maxHeight: '70vh' }}
            next={fetchNextData}
            dataLength={orgRule.length}
            hasMore={orgRule.length == 0 ? false : hasMore}
            loader={
              loader ? (
                <Grid className={`${classes.infiniteLoader}`}>
                  <CircularProgress />
                </Grid>
              ) : null
            }
          >
            <RACTable
              renderTableHead={renderResultHeader}
              renderTableContent={renderResultContent}
            />
          </InfiniteScroll>
        </CardContent>
      </Card>
    </>
  );
}
