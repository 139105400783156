import { CircularProgress, Grid, Typography } from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from './../AgreementContext';
import ErrorContent from './../ErrorMessageContent';
import { viewPDFclick } from './../commonFunctions';

export default function AgrDocument() {
  const classes = GlobalStyles();
  const { documentPageDisplay, loaders, documentData } =
    useContext(AgreementContext);

  return (
    <>
      {loaders.docLoader ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </>
      ) : (
        <></>
      )}
      {documentPageDisplay == 200 ? (
        documentData?.map((obj: any, index: any) => {
          return (
            <Grid container xs={12} key={index}>
              <Grid item xs={9} className={`${classes.popUpPadding}`}>
                <Typography
                  className={`${classes.Bold} ${classes.mB2}`}
                  variant="body2"
                >
                  {obj?.documentName}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <a
                  className={`${classes.centerAlign} ${classes.mB2} ${classes.textBlue} ${classes.semiBoldFont} ${classes.cursorStyle} ${classes.underline}`}
                  onClick={() => {
                    viewPDFclick(obj?.file, obj?.documentUrl);
                  }}
                >
                  View
                </a>
              </Grid>
            </Grid>
          );
        })
      ) : documentPageDisplay == 204 ? (
        <>
          {' '}
          <ErrorContent
            content={'No Records Found'}
            status={204}
            style={''}
          />{' '}
        </>
      ) : documentPageDisplay == 400 || documentPageDisplay == 500 ? (
        <>
          {' '}
          <ErrorContent content={'Data Not Found'} status={500} style={''} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
