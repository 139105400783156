import React, { useContext } from 'react';
import { LoanerAgrGrid } from '../MicroComponents/LoanerAgrGrid';
import { PromoAgrGrid } from '../MicroComponents/PromoAgrGrid';
import { RentalAgrGrid } from '../MicroComponents/RentalAgrGrid';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { Grid } from '@rentacenter/racstrap';

export function AgrHistory() {
  const { displayElements } = useContext(InventoryDetailsContext);
  return (
    <>
      <RentalAgrGrid />
      {displayElements.promoAgr ? <PromoAgrGrid /> : <></>}

      {displayElements.loanerAgr ? <LoanerAgrGrid /> : <></>}
      <Grid style={{ marginBottom: '80px' }}></Grid>
    </>
  );
}
