import {
  CheckCustomerLegalHold,
  GetCustomerAgreements,
  GetCustomerDetails,
  GetCustomerIdByGCId,
  GetCustomerPaymentHistory,
  GetCustomerWorkHistory,
  GetLegalCustomer,
  getGovtIdType,
} from '../../../api/user';
import { getUserDetails } from './CustomerDetailsHelperFunc';
const customerRecordIncompleteMessage = 'Customer Record is Incomplete';

export async function MakeApiCalls(customerId: any) {
  let customerDetailsResponse: any = '';
  let activeAgreementDetails: any = '';
  let inactiveAgreementDetails: any = '';
  let legalCheck: any = '';
  let legalCustomer: any = '';
  await Promise.all([
    GetCustomerDetails(customerId),
    GetCustomerAgreements(customerId, 'Y'),
    GetCustomerAgreements(customerId, 'N'),
    CheckCustomerLegalHold(customerId),
    GetLegalCustomer({
      customerId: parseInt(customerId),
      limit: 10,
      offset: 0,
    }),
  ]).then((resp) => {
    [
      customerDetailsResponse,
      inactiveAgreementDetails,
      activeAgreementDetails,
      legalCheck,
      legalCustomer,
    ] = resp;
  });
  return {
    customerDetailsResponse: customerDetailsResponse,
    activeAgreementDetails: activeAgreementDetails,
    inactiveAgreementDetails: inactiveAgreementDetails,
    legalCheck: legalCheck,
    legalCustomer: legalCustomer,
  };
}

export async function getCustomerDetails(customerId: any) {
  let legalCustomer: any = '';
  let customerDetailsResponse: any = '';
  let activeAgreement: any = '';
  let inActiveAgreement: any = '';
  let currentUserDetails: any = '';
  await Promise.all([
    getUserDetails(),
    GetCustomerDetails(customerId),
    // CheckCustomerLegalHold(customerId),
    GetLegalCustomer({
      customerId: parseInt(customerId),
      limit: 10,
      offset: 0,
    }),
    GetCustomerAgreements(customerId, 'Y'),
    GetCustomerAgreements(customerId, 'N'),
  ]).then((resp) => {
    [
      currentUserDetails,
      customerDetailsResponse,
      legalCustomer,
      inActiveAgreement,
      activeAgreement,
    ] = resp;
  });
  return {
    customerDetailsResponse: customerDetailsResponse,
    legalCustomer: legalCustomer,
    activeAgreement: activeAgreement,
    inActiveAgreement: inActiveAgreement,
    currentUserDetails: currentUserDetails,
  };
}

// export async function getAgreementDetails(customerId: any) {
//   let activeAgreement: any = '';
//   let inActiveAgreement: any = '';
//   await Promise.all([
//     GetCustomerAgreements(customerId, 'Y'),
//     GetCustomerAgreements(customerId, 'N'),
//   ]).then((resp) => {
//     [inActiveAgreement, activeAgreement] = resp;
//   });

//   return {
//     activeAgreement: activeAgreement,
//     inActiveAgreement: inActiveAgreement,
//   };
// }

export async function getLegalCustomerData(customerId: any) {
  let workHistory: any = '';
  let paymentHistory: any = '';
  let dropDowns: any = '';
  await Promise.all([
    GetCustomerPaymentHistory(customerId, '10', '0'),
    GetCustomerWorkHistory(customerId, 10, 0),
    getGovtIdType('0'),
  ]).then((resp) => {
    [paymentHistory, workHistory, dropDowns] = resp;
  });
  return {
    paymentHistory: paymentHistory,
    workHistory: workHistory,
    dropDowns: dropDowns,
  };
}

// export async function makePaymentAndWorkHistoryApiCall(
//   workHistoryOffset: any,
//   paymentHistoryOffset: any,
//   customerId: any
// ) {
//   let workHistory: any = '';
//   let paymentHistory: any = '';
//   await Promise.all([
//     GetCustomerPaymentHistory(customerId, '10', paymentHistoryOffset),
//     GetCustomerWorkHistory(customerId, 10, workHistoryOffset),
//   ]).then((resp) => {
//     [paymentHistory, workHistory] = resp;
//   });
//   return {
//     paymentHistory: paymentHistory,
//     workHistory: workHistory,
//   };
// }

// export async function getDropDown() {
//   let govtIdTypeDropDown: any = '';
//   await Promise.all([getDropDownData('0')]).then((resp) => {
//     [govtIdTypeDropDown] = resp;
//   });

//   return {
//     govtIdTypeDropDown,
//   };
// }

export async function getCustomerId(
  gcid: string,
  setErrorMessage: any,
  setErrorMessagePopup: any
) {
  const customerIdResponse: any = await GetCustomerIdByGCId(gcid);
  if (customerIdResponse?.data) {
    if (customerIdResponse?.status == 200) {
      return customerIdResponse.data.customerId;
    } else {
      setErrorMessage(customerRecordIncompleteMessage);
      setErrorMessagePopup(true);
    }
  } else {
    setErrorMessage(customerRecordIncompleteMessage);
    setErrorMessagePopup(true);
  }
}
