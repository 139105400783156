import { Grid, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { AgreementContext } from './../AgreementContext';
import { formatePayment } from './../commonFunctions';
import { useLocation } from 'react-router-dom';

export function PaymentDetailsCard() {
  const { classes, paymentDetails, paymentRecieptIndex } =
    useContext(AgreementContext);
  const paymentInfo = paymentDetails[paymentRecieptIndex];
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const agrType = queryParams.get('agrType');

  function addPayments(totalPayment: any, clubAmount: any) {
    const totalPaymentValue = parseFloat(totalPayment) || 0;
    const clubAmountValue = parseFloat(clubAmount) || 0;
    const result = totalPaymentValue + clubAmountValue;
    return formatePayment(result.toFixed(2));
  }

  const renderPaymentDetails = () => (
    <Grid item md={6} xs={12} sm={6}>
      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Receipt</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.receiptId ? (
              <Typography>{paymentInfo.receiptId}</Typography>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Store</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.store ? (
              <Typography>{paymentInfo.store}</Typography>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Payment Origin</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.paymentOrigin ? paymentInfo.paymentOrigin : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Tender Type</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.tenderType ? paymentInfo.tenderType : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Days Extended</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.daysExt ? paymentInfo.daysExt : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Days Late</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.daysLate ? paymentInfo.daysLate : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>NSF</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>{paymentInfo?.nsf ? paymentInfo.nsf : 'No'}</Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Old Due Date</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.oldDueDate ? paymentInfo?.oldDueDate : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Next Due Date</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue}`}
        >
          <Typography>
            {paymentInfo?.nextDueDate ? paymentInfo?.nextDueDate : '-'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderPaymentInfo = () => (
    <Grid item md={6} xs={12} sm={6}>
      <Grid container className={`${classes.pt1}`}>
        <Grid item md={4} xs={6} sm={6}>
          <Typography>Date</Typography>
        </Grid>
        <Grid
          item
          md={8}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.datePaid ? paymentInfo?.datePaid : '-'}{' '}
            {paymentInfo?.datePaid && paymentInfo?.timePaid
              ? ' ' + paymentInfo.timePaid + ' CST'
              : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={5} xs={6} sm={6}>
          <Typography>Net Rent</Typography>
        </Grid>
        <Grid
          item
          md={7}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.netRent
              ? formatePayment(paymentInfo.netRent)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Club</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {agrType !== 'club'
              ? paymentInfo?.clubAmount
                ? formatePayment(paymentInfo.clubAmount)
                : '$ 0.00'
              : paymentInfo?.totalPayment
              ? formatePayment(paymentInfo?.totalPayment)
              : '$0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>LDW</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.ldw ? formatePayment(paymentInfo.ldw) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Late Fee</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.lateFee
              ? formatePayment(paymentInfo.lateFee)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Discount</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.discount
              ? formatePayment(paymentInfo.discount)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Other</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.other ? formatePayment(paymentInfo.other) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Tax</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.tax ? formatePayment(paymentInfo.tax) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={`${classes.padding7}`}>
        <Grid md={12} className={`${classes.displayflex}`}>
          <Typography component="span" className={`${classes.borderLine}`} />
        </Grid>
      </Grid>

      <Grid container className={`${classes.pt1}`}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography>Total Payment</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          className={`${classes.textDarkSlateBlue} ${classes.textEnd}`}
        >
          <Typography>
            {paymentInfo?.totalPayment
              ? addPayments(paymentInfo.totalPayment, paymentInfo.clubAmount)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container md={12} className={`${classes.ptop}`}>
        {renderPaymentDetails()}
        {renderPaymentInfo()}
      </Grid>
    </>
  );
}
