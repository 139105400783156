import { Grid, Typography, Card, CardContent } from '@rentacenter/racstrap';
import { ReactComponent as NoRecordsImg } from '../Icons/No records.svg';
import React from 'react';

export function NoRecordsCard() {
  return (
    <>
      <Grid>
        <Card
          style={{
            boxShadow: 'none',
            width: '100%',
            borderRadius: '15px',
            padding: '0.5%',
          }}
        >
          <CardContent>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                height: '50%',
                marginBottom: '10px',
              }}
            >
              <NoRecordsImg style={{ display: 'block' }} />
            </Grid>

            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                height: '20%',
              }}
            >
              <Typography>No Records Found</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
