import React, { useState, createContext } from 'react';
import { InventoryStyles } from '../../JsStyles/InventoryStyles';
import {
  ChargeOffDetails,
  CurrentAgreements,
  DeprecciationEstimator,
  DisplayElements,
  GaapDepreciation,
  HeaderInfo,
  InventoryInfo,
  RecoveryDetails,
  RevenueDetails,
} from '../../Interfaces/Inventory/DetailsInterface';
export const InventoryDetailsContext = createContext<any>('');

export function InventoryDetailsContextProvider(props: any) {
  const classes = InventoryStyles();

  const [headerInformation, setHeaderInformation] = useState<HeaderInfo>({
    itemNumber: '',
    description: '',
    status: '',
    storeNumber: '',
    statusRefCode: '',
  });

  const [inventoryInformation, setInventoryInformation] =
    useState<InventoryInfo>({
      serialNumber: '',
      modelNumber: '',
      department: '',
      subDepartment: '',
      brand: '',
      bracket: '',
      currentTerm: '',
      currentRate: '',
      deptRefCode: '',
      subDeptRefCode: '',
      bracketRefCode: '',
      rmsItemNumber: '',
      auditMessage: '',
      invoiceNumber: '',
      invoiceDate: '',
    });

  const [currentAgreements, setCurrentAgreements] = useState<CurrentAgreements>(
    {
      agreementId: 0,
      agreementNumber: '',
      customerName: '',
      openDate: '',
      rate: '',
      term: '',
      totalRto: '',
      totalRevenue: '',
      mtdRevenue: '',
      agreementRv: '',
      agreementTypeRefCode: '',
    }
  );

  const [chargeOffDetails, setChargeOffDetails] = useState<ChargeOffDetails>({
    agreement: '',
    customer: '',
    date: '',
    reason: '',
    receipt: '',
  });

  const [recoveryDetails, setRecoveryDetails] = useState<RecoveryDetails>({
    recoveryDate: '',
    rvAtRecovery: '',
  });

  const [depreciationEstimator, setDepreciationEstimator] =
    useState<DeprecciationEstimator>({
      po: '',
      vendor: '',
      brand: '',
      modelNumber: '',
      serialNumber: '',
      purchaseDate: '',
      purchaseCost: '',
      totalRevenue: '',
      revenueTurns: '',
      remainingValue: '',
    });

  const [gaapDepreciation, setGaapDepreciation] = useState<GaapDepreciation>({
    depreciationMethod: '',
    depreciationPercentage: '',
    grossProfitPerPayment: '',
    turns: '',
  });

  const [revenueAndService, setRevenueAndService] = useState<RevenueDetails>({
    mtdRevenue: '',
    serviceExpense: '',
    timesOnRent: 0,
    timesOnService: 0,
    timesTransferred: 0,
    totalRevenue: '',
    timesMissingFromAudit: 0,
    lastStatusDate: '',
    actualStatus: '',
  });

  const [displayElements, setDisplayElements] = useState<DisplayElements>({
    currentAgr: false,
    chargeOff: false,
    recovery: false,
    promoAgr: false,
    loanerAgr: false,
  });

  const [tabsLoader, setTabsLoader] = useState<boolean>(true);
  const [activities, setActivities] = useState<any>([]);
  const [paymentHistory, setPaymentHistory] = useState<any>([]);
  const [invDeprConfigResponse, setInvDeprConfigResponse] = useState<any>();
  const [rentalAgreements, setRentalAgreements] = useState<any>([]);
  const [promoAgreements, setPromoAgreements] = useState<any>([]);
  const [loanerAgreements, setLoanerAgreements] = useState<any>([]);

  return (
    <div>
      <InventoryDetailsContext.Provider
        value={{
          classes,
          headerInformation,
          setHeaderInformation,
          inventoryInformation,
          setInventoryInformation,
          currentAgreements,
          setCurrentAgreements,
          chargeOffDetails,
          setChargeOffDetails,
          recoveryDetails,
          setRecoveryDetails,
          depreciationEstimator,
          setDepreciationEstimator,
          gaapDepreciation,
          setGaapDepreciation,
          invDeprConfigResponse,
          setInvDeprConfigResponse,
          rentalAgreements,
          setRentalAgreements,
          promoAgreements,
          setPromoAgreements,
          loanerAgreements,
          setLoanerAgreements,
          paymentHistory,
          setPaymentHistory,
          revenueAndService,
          setRevenueAndService,
          activities,
          setActivities,
          displayElements,
          setDisplayElements,
          tabsLoader,
          setTabsLoader,
        }}
      >
        {props.children}
      </InventoryDetailsContext.Provider>
    </div>
  );
}
