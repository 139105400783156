export function getLegalData(
  legalCustomer: any,
  setResponseStatus: any,
  setLegalTableBody: any,
  setLoader: any
) {
  if (legalCustomer) {
    if (legalCustomer.status == 200 || legalCustomer.status == 204) {
      if (Object.keys(legalCustomer)?.length != 0) {
        if (legalCustomer?.data?.legalholdCustomers) {
          setResponseStatus((prevStatus: any) => ({
            ...prevStatus,
            legalHoldTabStatus: 200,
          }));
          setLegalTableBody(legalCustomer?.data?.legalholdCustomers);
        } else {
          setResponseStatus((prevStatus: any) => ({
            ...prevStatus,
            legalHoldTabStatus: 204,
          }));
          setLegalTableBody([]);
        }
      } else {
        setResponseStatus((prevStatus: any) => ({
          ...prevStatus,
          legalHoldTabStatus: 204,
        }));
        setLegalTableBody([]);
      }
    } else {
      setLoader(false);
      setLegalTableBody([]);
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        legalHoldTabStatus: legalCustomer?.status,
      }));
    }
  } else {
    setLoader(false);
    setLegalTableBody([]);
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      legalHoldTabStatus: 500,
    }));
  }
}
