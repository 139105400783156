import { PaymentGrid } from '../MicroComponents/PaymentGrid';
import React from 'react';

export function PaymentHistoryTab(props?: any) {
  return (
    <>
      <PaymentGrid offset={props?.offset} offsetFunc={props?.offsetFunc} />
    </>
  );
}
