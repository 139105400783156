import {
  Grid,
  RACButton,
  Typography,
  RACTableRow,
  RACTableCell,
  CircularProgress,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { ReactComponent as Sort } from '../../../Icons/Sort.svg';
import { ReactComponent as Filter } from '../../../Icons/Filter.svg';
import { ReactComponent as UpArrow } from '../../../Icons/up-arrow-filter.svg';
import { ReactComponent as DownArrow } from '../../../Icons/down-arrow-filter.svg';
import { getAgreementData } from '../../../api/user';
import {
  buildPayload,
  formatDate,
  formatePayment,
  getAgrTypeDisplay,
  sortObject,
} from '../commonFunctions';
import { AgreementContext } from '../AgreementContext';
import { agreementSearchHeadings } from '../../../constants/constants';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import Agrsearchfilter from './AgreementSearchFilter';
import Agrsearchgrid from './AgreementSearchGrid';

export default function AgreementSearch() {
  const {
    agreementSearchData,
    setAgreementSearchData,
    displaySearch,
    displaySearchResults,
    setFieldDisable,
    validationData,
    setLoader,
    setDisplaySearchResults,
    setDisplaySearch,
    setFilterDisable,
    setPageDisplay,
    setAgreementSearchContent,
    firstNameSortOrder,
    lastNameSortOrder,
    agreementSearchContent,
    setLastNameSortOrder,
    setFirstNameSortOrder,
    filterDisable,
    loader,
    history,
    setOffset,
    setHasMore,
  } = useContext(AgreementContext);
  const classes = GlobalStyles();

  const renderAgreementTableHeadFn = () => (
    <>
      {agreementSearchHeadings.map((obj: any, index: any) => {
        if (agreementSearchContent.length > 1 && obj?.heading == 'Last Name') {
          return (
            <RACTableCell key={index}>
              {obj?.heading}{' '}
              {!lastNameSortOrder ? (
                <Sort
                  className={`${classes.sortUp}`}
                  onClick={() => {
                    sortObject(
                      agreementSearchContent,
                      'desc',
                      'lastName',
                      setAgreementSearchContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              ) : (
                <Sort
                  className={`${classes.sort}`}
                  onClick={() => {
                    sortObject(
                      agreementSearchContent,
                      'asc',
                      'lastName',
                      setAgreementSearchContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              )}
            </RACTableCell>
          );
        }
        if (agreementSearchContent.length > 1 && obj?.heading == 'First Name') {
          return (
            <RACTableCell key={index}>
              {obj?.heading}{' '}
              {!firstNameSortOrder ? (
                <Sort
                  className={`${classes.sortUp}`}
                  onClick={() => {
                    sortObject(
                      agreementSearchContent,
                      'desc',
                      'firstName',
                      setAgreementSearchContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              ) : (
                <Sort
                  className={`${classes.sort}`}
                  onClick={() => {
                    sortObject(
                      agreementSearchContent,
                      'asc',
                      'firstName',
                      setAgreementSearchContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              )}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj?.heading}</RACTableCell>;
      })}
    </>
  );

  async function fetchAgreementSearchData() {
    try {
      setOffset(20);
      setHasMore(true);
      setAgreementSearchContent([]);
      setPageDisplay(200);
      setDisplaySearchResults(true);
      setDisplaySearch(false);
      setLoader(true);
      const offset = 0;
      const payload = buildPayload(agreementSearchData, offset);
      const fetchedData: any = await getAgreementData(payload);
      setLoader(false);
      setFilterDisable(false);
      fetchedData?.status === 200
        ? handleSuccessPageDisplay(fetchedData)
        : handleFailurePageDisplay(fetchedData);
    } catch (error) {
      handleFetchError();
    }
  }

  function handleSuccessPageDisplay(fetchedData: any) {
    setPageDisplay(fetchedData.data.agreementSearch.length !== 0 ? 200 : 204);
    setAgreementSearchContent(fetchedData.data.agreementSearch);
  }

  function handleFailurePageDisplay(fetchedData: any) {
    setPageDisplay(fetchedData.status === 400 ? 400 : 500);
    setDisplaySearchResults(true);
  }

  function handleFetchError() {
    setDisplaySearchResults(true);
    setLoader(false);
    setPageDisplay(500);
  }

  function clearSearch() {
    setAgreementSearchData({
      ...agreementSearchData,
      lastName: '',
      firstName: '',
      storeNumber: '',
      agreementNumber: '',
      agreementType: '',
      status: '',
    });
    setFieldDisable(false);
  }

  function handleOnChange(e: any) {
    setAgreementSearchData({
      ...agreementSearchData,
      [e?.target?.name]: e?.target?.value,
    });
  }

  function onKeyPress(e: any) {
    (validationData || agreementSearchData.agreementNumber) && e.key == 'Enter'
      ? fetchAgreementSearchData()
      : null;
  }

  const handleInputChange = (e: any, regex: any, replace: any) => {
    !regex.test(e.target.value)
      ? (e.target.value = e.target.value.replace(replace, ''))
      : null;
    handleOnChange(e);
  };

  const getAgrType = (agreementType: string) => {
    switch (agreementType) {
      case 'Rental Agreement (RTO)':
        return 'rto';
      case 'Rent to Rent - Loaner':
        return 'loan';
      case 'Installment Notes':
        return 'notes';
      case 'Promo':
        return 'pro';
      case 'Club':
        return 'club';
      default:
        return agreementType;
    }
  };

  const getAgrStatus = (agreementStatus: string) => {
    switch (agreementStatus) {
      case 'A':
      case 'Active':
        return 'Active';
      case 'P':
      case 'PEND':
        return 'Pending';
      case 'I':
      case 'INACTIVE':
        return 'Inactive';
      case 'PRELIM':
        return 'Preliminary';
      case 'PRELIMCANC':
        return 'Preliminary Cancelled';
      case 'PRELIMINV':
        return 'Preliminary Inventory';
      case 'PRELIMPAID':
        return 'Preliminary Paid';
      case 'PRELIMRFND':
        return 'Preliminary Refunded';
      default:
        return agreementStatus;
    }
  };

  const rendertableContentAmount = (
    obj: any,
    agrId: any,
    path: any,
    search: any
  ) => (
    <>
      <RACTableCell
        className={`${classes.textDarkSlateBlue}`}
        onClick={() => {
          agrId ? redirect(path, search) : null;
        }}
      >
        {obj?.rate ? formatePayment(obj?.rate) : '$0.00'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue}`}
        onClick={() => {
          agrId ? redirect(path, search) : null;
        }}
      >
        {obj?.term ? obj?.term : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue}`}
        onClick={() => {
          agrId ? redirect(path, search) : null;
        }}
      >
        {obj?.rentPaid ? formatePayment(obj?.rentPaid) : '$0.00'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue}`}
        onClick={() => {
          agrId ? redirect(path, search) : null;
        }}
      >
        {obj?.dueDate && obj?.dueDate != 'null'
          ? formatDate(obj?.dueDate)
          : '-'}
      </RACTableCell>
    </>
  );
  const renderContentAgrData = (
    obj: any,
    agrId: any,
    path: any,
    search: any
  ) => {
    const Description =
      obj?.description?.length > 25
        ? `${obj?.description?.substring(0, 25)}...`
        : obj?.description;
    return (
      <>
        <RACTableCell
          className={`${classes.textDarkSlateBlue}`}
          title={obj?.description}
          onClick={() => {
            agrId ? redirect(path, search) : null;
          }}
        >
          {Description ? Description : '-'}
        </RACTableCell>
        <RACTableCell
          className={`${classes.textDarkSlateBlue}`}
          onClick={() => {
            agrId ? redirect(path, search) : null;
          }}
        >
          {obj?.agreementType ? getAgrTypeDisplay(obj?.agreementType) : '-'}
        </RACTableCell>
        <RACTableCell
          className={`${classes.textDarkSlateBlue}`}
          onClick={() => {
            agrId ? redirect(path, search) : null;
          }}
        >
          {obj?.status ? getAgrStatus(obj?.status) : '-'}
        </RACTableCell>
        <RACTableCell
          className={`${classes.textDarkSlateBlue}`}
          onClick={() => {
            agrId ? redirect(path, search) : null;
          }}
        >
          {obj?.subStatus ? obj?.subStatus : '-'}
        </RACTableCell>
      </>
    );
  };
  const renderTableContentName = (obj: any) => (
    <>
      <RACTableCell
        className={`${classes.textBlue} ${classes.cursorStyle}`}
        onClick={() => {
          obj?.customerId && obj?.lastName
            ? redirect(`/operations/customer/details/${obj?.customerId}`, null)
            : null;
        }}
      >
        {obj?.lastName ? obj?.lastName : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textBlue} ${classes.cursorStyle}`}
        onClick={() => {
          obj?.customerId && obj?.firstName
            ? redirect(`/operations/customer/details/${obj?.customerId}`, null)
            : null;
        }}
      >
        {obj?.firstName ? obj?.firstName : '-'}
      </RACTableCell>
    </>
  );
  const renderAgreementTableContentFn = () => (
    <>
      {loader ? (
        <RACTableRow>
          <RACTableCell colSpan={12}>
            <Grid className={`${classes.centerAlign}`}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      ) : null}
      {agreementSearchContent?.map((obj: any, index: any) => {
        const agrType = getAgrType(obj?.agreementType);
        const agrId = obj?.agreementId && obj?.agreementNumber;
        const path = `/operations/agreement/details/${obj?.customerId}/${obj?.agreementId}`;
        const search = `?agrType=${agrType}&storeNumber=${obj?.storeNumber}`;
        return (
          <RACTableRow key={index}>
            <RACTableCell
              className={`${classes.textBlue} ${classes.cursorStyle}`}
              onClick={() => {
                obj?.storeNumber
                  ? redirect(`/operations/store/${obj?.storeNumber}`, null)
                  : null;
              }}
            >
              {obj?.storeNumber ? obj?.storeNumber : '-'}
            </RACTableCell>
            <RACTableCell
              className={` ${classes.cursorStyle} ${classes.textBlue}`}
              onClick={() => {
                agrId ? redirect(path, search) : null;
              }}
            >
              {obj?.agreementNumber}
            </RACTableCell>
            {renderTableContentName(obj)}
            {renderContentAgrData(obj, agrId, path, search)}
            {rendertableContentAmount(obj, agrId, path, search)}
          </RACTableRow>
        );
      })}
    </>
  );

  const redirect = (path: any, search: any) => {
    history.push({
      pathname: path,
      search: search,
    });
  };

  return (
    <Grid container md={12} className={`${classes.pagePadding1}`}>
      <Grid container className={`${classes.mb3}`}>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt9} ${classes.title1} ${classes.mL1}`}
          >
            Agreement Search
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6} xs={6}>
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.mt9} ${classes.mR1} ${classes.floatRight} ${classes.padding0}`}
            disabled={filterDisable}
            onClick={() => {
              displaySearchResults
                ? setDisplaySearch(!displaySearch)
                : setDisplaySearch(displaySearch);
            }}
          >
            <Filter className={`${classes.mR1}`} />
            Filter
            {displaySearch ? (
              <UpArrow className={`${classes.mL1}`} />
            ) : (
              <DownArrow className={`${classes.mL1}`} />
            )}
          </RACButton>
        </Grid>
      </Grid>

      {displaySearch ? (
        <Agrsearchfilter
          onKeyPress={onKeyPress}
          handleInputChange={handleInputChange}
          handleOnChange={handleOnChange}
          fetchAgreementSearchData={fetchAgreementSearchData}
          clearSearch={clearSearch}
        />
      ) : (
        <></>
      )}

      {displaySearchResults ? (
        <Agrsearchgrid
          renderAgreementTableHeadFn={renderAgreementTableHeadFn}
          renderAgreementTableContentFn={renderAgreementTableContentFn}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
}
