import { useHistory } from 'react-router-dom';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import {
  Grid,
  RACButton,
  Typography,
  RACTableRow,
  RACTableCell,
  CircularProgress,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect } from 'react';
import { legalHoldGridHeadings } from '../../constants/constants';
import {
  ChangeDateFormat,
  getFormatedDropDown,
  getLegalHoldReasons,
  sortObject,
} from '../Agreement/commonFunctions';
import { ReactComponent as Sort } from '../../Icons/Sort.svg';
import { ReactComponent as Filter } from '../../Icons/Filter.svg';
import { ReactComponent as UpArrow } from '../../Icons/up-arrow-filter.svg';
import { ReactComponent as DownArrow } from '../../Icons/down-arrow-filter.svg';
import { GetLegalCustomer, getDropDownData } from '../../api/user';
import LegalHoldFilter from './LegalHoldFilter';
import { LegalHoldContext } from './LegalHoldContext';
import LegalHoldGrid from './LegalHoldGrid';

export default function LegalHold() {
  const history = useHistory();
  const classes = GlobalStyles();
  const {
    displaySearch,
    setDisplaySearch,
    displaySearchResults,
    setDisplaySearchResults,
    setPageDisplay,
    setDropDownloading,
    legalHoldSearchObj,
    setLegalHoldSearchObj,
    loaders,
    setLoaders,
    setReasonOptions,
    lastNameSortOrder,
    setLastNameSortOrder,
    firstNameSortOrder,
    setFirstNameSortOrder,
    legalHoldGridContent,
    setLegalHoldGridContent,
    validationData,
  } = useContext(LegalHoldContext);

  useEffect(() => {
    getLegalHoldData();
    fetchLegalHoldReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchLegalHoldReasons() {
    try {
      setDropDownloading(true);
      const dropDownData = await getDropDownData('0');
      setDropDownloading(false);
      if (dropDownData?.length != 0) {
        const legalHoldReasons = getFormatedDropDown(
          dropDownData?.data?.resLegalHoldList
        );
        setReasonOptions(legalHoldReasons);
      }
    } catch (e: any) {
      setDropDownloading(false);
    }
  }

  async function getLegalHoldData() {
    try {
      setLegalHoldGridContent([]);
      setPageDisplay(200);
      setDisplaySearchResults(true);
      setLoaders({ ...loaders, pageLoader: true });
      const fetchLegalHoldData = await GetLegalCustomer(legalHoldSearchObj);
      setDisplaySearch(false);
      setLoaders({ ...loaders, pageLoader: false });
      if (
        fetchLegalHoldData.status === 200 &&
        fetchLegalHoldData?.data?.legalholdCustomers.length !== 0
      ) {
        setPageDisplay(200);
        setDisplaySearchResults(true);
        setLegalHoldGridContent(fetchLegalHoldData?.data?.legalholdCustomers);
      } else if (
        fetchLegalHoldData.status === 204 ||
        fetchLegalHoldData?.data?.legalholdCustomers.length === 0
      ) {
        setPageDisplay(204);
        setDisplaySearchResults(true);
      } else if (fetchLegalHoldData.status === 400) {
        setPageDisplay(400);
        setDisplaySearchResults(true);
      } else {
        setPageDisplay(500);
        setDisplaySearchResults(true);
      }
    } catch (error: any) {
      setLoaders({ ...loaders, pageLoader: false });
      setPageDisplay(500);
      setDisplaySearchResults(true);
    }
  }

  const renderLegalHoldTableHeadFn = () => (
    <>
      {legalHoldGridHeadings.map((obj: any, index: any) => {
        if (legalHoldGridContent.length > 1 && obj?.heading == 'Last Name') {
          return (
            <RACTableCell key={index}>
              {obj?.heading}{' '}
              {!lastNameSortOrder ? (
                <Sort
                  className={`${classes.sortUp}`}
                  onClick={() => {
                    sortObject(
                      legalHoldGridContent,
                      'desc',
                      'lastName',
                      setLegalHoldGridContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              ) : (
                <Sort
                  className={`${classes.sort}`}
                  onClick={() => {
                    sortObject(
                      legalHoldGridContent,
                      'asc',
                      'lastName',
                      setLegalHoldGridContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              )}
            </RACTableCell>
          );
        }
        if (legalHoldGridContent.length > 1 && obj?.heading == 'First Name') {
          return (
            <RACTableCell key={index}>
              {obj?.heading}{' '}
              {!firstNameSortOrder ? (
                <Sort
                  className={`${classes.sortUp}`}
                  onClick={() => {
                    sortObject(
                      legalHoldGridContent,
                      'desc',
                      'firstName',
                      setLegalHoldGridContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              ) : (
                <Sort
                  className={`${classes.sort}`}
                  onClick={() => {
                    sortObject(
                      legalHoldGridContent,
                      'asc',
                      'firstName',
                      setLegalHoldGridContent,
                      setLastNameSortOrder,
                      setFirstNameSortOrder
                    );
                  }}
                ></Sort>
              )}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj?.heading}</RACTableCell>;
      })}
    </>
  );

  const renderLegalHoldContentHelperFn = (obj: any) => (
    <>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width1} ${classes.cursorStyle}`}
      >
        {obj?.socialSecurityNumber
          ? obj?.socialSecurityNumber.length > 9
            ? '-'
            : obj?.socialSecurityNumber.slice(-4)
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width1} ${classes.cursorStyle}`}
      >
        {obj?.emailAddress ? obj?.emailAddress : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width15} ${classes.cursorStyle}`}
        title={obj?.address[0]?.addressLine1}
      >
        {obj?.address[0]?.addressLine1
          ? obj?.address[0]?.addressLine1.length > 20
            ? `${obj?.address[0]?.addressLine1.substring(0, 20)}...`
            : obj?.address[0]?.addressLine1
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width1} ${classes.cursorStyle}`}
      >
        {obj?.address[0]?.city ? obj?.address[0]?.city : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width0} ${classes.cursorStyle}`}
      >
        {obj?.address[0]?.stateAbbreviation
          ? obj?.address[0]?.stateAbbreviation
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width0} ${classes.cursorStyle}`}
      >
        {obj?.address[0]?.zip ? obj?.address[0]?.zip : '-'}
      </RACTableCell>
    </>
  );

  const renderLegalHoldTableContentFn = () => (
    <>
      {loaders.pageLoader ? (
        <RACTableRow>
          <RACTableCell colSpan={12}>
            <Grid className={`${classes.centerAlign}`}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      ) : null}
      {legalHoldGridContent?.map((obj: any, index: any) => {
        const pathname = `/operations/customer/details/${obj?.customerId}/legal`;
        return (
          <RACTableRow
            key={index}
            className={classes.whitebackground}
            onClick={() => {
              redirect(pathname);
            }}
          >
            <RACTableCell
              className={`${classes.textBlue} ${classes.semiBoldFont} ${classes.cursorStyle} ${classes.width1}`}
            >
              {obj?.lastName ? obj?.lastName : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textBlue} ${classes.semiBoldFont} ${classes.cursorStyle} ${classes.width1}`}
            >
              {obj?.firstName ? obj?.firstName : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width15} ${classes.cursorStyle}`}
            >
              {obj?.holdReason ? getLegalHoldReasons(obj?.holdReason) : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.textDarkSlateBlue} ${classes.semiBoldFont} ${classes.width1} ${classes.cursorStyle}`}
            >
              {obj?.dateOfBirth
                ? obj?.dateOfBirth.length > 10
                  ? '-'
                  : ChangeDateFormat(obj?.dateOfBirth)
                : '-'}
            </RACTableCell>
            {renderLegalHoldContentHelperFn(obj)}
          </RACTableRow>
        );
      })}
    </>
  );

  function keyPress(e: any) {
    if (validationData && e.key == 'Enter') {
      getLegalHoldData();
    }
  }

  function clearSearch() {
    setLegalHoldSearchObj({
      ...legalHoldSearchObj,
      lastName: '',
      firstName: '',
      holdRefCode: '',
    });
  }

  const redirect = (path: any) => {
    history.push({
      pathname: path,
    });
  };

  return (
    <Grid container md={12} className={`${classes.pagePadding1}`}>
      <Grid container className={`${classes.mb3}`}>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt9} ${classes.title1} ${classes.mL1}`}
          >
            Legal Hold Customers
          </Typography>
        </Grid>
        <Grid item md={6} lg={6} sm={6} xs={6}>
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.mt9} ${classes.mR1} ${classes.floatRight} ${classes.padding0}`}
            onClick={() => {
              setDisplaySearch(!displaySearch);
            }}
          >
            <Filter className={`${classes.mR1}`} />
            Filter
            {displaySearch ? (
              <UpArrow className={`${classes.mL1}`} />
            ) : (
              <DownArrow className={`${classes.mL1}`} />
            )}
          </RACButton>
        </Grid>
      </Grid>

      {displaySearch ? (
        <LegalHoldFilter
          keyPress={keyPress}
          getLegalHoldData={getLegalHoldData}
          clearSearch={clearSearch}
        />
      ) : null}

      {displaySearchResults ? (
        <LegalHoldGrid
          renderLegalHoldTableHeadFn={renderLegalHoldTableHeadFn}
          renderLegalHoldTableContentFn={renderLegalHoldTableContentFn}
        />
      ) : null}
    </Grid>
  );
}
