import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import { customerDetailsStyle } from './../customerStyles';
import { ReactComponent as DetailsIcon } from './../../../emilyIcons/More info.svg';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import React from 'react';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { Loader } from '../CommonCompo/Loader';

export default function VehicleInfoTab(props: any) {
  const classes: any = customerDetailsStyle();

  const vehicleInfoHeading: tableHeadings[] = [
    { heading: 'Utilities' },
    { heading: 'Phone' },
    { heading: 'Make and Model' },
    { heading: 'Color' },
    { heading: 'Tag #' },
    { heading: 'State' },
    { heading: 'Action' },
  ];

  const vehicleTableBody = props.value.tableBodyOne;

  const vehicleTabHeading = () => (
    <>
      {vehicleInfoHeading.map((obj: any, index: any) => {
        if (obj.heading == 'Action') {
          return (
            <RACTableCell
              key={index}
              className={`${classes.bold} ${classes.textCenter}`}
            >
              {obj.heading}
            </RACTableCell>
          );
        }
        return (
          <RACTableCell key={index} className={classes.bold}>
            {obj.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  const vehicleTabContent = () => (
    <>
      {vehicleTableBody.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.utilities ? obj.utilities : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.phone ? obj.phone : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.vehicleMake
                ? obj.vehicleModel
                  ? obj.vehicleMake + ' ' + obj.vehicleModel
                  : obj.vehicleMake
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.vehicleColor ? obj.vehicleColor : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.vehicleTagNumber ? obj.vehicleTagNumber : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj?.vehRegState ? obj.vehRegState : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.width10} ${classes.semiBold} ${classes.textCenter}`}
            >
              <img src="" style={{ width: '90%', textAlign: 'center' }} />
              <DetailsIcon
                className={classes.iconWidth}
                onClick={() => {
                  props.value.setViewDetailsIndex(index);
                  props.value.setShowVehicleModal(true);
                }}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid container md={12}>
              {props.value.status == 200 ? (
                <RACTable
                  renderTableHead={vehicleTabHeading}
                  renderTableContent={vehicleTabContent}
                />
              ) : props.value.status == 204 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <NoRecordsFound />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    No Records Found
                  </Typography>
                </Grid>
              ) : props.value.status == 400 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Bad Request
                  </Typography>
                </Grid>
              ) : (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Something Went Wrong
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
