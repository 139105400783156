import { Grid, CircularProgress } from '@rentacenter/racstrap';
import React from 'react';

export function LoaderCard() {
  return (
    <>
      <Grid style={{ padding: '1.5% 0.2%', width: '100%', height: '250px' }}>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    </>
  );
}
