import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { customerDetailsStyle } from '../../Customer/customerStyles';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { LocationContext } from './LocationContext';
import { useHistory } from 'react-router';

export default function MessagePopup() {
  const classes = customerDetailsStyle();
  const { errorMessagePopup, errorMessage } = useContext(LocationContext);
  const history = useHistory();
  function showErrorMessgePopup() {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>

          <Typography className={`${classes.formLabel} ${classes.mt3}`}>
            {errorMessage}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.mt3} ${classes.textCenter}`}>
          <RACButton
            style={{ padding: '7px 10px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  }
  return (
    <RACModalCard
      isOpen={errorMessagePopup}
      closeIcon={false}
      maxWidth={'xs'}
      borderRadius={'25px !important'}
    >
      {showErrorMessgePopup()}
    </RACModalCard>
  );
}
