import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import {
  agreementData,
  clubData,
  loader,
  searchObj,
  sort,
} from '../../types/interface';
import { validateAgrData } from './commonFunctions';

export const AgreementContext = createContext<any>('');

export function AgreementProvider(props: any) {
  const history = useHistory();
  const classes = GlobalStyles();
  const [loader, setLoader] = useState<boolean>(false);
  const [agreementSearchData, setAgreementSearchData] = useState<searchObj>({
    lastName: '',
    firstName: '',
    storeNumber: '',
    agreementNumber: '',
    agreementType: '',
    status: '',
    limit: 20,
  });
  const [displaySearch, setDisplaySearch] = useState<boolean>(true);
  const [pageDisplay, setPageDisplay] = useState(200);
  const [displaySearchResults, setDisplaySearchResults] =
    useState<boolean>(false);
  const [filterDisable, setFilterDisable] = useState<boolean>(true);
  const validationData: boolean = validateAgrData(agreementSearchData);
  const clearButtonDisable: boolean =
    agreementSearchData?.storeNumber?.trim() != '' ||
    agreementSearchData?.agreementNumber?.trim() != '' ||
    agreementSearchData?.agreementType?.trim() != '' ||
    agreementSearchData?.status?.trim() != '' ||
    agreementSearchData?.firstName?.trim() != '' ||
    agreementSearchData?.lastName?.trim() != '';
  const [agreementSearchContent, setAgreementSearchContent] = useState<any>([]);
  const [fieldDisable, setFieldDisable] = useState<boolean>(false);
  const [lastNameSortOrder, setLastNameSortOrder] = useState<boolean>(true);
  const [firstNameSortOrder, setFirstNameSortOrder] = useState<boolean>(true);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [doc, setdoc] = useState(false);
  const [loaders, setLoaders] = useState<loader>({
    pageLoader: false,
    docLoader: false,
    recpLoader: false,
    fetchDataLoader: false,
  });
  const [agreementPageDisplay, setAgreementPageDisplay] = useState<number>(0);
  const [inventoryPageDisplay, setInventoryPageDisplay] = useState<number>(0);
  const [paymentPageDisplay, setPaymentPageDisplay] = useState<number>(0);
  const [activityPageDisplay, setActivityPageDisplay] = useState<number>(0);
  const [documentPageDisplay, setDocumentPageDisplay] = useState<number>(0);
  const [agreementErrorPopupOpen, setAgreementErrorPopupOpen] =
    useState<boolean>(true);
  const [receiptAlert, setReceiptAlert] = useState<boolean>(false);
  const [agreementData, setAgreementData] = useState<agreementData>({
    customerId: '',
    coCustomerId: '',
    customerName: '-',
    coCustomerName: '-',
    storeId: '-',
    agreement: '-',
    eComm: '-',
    description: '-',
    reinstatement: '-',
    agreementType: '-',
    coverage: '-',
    status: '-',
    dateOpened: '-',
    currentDueDate: '-',
    currentDaysLate: '0',
    autoPay: 'No',
    dateClosed: '-',
    closedReason: '-',
    rate: '0',
    schedule: '-',
    rentPaid: '0',
    amountPaid: '0',
    notePaid: '0',
    epo: '0',
    backRent: '0',
    promotionApplied: '-',
    promoDesc: '-',
    term: '0',
    remainingTerm: '0',
    totalRent: '0',
    totalCost: '0',
    remainingNotes: '0',
    cashPrice: '0',
    agreementRv: '0.00',
    freeTimeDays: '0',
    freeTimeAmount: '0',
  });
  const [clubData, setClubData] = useState<clubData>({
    membershipId: '-',
    status: '-',
    dateOpened: '-',
    dueDate: '-',
    daysLate: '0',
    autoPay: 'No',
    dateClosed: '-',
    rate: '0',
    amountPaid: '0',
    schedule: '-',
    freeTimeDays: '0',
    freeTimeAmount: '0',
    customerName: '',
    coCustomerName: '',
    coCustomerId: '',
  });
  const [documentData, setDocumentData] = useState<any>([]);
  const [paymentRecieptIndex, setPaymentRecieptIndex] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<sort>({
    dateSortOrder: true,
    dateActSortOrder: true,
    itemNumberSortOrder: true,
  });
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(20);
  const validPrilimStatus = [
    'Preliminary',
    'Preliminary Paid',
    'Preliminary Refunded',
  ];
  const [inventoryDetails, setInventoryDetails] = useState<any>([]);
  const [paymentDetails, setPaymentDetails] = useState<any>([]);
  const [activityDetails, setActivityDetails] = useState<any>([]);
  const errorMessage = 'Something Went Wrong';
  const noRecords = 'No Records Found';

  return (
    <div>
      <AgreementContext.Provider
        value={{
          agreementSearchData,
          setAgreementSearchData,
          displaySearch,
          setDisplaySearch,
          pageDisplay,
          setPageDisplay,
          displaySearchResults,
          setDisplaySearchResults,
          filterDisable,
          setFilterDisable,
          agreementSearchContent,
          setAgreementSearchContent,
          fieldDisable,
          setFieldDisable,
          lastNameSortOrder,
          setLastNameSortOrder,
          firstNameSortOrder,
          setFirstNameSortOrder,
          validationData,
          clearButtonDisable,
          history,
          classes,
          loader,
          setLoader,
          paymentPopUp,
          setPaymentPopUp,
          loaders,
          setLoaders,
          agreementPageDisplay,
          setAgreementPageDisplay,
          inventoryPageDisplay,
          setInventoryPageDisplay,
          paymentPageDisplay,
          setPaymentPageDisplay,
          activityPageDisplay,
          setActivityPageDisplay,
          documentPageDisplay,
          setDocumentPageDisplay,
          agreementErrorPopupOpen,
          setAgreementErrorPopupOpen,
          receiptAlert,
          setReceiptAlert,
          agreementData,
          setAgreementData,
          clubData,
          setClubData,
          documentData,
          setDocumentData,
          paymentRecieptIndex,
          setPaymentRecieptIndex,
          sortOrder,
          setSortOrder,
          hasMore,
          setHasMore,
          offset,
          setOffset,
          validPrilimStatus,
          inventoryDetails,
          setInventoryDetails,
          paymentDetails,
          setPaymentDetails,
          activityDetails,
          setActivityDetails,
          errorMessage,
          noRecords,
          doc,
          setdoc,
        }}
      >
        {props.children}
      </AgreementContext.Provider>
    </div>
  );
}
