import {
  Card,
  CardContent,
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from './../AgreementContext';
import ErrorContent from './../ErrorMessageContent';
import { inventoryHeadings, rmsHeadings } from '../../../constants/constants';
import { ReactComponent as Sort } from '../../../Icons/Sort.svg';
import { formatePayment, sorting } from './../commonFunctions';
import { useHistory } from 'react-router-dom';

export default function AgrInventory() {
  const classes: any = GlobalStyles();
  const {
    sortOrder,
    inventoryPageDisplay,
    inventoryDetails,
    setSortOrder,
    setPaymentDetails,
    setActivityDetails,
    setInventoryDetails,
    validPrilimStatus,
    agreementData,
  } = useContext(AgreementContext);
  const history = useHistory();

  const inventoryContentHelperFn = (obj: any) => (
    <>
      <RACTableCell
        className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.width2}`}
      >
        {obj?.subDepartment ? obj?.subDepartment : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBoldFont} ${
          validPrilimStatus.includes(agreementData?.status)
            ? ''
            : classes.floatRight
        } ${classes.mR1} ${classes.textDarkSlateBlue}  ${classes.semiBoldFont}`}
      >
        {validPrilimStatus.includes(agreementData?.status)
          ? obj?.purchaseOrderStatus
            ? obj?.purchaseOrderStatus
            : '-'
          : obj?.remainingValue
          ? formatePayment(obj?.remainingValue)
          : '-'}
      </RACTableCell>
    </>
  );
  const inventoryTableContentFn = () => (
    <>
      {inventoryDetails?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index}>
            <RACTableCell
              className={`${
                !validPrilimStatus.includes(agreementData?.status)
                  ? classes.textBlue
                  : classes.textDarkSlateBlue
              } 
               ${classes.semiBoldFont} ${
                !validPrilimStatus.includes(agreementData?.status)
                  ? classes.cursorStyle
                  : ''
              } ${classes.width2}`}
              onClick={() => {
                !validPrilimStatus.includes(agreementData?.status) &&
                obj?.inventoryNumber
                  ? history.push({
                      pathname: `/operations/inventory/${obj?.inventoryNumber}/${obj?.inventoryId}/details`,
                    })
                  : null;
              }}
            >
              {obj?.inventoryNumber ? obj?.inventoryNumber : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.width3}`}
            >
              {obj?.description ? obj?.description : '-'}
            </RACTableCell>
            <RACTableCell
              className={`${classes.semiBoldFont} ${classes.textDarkSlateBlue} ${classes.width2}`}
            >
              {obj?.department ? obj?.department : '-'}
            </RACTableCell>
            {inventoryContentHelperFn(obj)}
          </RACTableRow>
        );
      })}
    </>
  );

  const invHeading = validPrilimStatus.includes(agreementData?.status)
    ? rmsHeadings
    : inventoryHeadings;

  const inventoryTableHeadFn = () => (
    <>
      {invHeading.map((obj: any, index: any) => {
        return inventoryDetails.length > 1 &&
          (obj?.heading === 'Item #' || obj.heading == 'RMS #') ? (
          <RACTableCell key={index}>
            {obj?.heading}{' '}
            {!sortOrder.itemNumberSortOrder ? (
              <Sort
                className={`${classes.sortUp}`}
                onClick={() =>
                  sorting(
                    inventoryDetails,
                    'desc',
                    'inventoryNumber',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            ) : (
              <Sort
                className={`${classes.sort}`}
                onClick={() =>
                  sorting(
                    inventoryDetails,
                    'asc',
                    'inventoryNumber',
                    setSortOrder,
                    sortOrder,
                    setPaymentDetails,
                    setActivityDetails,
                    setInventoryDetails
                  )
                }
              />
            )}
          </RACTableCell>
        ) : (
          <RACTableCell key={index}>{obj?.heading}</RACTableCell>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.globalBackgroundColor} ${classes.padding1}`}
    >
      <Card className={classes.card2}>
        <CardContent>
          <Grid item md={12}>
            {inventoryPageDisplay == 204 ? (
              <>
                <ErrorContent
                  content={'No Records Found'}
                  status={204}
                  style={classes.mt3}
                />
              </>
            ) : inventoryPageDisplay == 200 ? (
              <RACTable
                className={classes.fixTableHeadAgrTrsRec}
                renderTableHead={() => inventoryTableHeadFn()}
                renderTableContent={() => inventoryTableContentFn()}
                stickyHeader
              />
            ) : inventoryPageDisplay == 500 ? (
              <>
                <ErrorContent
                  content={'Something Went Wrong'}
                  status={500}
                  style={classes.mt3}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
