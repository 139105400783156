export const status = [
  { label: 'All', value: '0' },
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
];
export const simsStore = [
  { label: 'All', value: '0' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];
