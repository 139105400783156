/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  RACTextbox,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import { useContext } from 'react';
import { AdminContext } from './AdminContext';
import { getmenuandstore } from '../../api/user';

export default function EmployeePopUp(props: any) {
  const {
    confirmationPopUp,
    setConfirmationPopUp,
    employeeId,
    setEmployeeId,
    assignMenuContent,
    employeePageDisplay,
    setEmployeePageDisplay,
    assignCheckedRows,
    setAssignMenuContent,
    setAssignCheckedRows,
    setLoader,
    setAssignMenuPageDisplay,
  } = useContext(AdminContext);

  async function fetchMenuBasedOnUser() {
    try {
      setLoader(true);
      setAssignMenuPageDisplay(0);
      const response = await getmenuandstore({
        coworkerId: employeeId,
        menuRequired: true,
        storesRequired: false,
        isAdmin: 'Y'
      });
      setLoader(false);
      if (
        response?.status === 200 &&
        response?.data?.menuConfig.length !== 0 &&
        response?.data?.menuConfig?.code != 400
      ) {
        setConfirmationPopUp({
          ...confirmationPopUp,
          employeePopUp: false,
        });
        const groupedMenu: any = {};
        const menu = response?.data?.menuConfig;
        const assignArray: any = [];
        menu.forEach((value: any) => {
          const {
            appName,
            appComponentName,
            appSubComponentName,
            appComponentFunctionName,
            hierarchy,
            hierarchyValue,
            edit,
            view,
            approve,
          } = value;
          groupedMenu[appName] = groupedMenu[appName] || [];
          groupedMenu[appName].push({
            appName,
            appComponentName,
            appSubComponentName,
            appComponentFunctionName,
            hierarchy,
            hierarchyValue,
            edit,
            view,
            approve,
            active : true
          });
          assignArray.push({
            appComponentFunction: appComponentFunctionName,
            view: String(view),
            edit: String(edit),
            active: '1',
            approve: String(approve),
          });
        });
        const sortedMenuGrouping = Object.values(groupedMenu)
          .map((menuGroup: any) => ({
            menuGrouping: menuGroup,
          }))
          .sort((a, b) =>
            a.menuGrouping[0].appName.localeCompare(b.menuGrouping[0].appName)
          );

        const menuContent = [...assignMenuContent];
        sortedMenuGrouping.forEach((obj2: any) => {
          const matchingObjIndex = menuContent.findIndex((obj: any) =>
            obj.menuGrouping.some(
              (menu: any) =>
                menu.appComponentFunctionName ===
                obj2.menuGrouping[0].appComponentFunctionName
            )
          );

          if (matchingObjIndex !== -1) {
            obj2.menuGrouping.forEach((menu2: any) => {
              const matchingMenuIndex = menuContent[
                matchingObjIndex
              ].menuGrouping.findIndex(
                (menu: any) =>
                  menu.appComponentFunctionName ===
                  menu2.appComponentFunctionName
              );

              if (matchingMenuIndex !== -1) {
                menuContent[matchingObjIndex].menuGrouping[matchingMenuIndex] =
                  menu2;
              } else {
                menuContent[matchingObjIndex].menuGrouping.push(menu2);
              }
            });
          } else {
            menuContent.push(obj2);
          }
        });

        setAssignMenuContent(menuContent);
        setAssignMenuPageDisplay(200);
        setAssignCheckedRows([...assignArray]);
      } else {
        setEmployeePageDisplay(400);
        setAssignMenuPageDisplay(200);
        setConfirmationPopUp({
          ...confirmationPopUp,
          employeePopUp: true,
        });
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
    }
  }

  const classes = GlobalAdminStyles();
  return (
    <RACModalCard
      isOpen={props.isOpen}
      closeIcon={true}
      maxWidth="xs"
      borderRadius="10px !important"
      className={classes.employeePopUp}
      onClose={() => {
        setConfirmationPopUp({
          ...confirmationPopUp,
          employeePopUp: false,
        });
        setEmployeePageDisplay(200)
      }}
      title="Copy User Menu Access"
    >
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid style={{ textAlign: 'left' }}>
          <Typography
            style={{
              fontFamily: 'OpenSans-bold',
              fontSize: '14px',
              marginLeft: '10px',
            }}
          >
            EmployeeId
          </Typography>
          <RACTextbox
            className={classes.p10}
            value={employeeId}
            OnChange={(e) => {
              !/^[0-9]{1,5}$/.test(e.target.value)
                ? (e.target.value = e.target.value.replace(/[^0-9]/g, ''))
                : null;
              setEmployeeId(e.target.value)
          }}
            OnKeydown={async (e) => {
              if (e.key == 'Enter') {
                setConfirmationPopUp({
                  ...confirmationPopUp,
                  employeePopUp: false,
                });
                await fetchMenuBasedOnUser();
              }
            }}
          />
          {employeePageDisplay == 400 ? (
            <Typography
              style={{
                fontFamily: 'OpenSans-bold',
                fontSize: '14px',
                textAlign: 'center',
                color: 'red',
              }}
            >
             Invalid EmployeeId
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item md={12} style={{ textAlign: 'right', padding: '8px' }}>
          <RACButton
            style={{ padding: '8px' }}
            variant="outlined"
            color="primary"
            onClick={() => {
              setConfirmationPopUp({
                ...confirmationPopUp,
                employeePopUp: false,
              });
              setEmployeePageDisplay(200)
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            style={{ marginLeft: '15px', padding: '8px' }}
            variant="contained"
            color="primary"
            disabled={employeeId != '' ? false : true}
            onClick={async () => {
              setConfirmationPopUp({
                ...confirmationPopUp,
                employeePopUp: false,
              });
              await fetchMenuBasedOnUser();
            }}
          >
            Copy
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
