/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { FilterWidget } from './MicroComponents/FilterWidget';
import { AppConfigurationContext } from '../../context/AppConfigurations/AppConfigurationContext';
import ErrorPopup from '../Inventory/GlobalComponents/ErrorPopup';
import { Footer } from './MicroComponents/Footer';
import { DynamicCard } from './MicroComponents/DynamicCard';
import { ResultWidget } from './MicroComponents/ResultWidget';

export function AppConfiguration() {
  const { classes,resultWidget } = useContext(
    AppConfigurationContext
  );
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);


  /**
   * Fetching the employeeId and menu permissions for the employee whether he can edit/view only
   * Also checking the employee is a franchise user or not
   */

  return (
    <>
      {showErrorPopup ? <ErrorPopup setFunction={setShowErrorPopup} /> : <></>}
      <div className={classes.p1}>
        <FilterWidget />
      </div>
      <div className={classes.p1}>
      {resultWidget==200?<ResultWidget/>:resultWidget==204?<DynamicCard cardType={'NORECORD'}/>:resultWidget==500?<DynamicCard cardType={'ERROR'}/>:resultWidget==1?<DynamicCard cardType={'LOADER'}/>:<></>}
      </div>
      <div style={{padding:'3rem 0rem',backgroundColor:'#ecf0f3'}}></div>
      <Footer />
    </>
  );
}
