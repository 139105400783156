import {
  Card,
  CardContent,
  Grid,
  Typography,
  RACTable,
  RACTableRow,
  RACTableCell,
} from '@rentacenter/racstrap';
import { AgreementsLegend } from '../../GlobalComponents/AgreementsLegend';
import React, { useContext, useState } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import {
  formatCurrency,
  formatDate,
  sortNumber,
  truncString,
} from '../../../../utils/InventoryUtils';
import { NoRecordsCard } from '../../GlobalComponents/NoRecordsCard';
import { ReactComponent as SortDesc } from '../../Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../../Icons/SortUp.svg';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

export function RentalAgrGrid() {
  const { classes, rentalAgreements, setRentalAgreements } = useContext(
    InventoryDetailsContext
  );
  const [sort, setSort] = useState<boolean>(true);
  const history = useHistory();

  const agrStatusIndicator = (val: any) => {
    if (val === 'Active') {
      return `${classes.ahActive} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Inactive') {
      return `${classes.ahInactive} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Pastdue') {
      return `${classes.ahDue} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Pending') {
      return `${classes.ahPending} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else {
      return `${classes.racpadLink} ${classes.racpadAgrLink}`;
    }
  };

  const agrHistoryheadings = [
    'Agreement #',
    'Begin RV',
    'End RV',
    'Depreciation',
    'Item Project Revenue',
    'Item Revenue',
    'Revenue/Item Project Total',
    'Item Allocation',
    'Open Date',
    'Close Date',
    'Customer Name',
    'Discount',
  ];

  const renderAgrHeadings = () => (
    <>
      {agrHistoryheadings.map((obj: any, index: number) => {
        if (obj == 'Agreement #') {
          return (
            <RACTableCell key={index}>
              {obj}{' '}
              {rentalAgreements?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  onClick={() => {
                    sortNumber(
                      'asc',
                      'agreementNumber',
                      rentalAgreements,
                      setRentalAgreements
                    );
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              ) : (
                <SortAsc
                  onClick={() => {
                    sortNumber(
                      'desc',
                      'agreementNumber',
                      rentalAgreements,
                      setRentalAgreements
                    );
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              )}
            </RACTableCell>
          );
        }

        if (obj == 'Revenue/Item Project Total') {
          return (
            <RACTableCell key={index} style={{ width: '9%' }}>
              {obj}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const contentHelperGrid = (obj: any, index: number) => (
    <>
      <RACTableCell key={index} style={{ width: '30' }}>
        {obj.agreementNumber ? (
          <a
            className={agrStatusIndicator(obj.status)}
            onClick={() => {
              history.push({
                pathname: `/operations/agreement/details/0000/${obj?.agreementId}`,
                search: `?agrType=rto`,
              });
            }}
          >
            {obj.agreementNumber}
          </a>
        ) : (
          '-'
        )}
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.beginRv ? formatCurrency(obj.beginRv) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.endRv ? formatCurrency(obj.endRv) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '20', paddingLeft: '25px' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.depreciation ? formatCurrency(obj.depreciation) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10', paddingLeft: '50px' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.itemProjectRevenue
            ? formatCurrency(obj.itemProjectRevenue)
            : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10', paddingLeft: '30px' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.itemRevenue ? formatCurrency(obj.itemRevenue) : '-'}
        </Typography>
      </RACTableCell>
    </>
  );

  const contentHelperGridTwo = (obj: any, index: number) => (
    <>
      <RACTableCell key={index} style={{ width: '15', paddingLeft: '30px' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.revenueOrItemProjectTotal
            ? formatCurrency(obj.revenueOrItemProjectTotal)
            : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10', paddingLeft: '50px' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.itemAllocation
            ? (obj.itemAllocation * 100).toFixed(2) + ' %'
            : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '20' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.openDate ? formatDate(obj.openDate) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '20' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.closeDate ? formatDate(obj.closeDate) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell
        key={index}
        style={{ width: '10' }}
        title={obj.customer ? obj.customer : ''}
      >
        <Typography className={`${classes.formValue}`}>
          {obj.customer ? truncString(obj.customer, 15, '...') : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.discount ? obj.discount : '0'}
        </Typography>
      </RACTableCell>
    </>
  );

  const renderAgrHistory = () => (
    <>
      {rentalAgreements.map((obj: any, index: any) => {
        return (
          <RACTableRow className={`${classes.whitebackground}`} key={index}>
            {contentHelperGrid(obj, index)}
            {contentHelperGridTwo(obj, index)}
          </RACTableRow>
        );
      })}
    </>
  );

  const noHistory = () => (
    <>
      <RACTableRow className={`${classes.noRecordHeight}`}>
        <RACTableCell colSpan={agrHistoryheadings.length}>
          <NoRecordsCard />
        </RACTableCell>
      </RACTableRow>
    </>
  );
  return (
    <>
      <Grid style={{ marginBottom: '10px' }}>
        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Grid item md={8} xs={3} lg={8}>
            <Typography variant="h5">Rental Agreements</Typography>
          </Grid>
          <Grid item md={4} xs={9} lg={4}>
            <AgreementsLegend />
          </Grid>
        </Grid>

        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Grid item md={12}>
            <Card className={`${classes.informationCard}`}>
              <CardContent>
                <InfiniteScroll
                  next={() => {
                    return null;
                  }}
                  dataLength={rentalAgreements?.length}
                  hasMore={false}
                  height={rentalAgreements?.length > 4 ? 250 : ''}
                  loader={''}
                >
                  <RACTable
                    style={{ maxHeight: '400px' }}
                    renderTableHead={renderAgrHeadings}
                    renderTableContent={
                      rentalAgreements.length == 0
                        ? noHistory
                        : renderAgrHistory
                    }
                    stickyHeader
                    className={classes.textFixHead}
                  />
                </InfiniteScroll>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
