/* eslint-disable no-console */
import {
  RACDatePicker,
  RACSelect,
  RACTextbox,
  RACTableCell,
  RACTableRow,
  RACButton,
} from '@rentacenter/racstrap';
import React from 'react';
import { ReactComponent as AddDataIcon } from './../../../emilyIcons/Record Add.svg';
import {
  taxExamptDDStatus,
  taxExamptDenialDDStatus,
} from '../../../constants/CustomerConstants';
import { ReactComponent as TickIcon } from './../../../emilyIcons/Record tick.svg';
import { ReactComponent as CancelIcon } from './../../../emilyIcons/Record delete.svg';
import { ReactComponent as EditIcon } from './../../../emilyIcons/Edit.svg';
import { getTimestampToDate, yearToDateFormater } from '../../../utils/utils';
import moment from 'moment';

const dateFormate = 'YYYY-MM-DD';
const currentDate = moment().format(dateFormate);

export function getInlineEdit(
  classes: any,
  taxExamptData: any,
  setTaxExamptData: any,
  taxExamptStatus: any,
  addNewRecord: any,
  taxExemptDDLoader: any,
  taxExemptDenialDropDown: any,
  inputValidation: any
) {
  return (
    <RACTableRow style={{ backgroundColor: 'white', width: '100%' }}>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width10}`}
      >
        {
          <RACTextbox
            type="text"
            disabled={taxExamptStatus}
            value={taxExamptData?.taxId}
            maxlength={30}
            name="taxId"
            OnKeydown={(e: any) =>
              handleEnterKeyForInsert(e, inputValidation, addNewRecord)
            }
            OnChange={(e: any) => {
              // if (!onlyNumber.test(e.target.value)) {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
              // }
              handleOnChange(
                e.target.value,
                'taxId',
                setTaxExamptData,
                taxExamptData
              );
            }}
          />
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACSelect
            options={taxExamptDDStatus}
            defaultValue={taxExamptData.status}
            isDisabled={taxExamptStatus}
            name="status"
            onChange={(e: any) => {
              handleOnChange(
                e.target.value,
                'status',
                setTaxExamptData,
                taxExamptData
              );
            }}
          />
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptData.effectiveDate}
            disabled={taxExamptStatus || taxExamptData.status != 'APP'}
            inputProps={{
              min: currentDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'effectiveDate',
                setTaxExamptData,
                taxExamptData
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptData.expirationDate}
            disabled={taxExamptStatus || taxExamptData.status != 'APP'}
            inputProps={{
              min: currentDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'expirationDate',
                setTaxExamptData,
                taxExamptData
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptData.approvalDenailDate}
            disabled={taxExamptStatus || taxExamptData.status != 'APP'}
            inputProps={{
              min: currentDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'approvalDenailDate',
                setTaxExamptData,
                taxExamptData
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15} `}
      >
        {
          <RACSelect
            options={taxExemptDenialDropDown}
            loading={taxExemptDDLoader}
            defaultValue={taxExamptData.denialReason}
            isDisabled={true}
            classes={{
              option: `${classes.option}`,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e.target.value,
                'denialReason',
                setTaxExamptData,
                taxExamptData
              );
            }}
          />
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textCenter} ${classes.width15} ${classes.hoverStyleRemover}`}
      >
        <RACButton
          disabled={taxExamptStatus || !inputValidation}
          className={
            taxExamptStatus || !inputValidation
              ? `${classes.buttonDisableColor}`
              : ''
          }
        >
          {
            <AddDataIcon
              onClick={() => {
                addNewRecord();
              }}
            />
          }
        </RACButton>
      </RACTableCell>
    </RACTableRow>
  );
}

export function handleOnChange(
  value: any,
  ans: any,
  setFunction: any,
  stateVariableName: any
) {
  if (ans == 'status' && (value == 'PEND' || value == 'DENY' || value == '0')) {
    setFunction({
      ...stateVariableName,
      [ans]: value,
      effectiveDate: null,
      expirationDate: null,
      approvalDenailDate: null,
    });
  } else if (ans == 'taxExemptStatus' && (value == 'PEND' || value == 'DENY')) {
    setFunction({
      ...stateVariableName,
      [ans]: value,
      submittedDate: null,
      expirationDate: null,
    });
  } else if (ans == 'taxExemptStatus' && value == 'APP') {
    setFunction({
      ...stateVariableName,
      [ans]: value,
      taxExemptStatusReasonDesc: 0,
    });
  } else if ((ans == 'holdRefCode' || ans == 'unholdRefCode') && value == '0') {
    setFunction({});
  } else {
    setFunction({ ...stateVariableName, [ans]: value });
  }
  if (ans == 'taxExemptStatus' && value == 'PEND') {
    setFunction({
      ...stateVariableName,
      [ans]: value,
      submittedDate: null,
      expirationDate: null,
      taxExemptStatusDate: null,
      taxExemptStatusReasonDesc: 0,
    });
  }
}

function setEditDetails(
  setTaxExamptEditDetails: any,
  taxExamptTableBody: any,
  index: any
) {
  const taxObject = taxExamptTableBody[index];
  taxObject.submittedDate =
    taxObject?.taxExemptStatus != 'PEND'
      ? taxObject?.submittedDate
        ? taxObject?.submittedDate?.slice(0, 10)
        : ''
      : '';
  taxObject.expirationDate =
    taxObject?.taxExemptStatus != 'PEND'
      ? taxObject?.expirationDate
        ? taxObject?.expirationDate?.slice(0, 10)
        : ''
      : '';
  taxObject.taxExemptStatusDate =
    taxObject.taxExemptStatus != 'PEND'
      ? taxObject?.taxExemptStatusDate
        ? taxObject?.taxExemptStatusDate?.slice(0, 10)
        : ''
      : '';
  setTaxExamptEditDetails(taxObject);
}

export function getTaxExemptRow(
  index: any,
  classes: any,
  taxExamptEditDetails: any,
  setTaxExamptEditDetails: any,
  addUpdatedRecord: any,
  setTaxExamptEditId: any,
  taxExemptDDLoader: any,
  taxExemptDenialDropDown: any,
  updateValidation: any,
  initialValidation: any
) {
  return (
    <RACTableRow style={{ backgroundColor: 'white' }}>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width10}`}
      >
        <RACTextbox
          type="text"
          value={taxExamptEditDetails?.federalTaxId}
          OnKeydown={(e: any) =>
            handleEnterKeyForInsert(
              e,
              !updateValidation,
              addUpdatedRecord,
              !initialValidation
            )
          }
          name="taxId"
          maxlength={30}
          OnChange={(e: any) => {
            // if (!onlyNumber.test(e.target.value)) {
            //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
            // }
            handleOnChange(
              e.target.value,
              'federalTaxId',
              setTaxExamptEditDetails,
              taxExamptEditDetails
            );
          }}
        />
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACSelect
            options={taxExamptDenialDDStatus}
            defaultValue={taxExamptEditDetails.taxExemptStatus}
            name="status"
            onChange={(e: any) => {
              handleOnChange(
                e.target.value,
                'taxExemptStatus',
                setTaxExamptEditDetails,
                taxExamptEditDetails
              );
            }}
          />
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptEditDetails.submittedDate}
            disabled={taxExamptEditDetails.taxExemptStatus != 'APP'}
            inputProps={{
              min: currentDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'submittedDate',
                setTaxExamptEditDetails,
                taxExamptEditDetails
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptEditDetails.expirationDate}
            disabled={taxExamptEditDetails.taxExemptStatus != 'APP'}
            inputProps={{
              min: taxExamptEditDetails.submittedDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'expirationDate',
                setTaxExamptEditDetails,
                taxExamptEditDetails
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {
          <RACDatePicker
            label=""
            value={taxExamptEditDetails.taxExemptStatusDate}
            disabled={
              taxExamptEditDetails.taxExemptStatus == 'PEND' ||
              taxExamptEditDetails.taxExemptStatus == '0'
            }
            inputProps={{
              min: currentDate,
            }}
            onChange={(e: any) => {
              handleOnChange(
                e,
                'taxExemptStatusDate',
                setTaxExamptEditDetails,
                taxExamptEditDetails
              );
            }}
          ></RACDatePicker>
        }
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width0}`}
      >
        <RACSelect
          options={taxExemptDenialDropDown}
          loading={taxExemptDDLoader}
          isDisabled={taxExamptEditDetails.taxExemptStatus != 'DENY'}
          classes={{
            option: `${classes.option}`,
          }}
          defaultValue={
            taxExamptEditDetails.taxExemptStatusReasonDesc
              ? taxExamptEditDetails.taxExemptStatusReasonDesc
              : '0'
          }
          onChange={(e: any) => {
            handleOnChange(
              e.target.value,
              'taxExemptStatusReasonDesc',
              setTaxExamptEditDetails,
              taxExamptEditDetails
            );
          }}
        />
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textCenter} ${classes.hoverStyleRemover}`}
      >
        {
          <>
            <RACButton
              disabled={updateValidation || initialValidation}
              className={
                updateValidation || initialValidation
                  ? `${classes.buttonDisableColor} ${classes.width15}`
                  : `${classes.width15}`
              }
              onClick={() => {
                addUpdatedRecord();
              }}
            >
              <TickIcon />
            </RACButton>
            <RACButton
              className={`${classes.width15}`}
              onClick={() => {
                setTaxExamptEditId(-1);
              }}
            >
              <CancelIcon />
            </RACButton>
          </>
        }
      </RACTableCell>
    </RACTableRow>
  );
}

export function getEditTaxExamptRow(
  data: any,
  index: any,
  classes: any,
  user: any,
  setTaxExamptEditId: any,
  setTaxExamptEditDetails: any,
  taxExamptTableBody: any
) {
  return (
    <RACTableRow style={{ backgroundColor: 'white' }}>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width10}`}
      >
        {data?.federalTaxId ? data?.federalTaxId : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {data?.taxExemptStatusDesc
          ? data?.taxExemptStatusDesc == 'Approved'
            ? 'Exempt'
            : data?.taxExemptStatusDesc
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {data?.submittedDate && data?.taxExemptStatusDesc == 'Approved'
          ? getTimestampToDate(data.submittedDate)
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {data?.expirationDate && data?.taxExemptStatusDesc == 'Approved'
          ? yearToDateFormater(data.expirationDate)
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {data?.taxExemptStatusDate && data?.taxExemptStatusDesc != 'Pending'
          ? getTimestampToDate(data.taxExemptStatusDate)
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue} ${classes.width15}`}
      >
        {data?.taxExemptStatusReasonDesc ? data.taxExemptStatusReasonDesc : '-'}
      </RACTableCell>
      {user.includes('Tax') ? (
        <RACTableCell
          className={`${classes.semiBold}  ${classes.textCenter} ${classes.hoverStyleRemover} ${classes.width15}`}
        >
          <RACButton
            disabled={data?.taxExemptStatusDesc == 'Denied'}
            className={
              data?.taxExemptStatusDesc == 'Denied'
                ? `${classes.buttonDisableColor}`
                : ``
            }
          >
            <EditIcon
              onClick={() => {
                setTaxExamptEditId(index);
                setEditDetails(
                  setTaxExamptEditDetails,
                  taxExamptTableBody,
                  index
                );
              }}
            />
          </RACButton>
        </RACTableCell>
      ) : (
        ''
      )}
    </RACTableRow>
  );
}

export function removeKeys(payload: any) {
  return Object.keys(payload).forEach(
    (key: any) => payload[key] === null && delete payload[key]
  );
}

export function formInsertPayload(taxExamptData: any, props: any) {
  return {
    customerId: props.value.customerId,
    taxId: taxExamptData.taxId ? taxExamptData.taxId : null,
    status: taxExamptData.status != '0' ? taxExamptData.status : null,
    effectiveDate: taxExamptData.effectiveDate
      ? taxExamptData.effectiveDate
      : null,
    expirationDate: taxExamptData.expirationDate
      ? taxExamptData.expirationDate
      : null,
    approvalOrDenialDate: taxExamptData.approvalDenailDate
      ? taxExamptData.approvalDenailDate
      : null,
  };
}

export function formUpdateData(taxExamptEditDetails: any, props: any) {
  return {
    customerId: props.value.customerId,
    customerTaxExemptId: taxExamptEditDetails.customerTaxExemptId,
    taxId: taxExamptEditDetails.federalTaxId
      ? taxExamptEditDetails.federalTaxId
      : null,
    status: taxExamptEditDetails.taxExemptStatus
      ? taxExamptEditDetails.taxExemptStatus
      : null,
    effectiveDate: taxExamptEditDetails.submittedDate
      ? taxExamptEditDetails.submittedDate
      : null,
    expirationDate: taxExamptEditDetails.expirationDate
      ? taxExamptEditDetails.expirationDate
      : null,
    approvalOrDenialDate: taxExamptEditDetails.taxExemptStatusDate
      ? taxExamptEditDetails.taxExemptStatusDate
      : null,
  };
}

export function formPendPayload(taxExamptEditDetails: any, props: any) {
  return {
    customerId: props.value.customerId,
    customerTaxExemptId: taxExamptEditDetails.customerTaxExemptId,
    taxId: taxExamptEditDetails.federalTaxId,
    status: taxExamptEditDetails.taxExemptStatus
      ? taxExamptEditDetails.taxExemptStatus
      : null,
  };
}

export function validateDates(startDate: any, endDate: any, approvalDate: any) {
  let validationResult = true;
  let message = 'Provide Valid Data';
  if (!endDate) {
    return {
      validationResult,
      message,
    };
  }
  if (startDate && endDate) {
    validationResult =
      isValidStartDate(startDate) && isValidEndDate(endDate, startDate);
    message = getValidationMessage(startDate, endDate, validationResult);
  } else if (startDate && (!endDate || endDate === '')) {
    validationResult = false;
    message = 'Enter valid Expiration Date';
  } else if (endDate && (!startDate || startDate === '')) {
    validationResult = false;
    message = 'Enter valid Effective Date';
  }
  if (approvalDate && validationResult && approvalDate < currentDate) {
    validationResult = approvalDate >= currentDate;
    message = 'Approval or Denial Date must be current date or later';
  }
  // if (approvalDate && validationResult && approvalDate < startDate) {
  //   validationResult = approvalDate >= startDate;
  //   message = 'Approval or Denial Date must be greater than Effective date';
  // }

  return {
    validationResult,
    message,
  };
}

function isValidStartDate(startDate: any) {
  return startDate && startDate >= currentDate;
}

function isValidEndDate(endDate: any, startDate: any) {
  return endDate && (!startDate || endDate > startDate);
}

function getValidationMessage(
  startDate: any,
  endDate: any,
  validationResult: any
) {
  return !validationResult
    ? !isValidStartDate(startDate)
      ? 'Effective Date must be today or later. Please select valid date.'
      : !isValidEndDate(endDate, startDate)
      ? 'Expiration Date must be greater than the Effective Date'
      : 'Enter Valid Effective Date'
    : 'Provide valid Date';
}

export function formDenyPayload(taxExamptEditDetails: any, props: any) {
  return {
    customerId: props.value.customerId,
    customerTaxExemptId: taxExamptEditDetails.customerTaxExemptId,
    taxId: taxExamptEditDetails.federalTaxId
      ? taxExamptEditDetails.federalTaxId
      : null,
    status: taxExamptEditDetails.taxExemptStatus
      ? taxExamptEditDetails.taxExemptStatus
      : null,
    approvalOrDenialDate: taxExamptEditDetails.taxExemptStatusDate
      ? taxExamptEditDetails.taxExemptStatusDate
      : null,
    denialReason: taxExamptEditDetails.taxExemptStatusReasonDesc
      ? taxExamptEditDetails.taxExemptStatusReasonDesc
      : null,
  };
}

function handleEnterKeyForInsert(
  e: any,
  validation: any,
  triggerFunction: any,
  validation2: any = true
) {
  if (e.key == 'Enter' && validation && validation2) {
    triggerFunction();
  }
}
