import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as DetailsIcon } from './../../../emilyIcons/More info.svg';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { ReactComponent as PdfIcon } from './../../../emilyIcons/View PDF.svg';
import {
  PaymentDocumentInfo,
  PaymentHistory,
} from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import { ReactComponent as SortDownIcon } from './../../../emilyIcons/Sort.svg';
import { ReactComponent as SortUpIcon } from '../../../assets/images/sort-up-icon.svg';
import React, { useContext } from 'react';
import { sortDates, viewPDFclick } from './../../../utils/utils';
import {
  GetCustomerPaymentHistory,
  GetPaymentReceiptById,
} from '../../../api/user';
import {
  formPaymentHistoryToWorkHistoryData,
  formate,
  formatePayment,
} from '../CustomerDependencies/CustomerDetailsHelperFunc';
import { CustomerContext } from '../CustomerDependencies/CustomerContext';
import { paymentHistoryTableHeading } from '../../../constants/CustomerConstants';
import { useHistory } from 'react-router';
import { Loader } from '../CommonCompo/Loader';
import { customerDetailsStyle } from '../customerStyles';

export default function PaymentHistoryTab(props: any) {
  const limit = 10;
  const {
    // classes,
    toggleObject,
    setToggleObj,
    setErrorMessage,
    setErrorMessagePopup,
    setPageLoader,
  } = useContext(CustomerContext);

  const history = useHistory();
  let paymentHistoryTableBody = props.value.tableBodyOne;
  const hasMore = props.value.hasMore;
  const fetchDataLoader = props.value.fetchDataLoader;
  const setFetchDataLoader = props.value.setFetchDataLoader;
  const setOffset = props.value.setOffset;
  const setWorkHistoryTableBody = props.value.setWorkHistoryTableBody;
  const setHasMore = props.value.setHasMore;
  const offset = props.value.offset;
  const classes = customerDetailsStyle();

  const paymentHistoryTabHead = () => (
    <>
      {paymentHistoryTableHeading.map((heading: any, index: number) => {
        if (heading.heading == 'Total') {
          return (
            <RACTableCell
              key={index}
              className={`${classes.textRight} ${classes.bold}`}
            >
              {heading.heading}
            </RACTableCell>
          );
        } else if (heading.heading == 'EXT' || heading.heading == 'Late') {
          return (
            <RACTableCell
              key={index}
              className={`${classes.bold} ${classes.width3}`}
            >
              {heading.heading}
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              className={`${classes.bold}  ${
                heading.heading == 'Action' ? classes.textCenter : ''
              }`}
            >
              {heading.heading}
              {index == 0 ? (
                !toggleObject.referenceStatus ? (
                  <SortDownIcon
                    className={`${classes.sortIconPosition}`}
                    onClick={() =>
                      (paymentHistoryTableBody = sortDates(
                        'asc',
                        'datePaid',
                        paymentHistoryTableBody,
                        'referenceStatus',
                        setToggleObj,
                        props.value.setPaymentHistoryTableBody
                      ))
                    }
                  />
                ) : (
                  <SortUpIcon
                    className={classes.sortIconPosition}
                    onClick={() =>
                      (paymentHistoryTableBody = sortDates(
                        'desc',
                        'datePaid',
                        paymentHistoryTableBody,
                        'referenceStatus',
                        setToggleObj,
                        props.value.setPaymentHistoryTableBody
                      ))
                    }
                  />
                )
              ) : (
                ''
              )}
            </RACTableCell>
          );
        }
      })}
    </>
  );
  function getTitleLabel(titlelabel: any) {
    return titlelabel ? (titlelabel.length > 15 ? titlelabel : null) : null;
  }

  const renderTableCell = (
    value: any,
    className: string,
    titlelabel?: string,
    daysExt?: number
  ) => (
    <RACTableCell
      className={`${classes.semiBold} ${classes.textDarkSlateBlue} ${className}`}
      title={getTitleLabel(titlelabel)}
    >
      {daysExt == 1 ? (
        <Typography className={className}>{value}</Typography>
      ) : daysExt == 2 ? (
        <Typography
          onClick={() =>
            history.push({
              pathname: `/operations/store/${value}`,
            })
          }
          className={className}
        >
          {value}
        </Typography>
      ) : value ? (
        <Typography className={className}>{value}</Typography>
      ) : (
        '-'
      )}
    </RACTableCell>
  );

  const renderDetailsCell = (index: number, data: any) => (
    <RACTableCell
      className={`${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.textCenter} `}
    >
      <DetailsIcon
        className={classes.iconWidth}
        onClick={() => {
          props.value.setPaymentHistoryModel(true);
          props.value.setViewDetailsIndex(index);
        }}
      />
      <PdfIcon
        className={classes.iconWidth}
        onClick={() => {
          getPaymentReceipt('RCP', data.receiptId);
        }}
      />
    </RACTableCell>
  );

  function renderPaymentHistoryDetails(data: any, index: any) {
    return (
      <RACTableRow style={{ backgroundColor: 'white' }} key={index}>
        {renderTableCell(
          data.datePaid ? data.datePaid.slice(0, 20) + ' CST' : '-',
          ''
        )}
        {renderTableCell(data.receiptId ? data.receiptId : '-', '')}
        {renderTableCell(
          data.store ? data.store : '-',
          `${classes.linkColor}`,
          '',
          2
        )}
        {renderTableCell(
          data.paymentOrigin
            ? data.paymentOrigin.length >= 40
              ? data.paymentOrigin.slice(0, 38) + '...'
              : data.paymentOrigin
            : '-',
          '',
          data.paymentOrigin ? data.paymentOrigin : '-'
        )}
        {renderTableCell(data.tenderType ? data.tenderType : '-', '')}
        {renderTableCell(data.cC4Digit ? data.cC4Digit : '-', ``)}
        {renderTableCell(
          data.daysExt !== undefined && data.daysExt !== null
            ? data.daysExt
            : '-',
          `${classes.textRight}`,
          '',
          1
        )}
        {renderTableCell(
          data.daysLate ? data.daysLate : '-',
          `${classes.textRight}`
        )}
        {renderTableCell(data.nsf ? data.nsf : '-', '')}
        {renderTableCell(
          data.totalPayment ? data.totalPayment : '$0.00',
          `${classes.textRight}`
        )}
        {renderDetailsCell(index, data)}
      </RACTableRow>
    );
  }

  //payment history table body part
  const paymentHistoryTabContent = () => (
    <>
      {paymentHistoryTableBody.map((data: any, index: number) =>
        renderPaymentHistoryDetails(data, index)
      )}
    </>
  );

  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setFetchDataLoader(true);
      setOffset(offset + 10);
      let paymentHistory = await GetCustomerPaymentHistory(
        props.value.customerId,
        limit,
        offset
      );
      if (paymentHistory.status == 200 || paymentHistory.status == 204) {
        paymentHistory = paymentHistory?.data?.value?.customerPaymentHistory;
        setFetchDataLoader(false);

        const paymentDetails: PaymentHistory[] = [];

        paymentHistory.forEach((element: any) => {
          const data: PaymentHistory = {
            receiptId: element?.receiptId,
            store: element?.store,
            paymentOrigin: element?.paymentOrigin,
            tenderType: element?.tenderType,
            daysExt: element?.daysExt,
            daysLate: element?.daysLate,
            datePaid: formate(element),
            pastDueDate: element?.pastDueDate,
            nextDueDate: element?.nextDueDate,
            actualTerm: element?.actualTerm,
            rentPaid: element?.rentPaid,
            closeDate: element?.closeDate,
            agreementStatusReason: element?.agreementStatusReason,
            agreementId: element?.agreementId,
            oldDueDate: element?.oldDueDate,
            netRent: element?.netRent,
            clubAmount: element?.clubAmount,
            ldw: element?.ldw,
            lateFee: element?.lateFee,
            discount: element?.discount,
            other: element?.other,
            tax: element?.tax,
            totalPayment: element?.totalPayment
              ? formatePayment(element?.totalPayment)
              : '-',
            nsf: element?.nsf,
            cc4Digit: element?.cc4Digit,
            coWorker: element?.coWorker,
          };
          paymentDetails.push(data);
        });

        props.value.setPaymentHistoryTableBody([
          ...paymentHistoryTableBody,
          ...paymentDetails,
        ]);
        const workHistoryArray =
          formPaymentHistoryToWorkHistoryData(paymentHistory);

        setWorkHistoryTableBody((prev: any) => [...prev, ...workHistoryArray]);

        if (paymentHistory?.length !== 10) {
          setHasMore(false);
        }
      } else {
        setFetchDataLoader(false);
        setHasMore(false);
      }
    } catch (e: any) {
      setFetchDataLoader(false);
      setHasMore(false);
    }
  };

  async function getPaymentReceipt(type: string, receiptId: any) {
    try {
      setPageLoader(true);
      const receiptResponse = await GetPaymentReceiptById(receiptId, type);
      if (
        receiptResponse?.status == 200 &&
        Object.entries(receiptResponse?.data).length !== 0
      ) {
        const resposnseArray: PaymentDocumentInfo[] =
          receiptResponse?.data?.fileList;

        const documentB64 = resposnseArray.find(
          (obj: any) => obj['docType'] === 'PRCT'
        );

        if (documentB64 && documentB64?.fileObject) {
          viewPDFclick(documentB64?.fileObject, documentB64?.fileName);
          setPageLoader(false);
        } else {
          setPageLoader(false);
          setErrorMessage('Not able to Get Receipt information');
          setErrorMessagePopup(true);
        }
      } else {
        setPageLoader(false);
        setErrorMessage('Not able to Get Receipt information');
        setErrorMessagePopup(true);
      }
    } catch (e: any) {
      setErrorMessage(e.message);
      setErrorMessagePopup(true);
    }
  }

  const renderPaymentContent = () =>
    paymentHistoryTableBody?.length > 9 ? (
      <InfiniteScroll
        next={fetchNextData}
        dataLength={paymentHistoryTableBody?.length}
        hasMore={hasMore}
        height={390}
        loader={
          fetchDataLoader ? (
            <Grid className={classes.GridLoader}>
              <CircularProgress></CircularProgress>
            </Grid>
          ) : null
        }
      >
        <RACTable
          renderTableHead={paymentHistoryTabHead}
          renderTableContent={paymentHistoryTabContent}
          stickyHeader={true}
          className={classes.fixTableHeadAgrTrsRec}
        />
      </InfiniteScroll>
    ) : props.value.status == 200 && paymentHistoryTableBody.length !== 0 ? (
      <RACTable
        renderTableHead={paymentHistoryTabHead}
        renderTableContent={paymentHistoryTabContent}
        stickyHeader={true}
        className={classes.fixTableHeadAgrTrsRec}
      />
    ) : props.value.status == 204 && paymentHistoryTableBody.length == 0 ? (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <NoRecordsFound />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          No Records Found
        </Typography>
      </Grid>
    ) : props.value.status == 400 ? (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <AlertIcon />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          Bad Request
        </Typography>
      </Grid>
    ) : (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <AlertIcon />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          Something Went Wrong
        </Typography>
      </Grid>
    );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01}`}
    >
      <Card className={`${classes.tabsCard1} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid container md={12}>
              {renderPaymentContent()}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
