/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
    Grid,
    RACButton,
    RACModalCard,
    Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import { useContext } from 'react';
import { AdminContext } from './AdminContext';

export default function ConfirmationPopUp(props: any) {
    const {
        confirmationPopUp, setConfirmationPopUp, setCheckedRows, setDisable, disable, setDeletedRows
    } = useContext(AdminContext);

    const classes = GlobalAdminStyles();
    return (
        <RACModalCard
            isOpen={props.isOpen}
            closeIcon={true}
            maxWidth="xs"
            borderRadius="10px !important"
            className={classes.confirmationPopup}
            onClose={() => {
                setConfirmationPopUp({
                    ...confirmationPopUp,
                    isOpen: false
                })
                props?.isRemove ? setDeletedRows([]) : null
            }}
        >
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.centerAlign}>
                    <Typography style={{ fontFamily: 'OpenSans-bold', fontSize: '18px' }}>
                        {props?.isAssign ? 'Are you sure want to assign this permission?' : props?.isSave ? 'Are you sure want to save?'
                            : 'Are you sure want to remove access?'}
                    </Typography>
                    {(props?.isSave == true && props?.isUser != true) || (props?.isAssign != true && props?.isUser != true) ? (
                        <>
                            <Typography style={{ fontSize: '17px', marginTop: '12px' }}>
                                <span style={{ fontFamily: 'OpenSans-bold' }}>Note:</span>
                                {props?.isSave ? ' Changes made and saved will not only update' :
                                    ' Remove access not only update'}
                            </Typography>
                            <Typography style={{ fontSize: '16px', marginTop: '3px' }}>
                                the individual user but also update the hierarchy it was set in
                            </Typography>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item md={12} style={{ textAlign: 'center', marginTop: '25px' }}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setConfirmationPopUp({
                                ...confirmationPopUp,
                                isOpen: false
                            })
                            props?.isRemove ? setDeletedRows([]) : null
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        style={{ marginLeft: '15px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setConfirmationPopUp({
                                ...confirmationPopUp,
                                isPinOpen: true,
                                isOpen: false
                            })
                            setDisable({ ...disable, continue: true })
                        }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </div>
        </RACModalCard>
    );
}
