/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  Card,
  CardContent,
  Divider,
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { AdminContext } from './AdminContext';
import {
  HierarchyType,
  getParentHierarchyOptions,
} from '../../constants/constants';
import { fetchOrganizationDetails } from '../../api/user';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import { formatString } from './commonFunction';

export default function MenuPermissionsFilter(props: any) {
  const {
    menuSearchData,
    setMenuSearchData,
    parentHierarchyType,
    setParentHierarchyType,
    hierarchyValue,
    setHierarchyValue,
    parentHierarchyValue,
    setParentHierarchyValue,
    dropDown,
    roleData,
    errorMessage,
    setErrorMessage,
    disable,
    setDisable,
    clearButton,
    referenceDetails,
    hierarchyName,
    setReferenceDetails,
    dropDownLoading,
    setDropDownLoading,
    validationData,
    setDisplayContent,
    stateDetails
  } = useContext(AdminContext);
  const classes = GlobalAdminStyles();

  const handleInputChange = (e: any, regex: any, replace: any) => {
    !regex.test(e.target.value)
      ? (e.target.value = e.target.value.replace(replace, ''))
      : null;

    props.handleOnChange(e);
  };

  async function dropDownSetter(
    hierarchy: any,
    parentHierarchy: any,
    value: any
  ) {
    const body = {
      type: parentHierarchy,
      filterType: hierarchy,
      filterValue: [value],
    };
    setDisable((prevDisable: any) => ({
      ...prevDisable,
      parentHierarchy: false,
      parentHierarchyValue: true,
    }));
    setDropDownLoading((prevDisable: any) => ({
      ...prevDisable,
      parentHierarchyValueLoading: true,
    }));
    const response: any = await fetchOrganizationDetails(body);
    if (menuSearchData?.parentHierarchy != '') {
      setDisable((prevDisable: any) => ({
        ...prevDisable,
        parentHierarchyValue: false,
      }));
    }
    setDropDownLoading((prevDisable: any) => ({
      ...prevDisable,
      parentHierarchyValueLoading: false,
    }));
    if (
      response?.status == 200 &&
      response?.data?.result &&
      response?.data?.result?.length > 0
    ) {
      const data = response?.data?.result;
      const dataArray: any = [];
      dataArray.push({ label: 'Select', value: '' });
      data.map((item: any) => {
        dataArray.push({
          label: item?.referenceName,
          value: (item?.referenceValue).toString(),
        });
      });
      setParentHierarchyValue(dataArray);
    } else {
      setParentHierarchyValue([{ label: 'Select', value: '' }]);
    }
  }

  return (
    <>
      <Grid container md={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container md={12}>
              <Grid item md={5}>
                <Typography
                  className={`${classes.title}`}
                  style={{ marginLeft: '5px', marginBottom: '15px' }}
                >
                  Search by Employee
                </Typography>
              </Grid>
              <Grid item md={7}>
                <Typography className={`${classes.title}`}>
                  Search by Hierarchy
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid container md={5} spacing={1} style={{ marginLeft: '5px' }}>
                <Grid container style={{ width: '100%' }}>
                  <Grid item style={{ width: '47%' }}>
                    <RACTextbox
                      type="text"
                      name="employeeId"
                      inputlabel="Employee Id"
                      disabled={disable.employeeId}
                      placeHolder={'Enter Employee Id'}
                      maxlength={30}
                      value={menuSearchData.employeeId}
                      className={classes.w90}
                      inputLabelClassname={classes.title1}
                      OnChange={(e) => {
                        handleInputChange(e, /^[0-9]{1,5}$/, /[^0-9]/g);
                        if (e.target.value !== '' || menuSearchData.emailId != '') {
                            setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                hierarchy: true,
                                hierarchyValue: true,
                                parentHierarchy: true,
                                parentHierarchyValue: true
                            })),
                                setMenuSearchData((prevMenuSearchData: any) => ({
                                    ...prevMenuSearchData,
                                    hierarchy: '',
                                    parentHierarchy: '',
                                    parentHierarchyValue: '',
                                    hierarchyValue: ''
                                }))
                        } else {
                            setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                hierarchy: false,
                                hierarchyValue: true,
                                parentHierarchy: true,
                                parentHierarchyValue: true
                            }));
                        }
                    }}
                      OnKeydown={(e: any) => {
                        props?.onKeyPress(e);
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: '47%' }}>
                    <RACTextbox
                      type="text"
                      name="emailId"
                      disabled={disable.emailId}
                      inputlabel="Email Id"
                      placeHolder={'Enter Email Id'}
                      value={menuSearchData.emailId}
                      className={classes.w90}
                      inputLabelClassname={classes.title1}
                      errorMessage={errorMessage}
                      OnChange={(e) => {
                        props.handleOnChange(e);
                        setErrorMessage('')
                        if (e.target.value !== '' || menuSearchData.employeeId != '') {
                            setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                hierarchy: true,
                                hierarchyValue: true,
                                parentHierarchy: true,
                                parentHierarchyValue: true
                            })),
                                setMenuSearchData((prevMenuSearchData: any) => ({
                                    ...prevMenuSearchData,
                                    hierarchy: '',
                                    parentHierarchy: '',
                                    parentHierarchyValue: '',
                                    hierarchyValue: ''
                                }))
                        } else {
                            setDisable((prevDisable: any) => ({
                                ...prevDisable,
                                hierarchy: false,
                                hierarchyValue: true,
                                parentHierarchy: true,
                                parentHierarchyValue: true
                            }));
                        }
                    }}
                      OnKeydown={(e: any) => {
                        props?.onKeyPress(e);
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: '6%' }}>
                    <Divider
                      className={`${classes.w1}`}
                      orientation="vertical"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container md={7} spacing={2}>
                <Grid item md={3}>
                  <RACSelect
                    name="hierarchy"
                    inputLabel="Hierarchy"
                    options={HierarchyType}
                    loading={dropDownLoading.hierarchyLoading}
                    isDisabled={disable.hierarchy}
                    defaultValue={menuSearchData?.hierarchy}
                    inputLabelClassName={classes.title1}
                    onChange={(e: any) => {
                      setMenuSearchData((prevMenuSearchData: any) => ({
                        ...prevMenuSearchData,
                        hierarchy: e?.target?.value,
                        parentHierarchy: '',
                        parentHierarchyValue: '',
                        hierarchyValue: '',
                        emailId: '',
                        employeeId: ''
                      }));

                      if (e?.target?.value !== '') {
                        setDisable((prevDisable: any) => ({
                          ...prevDisable,
                          employeeId: true,
                          emailId: true,
                          hierarchyValue: false,
                        }));
                        switch (e?.target?.value) {
                          case 'STORE':
                            setHierarchyValue(dropDown.store);
                            break;
                          case 'DISTRICT':
                            setHierarchyValue(dropDown.district);
                            break;
                          case 'REGION':
                            setHierarchyValue(dropDown.region);
                            break;
                          case 'STATE':
                            setHierarchyValue(dropDown.state);
                            break;
                          case 'COMPANY':
                            setHierarchyValue(dropDown.company);
                            break;
                          case 'COUNTRY':
                            setHierarchyValue(dropDown.country);
                            break;
                          case 'LOB':
                            setHierarchyValue(dropDown.lob);
                            break;
                          case 'ROLE':
                            setHierarchyValue(dropDown.role);
                            break;
                          default:
                            [];
                        }
                      } else {
                        setDisable((prevDisable: any) => ({
                          ...prevDisable,
                          employeeId: false,
                          emailId: false,
                          hierarchyValue: true,
                        }));
                      }

                      if (
                        e?.target?.value === 'STATE' ||
                        e?.target?.value === 'COUNTRY'
                      ) {
                        setMenuSearchData((prevMenuSearchData: any) => ({
                          ...prevMenuSearchData,
                          parentHierarchyValue: '',
                        }));
                        setParentHierarchyValue([
                          { label: 'Select', value: '' },
                        ]);
                        const getParentHierarchy: any =
                          getParentHierarchyOptions(e?.target?.value);
                        setParentHierarchyType(getParentHierarchy);
                      } else {
                        setDisable((prevDisable: any) => ({
                          ...prevDisable,
                          parentHierarchy: true,
                          parentHierarchyValue: true,
                        }));
                      }
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    name={hierarchyName}
                    inputLabel={
                      formatString(menuSearchData?.hierarchy) ||
                      'Hierarchy Value'
                    }
                    isDisabled={disable.hierarchyValue}
                    options={hierarchyValue}
                    defaultValue={menuSearchData?.hierarchyValue}
                    inputLabelClassName={classes.title1}
                    onChange={(e: any) => {
                      setMenuSearchData({
                        ...menuSearchData,
                        hierarchyValue: e?.target?.value,
                        parentHierarchyValue: '',
                      });
                      if  (
                        (menuSearchData?.hierarchy == 'STATE' ||
                        menuSearchData?.hierarchy == 'COUNTRY') &&
                        e?.target?.value != ''
                      ) {
                        const parentHierarchy =
                          menuSearchData?.hierarchy == 'STATE'
                            ? 'COMPANY'
                            : 'LOB';
                        dropDownSetter(
                          menuSearchData?.hierarchy,
                          parentHierarchy,
                          e?.target?.value
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    name="parentHierarchy"
                    inputLabel="Parent Hierarchy"
                    options={parentHierarchyType}
                    defaultValue={menuSearchData?.parentHierarchy}
                    inputLabelClassName={classes.title1}
                    isDisabled={disable.parentHierarchy}
                    onChange={(e: any) => {
                      setMenuSearchData({
                        ...menuSearchData,
                        parentHierarchy: e?.target?.value,
                      });
                      if (e?.target?.value !== '') {
                        setDisable({ ...disable, parentHierarchyValue: false });
                      } else {
                        setDisable({ ...disable, parentHierarchyValue: true });
                        setMenuSearchData({
                          ...menuSearchData,
                          parentHierarchy: e?.target?.value,
                          parentHierarchyValue: '',
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    name="parentHierarchyValue"
                    inputLabel={
                      formatString(menuSearchData?.parentHierarchy) ||
                      'Parent Hierarchy Value'
                    }
                    isDisabled={disable.parentHierarchyValue}
                    options={parentHierarchyValue}
                    defaultValue={menuSearchData?.parentHierarchyValue}
                    inputLabelClassName={classes.title1}
                    loading={dropDownLoading.parentHierarchyValueLoading}
                    onChange={(e: any) => {
                      setMenuSearchData({
                        ...menuSearchData,
                        parentHierarchyValue: e?.target?.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container md={12} spacing={2}>
              <Grid
                item
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className={`${classes.mt20}`}
              >
                <RACButton
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={validationData ? false : true}
                  className={`${classes.floatRight}`}
                  onClick={() => {
                    if (
                      menuSearchData.emailId &&
                      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                        menuSearchData.emailId
                      )
                    ) {
                      setErrorMessage('Invalid Email Id');
                    } else {
                      setErrorMessage('');
                      menuSearchData.employeeId || menuSearchData.emailId
                        ? setDisplayContent('EMPLOYEE')
                        : menuSearchData.hierarchy == 'STATE' &&
                          menuSearchData.parentHierarchy == 'COMPANY'
                        ? setDisplayContent('STATECOMPANY')
                        : menuSearchData.hierarchy == 'COUNTRY' &&
                          menuSearchData.parentHierarchy == 'LOB'
                        ? setDisplayContent('COUNTRYLOB')
                        : setDisplayContent(menuSearchData.hierarchy);
                      if (
                        menuSearchData.hierarchy === 'COUNTRY' ||
                        menuSearchData.hierarchy === 'LOB'
                      ) {
                        const filterData = hierarchyValue?.filter(
                          (val: any) =>
                            val?.value === menuSearchData?.hierarchyValue
                        );
                        setReferenceDetails({
                          ...referenceDetails,
                          countryRefCode: filterData[0]?.value,
                          countryName: filterData[0]?.label,
                          lobCode: filterData[0]?.value,
                          lobName: filterData[0]?.label,
                        });
                      }
                        if ((menuSearchData.hierarchy === 'COUNTRY' && menuSearchData.parentHierarchy === 'LOB') || menuSearchData.hierarchy === 'STATE' && menuSearchData.parentHierarchy === 'COMPANY') {
                            const state = menuSearchData.hierarchy === 'STATE' ? true : false;
                            const stateArray = state ? stateDetails?.filter((val: any) => val?.referenceValue === menuSearchData?.hierarchyValue) : [];
                            const filterData = hierarchyValue?.filter((val: any) => val?.value === menuSearchData?.hierarchyValue);
                            const parentFilterData = parentHierarchyValue?.filter((val: any) => val?.value === menuSearchData?.parentHierarchyValue);
                            setReferenceDetails({
                                ...referenceDetails,
                                childName: state ? stateArray[0]?.referenceName : filterData[0]?.label,
                                childCode: state ? stateArray[0]?.referenceValue : filterData[0]?.value,
                                parentName: parentFilterData[0]?.label,
                                parentCode: parentFilterData[0]?.value,
                            });
                        }
                      if (menuSearchData.hierarchy === 'ROLE') {
                        const data = roleData?.filter(
                          (val: any) =>
                            val?.appRoleCode === menuSearchData?.hierarchyValue
                        );
                        setReferenceDetails({
                          ...referenceDetails,
                          role: data[0]?.appRoleName,
                          roleCode: data[0]?.appRoleCode,
                        });
                      }
                      props.fetchMenuAndStore(menuSearchData, true);
                    }
                  }}
                >
                  Apply
                </RACButton>
                <RACButton
                  color="primary"
                  variant="outlined"
                  size="large"
                  disabled={clearButton ? false : true}
                  className={`${classes.floatRight} ${classes.mR1} ${classes.padding6}`}
                  onClick={() => {
                    props.clearSearch();
                  }}
                >
                  Clear
                </RACButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
