/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { ReactComponent as SuccessIcon } from '../../Icons/Done.svg';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AdminContext } from './AdminContext';

export default function SuccessPopUp(props: any) {
  const classes = GlobalStyles();
  const {
    confirmationPopUp,
    setConfirmationPopUp,
    successDisplay,
    setDisplayAssignMenu,
    setDisplaySearch,
    displaySearchResults,
    setDisplaySearchResults,
    setMenuSearchData,
    setDisable, setErrorMessage
  } = useContext(AdminContext);

  function clearSearch() {
    setMenuSearchData((prevMenuSearchData: any) => ({
      ...prevMenuSearchData,
      employeeId: '',
      emailId: '',
      hierarchy: '',
      hierarchyValue: '',
      parentHierarchy: '',
      parentHierarchyValue: ''
    }));
    setDisable((prevDisable: any) => ({
      ...prevDisable,
      employeeId: false,
      emailId: false,
      hierarchy: false,
      hierarchyValue: true,
      parentHierarchy: true,
      parentHierarchyValue: true
    }));
    setErrorMessage('')
  }
  return (
    <RACModalCard
      isOpen={props.isOpen}
      closeIcon={true}
      maxWidth="xs"
      borderRadius="25px !important"
      onClose={() => {
        setConfirmationPopUp({
          ...confirmationPopUp,
          successPopUp: false,
        });
        if (props?.value != 'MenuConfig' && successDisplay == 200) {
          clearSearch()
          setDisplayAssignMenu(false)
          setDisplaySearch(true)
          displaySearchResults ? setDisplaySearchResults(true) : setDisplaySearchResults(false)
      }
      }}
    >
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.centerAlign}>
          {successDisplay == 200 ? <SuccessIcon /> : <AlertIcon></AlertIcon>}
          <Typography className={`${classes.formLabel} ${classes.mt2}`}>
            {successDisplay == 200 ? ' Saved Successfully' : 'Bad request'}
          </Typography>
          {successDisplay == 200 ?
            <>
              <Typography className={`${classes.formLabel} ${classes.mt2}`}>
              <span style={{ fontFamily: 'OpenSans-bold' }}>Note: </span> The changes will be reflected
              </Typography>
              <Typography className={`${classes.formLabel}`}>
                in the application in 0 to 24 hours
              </Typography>
            </> : ''}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.centerAlign}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setConfirmationPopUp({
                ...confirmationPopUp,
                successPopUp: false,
              });
              if (props?.value != 'MenuConfig' && successDisplay == 200) {
                clearSearch()
                setDisplayAssignMenu(false)
                setDisplaySearch(true)
                displaySearchResults ? setDisplaySearchResults(true) : setDisplaySearchResults(false)
            }
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
