import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function SomethingWentWrongPopUp(props: any) {
  const classes = GlobalStyles();
  const history = useHistory();
  return (
    <RACModalCard
      isOpen={props.tab}
      closeIcon={false}
      maxWidth="xs"
      borderRadius="25px !important"
    >
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.centerAlign}>
          <AlertIcon></AlertIcon>
          <Typography className={`${classes.formLabel}  ${classes.mt2}`}>
            Something went wrong
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.centerAlign}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
