import { Grid, RACTabs } from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { InformationTab } from './DetailsComponents/TabComponents/InformationTab';
import { InventoryDetailsContext } from '../../context/Inventory/InventoryDetailsContext';
import { AgrHistory } from './DetailsComponents/TabComponents/AgrHistoryTab';
import { Footer } from './DetailsComponents/TabComponents/Footer';
import { Header } from './DetailsComponents/TabComponents/Header';
import { DetailsTab } from './DetailsComponents/TabComponents/DetailsTab';
import { PaymentHistoryTab } from './DetailsComponents/TabComponents/PaymentHistoryTab';
import { ActivitiesTab } from './DetailsComponents/TabComponents/ActivitiesTab';
import {
  getActivities,
  getInventoryAgreementHistory,
  getInventoryDetails,
  getInventoryInformation,
  getInventoryPaymentHistory,
} from '../../api/user';
import ErrorPopup from './GlobalComponents/ErrorPopup';
import { LoaderCard } from './GlobalComponents/LoadingCard';
import { useParams } from 'react-router-dom';

export function InventoryDetails() {
  const routePath: any = useParams();
  const InventoryNumber = routePath.inventoryNumber;
  const InventoryId = routePath.inventoryId;
  const [showError, setShowError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [sendBack, setSendBack] = useState<boolean>(false);
  const [paymentOffset, setPaymentOffset] = useState<number>(0);
  const [activityOffset, setActivityOffset] = useState<number>(0);
  const {
    classes,
    setActivities,
    setHeaderInformation,
    setInventoryInformation,
    setCurrentAgreements,
    setChargeOffDetails,
    setRecoveryDetails,
    setDepreciationEstimator,
    setGaapDepreciation,
    gaapDepreciation,
    setRentalAgreements,
    setPromoAgreements,
    displayElements,
    setDisplayElements,
    setLoanerAgreements,
    setRevenueAndService,
    tabsLoader,
    setTabsLoader,
    setPaymentHistory,
  } = useContext(InventoryDetailsContext);

  const handleInventoryInfo = (data: any) => {
    try {
      if (data.inventoryHeader) {
        setHeaderInformation({
          itemNumber: data?.inventoryHeader?.itemNumber,
          description: data?.inventoryHeader?.description,
          status: data?.inventoryHeader?.status,
          storeNumber: data?.inventoryHeader?.storeNumber,
          statusRefCode: data?.inventoryHeader?.statusRefCode,
        });
      }

      if (data?.inventoryInformation) {
        setInventoryInformation({
          serialNumber: data?.inventoryInformation?.serialNumber,
          modelNumber: data?.inventoryInformation?.modelNumber,
          department: data?.inventoryInformation?.department,
          subDepartment: data?.inventoryInformation?.subDepartment,
          brand: data?.inventoryInformation?.brand,
          bracket: data?.inventoryInformation?.bracket,
          currentTerm: data?.inventoryInformation?.currentTerm,
          currentRate: data?.inventoryInformation?.currentRate,
          deptRefCode: data?.inventoryInformation?.deptRefCode,
          subDeptRefCode: data?.inventoryInformation?.subDeptRefCode,
          bracketRefCode: data?.inventoryInformation?.bracketRefCode,
          rmsItemNumber: data?.inventoryInformation?.rmsItemNumber,
          auditMessage: data?.inventoryInformation?.auditMessage,
          invoiceNumber: data?.inventoryInformation?.invoiceNumber,
          invoiceDate: data?.inventoryInformation?.invoiceDate,
        });
      }
      if (data?.currentAgreement) {
        setCurrentAgreements({
          agreementId: data?.currentAgreement.agreementId,
          agreementNumber: data?.currentAgreement.agreementNumber,
          customerName: data?.currentAgreement.customerName,
          openDate: data?.currentAgreement.openDate,
          rate: data?.currentAgreement.rate,
          term: data?.currentAgreement.term,
          totalRto: data?.currentAgreement.totalRto,
          totalRevenue: data?.currentAgreement.totalRevenue,
          mtdRevenue: data?.currentAgreement.mtdRevenue,
          agreementRv: data?.currentAgreement.agreementRv,
          agreementTypeRefCode: data?.currentAgreement.agreementTypeRefCode,
        });
      }

      if (data?.chargedOff) {
        setChargeOffDetails({
          agreementId: data?.chargedOff?.agreementId,
          agreement: data?.chargedOff?.agreement,
          customer: data?.chargedOff?.customer,
          date: data?.chargedOff?.date,
          reason: data?.chargedOff?.reason,
          receipt: data?.chargedOff?.receipt,
        });
      }
      if (data?.recovery) {
        setRecoveryDetails({
          recoveryDate: data?.recovery?.recoveryDate,
          rvAtRecovery: data?.recovery?.rvAtRecovery,
        });
      }

      setDisplayElements({
        ...displayElements,
        currentAgr: data?.currentAgreement ? true : false,
        chargeOff: data?.chargedOff ? true : false,
        recovery:
          data?.recovery && data?.recovery?.recoveryDate !== null
            ? true
            : false,
      });
    } catch (error: any) {
      return null;
    }
  };

  const handleDetailsInfo = (detailsResponse: any) => {
    try {
      if (detailsResponse && detailsResponse?.status == 200) {
        const detailsInfo = detailsResponse?.data;
        setDepreciationEstimator({
          po: detailsInfo?.depreciationEstimator.po,
          vendor: detailsInfo?.depreciationEstimator.vendor,
          brand: detailsInfo?.depreciationEstimator.brand,
          modelNumber: detailsInfo?.depreciationEstimator.modelNumber,
          serialNumber: detailsInfo?.depreciationEstimator.serialNumber,
          purchaseDate: detailsInfo?.depreciationEstimator.purchaseDate,
          purchaseCost: detailsInfo?.depreciationEstimator.purchaseCost,
          totalRevenue: detailsInfo?.depreciationEstimator.totalRevenue,
          revenueTurns: detailsInfo?.depreciationEstimator.revenueTurns,
          remainingValue: detailsInfo?.depreciationEstimator.remainingValue,
        });

        setGaapDepreciation({
          ...gaapDepreciation,
          depreciationPercentage:
            detailsInfo?.gaapDepreciation.depreciationPercentage,
          grossProfitPerPayment:
            detailsInfo?.gaapDepreciation.grossProfitPerPayment,
          turns: detailsInfo?.gaapDepreciation.turns,
          depreciationMethod: detailsInfo?.gaapDepreciation.depreciationMethod
            ? detailsInfo?.gaapDepreciation.depreciationMethod
            : '-',
        });
      } else {
        setDepreciationEstimator({
          po: '',
          vendor: '',
          brand: '',
          modelNumber: '',
          serialNumber: '',
          purchaseDate: '',
          purchaseCost: '',
          totalRevenue: '',
          revenueTurns: '',
          remainingValue: '',
        });
        setGaapDepreciation({
          depreciationMethod: '',
          depreciationPercentage: '',
          grossProfitPerPayment: '',
          turns: '',
        });
      }
    } catch (err: any) {
      setShowError(true);
    }
  };

  const handleActivityInfo = (activitiesInfo: any) => {
    try {
      if (activitiesInfo?.reveneueAndTime && activitiesInfo?.activities) {
        setRevenueAndService({
          mtdRevenue: activitiesInfo?.reveneueAndTime?.mtdRevenue,
          serviceExpense: activitiesInfo?.reveneueAndTime?.serviceExpense,
          timesOnRent: activitiesInfo?.reveneueAndTime?.timesOnRent,
          timesOnService: activitiesInfo?.reveneueAndTime?.timesOnService,
          timesTransferred: activitiesInfo?.reveneueAndTime?.timesTransferred,
          totalRevenue: activitiesInfo?.reveneueAndTime?.totalRevenue,
          timesMissingFromAudit:
            activitiesInfo?.reveneueAndTime?.timesMissingFromInvAudit,
          lastStatusDate: activitiesInfo?.reveneueAndTime?.lastStatusDate,
          actualStatus: activitiesInfo?.reveneueAndTime?.actualStatus,
        });
        setActivities(activitiesInfo?.activities);
      }
    } catch (err: any) {
      setShowError(true);
    }
  };

  const handlePaymentHistory = (paymentResponse: any) => {
    if (
      paymentResponse &&
      paymentResponse?.status == 200 &&
      paymentResponse?.data?.value &&
      paymentResponse?.data?.value?.paymentHistoryGridInfo
    ) {
      setPaymentHistory(paymentResponse?.data?.value?.paymentHistoryGridInfo);
    } else {
      setPaymentHistory([]);
    }
  };

  const handleDisplayElements = (
    inventoryInfoResponse: any,
    agreementResponse: any
  ) => {
    setDisplayElements({
      ...displayElements,
      currentAgr: inventoryInfoResponse?.data?.currentAgreement ? true : false,
      chargeOff: inventoryInfoResponse?.data?.chargedOff ? true : false,
      recovery: inventoryInfoResponse?.data?.recovery ? true : false,
      promoAgr:
        agreementResponse?.data?.promoAgreement &&
        agreementResponse?.data?.promoAgreement?.length > 0
          ? true
          : false,
      loanerAgr:
        agreementResponse?.data?.loanerAgreement &&
        agreementResponse?.data?.loanerAgreement.length > 0
          ? true
          : false,
    });
  };
  const renderTabs = async () => {
    try {
      setTabsLoader(true);
      const inventoryInfoResponse = await getInventoryInformation(
        InventoryNumber
      );
      setLoader(false);
      if (inventoryInfoResponse && inventoryInfoResponse?.status == 200) {
        handleInventoryInfo(inventoryInfoResponse?.data);
      } else {
        setHeaderInformation({
          itemNumber: '',
          description: '',
          status: '',
          storeNumber: '',
          statusRefCode: '',
        });
        setInventoryInformation({
          serialNumber: '',
          modelNumber: '',
          department: '',
          subDepartment: '',
          brand: '',
          bracket: '',
          currentTerm: '',
          currentRate: '',
          deptRefCode: '',
          subDeptRefCode: '',
          bracketRefCode: '',
          rmsItemNumber: '',
          auditMessage: '',
          invoiceNumber: '',
          invoiceDate: '',
        });
        setCurrentAgreements({
          agreementId: 0,
          agreementNumber: '',
          customerName: '',
          openDate: '',
          rate: '',
          term: '',
          totalRto: '',
          totalRevenue: '',
          mtdRevenue: '',
          agreementRv: '',
          agreementTypeRefCode: '',
        });
        setChargeOffDetails({
          agreement: '',
          customer: '',
          date: '',
          reason: '',
          receipt: '',
        });
        setRecoveryDetails({
          recoveryDate: '',
          rvAtRecovery: '',
        });
      }

      const [
        detailsResponse,
        agreementResponse,
        paymentHistoryresponse,
        activitiesResponse,
      ] = await Promise.all([
        getInventoryDetails(InventoryNumber),
        getInventoryAgreementHistory(InventoryNumber),
        getInventoryPaymentHistory(0, InventoryId),
        getActivities(InventoryNumber, 0),
      ]);

      setTabsLoader(false);

      handleDetailsInfo(detailsResponse);

      if (activitiesResponse && activitiesResponse?.status == 200) {
        handleActivityInfo(activitiesResponse?.data);
      } else {
        setRevenueAndService({
          mtdRevenue: '',
          serviceExpense: '',
          timesOnRent: 0,
          timesOnService: 0,
          timesTransferred: 0,
          totalRevenue: '',
          timesMissingFromAudit: 0,
          lastStatusDate: '',
          actualStatus: '',
        });
        setActivities([]);
      }

      if (
        agreementResponse &&
        agreementResponse?.status == 200 &&
        agreementResponse?.data
      ) {
        setRentalAgreements(agreementResponse?.data?.rentalAgreement);
        setPromoAgreements(agreementResponse?.data?.promoAgreement);
        setLoanerAgreements(agreementResponse?.data?.loanerAgreement);
      } else {
        setRentalAgreements([]);
        setPromoAgreements([]);
        setLoanerAgreements([]);
      }

      handlePaymentHistory(paymentHistoryresponse);

      if (
        inventoryInfoResponse.status !== 200 &&
        detailsResponse.status !== 200 &&
        activitiesResponse.status !== 200 &&
        agreementResponse.status !== 200
      ) {
        setSendBack(true);
      }

      handleDisplayElements(inventoryInfoResponse, agreementResponse);
    } catch (error: any) {
      setLoader(false);
      setSendBack(true);
    }
  };

  useEffect(() => {
    renderTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showError ? <ErrorPopup setFunction={setShowError} /> : <></>}
      {sendBack ? <ErrorPopup navigateBack={true} /> : <></>}
      <Header />
      <Grid container className={`${classes.tabstest}`}>
        <RACTabs
          tabs={[
            'Inventory Information',
            'Details',
            'Agreement History',
            'Payment History',
            'Activities',
          ]}
          contentForTabs={[
            loader ? <LoaderCard /> : <InformationTab key={1} />,
            tabsLoader ? <LoaderCard /> : <DetailsTab key={2} />,
            tabsLoader ? <LoaderCard /> : <AgrHistory key={3} />,
            tabsLoader ? (
              <LoaderCard />
            ) : (
              <PaymentHistoryTab
                key={4}
                offset={paymentOffset}
                offsetFunc={setPaymentOffset}
              />
            ),
            tabsLoader ? (
              <LoaderCard />
            ) : (
              <ActivitiesTab
                key={5}
                offset={activityOffset}
                offsetFunc={setActivityOffset}
              />
            ),
          ]}
          defaultTab={0}
        />
      </Grid>
      <Footer />
    </>
  );
}
