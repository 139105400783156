import { DepreciationEstimator } from '../MicroComponents/DepreciationEstimator';
import { GaapDepreciation } from '../MicroComponents/GaapDepreciation';
import React from 'react';

export function DetailsTab() {
  return (
    <>
      <DepreciationEstimator />
      <GaapDepreciation />
    </>
  );
}
