import React, { useState, createContext } from 'react';
import { InventoryStyles } from '../../JsStyles/InventoryStyles';
import {
  ArrayFields,
  DropDownData,
  SearchParams,
  SearchPayload,
} from '../../Interfaces/Inventory/SearchInterface';
export const InventorySearchContext = createContext<any>('');

export function InventorySearchContextProvider(props: any) {
  const classes = InventoryStyles();
  const [showError, setShowError] = useState<boolean>(false);
  // const [showLoader, setShowLoader] = useState<boolean>(false);
  const [gridLoader, setGridLoader] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [noRecords, setNoRecords] = useState<boolean>(false);
  const [dropdownLoader, setDropdownLoader] = useState<boolean>(true);
  const [locationType, setLocationType] = useState<string>('Store');
  const [conditionsOptions, setConditionsOptions] = useState<DropDownData[]>([
    { label: 'Select', value: '0' },
  ]);
  const [departmentOptions, setDepartmentOptions] = useState<DropDownData[]>([
    { label: 'Select', value: '' },
  ]);
  const [subDepartmentOptions, setSubDepartmentOptions] = useState<
    DropDownData[]
  >([{ label: 'Select', value: '' }]);
  const [statusOptions, setStatusOptions] = useState<DropDownData[]>([
    { label: 'Select', value: '' },
  ]);
  const [searchRequest, setSearchRequest] = useState<SearchPayload>({
    storeNumbers: null,
    itemNumber: null,
    serialNumber: '',
    bracket: '',
    brand: '',
    modelNumber: '',
    poNumber: '',
    statusId: null,
    subStatusId: [],
    conditionId: null,
    department: '',
    subDepartment: '',
    setNumber: 1,
    isActivePriceTag: false,
    limit: 25,
  });
  const [searchParams, setSearchParams] = useState<SearchParams>({
    serialNumber: '',
    bracket: '',
    brand: '',
    modelNumber: '',
    poNumber: '',
    statusId: '',
    subStatusId: [],
    conditionId: '0',
    department: '',
    subDepartment: '',
    setNumber: 0,
    isActivePriceTag: false,
  });
  const [filteredInventories, setFilteredInventories] = useState<any[]>([]);
  const [arrayFileds, setArrayFields] = useState<ArrayFields>({
    storeNumber: '',
    itemNumber: '',
  });

  return (
    <div>
      <InventorySearchContext.Provider
        value={{
          showError,
          setShowError,
          showFilter,
          setShowFilter,
          showResult,
          setShowResult,
          noRecords,
          setNoRecords,
          dropdownLoader,
          setDropdownLoader,
          classes,
          locationType,
          setLocationType,
          filteredInventories,
          setFilteredInventories,
          searchParams,
          setSearchParams,
          conditionsOptions,
          setConditionsOptions,
          departmentOptions,
          setDepartmentOptions,
          subDepartmentOptions,
          setSubDepartmentOptions,
          statusOptions,
          setStatusOptions,
          searchRequest,
          setSearchRequest,
          arrayFileds,
          setArrayFields,
          gridLoader,
          setGridLoader,
        }}
      >
        {props.children}
      </InventorySearchContext.Provider>
    </div>
  );
}
