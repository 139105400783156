import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import React, { useContext } from 'react';
import { ResetPinContext } from './ResetPinContext';

export default function ConfirmationPopUp() {
  const { popUp, setPopUp } = useContext(ResetPinContext);

  const classes = GlobalAdminStyles();
  return (
    <RACModalCard
      isOpen={popUp.confirmationPopUp}
      closeIcon={true}
      maxWidth="xs"
      borderRadius="10px !important"
      className={classes.confirmationPopup1}
      onClose={() => {
        setPopUp({ ...popUp, confirmationPopUp: false });
      }}
    >
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.centerAlign}>
          <Typography style={{ fontSize: '20px', fontFamily: 'OpenSans-bold' }}>
            Are you sure want to reset the PIN
          </Typography>
          <Typography style={{ fontSize: '20px', fontFamily: 'OpenSans-bold' }}>
            for this user?
          </Typography>
        </Grid>
        <Grid item md={12} style={{ textAlign: 'center', marginTop: '25px' }}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              setPopUp({ ...popUp, confirmationPopUp: false });
            }}
          >
            No
          </RACButton>
          <RACButton
            style={{ marginLeft: '15px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setPopUp({ ...popUp, pinPopUp: true, confirmationPopUp: false });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
