import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACTextbox,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { ResetPinContext } from './ResetPinContext';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';

export default function ResetPinFilter(props: any) {
  const classes = GlobalAdminStyles();
  const {
    validationData,
    resetPinSearchObj,
    setResetPinSearchObj,
    errorMessage,
    setErrorMessage,
  } = useContext(ResetPinContext);

  return (
    <Grid container md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12} spacing={2}>
            <Grid item style={{ width: '20%' }}>
              <RACTextbox
                type="text"
                name="employeeId"
                inputlabel="Employee ID"
                value={resetPinSearchObj.employeeId}
                OnKeydown={(e) => {
                  props.keyPress(e);
                }}
                inputLabelClassname={classes.title3}
                maxlength={30}
                className={classes.textBox}
                placeHolder={'Enter Employee Id'}
                OnChange={(e) => {
                  const onlyNumbers = /^[0-9]{1,5}$/;
                  if (!onlyNumbers.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }
                  setResetPinSearchObj({
                    ...resetPinSearchObj,
                    employeeId: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item style={{ width: '20%' }}>
              <RACTextbox
                type="text"
                name="emailId"
                inputlabel="Email ID"
                value={resetPinSearchObj.emailId}
                placeHolder={'Enter Email Id'}
                inputLabelClassname={classes.title3}
                className={classes.textBox}
                errorMessage={errorMessage}
                OnKeydown={(e) => {
                  props.keyPress(e);
                }}
                OnChange={(e) => {
                  setErrorMessage('');
                  setResetPinSearchObj({
                    ...resetPinSearchObj,
                    emailId: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item style={{ width: '60%', marginTop: '30px' }}>
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                disabled={validationData ? false : true}
                className={`${classes.floatRight}`}
                onClick={() => {
                  if (
                    resetPinSearchObj.emailId &&
                    !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                      resetPinSearchObj.emailId
                    )
                  ) {
                    setErrorMessage('Invalid Email Id');
                  } else {
                    setErrorMessage('');
                    props.getResetPinSearchData(resetPinSearchObj);
                  }
                }}
              >
                Apply
              </RACButton>
              <RACButton
                color="primary"
                variant="outlined"
                size="large"
                disabled={validationData ? false : true}
                className={`${classes.floatRight} ${classes.mR1} ${classes.padding6}`}
                onClick={() => {
                  props.clearSearch();
                }}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
