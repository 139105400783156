import React, { useState, useEffect, useContext } from 'react';

import {
  CardContent,
  Card,
  Grid,
  RACButton,
  RACTextbox,
  RACSelect,
  Typography,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACModalCard,
  CircularProgress,
} from '@rentacenter/racstrap';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import { ReactComponent as AlertIcon } from './../../emilyIcons/no-records-found.svg';
import { customerDetailsStyle } from './../Customer/customerStyles';
import { simsStore, status } from '../../constants/LocationConstants';
import {
  formLocationBody,
  formPayload,
  formatePhoneNumber,
  getSearchValidation,
  removeDuplicatePayload,
} from './LocationDependencies/LocationCommonFunc';
import { dropdown } from '../../constants/customerDetailsInterface';
import { getFormatedStatesDropDown } from '../Customer/CustomerDependencies/CustomerSearchDependencies';
import { getDropDownData, getStoreDetails } from '../../api/user';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Filter } from './../../Icons/Filter.svg';
import { ReactComponent as UpArrow } from './../../Icons/up-arrow-filter.svg';
import { ReactComponent as DownArrow } from './../../Icons/down-arrow-filter.svg';
import { LocationContext } from './LocationDependencies/LocationContext';
export default function LocationSearch() {
  const classes = customerDetailsStyle();
  const history = useHistory();

  const [inputCard, setInputCard] = useState<boolean>(true);
  const [storeDetails, setStoreDetails] = useState<boolean>(false);
  const {
    setErrorMessage,
    setErrorMessagePopup,
    errorMessagePopup,
    errorMessage,
  } = useContext(LocationContext);

  const locationTableHeading = [
    { heading: 'Store #' },
    { heading: 'Store Description' },
    { heading: 'Address' },
    { heading: 'City' },
    { heading: 'State' },
    { heading: 'Zip Code' },
    { heading: 'Phone #' },
    { heading: 'Country' },
    { heading: 'Host Brand' },
  ];

  const [locationTableBody, setLocationTableBody] = useState<any>([
    {
      storeId: '98765',
      storeDesc: 'SIOUX FALLS',
      address: 'New Addresss',
      city: 'CIty name',
      state: 'SD',
      zipcode: '987653',
      phone: '(555)555-1225',
      country: 'US',
      hostBrand: '',
    },
  ]);

  const searchData = {
    storeId: '',
    phoneNumber: '',
    addressLine: '',
    zipCode: '',
    city: '',
    state: '0',
    status: '0',
    simsStore: '0',
    hostBrand: '',
    vendorName: '',
  };

  const [searchPayload, setSearchPayload] = useState<any>(searchData);
  const border = '25px !important';
  const onlyLetters = /^[A-Za-z]+$/;
  const onlyNumber = /^[0-9]+$/;

  const [loader, setLoader] = useState<boolean>(false);
  const [dropDownloading, setDropDownloading] = useState<boolean>(true);

  const [validPhone, setValidPhone] = useState<boolean>(false);
  const [states, setStates] = useState<dropdown[]>([
    { label: 'Select', value: '0' },
  ]);
  const [gridStatus, setGridStatus] = useState<number>(200);
  const validationResponse = getSearchValidation(searchPayload, validPhone);

  const checkClearValidation = !(
    searchPayload.storeId != '' ||
    searchPayload.phoneNumber != '' ||
    searchPayload.addressLine != '' ||
    searchPayload.zipCode != '' ||
    searchPayload.city != '' ||
    searchPayload.state != '0' ||
    searchPayload.status != '0' ||
    searchPayload.simsStore != '0' ||
    searchPayload.hostBrand != '' ||
    searchPayload.vendorName != ''
  );

  useEffect(() => {
    fetchDropDown();
  }, []);

  async function fetchDropDown() {
    try {
      setDropDownloading(true);
      let statesResponse: any = await getDropDownData('0');
      statesResponse = statesResponse?.data?.stateList?.getStateResponse;
      if (statesResponse?.length > 0) {
        const states: dropdown[] = getFormatedStatesDropDown(
          statesResponse,
          true
        );
        setStates(states);
      }
      setDropDownloading(false);
    } catch (e: any) {
      setDropDownloading(false);
    }
  }

  function formateZipNumber(value: any) {
    if (value.length > 5) {
      const firstFiveDigits = value.slice(0, 5);
      const remainingDigits = value.slice(5);
      value = `${firstFiveDigits}-${remainingDigits}`;
    } else {
      value = value.replace(/\D/g, '');
    }
    return value;
  }

  function handleInputData(key: string, value: any) {
    if (key == 'phoneNumber') {
      const regex = /^\d{10}$/;
      const isValid = regex.test(value);
      setValidPhone(!isValid);
      value = formatePhoneNumber(value);
    }
    if (key == 'zipCode') {
      value = formateZipNumber(value);
    }
    setSearchPayload({ ...searchPayload, [key]: value });
  }

  async function getLocationResults() {
    try {
      setLoader(true);
      setGridStatus(200);
      setStoreDetails(true);
      setInputCard(false);
      const payload: any = formPayload(searchPayload);
      removeDuplicatePayload(payload);
      const storeDetailsResponse = await getStoreDetails(payload);
      setLoader(false);
      if (
        storeDetailsResponse?.status == 200 ||
        storeDetailsResponse?.status == 204
      ) {
        if (storeDetailsResponse?.data && storeDetailsResponse?.data !== '') {
          const formLocationData = formLocationBody(storeDetailsResponse?.data);
          setLocationTableBody(formLocationData);
          setGridStatus(200);
        } else {
          setGridStatus(204);
          setLocationTableBody([]);
        }
      } else if (storeDetailsResponse?.status == 400) {
        setLocationTableBody([]);
        setGridStatus(400);
        setErrorMessagePopup(true);
        setErrorMessage('Bad Request');
      } else {
        setGridStatus(400);
        setLocationTableBody([]);
        setErrorMessagePopup(true);
        setErrorMessage('Something Went Wrong');
      }
    } catch (e: any) {
      setLoader(false);
      setGridStatus(500);
      setErrorMessagePopup(true);
      setErrorMessage('Something Went Wrong');
    }
  }

  function locationResultTableHeading() {
    return (
      <>
        {locationTableHeading.map((obj: any, index: any) => {
          return <RACTableCell key={index}>{obj.heading}</RACTableCell>;
        })}
      </>
    );
  }

  function locationResultTableBody() {
    const formatCellValue = (value: any) => {
      if (value && value.length > 28) {
        return value.slice(0, 27) + '....';
      } else {
        return value || '-';
      }
    };
    return (
      <>
        {loader ? (
          <RACTableRow>
            <RACTableCell colSpan={12}>
              <Grid className={`${classes.textCenter}`}>
                <CircularProgress />
              </Grid>
            </RACTableCell>
          </RACTableRow>
        ) : (
          locationTableBody.map((obj: any, index: any) => {
            return (
              <RACTableRow
                className={`${classes.fixTableHeadAgrTrsRec} ${classes.pointer}`}
                backgroundColor="white"
                key={index}
                onClick={() => {
                  history.push(`/operations/store/${obj.storeId}`);
                }}
              >
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.linkColor} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.storeId)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.storeDesc)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.address)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.city)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.state)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.zipcode)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.phone)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold}`}
                >
                  {formatCellValue(obj.country)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue} ${classes.semiBold} ${classes.width16}`}
                >
                  {formatCellValue(obj.hostBrand)}
                </RACTableCell>
              </RACTableRow>
            );
          })
        )}
      </>
    );
  }

  function handleNumberAndInsert(e: any) {
    if (!onlyNumber.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    handleInputData(e.target.name, e.target.value);
  }

  function handleEnterKey(e: any) {
    if (e.key == 'Enter' && validationResponse) {
      getLocationResults();
    }
  }

  function handleLettersAndInsert(e: any) {
    if (!onlyLetters.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
    }
    handleInputData(e.target.name, e.target.value);
  }

  function getFilterArrow() {
    return inputCard ? (
      <UpArrow className={`${classes.mL1} ${classes.pointer}`} />
    ) : (
      <DownArrow className={`${classes.mL1} ${classes.pointer}`} />
    );
  }

  function showErrorMessagePopup() {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>

          <Typography className={`${classes.formLabel} ${classes.mt3}`}>
            {errorMessage}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.mt3} ${classes.textCenter}`}>
          <RACButton
            style={{ padding: '7px 10px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMessagePopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  }

  return (
    <Grid container md={12} className={`${classes.pt4}`}>
      <Grid container md={12} className={`${classes.mb3} ${classes.p01}`}>
        <Grid item md={11}>
          <Typography
            variant="h6"
            className={`${classes.floatLeft} ${classes.mt2} ${classes.title}`}
          >
            Location Search
          </Typography>
        </Grid>

        <Grid item md={1}>
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.floatRight}`}
            disabled={!storeDetails}
            onClick={() => {
              setInputCard(!inputCard);
            }}
          >
            <Filter className={`${classes.mR1} ${classes.h1}`} />
            Filter
            {getFilterArrow()}
          </RACButton>
        </Grid>
      </Grid>
      {inputCard ? (
        <Grid container md={12} className={`${classes.mb3} ${classes.p01}`}>
          <Card className={classes.searchCard}>
            <CardContent>
              <Grid container md={12} spacing={2} className={`${classes.mb3}`}>
                <Grid item md={2}>
                  <RACTextbox
                    type="text"
                    name="storeId"
                    inputlabel="Store #"
                    placeHolder={'Store #'}
                    maxlength={5}
                    value={searchPayload.storeId}
                    inputLabelClassname={classes.mb1}
                    errorMessage={''}
                    OnChange={(e: any) => {
                      handleNumberAndInsert(e);
                    }}
                    OnKeydown={(e: any) => {
                      handleEnterKey(e);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    type="text"
                    name="phoneNumber"
                    inputlabel="Phone #"
                    placeHolder={'Phone #'}
                    value={searchPayload.phoneNumber}
                    maxlength={10}
                    inputLabelClassname={classes.mb1}
                    errorMessage={
                      validPhone && searchPayload.phoneNumber != ''
                        ? 'Enter Valid Phone Number'
                        : ''
                    }
                    OnChange={(e: any) => {
                      handleNumberAndInsert(e);
                    }}
                    OnKeydown={(e: any) => {
                      handleEnterKey(e);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    type="text"
                    name="addressLine"
                    inputlabel="Address Line"
                    placeHolder={'Address Line'}
                    maxlength={30}
                    value={searchPayload.addressLine}
                    inputLabelClassname={classes.mb1}
                    errorMessage={''}
                    OnChange={(e: any) => {
                      handleInputData(e.target.name, e.target.value);
                    }}
                    OnKeydown={(e: any) => {
                      handleEnterKey(e);
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <Grid container md={12} spacing={2}>
                    <Grid item md={4}>
                      <RACTextbox
                        type="text"
                        name="zipCode"
                        inputlabel="Zip Code"
                        placeHolder={'Zip Code'}
                        maxlength={10}
                        minlength={5}
                        value={searchPayload.zipCode}
                        inputLabelClassname={classes.mb1}
                        errorMessage={''}
                        OnChange={(e: any) => {
                          handleNumberAndInsert(e);
                        }}
                        OnKeydown={(e: any) => {
                          handleEnterKey(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <RACTextbox
                        type="text"
                        name="city"
                        inputlabel="City"
                        value={searchPayload.city}
                        placeHolder={'City'}
                        maxlength={30}
                        inputLabelClassname={classes.mb1}
                        errorMessage={''}
                        OnChange={(e: any) => {
                          handleLettersAndInsert(e);
                        }}
                        OnKeydown={(e: any) => {
                          handleEnterKey(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <RACSelect
                        inputLabel={'State'}
                        loading={dropDownloading}
                        options={states}
                        name="state"
                        defaultValue={searchPayload.state}
                        onChange={(e: any) => {
                          handleInputData('state', e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <RACSelect
                    inputLabel={'Status'}
                    isDisabled={checkClearValidation}
                    options={status}
                    name="status"
                    defaultValue={searchPayload.status}
                    onChange={(e: any) => {
                      handleInputData('status', e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} md={12}>
                <Grid item md={2}>
                  <RACSelect
                    inputLabel={'SIMS Store'}
                    isDisabled={checkClearValidation}
                    options={simsStore}
                    name="status"
                    defaultValue={searchPayload.simsStore}
                    onChange={(e: any) => {
                      handleInputData('simsStore', e.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    type="text"
                    name="hostBrand"
                    inputlabel="Host Brand"
                    placeHolder={'Host Brand'}
                    maxlength={30}
                    value={searchPayload.hostBrand}
                    inputLabelClassname={classes.mb1}
                    errorMessage={''}
                    OnChange={(e: any) => {
                      handleLettersAndInsert(e);
                    }}
                    OnKeydown={(e: any) => {
                      handleEnterKey(e);
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <RACTextbox
                    type="text"
                    name="vendorName"
                    inputlabel="Vendor Name"
                    placeHolder={'Vendor Name'}
                    value={searchPayload.vendorName}
                    maxlength={30}
                    inputLabelClassname={classes.mb1}
                    errorMessage={''}
                    OnChange={(e: any) => {
                      handleLettersAndInsert(e);
                    }}
                    OnKeydown={(e: any) => {
                      handleEnterKey(e);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  className={`${classes.mt2} ${classes.floatRight}`}
                >
                  <RACButton
                    disabled={!validationResponse}
                    variant="contained"
                    color="primary"
                    className={`${classes.floatRight} ${classes.ms2}`}
                    onClick={() => {
                      getLocationResults();
                    }}
                  >
                    Apply
                  </RACButton>

                  <RACButton
                    disabled={checkClearValidation}
                    variant="outlined"
                    color="primary"
                    className={`${classes.floatRight} ${classes.p10p25}`}
                    onClick={() => {
                      setSearchPayload(searchData);
                    }}
                  >
                    Clear
                  </RACButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        ''
      )}

      {storeDetails ? (
        <Grid container md={12} className={`${classes.p01}`}>
          <Card
            className={
              inputCard && locationTableBody.length > 19
                ? `${classes.searchCardLocation1} ${classes.overflowAuto}`
                : `${classes.searchCardLocation} ${classes.overflowAuto}`
            }
          >
            <CardContent>
              <Grid>
                {gridStatus == 200 ? (
                  <RACTable
                    className={classes.fixTableHeadAgrTrsRec}
                    stickyHeader={true}
                    renderTableHead={locationResultTableHeading}
                    renderTableContent={locationResultTableBody}
                  />
                ) : (
                  <Grid
                    className={
                      inputCard
                        ? `${classes.textCenter} ${classes.mt15}`
                        : `${classes.textCenter} ${classes.mt2}`
                    }
                  >
                    <NoRecordsFound />
                    <Typography
                      className={`${classes.subTitle} ${classes.mb3}`}
                    >
                      No records found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
      <RACModalCard
        isOpen={errorMessagePopup}
        closeIcon={false}
        maxWidth={'xs'}
        borderRadius={border}
      >
        {showErrorMessagePopup()}
      </RACModalCard>
    </Grid>
  );
}
