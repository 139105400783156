import { Grid, Typography } from '@rentacenter/racstrap';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { ReactComponent as NoRecords } from '../../Icons/No records.svg';
import React from 'react';

export default function ErrorContent(props: any) {
  const classes = GlobalStyles();
  return (
    <Grid container md={12}>
      <Grid item md={12} className={`${classes.centerAlign}`}>
        <Typography
          variant="body2"
          className={`${classes.mb4} ${props?.style}`}
        >
          {props?.status == 204 ? <NoRecords /> : <AlertIcon />}
        </Typography>
        <Typography variant="body2">{props?.content}</Typography>
      </Grid>
    </Grid>
  );
}
