import { Grid, CircularProgress } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { CustomerContext } from '../CustomerDependencies/CustomerContext';

export function Loader() {
  const { classes } = useContext(CustomerContext);
  return (
    <Grid className={` ${classes.textCenter}`}>
      <CircularProgress />
    </Grid>
  );
}
