import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import { useContext } from 'react';
import React from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import {
  formatCurrency,
  formatDate,
  truncString,
} from '../../../../utils/InventoryUtils';

export function DepreciationEstimator() {
  const { classes, depreciationEstimator } = useContext(
    InventoryDetailsContext
  );

  return (
    <Grid style={{ marginBottom: '10px' }}>
      <Grid item md={12} style={{ marginBottom: '8px' }}>
        <Typography variant="h5">Depreciation Estimator</Typography>
      </Grid>

      <Card className={`${classes.informationCard}`}>
        <CardContent>
          <Grid container md={12} className={`${classes.cardFirstRow}`}>
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>PO #</Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.po ? depreciationEstimator?.po : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>Vendor</Typography>
              <Typography
                className={`${classes.formValue}`}
                noWrap
                title={
                  depreciationEstimator?.vendor
                    ? depreciationEstimator?.vendor
                    : ''
                }
              >
                {depreciationEstimator?.vendor
                  ? truncString(depreciationEstimator?.vendor, 20, '...')
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>Brand</Typography>
              <Typography
                className={`${classes.formValue}`}
                noWrap
                title={
                  depreciationEstimator?.brand
                    ? depreciationEstimator?.brand
                    : ''
                }
              >
                {depreciationEstimator?.brand
                  ? truncString(depreciationEstimator?.brand, 20, '...')
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Model #
              </Typography>
              <Typography
                className={`${classes.formValue}`}
                noWrap
                title={
                  depreciationEstimator?.modelNumber
                    ? depreciationEstimator?.modelNumber
                    : ''
                }
              >
                {depreciationEstimator?.modelNumber
                  ? depreciationEstimator?.modelNumber
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Serial #
              </Typography>
              <Typography
                className={`${classes.formValue}`}
                noWrap
                title={
                  depreciationEstimator?.serialNumber
                    ? depreciationEstimator?.serialNumber
                    : ''
                }
              >
                {depreciationEstimator?.serialNumber
                  ? depreciationEstimator?.serialNumber
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Purchase Date
              </Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.purchaseDate
                  ? formatDate(depreciationEstimator?.purchaseDate)
                  : '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container md={12} className={`${classes.cardRow}`}>
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Purchase Cost
              </Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.purchaseCost
                  ? formatCurrency(depreciationEstimator?.purchaseCost)
                  : '$ 0.00'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Total Revenue
              </Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.totalRevenue
                  ? formatCurrency(depreciationEstimator?.totalRevenue)
                  : '$ 0.00'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Revenue Turns
              </Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.revenueTurns
                  ? depreciationEstimator?.revenueTurns
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <Typography className={`${classes.formLabel}`}>
                Remaining Value
              </Typography>
              <Typography className={`${classes.formValue}`}>
                {depreciationEstimator?.remainingValue
                  ? formatCurrency(depreciationEstimator?.remainingValue)
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
