import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { LegalHoldContext } from './LegalHoldContext';

export default function LegalHoldFilter(props: any) {
  const classes = GlobalStyles();
  const {
    legalHoldSearchObj,
    setLegalHoldSearchObj,
    dropDownloading,
    reasonOptions,
    validationData,
    clearButtonDisable,
  } = useContext(LegalHoldContext);

  return (
    <Grid container md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12} spacing={2}>
            <Grid item md={2} lg={2} sm={2} xs={2}>
              <RACTextbox
                type="text"
                name="lastName"
                inputlabel="Last Name"
                value={legalHoldSearchObj.lastName}
                OnKeydown={(e) => {
                  props.keyPress(e);
                }}
                OnChange={(e) => {
                  const onlyLetters = /^[A-Za-z]+$/;
                  if (!onlyLetters.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
                  }
                  setLegalHoldSearchObj({
                    ...legalHoldSearchObj,
                    lastName: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={2} xs={2}>
              <RACTextbox
                type="text"
                name="firstName"
                inputlabel="First Name"
                value={legalHoldSearchObj.firstName}
                OnKeydown={(e) => {
                  props.keyPress(e);
                }}
                OnChange={(e) => {
                  const onlyLetters = /^[A-Za-z]+$/;
                  if (!onlyLetters.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
                  }
                  setLegalHoldSearchObj({
                    ...legalHoldSearchObj,
                    firstName: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={2} xs={2}>
              <RACSelect
                name="reason"
                inputLabel="Reason"
                loading={dropDownloading}
                options={reasonOptions}
                defaultValue={legalHoldSearchObj.holdRefCode}
                onChange={(e) => {
                  setLegalHoldSearchObj({
                    ...legalHoldSearchObj,
                    holdRefCode: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={6} className={`${classes.mt2}`}>
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                disabled={validationData ? false : true}
                className={`${classes.floatRight}`}
                onClick={() => {
                  props.getLegalHoldData();
                }}
              >
                Apply
              </RACButton>
              <RACButton
                color="primary"
                variant="outlined"
                size="large"
                disabled={clearButtonDisable ? false : true}
                className={`${classes.floatRight} ${classes.mR1} ${classes.padding6}`}
                onClick={() => {
                  props.clearSearch();
                }}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
