import { Grid, Typography, RACButton } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventorySearchContext } from '../../../context/Inventory/InventorySearchContext';
import { ReactComponent as Filter } from '../Icons/Filter.svg';
import { ReactComponent as Expand } from '../Icons/down-arrow-filter.svg';
import { ReactComponent as Collapse } from '../Icons/up-arrow-filter.svg';

export function SearchHeader() {
  const { showFilter, setShowFilter, showResult } = useContext(
    InventorySearchContext
  );
  return (
    <>
      <Grid container md={12} style={{ paddingBottom: '1rem' }}>
        <Grid item md={11} sm={10} xs={9} lg={11}>
          <Typography variant="h5" style={{ marginTop: '1rem' }}>
            Inventory Search
          </Typography>
        </Grid>
        <Grid
          item
          md={1}
          sm={2}
          xs={3}
          lg={1}
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '0.2rem',
          }}
        >
          <RACButton
            variant="contained"
            color="primary"
            startIcon={<Filter />}
            disabled={!showResult}
            endIcon={showFilter ? <Collapse /> : <Expand />}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            Filter
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
}
