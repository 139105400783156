import {
  Card,
  CardContent,
  Grid,
  RACTable,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import ErrorContent from '../Agreement/ErrorMessageContent';
import { ResetPinContext } from './ResetPinContext';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import ConfirmationPopUp from './ConfirmationPopUp';
import { PinPopUp } from './PinPopUp';
import SuccessPopUp from './SuccessPopUp';

export default function ResetPinGrid(props: any) {
  const classes = GlobalAdminStyles();
  const { pageDisplay, popUp } = useContext(ResetPinContext);

  const renderErrorContent = (content: any, status: any) => (
    <ErrorContent content={content} status={status} style={classes.mt3} />
  );

  const renderTable = () => (
    <>
      <RACTable
        renderTableHead={props.renderResetPinTableHeadFn}
        renderTableContent={props.renderResetPinTableContentFn}
      />
    </>
  );

  return (
    <Grid container md={12} style={{ marginTop: '35px' }}>
      <Grid item md={12}>
        <Typography
          className={`${classes.floatLeft} ${classes.title4} ${classes.mL1} ${classes.mb15}`}
        >
          Search Results
        </Typography>
      </Grid>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            className={
              pageDisplay != 200
                ? classes.fixTableHeadAgrTrsRec1
                : classes.fixTableHeadAgrTrsRec
            }
          >
            {pageDisplay === 204
              ? renderErrorContent('No Records Found', 204)
              : pageDisplay === 200
              ? renderTable()
              : pageDisplay == 400
              ? renderErrorContent('Bad Request', 400)
              : pageDisplay == 500
              ? renderErrorContent('Something Went Wrong', 500)
              : null}
          </Grid>
        </CardContent>
      </Card>
      {popUp.confirmationPopUp ? <ConfirmationPopUp /> : null}
      {popUp.pinPopUp ? <PinPopUp /> : null}
      {popUp.successPopUp ? <SuccessPopUp /> : null}
    </Grid>
  );
}
