import { Card, CardContent, Grid, Typography } from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from '../AgreementContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { formatDate, formatePayment } from '../commonFunctions';

export default function AgrClubContent() {
  const classes = GlobalStyles();
  const { clubData, setdoc } = useContext(AgreementContext);
  const params: any = useParams();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const storeNumber = queryParams.get('storeNumber');
  const history = useHistory();

  const headContent = () => (
    <Grid container className={`${classes.mb3} ${classes.width100}`}>
      <Grid item className={`${classes.width4}`}>
        <Typography
          variant="h1"
          className={` ${classes.mt10} ${classes.title}`}
        >
          Club Details
        </Typography>
      </Grid>
      <Grid item className={`${classes.width5}`}>
        <Typography className={`${classes.mt3}`} variant="body1">
          Customer
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle}`}
          onClick={() => {
            {
              params.customerId
                ? redirect(`/operations/customer/details/${params.customerId}`)
                : null;
            }
          }}
        >
          {clubData.customerName ? clubData.customerName : '-'}
        </Typography>
      </Grid>
      <Grid item className={`${classes.width5}`}>
        <Typography className={`${classes.mt3}`} variant="body1">
          Co-Customer
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle}`}
          onClick={() => {
            {
              clubData.coCustomerId != ''
                ? redirect(
                    `/operations/customer/details/${clubData.coCustomerId}`
                  )
                : null;
            }
          }}
        >
          {clubData.coCustomerName ? clubData.coCustomerName : '-'}
        </Typography>
      </Grid>
      <Grid item className={`${classes.width0}`}>
        <Typography className={`${classes.mt3}`} variant="body1">
          Store#
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle}`}
          onClick={() => {
            {
              storeNumber ? redirect(`/operations/store/${storeNumber}`) : null;
            }
          }}
        >
          {storeNumber ? storeNumber : '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  const clubContent = () => (
    <Grid item md={6}>
      <Grid container md={12}>
        <Grid item md={12}>
          <Typography variant="h4" className={`${classes.mb0}`}>
            Club
          </Typography>
        </Grid>
        <Grid container md={6}>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Membership ID
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.membershipId ? clubData.membershipId : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Status
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.status
                  ? clubData.status.length > 16
                    ? `${clubData.status.substring(0, 16)}...`
                    : clubData.status
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Date opened
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.dateOpened ? formatDate(clubData.dateOpened) : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Current Due Date
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.dueDate ? formatDate(clubData.dueDate) : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container md={6}>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Current Days Late
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.daysLate ? clubData.daysLate : '0'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Enrolled in AutoPay
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.autoPay ? clubData.autoPay : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Date Closed
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {clubData.dateClosed ? formatDate(clubData.dateClosed) : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
              ></Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`}></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const redirect = (path: any) => {
    history.push({
      pathname: path,
    });
  };

  return (
    <Grid>
      <Grid
        container
        md={12}
        className={`${classes.globalBackgroundColor} ${classes.pagePadding}`}
      >
        {headContent()}
        <Grid container md={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2} md={12}>
                {clubContent()}
                <Grid item md={3}>
                  <Typography variant="h4" className={`${classes.mb0}`}>
                    Rate
                  </Typography>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        className={`${classes.mt3} ${classes.Bold}`}
                        variant="body2"
                      >
                        Rate
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={`${classes.mt3}`} variant="body2">
                        {clubData.rate
                          ? formatePayment(clubData.rate)
                          : '$0.00'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        className={`${classes.mt3} ${classes.Bold}`}
                        variant="body2"
                      >
                        Amount Paid
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={`${classes.mt3}`} variant="body2">
                        {' '}
                        {clubData.amountPaid
                          ? formatePayment(clubData.amountPaid)
                          : '$0.00'}{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        className={`${classes.mt3} ${classes.Bold}`}
                        variant="body2"
                      >
                        Schedule
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={`${classes.mt3}`} variant="body2">
                        {clubData.schedule ? clubData.schedule : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="h4" className={`${classes.mb0}`}>
                    Free Time
                  </Typography>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        className={`${classes.mt3} ${classes.Bold}`}
                        variant="body2"
                      >
                        Free Time Days
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={`${classes.mt3}`} variant="body2">
                        {clubData?.freeTimeDays ? clubData.freeTimeDays : '0'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        className={`${classes.mt3} ${classes.Bold}`}
                        variant="body2"
                      >
                        Free Time Amount
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className={`${classes.mt3}`} variant="body2">
                        {clubData?.freeTimeAmount
                          ? formatePayment(clubData.freeTimeAmount)
                          : '$0.00'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item md={6}>
                      <Typography
                        variant="h4"
                        className={`${classes.mt3} ${classes.mb0}`}
                      >
                        Documents
                      </Typography>
                      <Typography
                        className={`${classes.mt3} ${classes.textBlue} ${classes.Bold} ${classes.cursorStyle}`}
                        variant="body2"
                      >
                        <a onClick={() => setdoc(true)}>View Documents</a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
