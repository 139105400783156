/* eslint-disable prettier/prettier */
/* eslint-disable */
import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import { AssignMenuSearchData, ConfirmationPopUp, Disable, DropDown, DropDownLoading, MenuSearchData, ReferenceDetails } from '../../types/MenuPermissionsInterface';

export const AdminContext = createContext<any>('');

export function AdminProvider(props: any) {
  const history = useHistory();
  const classes = GlobalAdminStyles();
  const [loader, setLoader] = useState<boolean>(false);
  const [assignLoader, setAssignLoader] = useState<boolean>(false);
  const [menuSearchData, setMenuSearchData] = useState<MenuSearchData>({
      employeeId: '',
      emailId: '',
      hierarchy: '',
      hierarchyValue: '',
      parentHierarchy: '',
      parentHierarchyValue: '',
      userStore: ''
  });
  const [assignMenuSearchData, setAssignMenuSearchData] = useState<AssignMenuSearchData>({
      employeeId: '',
      hierarchy: '',
      hierarchyValue: '',
      parentHierarchy: '',
      parentHierarchyValue: '',
  });
  const [parentHierarchyType, setParentHierarchyType] = useState([{
      "label": "Select",
      "value": ''
  }])
  const [hierarchyValue, setHierarchyValue] = useState([{
      "label": "Select",
      "value": ''
  }])
  const [parentHierarchyValue, setParentHierarchyValue] = useState([{
      "label": "Select",
      "value": ''
  }])
  const [assignParentHierarchyType, setAssignParentHierarchyType] = useState([{
      "label": "Select",
      "value": ''
  }])
  const [assignHierarchyValue, setAssignHierarchyValue] = useState<any>([])
  const [aSHierarchyValue, setAsHierarchyValue] = useState<any>([])
  const [assignParentHierarchyValue, setAssignParentHierarchyValue] = useState([{
      "label": "Select",
      "value": ''
  }])
  const [userStore, setUserStore] = useState([{
      "label": "No Stores",
      "value": ''
  }])
  const [hierarchyName, setHierarchyName] = useState<string>('Hierarchy value')
  const [dropDownLoading, setDropDownLoading] = useState<DropDownLoading>({
      hierarchyLoading: false,
      hierarchyValueLoading: false,
      parentHierarchyValueLoading: false,
      aMHierarchyValueLoading: false,
      aMParentHierarchyValueLoading: false
  });
  const [hasSelectAll, setHasSelectAll] = useState<boolean>(false);
  const [displaySearch, setDisplaySearch] = useState<boolean>(true);
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false);
  const [displayAssignMenu, setDisplayAssignMenu] = useState<boolean>(false);
  const [filterDisable, setFilterDisable] = useState<boolean>(true);
    const validationData: any =
        (menuSearchData?.employeeId || menuSearchData?.emailId || (menuSearchData?.hierarchy && menuSearchData?.hierarchyValue)) &&
        ((menuSearchData?.parentHierarchyValue && menuSearchData?.parentHierarchy && menuSearchData?.hierarchyValue && menuSearchData?.hierarchy) || (!menuSearchData?.parentHierarchyValue && !menuSearchData?.parentHierarchy));
  const clearButton: boolean =
      (menuSearchData?.employeeId?.trim() != '') ||
      (menuSearchData?.emailId?.trim() != '') ||
      (menuSearchData?.hierarchy?.trim() != '') ||
      (menuSearchData?.hierarchyValue?.trim() != '') ||
      (menuSearchData?.parentHierarchy?.trim() != '') ||
      (menuSearchData?.parentHierarchyValue?.trim() != '')
  const [disable, setDisable] = useState<Disable>({
      employeeId: false,
      emailId: false,
      hierarchy: false,
      hierarchyValue: true,
      parentHierarchy: true,
      parentHierarchyValue: true,
      assignHierarchyValue: true,
      assignParentHierarchy: true,
      assignMenu: true,
      continue: true
  })
  const [pageDisplay, setPageDisplay] = useState<number>(0)
  const [assignMenuPageDisplay, setAssignMenuPageDisplay] = useState<number>(0);
  const [employeePageDisplay, setEmployeePageDisplay] = useState<number>(0)
  const [displayContent, setDisplayContent] = useState<any>('');
  const [menuSearchContent, setMenuSearchContent] = useState<any>([]);
  const [assignMenuContent, setAssignMenuContent] = useState<any>([]);
  const [assignMenuCopy, setAssignMenuCopy] = useState<any>([]);
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [deletedRows, setDeletedRows] = useState<any>([]);
  const [finalResponse, setFinalResponse] = useState<any>([]);
  const [assignCheckedRows, setAssignCheckedRows] = useState<any>([]);
  const [assignHierarchy, setAssignHierarchy] = useState<any>([]);
  const [pinDisplay, setPinDisplay] = useState<number>(0);
  const [successDisplay, setSuccessDisplay] = useState<number>(0);
  const [checkPin, setCheckPin] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [confirmationPopUp, setConfirmationPopUp] = useState<ConfirmationPopUp>({
      iopen: false,
      isPinOpen: false,
      employeePopUp: false,
      successPopUp: false,
      isUser: false,
      isAssign: false,
      isRemove: false,
      isSave: false,
  });
  const [roleData, setRoleData] = useState<any>([]);
  const [referenceDetails, setReferenceDetails] = useState<ReferenceDetails>({
    firstName: '-',
    lastName: '-',
    employeeId: '-',
    role: '-',
    roleCode: '-',
    emailId: '-',
    associationCode: '-',
    address: '-',
    city: '-',
    zipCode: '-',
    phone: '-',
    managerName: '-',
    noOfStores: '-',
    parentAssociationCode: '-',
    countryRefCode: '-',
    stateName: '-',
    companyType: '-',
    companyName: '-',
    lobCode: '-',
    countryName: '-',
    lobName: '-',
    childName: '-',
    childCode: '-',
    parentName: '-',
    parentCode: '-'
});
const [stateDetails, setStateDetails] = useState<any>([]);
  const [employeeId, setEmployeeId] = useState<any>()
  const [dropDown, setDropDown] = useState<DropDown>({
    store: [],
    role: [],
    district: [],
    region: [],
    state: [],
    company: [],
    country: [],
    lob: []
});

  return (
      <div>
          <AdminContext.Provider
              value={{
                  history,
                  classes,
                  loader, setLoader, employeeId, setEmployeeId,
                  menuSearchData, setMenuSearchData,
                  parentHierarchyType, setParentHierarchyType,
                  hierarchyValue, setHierarchyValue, dropDown, setDropDown,
                  parentHierarchyValue, setParentHierarchyValue, employeePageDisplay, setEmployeePageDisplay,
                  displaySearch, setDisplaySearch, hasSelectAll, setHasSelectAll,
                  displaySearchResults, setDisplaySearchResults, aSHierarchyValue, setAsHierarchyValue,
                  displayAssignMenu, setDisplayAssignMenu, assignHierarchy, setAssignHierarchy,
                  filterDisable, setFilterDisable, pinDisplay, setPinDisplay, roleData, setRoleData,
                  validationData, disable, setDisable, assignMenuPageDisplay, setAssignMenuPageDisplay,
                  pageDisplay, setPageDisplay, clearButton, assignMenuContent, setAssignMenuContent,
                  menuSearchContent, setMenuSearchContent, checkPin, setCheckPin, successDisplay, setSuccessDisplay,
                  dropDownLoading, setDropDownLoading, userStore, setUserStore, finalResponse, setFinalResponse,
                  checkedRows, setCheckedRows, hierarchyName, setHierarchyName, confirmationPopUp, setConfirmationPopUp,
                  referenceDetails, setReferenceDetails, displayContent, setDisplayContent, assignCheckedRows, setAssignCheckedRows,
                  assignParentHierarchyType, setAssignParentHierarchyType, assignHierarchyValue, setAssignHierarchyValue,
                  assignParentHierarchyValue, setAssignParentHierarchyValue, assignMenuSearchData, setAssignMenuSearchData,
                  assignLoader, setAssignLoader, assignMenuCopy, setAssignMenuCopy, errorMessage, setErrorMessage,
                  stateDetails, setStateDetails, deletedRows, setDeletedRows
              }}
          >
              {props.children}
          </AdminContext.Provider>
      </div>
  );
}
