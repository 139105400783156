import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@rentacenter/racstrap';
import {
  useAleCustomer,
  useAleCustomerActions,
} from '../../context/AleCustomer/AleCustomerProvider';
import { Footer } from '../../common/Footer/Footer';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AleFooter } from './AleFooter';
import { AleCustomerList } from './AleCustomerList';

export const useStyles = makeStyles(() => ({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
}));

export const AleCustomer = () => {
  const ownClasses = useStyles();
  const { loading, hasApiError } = useAleCustomer();
  const { fetchCustomerDetails } = useAleCustomerActions();

  useEffect(() => {
    fetchCustomerDetails('99999');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerDetailLoaded =
    loading !== undefined && !loading && !hasApiError;

  return (
    <>
      <ApiStateWrapper
        loading={loading}
        hasApiError={hasApiError}
        successContent={<></>}
        classes={{
          loader: ownClasses.contentHeight,
          apiError: ownClasses.contentHeight,
        }}
      />
      {customerDetailLoaded && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AleCustomerList />
          </Grid>

          <Footer>
            <AleFooter />
          </Footer>
        </>
      )}
      {(hasApiError || loading) && <Footer>Not loaded .... </Footer>}
    </>
  );
};
