import React, { createContext, useState } from 'react';
export const ResetPinContext = createContext<any>('');

export function ResetPinProvider(props: any) {
  const [displaySearchResults, setDisplaySearchResults] =
    useState<boolean>(false);
  const [pageDisplay, setPageDisplay] = useState(200);
  const [resetPinSearchObj, setResetPinSearchObj] = useState<ResetPinSearchObj>(
    {
      employeeId: '',
      emailId: '',
    }
  );
  const [checkPin, setCheckPin] = useState<any>('');
  const [userId, setUserId] = useState<any>('');
  const [resetPinGridContent, setResetPinGridContent] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [pinDisplay, setPinDisplay] = useState<number>(0);
  const [successDisplay, setSuccessDisplay] = useState<number>(200);
  const [popUp, setPopUp] = useState<PopUp>({
    confirmationPopUp: false,
    pinPopUp: false,
    successPopUp: false,
  });
  const validationData: boolean =
    resetPinSearchObj?.employeeId?.trim() != '' ||
    resetPinSearchObj?.emailId?.trim() != '';

  interface ResetPinSearchObj {
    employeeId: string;
    emailId: string;
  }

  interface PopUp {
    confirmationPopUp: boolean;
    pinPopUp: boolean;
    successPopUp: boolean;
  }

  return (
    <div>
      <ResetPinContext.Provider
        value={{
          displaySearchResults,
          setDisplaySearchResults,
          pageDisplay,
          setPageDisplay,
          loader,
          setLoader,
          popUp,
          setPopUp,
          validationData,
          resetPinSearchObj,
          userId,
          setUserId,
          setResetPinSearchObj,
          resetPinGridContent,
          setResetPinGridContent,
          errorMessage,
          setErrorMessage,
          checkPin,
          setCheckPin,
          disable,
          setDisable,
          pinDisplay,
          setPinDisplay,
          successDisplay,
          setSuccessDisplay,
        }}
      >
        {props.children}
      </ResetPinContext.Provider>
    </div>
  );
}
