import { Grid, RACButton } from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { AppConfigurationStyles } from '../../../JsStyles/AppConfigurationStyles';
import { useHistory } from 'react-router-dom';
import { AppConfigurationContext } from '../../../context/AppConfigurations/AppConfigurationContext';
import { AssignRule } from './AssignRule';

export function Footer() {
  const styles = AppConfigurationStyles();
  const { viewMode, assignButtonLoader } = useContext(AppConfigurationContext);
  const [showAssignRule, setShowAssignRule] = useState<boolean>(false);
  const history = useHistory();
  return (
    <>
      {showAssignRule ? (
        <AssignRule closePopupFunction={setShowAssignRule} />
      ) : (
        <></>
      )}
      <Grid className={styles.fixedFooter}>
        {!viewMode ? (
          <Grid style={{ float: 'right' }}>
            <RACButton
              variant="contained"
              color="primary"
              disabled={assignButtonLoader}
              onClick={() => {
                setShowAssignRule(true);
              }}
              loading={assignButtonLoader}
            >
              Assign Rule
            </RACButton>
          </Grid>
        ) : (
          <></>
        )}

        <Grid style={{ float: 'left' }}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/dashboard/homepage`,
              });
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
}
