// eslint-disable-next-line @typescript-eslint/no-var-requires
/* eslint-disable */
// import { createBrowserHistory, History } from 'history';
const {createBrowserHistory, History} = require('history');
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/App';
import './index.css';


if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}

export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetName: () => string;
}

window.renderOpsMgmt = (containerId: string, history: typeof History, customProps?: CustomPropInterface) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.render(<App history={history} customProps={customProps} />, elem);
  }
};

window.unmountOpsMgmt = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};
if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });
    window.renderOpsMgmt('root', defaultHistory);
  });
}
