import {
  RACTableRow,
  RACTable,
  RACTableCell,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { NoRecordsCard } from '../../GlobalComponents/NoRecordsCard';
import {
  formatCurrency,
  formatDate,
  sortDateTimeObjects,
  truncString,
  viewPDFclick,
} from '../../../../utils/InventoryUtils';
import { ReactComponent as SortDesc } from '../../Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../../Icons/SortUp.svg';
import { ReactComponent as MoreInfo } from '../../Icons/More info.svg';
import { ReactComponent as ViewPdf } from '../../Icons/View PDF.svg';
import { PaymentDetailsCard } from '../../GlobalComponents/PaymentDetailsCard';
import { getDocument, getInventoryPaymentHistory } from '../../../../api/user';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ErrorPopup from '../../GlobalComponents/ErrorPopup';
import { LoadingScreen } from '../../GlobalComponents/LoadingScreen';

export function PaymentGrid(props?: any) {
  const { classes, paymentHistory, setPaymentHistory } = useContext(
    InventoryDetailsContext
  );
  const routePath: any = useParams();
  const InventoryId = routePath.inventoryId;
  const [showError, setShowError] = useState<boolean>(false);
  const [noDocuments, setNoDocuments] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<boolean>(true);
  const history = useHistory();
  const [paymentCardObj, setPaymentCardObj] = useState<any>();
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const paymentGridHeading = [
    'Date',
    'Receipt #',
    'Store',
    'Origin',
    'Tender',
    'CC Last 4 Digit',
    'EXT',
    'Late',
    'NSF',
    'Total',
    'Action',
  ];

  const fetchNextData = async () => {
    try {
      setLoader(true);
      const paymentResponse = await getInventoryPaymentHistory(
        props?.offset + 10,
        InventoryId
      );
      setLoader(false);
      if (paymentResponse && paymentResponse.status == 200) {
        props.offsetFunc(props?.offset + 10);
        if (paymentResponse?.data?.value?.paymentHistoryGridInfo?.length < 10) {
          setHasMore(false);
        }
        setPaymentHistory([
          ...paymentHistory,
          ...paymentResponse?.data?.value?.paymentHistoryGridInfo,
        ]);
      }
    } catch {
      setLoader(false);
      setShowError(true);
    }
  };

  async function viewDocument(id: string) {
    try {
      setShowLoading(true);
      const printResult = await getDocument(id, 'RCP');
      setShowLoading(false);
      if (
        printResult?.status == 200 &&
        printResult?.data &&
        printResult?.data?.fileList
      ) {
        const responseArray = printResult?.data?.fileList;
        const documentB64 = responseArray.find(
          (obj: any) => obj['docType'] === 'PRCT'
        );
        if (documentB64 && documentB64?.fileObject) {
          viewPDFclick(documentB64?.fileObject, documentB64?.fileName);
        } else {
          setNoDocuments(true);
        }
      } else {
        setNoDocuments(true);
      }
    } catch (error: any) {
      setShowError(true);
    }
  }

  const noHistory = () => (
    <>
      <RACTableRow className={`${classes.noRecordHeight}`}>
        <RACTableCell colSpan={paymentGridHeading?.length}>
          <NoRecordsCard />
        </RACTableCell>
      </RACTableRow>
    </>
  );

  const renderAgreementHeadings = () => (
    <>
      {paymentGridHeading.map((obj: any, index: number) => {
        if (obj == 'Date') {
          return (
            <RACTableCell key={index}>
              {obj}{' '}
              {paymentHistory?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  onClick={() => {
                    const sortedArray = sortDateTimeObjects(
                      paymentHistory,
                      'asc'
                    );
                    setPaymentHistory(sortedArray);
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              ) : (
                <SortAsc
                  onClick={() => {
                    const sortedArray = sortDateTimeObjects(
                      paymentHistory,
                      'desc'
                    );
                    setPaymentHistory(sortedArray);
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              )}
            </RACTableCell>
          );
        }

        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const bindContentOne = (obj: any, index: any) => (
    <>
      <RACTableCell key={index} style={{ width: '17%' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.datePaid
            ? formatDate(obj.datePaid) + ' ' + obj.timePaid + ' CST'
            : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10%' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.receiptId ? obj.receiptId : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '8%' }}>
        <Typography
          className={classes.racpadLink}
          onClick={() => {
            history.push({
              pathname: `/operations/store/${obj?.store}`,
            });
          }}
        >
          {obj.store ? obj.store : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '10%' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.paymentOrigin ? obj.paymentOrigin : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell
        key={index}
        style={{ width: '10%' }}
        title={obj.tenderType ? obj.tenderType : ''}
      >
        {
          <Typography className={`${classes.formValue}`}>
            {obj.tenderType ? truncString(obj.tenderType, 13, '...') : '-'}
          </Typography>
        }
      </RACTableCell>
    </>
  );

  const bindContentTwo = (obj: any, index: number) => (
    <>
      <RACTableCell key={index} style={{ width: '13%' }}>
        {
          <Typography className={`${classes.formValue}`}>
            {obj.cC4Digit ? obj.cC4Digit : '-'}
          </Typography>
        }
      </RACTableCell>

      <RACTableCell key={index}>
        {
          <Typography className={`${classes.formValue}`}>
            {obj.daysExt ? obj.daysExt : '-'}
          </Typography>
        }
      </RACTableCell>

      <RACTableCell key={index}>
        {
          <Typography className={`${classes.formValue}`}>
            {obj.daysLate ? obj.daysLate : '-'}
          </Typography>
        }
      </RACTableCell>

      <RACTableCell key={index}>
        {
          <Typography className={`${classes.formValue}`}>
            {obj.nsf ? obj.nsf : '-'}
          </Typography>
        }
      </RACTableCell>

      <RACTableCell key={index} style={{ width: '7%' }}>
        <Typography className={`${classes.formValue}`}>
          {obj.totalPayment ? formatCurrency(obj.totalPayment) : '-'}
        </Typography>
      </RACTableCell>

      <RACTableCell key={index}>
        <MoreInfo
          className={`${classes.iconSize}`}
          style={{ marginRight: '5px' }}
          onClick={() => {
            setPaymentCardObj(paymentHistory[index]);
            setShowMoreInfo(true);
          }}
        />
        <ViewPdf
          className={`${classes.iconSize}`}
          onClick={() => {
            viewDocument(obj?.receiptId);
          }}
        />
      </RACTableCell>
    </>
  );
  const renderPaymentHistory = () => (
    <>
      {paymentHistory.map((obj: any, index: any) => {
        return (
          <RACTableRow className={`${classes.whitebackground}`} key={index}>
            {bindContentOne(obj, index)}
            {bindContentTwo(obj, index)}
          </RACTableRow>
        );
      })}
    </>
  );

  return (
    <>
      {showLoading ? <LoadingScreen /> : <></>}
      {noDocuments ? (
        <ErrorPopup
          message={'Not able to Get Receipt information'}
          setFunction={setNoDocuments}
        />
      ) : (
        <></>
      )}
      {showError ? <ErrorPopup setFunction={setShowError} /> : <></>}
      {showMoreInfo ? (
        <PaymentDetailsCard
          setFunction={setShowMoreInfo}
          paymentDetails={paymentCardObj}
        />
      ) : (
        <></>
      )}
      <Grid style={{ marginBottom: '80px' }}>
        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Grid item md={12}>
            <Typography variant="h5">Payment History</Typography>
          </Grid>
        </Grid>

        <Grid container md={12}>
          <Grid item md={12}>
            <Card className={`${classes.informationCard}`}>
              <CardContent>
                <InfiniteScroll
                  next={fetchNextData}
                  dataLength={paymentHistory?.length}
                  hasMore={paymentHistory?.length < 10 ? false : hasMore}
                  height={paymentHistory?.length > 6 ? '60vh' : ''}
                  loader={
                    loader ? (
                      <Grid
                        style={{
                          textAlign: 'center',
                          marginBottom: '32px',
                          fontSize: '16px',
                          color: 'black',
                          margin: '30px 0px',
                        }}
                      >
                        <CircularProgress />
                      </Grid>
                    ) : null
                  }
                >
                  <RACTable
                    renderTableHead={renderAgreementHeadings}
                    renderTableContent={
                      paymentHistory?.length == 0
                        ? noHistory
                        : renderPaymentHistory
                    }
                    stickyHeader
                    className={classes.fixTableHeadAgrTrsRec}
                  />
                </InfiniteScroll>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
