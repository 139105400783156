/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  CardContent,
  Grid,
  Card,
  CircularProgress,
  Typography,
  RACSelect,
  RACTable,
} from '@rentacenter/racstrap';
import { useContext } from 'react';
import { AdminContext } from './AdminContext';
import ErrorContent from '../Agreement/ErrorMessageContent';
import { fetchConfigReference } from '../../api/user';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';

export function ContentHeaders(props: any) {
  const {
    loader,
    setLoader,
    menuSearchData,
    setMenuSearchData,
    parentHierarchyType,
    setParentHierarchyType,
    hierarchyValue,
    setHierarchyValue,
    parentHierarchyValue,
    setParentHierarchyValue,
    displaySearch,
    setDisplaySearch,
    displaySearchResults,
    setDisplaySearchResults,
    displayAssignMenu,
    setDisplayAssignMenu,
    filterDisable,
    setFilterDisable,
    buttonDisable,
    hierarchyFieldDisable,
    setHierarchyFieldDisable,
    parentHierarchyFieldDisable,
    setParentHierarchyFieldDisable,
    pageDisplay,
    setPageDisplay,
    displayContent,
    menuSearchContent,
    setMenuSearchContent,
    dropDownLoading,
    setDropDownLoading,
    insertUpdateData,
    setInsertUpdateData,
    validationData,
    userStore,
    referenceDetails,
  } = useContext(AdminContext);

  const classes = GlobalAdminStyles();

  return (
    <>
        {displayContent == "EMPLOYEE" ?
            <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                <Grid item md={3}>
                    <Typography className={`${classes.textBlue}`}>First Name: {referenceDetails.firstName}</Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography className={`${classes.textBlue}`}>Last Name: {referenceDetails.lastName}</Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography className={`${classes.textBlue}`}>Employee Id: {referenceDetails.employeeId}</Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography className={`${classes.textBlue}`}>Role: {referenceDetails.role}</Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography className={`${classes.textBlue}`}>E Mail ID: {referenceDetails.emailId}</Typography>
                </Grid>
            </Grid>
            : displayContent == 'STORE' ?
                <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                    <Grid item md={2}>
                        <Typography className={`${classes.textBlue}`}>Store Number: {referenceDetails.associationCode}</Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography title={referenceDetails.address}
                            className={`${classes.textBlue}`}>Address: {referenceDetails.address ? referenceDetails.address.length > 16 ? `${referenceDetails.address.substring(0, 16)}...` : referenceDetails.address : '-'} </Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography className={`${classes.textBlue}`}>City: {referenceDetails.city}</Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography className={`${classes.textBlue}`}>State: {referenceDetails.stateName}</Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography className={`${classes.textBlue}`}>Zip Code: {referenceDetails.zipCode}</Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography className={`${classes.textBlue}`}>Phone #: {referenceDetails.phone}</Typography>
                    </Grid>
                </Grid> : displayContent == 'DISTRICT' ?
                    <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                        <Grid item md={3}>
                            <Typography className={`${classes.textBlue}`}>District: {referenceDetails.associationCode}</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={`${classes.textBlue}`}>District Manager: {referenceDetails.managerName}</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography className={`${classes.textBlue}`}># of Stores: {referenceDetails.noOfStores}</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography className={`${classes.textBlue}`}>Region: {referenceDetails.parentAssociationCode}</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography className={`${classes.textBlue}`}>Country: {referenceDetails.countryRefCode}</Typography>
                        </Grid>
                    </Grid> : displayContent == 'REGION' ?
                        <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                            <Grid item md={3}>
                                <Typography className={`${classes.textBlue}`}>Region Code: {referenceDetails.associationCode}</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className={`${classes.textBlue}`}>Regional Director: {referenceDetails.managerName}</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className={`${classes.textBlue}`}># of Stores: {referenceDetails.noOfStores}</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <Typography className={`${classes.textBlue}`}>Country: {referenceDetails.countryRefCode}</Typography>
                            </Grid>
                        </Grid> : displayContent == 'STATE' ?
                            <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                <Grid item md={3}>
                                    <Typography className={`${classes.textBlue}`}>State Name: {referenceDetails.stateName}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography className={`${classes.textBlue}`}>State Code: {referenceDetails.associationCode}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography className={`${classes.textBlue}`}># of Stores: {referenceDetails.noOfStores}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography className={`${classes.textBlue}`}>Country: {referenceDetails.countryRefCode}</Typography>
                                </Grid>
                            </Grid> : displayContent == 'COMPANY' ?
                                <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                    <Grid item md={4}>
                                        <Typography title={referenceDetails.companyName} className={`${classes.textBlue}`}>Company Name: {referenceDetails.companyName ? referenceDetails.companyName.length > 40 ?
                                            `${referenceDetails.companyName.substring(0, 40)}...` : referenceDetails.companyName : '-'}</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography className={`${classes.textBlue}`}>Company Code: {referenceDetails.associationCode}</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography className={`${classes.textBlue}`}>Company Type: {referenceDetails.companyType}</Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography className={`${classes.textBlue}`}>LOB Code: {referenceDetails.lobCode}</Typography>
                                    </Grid>
                                </Grid> : displayContent == 'COUNTRY' ?
                                    <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                        <Grid item md={6}>
                                            <Typography className={`${classes.textBlue}`}>Country Name:  {referenceDetails.countryName}</Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography className={`${classes.textBlue}`}>Country Code:  {referenceDetails.countryRefCode}</Typography>
                                        </Grid>
                                    </Grid> : displayContent == 'LOB' ?
                                        <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                            <Grid item md={6}>
                                                <Typography className={`${classes.textBlue}`}>LOB Name:  {referenceDetails.lobName}</Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography className={`${classes.textBlue}`}>LOB Code:  {referenceDetails.lobCode}</Typography>
                                            </Grid>
                                        </Grid>
                                        : displayContent == 'ROLE' ?
                                            <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                                <Grid item md={6}>
                                                    <Typography className={`${classes.textBlue}`}>Role:  {referenceDetails.roleCode}</Typography>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Typography className={`${classes.textBlue}`}>Role Name:  {referenceDetails.role}</Typography>
                                                </Grid>
                                            </Grid> : displayContent == 'STATECOMPANY' ?
                                                <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                                    <Grid item md={3}>
                                                        <Typography className={`${classes.textBlue}`}>State Name: {referenceDetails.childName}</Typography>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <Typography className={`${classes.textBlue}`}>State Code: {referenceDetails.childCode}</Typography>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <Typography title={referenceDetails.parentName} className={`${classes.textBlue}`}>Company Name: 
                                                            {referenceDetails.parentName ? referenceDetails.parentName.length > 40 ?
                                                                `${referenceDetails.parentName.substring(0, 40)}...` : referenceDetails.parentName : '-'}</Typography>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <Typography className={`${classes.textBlue} ${classes.floatRight}`}>Company Code: {referenceDetails.parentCode}</Typography>
                                                    </Grid>
                                                </Grid> : displayContent == 'COUNTRYLOB' ?
                                                    <Grid container md={12} style={{ backgroundColor: '#eff4ff', padding: '15px', borderRadius: '10px' }}>
                                                        <Grid item md={3}>
                                                            <Typography className={`${classes.textBlue}`}>Country Name: {referenceDetails.childName}</Typography>
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <Typography className={`${classes.textBlue}`}>Country Code: {referenceDetails.childCode}</Typography>
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <Typography className={`${classes.textBlue}`}>LOB Name: {referenceDetails.parentName}</Typography>
                                                        </Grid>
                                                        <Grid item md={3}>
                                                            <Typography className={`${classes.textBlue}`}>LOB Code: {referenceDetails.parentCode}</Typography>
                                                        </Grid>
                                                    </Grid> : <></>}
    </>
)
}
