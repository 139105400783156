/*eslint-disable no-console*/
import React, { useRef } from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import {
  getAleCustomers,
  getCurrentUser,
  makeAlePayment,
} from '../../api/user';
import {
  AgreementInfo,
  AleLeaseDTO,
  AlePaymentRequest,
} from '../../types/types';
import { getFormattedDate } from '../../utils/utils';

const EmptyCustomerDetails: AleLeaseDTO[] = [
  {
    agreement: [],
    storeNumber: '',
    storeId: '',
    firstName: '',
    lastName: '',
    customerId: '',
  },
];

export interface AleCustomerState {
  aleCustomerDetails: AleLeaseDTO[];
  loading?: boolean;
  processing?: boolean;
  aleDollarTotal: number;
  statprogress: number;
  aleCount: number;
  exportToPDF: any;
  aleAgmLeftCount: number;
  stagedAgreements: Set<string>;
  paymentResponses: Map<string, string>;
  hasApiError: boolean;
}

export interface AleCustomerDispatchState {
  fetchCustomerDetails: (storeId: string) => void;
  updateAleCount: (count: number) => void;
  updateAgmLeftCount: (count: number) => void;
  updateDollarTotal: (count: number) => void;
  updateStagedAgms: (staged: Set<string>) => void;
  makePaymentForCustomer: () => void;
  saveToPdf: () => void;
}

export const AleCustomerStateContext = createContext<AleCustomerState>(
  {} as AleCustomerState
);
export const AleCustomerDispatchContext =
  createContext<AleCustomerDispatchState>({} as AleCustomerDispatchState);

export const AleCustomerProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>();
  const [processing, setProcessing] = useState<boolean>();
  const [hasApiError, setHasApiError] = useState(false);
  const [aleCount, setAleCount] = useState(0);
  const [statprogress, setStatprogress] = useState(0);
  const [aleAgmLeftCount, setAleAgmLeftCount] = useState(0);
  const [aleDollarTotal, setAleDollarTotal] = useState(0);
  const [stagedAgreements, setStagedAgreements] = useState(new Set<string>());
  const svcStatusMap = useRef(new Map<string, string>());
  const exportToPDF = useRef<HTMLTableElement | null>(null);
  const [paymentResponses, setpaymentResponses] = useState(
    new Map<string, string>()
  );
  const [coworker, setCoworker] = useState('');

  const [aleCustomerDetails, setAleCustomerDetails] =
    useState<AleLeaseDTO[]>(EmptyCustomerDetails);

  const updateAleCount = (count: number) => setAleCount(count);
  const updateAgmLeftCount = (count: number) => setAleAgmLeftCount(count);
  const updateDollarTotal = (count: number) => setAleDollarTotal(count);
  const updateStagedAgms = (staged: Set<string>) => setStagedAgreements(staged);

  const isStaged = (oneAgm: AgreementInfo): boolean => {
    if (stagedAgreements?.has(oneAgm.agreementId)) {
      return true;
    }

    return false;
  };

  const filterColumns = (): any[] => {
    const rows =
      exportToPDF &&
      exportToPDF.current &&
      exportToPDF.current.querySelectorAll('tr');

    // Create an array to hold filtered data
    const filteredData: any[] = [];

    if (rows) {
      rows.forEach((row) => {
        const cells = row.querySelectorAll('th, td');
        const rowData: any[] = [];

        cells.forEach((cell, cellIndex) => {
          // Ignore statusing  columns
          if (cellIndex !== 10 && cellIndex !== 11) {
            rowData.push(cell.textContent);
          }
        });

        // Add the filtered row data to the filteredData array
        filteredData.push(rowData);
      });
    }
    return filteredData;
  };

  const saveToPdf = () => {
    const filtered = filterColumns();
    if (exportToPDF.current && filtered && filtered.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(filtered);
      // const worksheet = XLSX.utils.table_to_sheet(exportToPDF.current);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, getFormattedDate());

      const workbookBinary = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const alefilename = `rpt-${getFormattedDate()}-ALE.xlsx`;
      saveAs(
        new Blob([workbookBinary], { type: 'application/octet-stream' }),
        alefilename
      );
    }
  };

  const calculateInitTotals = (aleAgreements: AleLeaseDTO[]): void => {
    let count = 0;
    aleAgreements.forEach((customer) => {
      customer.agreement.forEach((agm) => {
        const numTotal = parseFloat(agm.regularTotal);
        if (!isNaN(numTotal)) {
          count = count + 1;
        }
      });
    });
    setAleAgmLeftCount(count);
    setAleCustomerDetails(aleAgreements);
  };
  const buildPayload = (
    oneAgm: AgreementInfo,
    oneCustomer: AleLeaseDTO
  ): any => {
    const payload: AlePaymentRequest = {
      alePayment: [
        {
          storeNumber: oneCustomer.storeNumber,
          coWorkerId: coworker,
          customerId: oneCustomer.customerId,
          agreementId: oneAgm.agreementId,
          amount: oneAgm.regularTotal,
        },
      ],
    };

    return payload;
  };

  const retrieveServiceMsg = (output: any): string => {
    let msg = 'UNABLE to make payment!';
    if (output?.errors) {
      const parseableJson = output.errors?.[0]?.message;
      const objWithMsg = parseableJson && JSON.parse(parseableJson);
      msg = objWithMsg?.receiptInfo?.[0]?.statusMsg;
    }
    if (output?.receiptInfo?.[0]?.statusMsg) {
      msg = output.receiptInfo[0].statusMsg;
    }
    return msg;
  };
  const makePaymentForCustomer = async () => {
    // go through all of the agreements and if it is staged call the service to make the payment
    svcStatusMap.current = new Map<string, string>();
    setProcessing(true);
    const totalCount: number = stagedAgreements.size;
    const progressCount = 1;
    setStatprogress(0);

    for (const oneCustomer of aleCustomerDetails) {
      // one customer but how many agreements do they have ?
      for (const oneAgm of oneCustomer.agreement) {
        if (isStaged(oneAgm)) {
          const payload = buildPayload(oneAgm, oneCustomer);
          if (payload) {
            const out = await makeAlePayment(payload);
            const msg = retrieveServiceMsg(out?.data);

            const newResponse = new Map<string, string>(svcStatusMap.current);
            svcStatusMap.current.set(oneAgm.agreementId, msg);
            newResponse.set(oneAgm.agreementId, msg);
            const prog: number = (progressCount / totalCount) * 100;
            setStatprogress(Math.floor(prog));
            setpaymentResponses(newResponse);
          }
        }
      }
    }
    setProcessing(false);
  };

  const fetchCustomerDetails = async (storeId: string) => {
    setHasApiError(false);
    setLoading(true);
    const userDetails: any = await getCurrentUser();
    const employeeId = userDetails?.data?.employeeId;

    setCoworker(employeeId);
    await getAleCustomers(storeId)
      .then((response) => {
        calculateInitTotals(response.data);
      })
      .then((error) => console.log(error))
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  return (
    <AleCustomerStateContext.Provider
      value={{
        aleCustomerDetails,
        loading,
        processing,
        statprogress,
        aleCount,
        aleAgmLeftCount,
        aleDollarTotal,
        hasApiError,
        exportToPDF,
        stagedAgreements,
        paymentResponses,
      }}
    >
      <AleCustomerDispatchContext.Provider
        value={{
          fetchCustomerDetails,
          makePaymentForCustomer,
          updateAleCount,
          updateAgmLeftCount,
          updateDollarTotal,
          updateStagedAgms,
          saveToPdf,
        }}
      >
        {props.children}
      </AleCustomerDispatchContext.Provider>
    </AleCustomerStateContext.Provider>
  );
};

export const useAleCustomer = () => useContext(AleCustomerStateContext);

export const useAleCustomerActions = () =>
  useContext(AleCustomerDispatchContext);
