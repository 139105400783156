import React, { createContext, useState } from 'react';

export const LocationContext = createContext<any>('');

export function LocationContextProvider(props: any) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);

  return (
    <LocationContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        errorMessagePopup,
        setErrorMessagePopup,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
}
