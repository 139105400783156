import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import { customerDetailsStyle } from './../customerStyles';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as DetailsIcon } from '../../../emilyIcons/More info.svg';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import React from 'react';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { Loader } from '../CommonCompo/Loader';

export default function ResidenceTab(props: any) {
  const classes: any = customerDetailsStyle();

  const residenceInfoHeading: tableHeadings[] = [
    { heading: 'Status' },
    { heading: 'Rent/Own' },
    { heading: "Landlord's Name" },
    { heading: 'Phone #' },
    { heading: 'Time To Call' },
    { heading: 'Action' },
  ];

  const residenceTableBody = props.value.tableBodyOne;

  const residenceTabHeading = () => (
    <>
      {residenceInfoHeading.map((obj: any, index: any) => {
        if (obj.heading == 'Action') {
          return (
            <RACTableCell
              key={index}
              className={`${classes.bold} ${classes.textCenter}`}
            >
              {obj.heading}
            </RACTableCell>
          );
        }
        return (
          <RACTableCell key={index} className={`${classes.bold}`}>
            {obj.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  const residenceTabContent = () => (
    <>
      {residenceTableBody.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.active == 'Y' ? 'Active' : 'In Active'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.monthlyRent ? 'Rent' : 'Own'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.landlordFirstName
                ? obj.landlordLastName
                  ? obj.landlordFirstName + ' ' + obj.landlordLastName
                  : obj.landlordFirstName
                : ''}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.phoneNumber
                ? `(${obj.phoneNumber.slice(0, 3)}) ${obj.phoneNumber.slice(
                    3,
                    6
                  )}-${obj.phoneNumber.slice(6)}`
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.bestTimeToCallDesc ? obj.bestTimeToCallDesc : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '10%' }}
              className={`${classes.textCenter}`}
            >
              <DetailsIcon
                className={classes.iconWidth}
                onClick={() => {
                  props.value.setShowResidencPopup(true);
                }}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01}`}
    >
      <Card
        className={`${classes.tabsCard} ${classes.mt3} ${classes.textCenter}`}
      >
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid container md={12}>
              {props.value.status == 200 ? (
                <RACTable
                  renderTableHead={residenceTabHeading}
                  renderTableContent={residenceTabContent}
                />
              ) : props.value.status == 204 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <NoRecordsFound />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    No Records Found
                  </Typography>
                </Grid>
              ) : props.value.status == 400 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Bad Request
                  </Typography>
                </Grid>
              ) : (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Something Went Wrong
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
