import { tableHeadings } from './customerDetailsInterface';
import { CustomerFormData, dropdown } from './customerSearchInterface';

export const somethingWentWrong = 'Something Went Wrong';
export const borderStyle = '25px !important';

export const workHistoryTableHeading: tableHeadings[] = [
  { heading: 'Date' },
  { heading: 'Worked Result' },
  { heading: 'Late' },
  { heading: 'Phone #' },
  { heading: 'Relation' },
  { heading: 'Coworker' },
  { heading: 'Action' },
];

export const paymentHistoryTableHeading: tableHeadings[] = [
  { heading: 'Date' },
  { heading: 'Receipt #' },
  { heading: 'Store' },
  { heading: 'Origin' },
  { heading: 'Tender' },
  { heading: 'CC Last 4 Digit' },
  { heading: 'EXT' },
  { heading: 'Late' },
  { heading: 'NSF' },
  { heading: 'Total' },
  { heading: 'Action' },
];

export const taxExamptDDStatus = [
  { label: 'Select', value: '0' },
  { label: 'Pending', value: 'PEND' },
  { label: 'Exempt', value: 'APP' },
];

export const taxExamptDenialDDStatus = [
  { label: 'Select', value: '0' },
  { label: 'Pending', value: 'PEND' },
  { label: 'Exempt', value: 'APP' },
  { label: 'Denied', value: 'DENY' },
];

// const user = false;

// export const taxExamptTableHeading: tableHeadings[] = [
//   { heading: 'Tax Id' },
//   { heading: 'Status' },
//   { heading: 'Effective Date' },
//   { heading: 'Expiration Date' },
//   { heading: 'Approval/Denial Date' },
//   { heading: 'Denial Reason' },
//   user ? { heading: 'Action' } : { heading: '' },
// ];

export const legalTableHeading: tableHeadings[] = [
  { heading: 'Effective Date' },
  { heading: 'Reason' },
  { heading: 'Closed Date' },
  { heading: 'Closed Reason' },
  { heading: 'Action' },
];

export const taxExamptDetails: any = {
  customerId: 0,
  taxId: '',
  status: '0',
  effectiveDate: '',
  expirationDate: '',
  approvalDenailDate: '',
  denialReason: '0',
};

export const taxExamptEditIndexDetails: any = {
  customerId: 0,
  taxId: '',
  status: '0',
  effectiveDate: '',
  expirationDate: '',
  approvalDenailDate: '',
  denialReason: '0',
};

export const taxExampttableBody: any[] = [
  {
    taxId: '9876543',
    status: 'Approved',
    effectiveDate: '-',
    approvalDenail: '-',
    denialReason: 'Denied',
    expirationDate: '-',
  },
];

export const paymentOptionTableBodies: any = [
  {
    remotePayment: '-',
    ach: '-',
    eligibleForPay: '',
    checks: '-',
    enrollToAutoPay: '-',
  },
];

export const phonePopupDetailsHeading: tableHeadings[] = [
  { heading: 'Status' },
  { heading: 'Phone #' },
  { heading: 'EXT' },
  { heading: 'Time to Call' },
  { heading: 'Type' },
  { heading: 'Is Primary' },
];

export const unholdDropDown: dropdown[] = [
  { label: 'Select', value: '0' },
  { label: 'Human Error', value: 'Human Error' },
];

export const inputErrorMessage = {
  email: 'Enter valid Email Address',
  phone: 'Enter valid Phone Number',
};

export const customerSearchHeading: tableHeadings[] = [
  { heading: 'Last Name' },
  { heading: 'First Name' },
  { heading: 'SSN' },
  { heading: 'Email Address' },
  { heading: 'Date of Birth' },
  { heading: 'Address' },
  { heading: 'City' },
  { heading: 'State' },
  { heading: 'Zip Code' },
  { heading: 'Phone #' },
  { heading: 'Active' },
];

export const customerFormData: CustomerFormData = {
  lastName: '',
  firstName: '',
  dateOfBirth: '',
  ssn1: '',
  ssn2: '',
  ssn3: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  zip: '',
  city: '',
  state: '0',
  govIdType: '0',
  govtId: '',
  customerId: '',
};

//DropDowns

//Regex
export const onlyLetters = /^[A-Za-z]+$/;
export const mail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const onlyNumber = /^[0-9]+$/;
