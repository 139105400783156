import {
  CardContent,
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
} from '@rentacenter/racstrap';
import { Card } from '@rentacenter/racstrap';
import { AgreementContext } from '../AgreementContext';
import React, { useContext } from 'react';
import { AgreementType, Status } from '../../../constants/constants';

export default function Agrsearchfilter(props: any) {
  const {
    agreementSearchData,
    setAgreementSearchData,
    fieldDisable,
    setFieldDisable,
    validationData,
    classes,
    clearButtonDisable,
  } = useContext(AgreementContext);

  const nameRegexFn = (e: any) =>
    props?.handleInputChange(e, /^[A-Za-z-' ']+$/, /[^A-Za-z-' ']/g);

  return (
    <Grid container md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container md={12} spacing={2}>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="lastName"
                inputlabel="Last Name"
                disabled={fieldDisable}
                maxlength={30}
                value={agreementSearchData.lastName}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  nameRegexFn(e);
                  props?.handleOnChange(e);
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="firstName"
                inputlabel="First Name"
                disabled={fieldDisable}
                maxlength={30}
                value={agreementSearchData.firstName}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  nameRegexFn(e);
                  props?.handleOnChange(e);
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="storeNumber"
                inputlabel="Store #"
                disabled={fieldDisable}
                maxlength={5}
                value={agreementSearchData?.storeNumber}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                OnChange={(e: any) => {
                  props?.handleInputChange(e, /^[0-9]{1,5}$/, /[^0-9]/g);
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACTextbox
                type="text"
                name="agreementNumber"
                inputlabel="Agreement #"
                maxlength={19}
                OnKeydown={(e: any) => {
                  props?.onKeyPress(e);
                }}
                value={agreementSearchData?.agreementNumber}
                OnChange={(e: any) => {
                  e.target.value.trim() != ''
                    ? setFieldDisable(true)
                    : setFieldDisable(false);
                  props?.handleOnChange(e);
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACSelect
                name="agreementType"
                inputLabel="Agreement Type"
                isDisabled={fieldDisable}
                options={AgreementType}
                defaultValue={agreementSearchData?.agreementType}
                onChange={(e: any) => {
                  setAgreementSearchData({
                    ...agreementSearchData,
                    agreementType: e?.target?.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={2} lg={2} sm={4} xs={6}>
              <RACSelect
                name="status"
                inputLabel="Status"
                isDisabled={fieldDisable}
                options={Status}
                defaultValue={agreementSearchData?.status}
                onChange={(e: any) => {
                  setAgreementSearchData({
                    ...agreementSearchData,
                    status: e?.target?.value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container md={12} spacing={2}>
            <Grid
              item
              md={12}
              lg={12}
              sm={12}
              xs={12}
              className={`${classes.mt2}`}
            >
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                disabled={
                  validationData ||
                  agreementSearchData?.agreementNumber?.trim() !== ''
                    ? false
                    : true
                }
                className={`${classes.floatRight}`}
                onClick={() => {
                  props?.fetchAgreementSearchData();
                }}
              >
                Apply
              </RACButton>
              <RACButton
                color="primary"
                variant="outlined"
                size="large"
                disabled={clearButtonDisable ? false : true}
                className={`${classes.floatRight} ${classes.mR1} ${classes.padding6}`}
                onClick={() => {
                  props?.clearSearch();
                }}
              >
                Clear
              </RACButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
