import {
  Card,
  CardContent,
  Grid,
  RACTooltip,
  Typography,
} from '@rentacenter/racstrap';
import { GlobalStyles } from '../../../JsStyles/globalStyles';
import React, { useContext } from 'react';
import { AgreementContext } from '../AgreementContext';
import { ReactComponent as Info } from '../../../Icons/info-tooltip.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  formatDate,
  formatePayment,
  getAgrTypeDisplay,
} from '../commonFunctions';

export default function AgrContent() {
  const classes = GlobalStyles();
  const { agreementData, setdoc } = useContext(AgreementContext);
  const params: any = useParams();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const agrType = queryParams.get('agrType');
  const storeNumber = queryParams.get('storeNumber');
  const history = useHistory();

  const contentHead = () => (
    <Grid container className={`${classes.mb3} ${classes.width100}`}>
      <Grid item className={`${classes.width4}`}>
        <Typography
          variant="h1"
          className={` ${classes.mt10}  ${classes.title1}`}
        >
          {agrType != 'notes'
            ? 'Agreement Details'
            : 'Installment Note Details'}
        </Typography>
      </Grid>
      <Grid item className={`${classes.width5}`}>
        <Typography className={`${classes.mt3}`} variant="body1">
          Customer
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle}`}
          onClick={() => {
            {
              params.customerId
                ? redirect(
                    `/operations/customer/details/${agreementData.customerId}`
                  )
                : null;
            }
          }}
        >
          {agreementData.customerName ? agreementData.customerName : '-'}
        </Typography>
      </Grid>
      <Grid item className={`${classes.width5}`}>
        <Typography className={`${classes.mt3}`} variant="body1">
          Co-Customer
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle}`}
          onClick={() => {
            {
              agreementData.coCustomerId != ''
                ? redirect(
                    `/operations/customer/details/${agreementData.coCustomerId}`
                  )
                : null;
            }
          }}
        >
          {agreementData.coCustomerName ? agreementData.coCustomerName : '-'}
        </Typography>
      </Grid>
      <Grid item className={`${classes.width0}`}>
        <Typography
          className={`${classes.mt3} ${classes.centerAlign}`}
          variant="body1"
        >
          Store#
        </Typography>
        <Typography
          className={`${classes.textBlue} ${classes.cursorStyle} ${classes.centerAlign}`}
          onClick={() => {
            {
              storeNumber ? redirect(`/operations/store/${storeNumber}`) : null;
            }
          }}
        >
          {agreementData.storeId ? agreementData.storeId : '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  const agreementContent = () => (
    <Grid item md={4}>
      <Typography variant="h4" className={`${classes.mb0}`}>
        Agreement
      </Typography>
      <Grid container md={12}>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Agreement #
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.agreement ? agreementData.agreement : '-'}
            </Typography>
          </Grid>
        </Grid>
        {agreementData.eComm && (
          <Grid container md={12}>
            <Grid item md={4}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                eComm Order #
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.eComm ? agreementData.eComm : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Description
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.description ? agreementData.description : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Agreement Type
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.agreementType
                ? getAgrTypeDisplay(agreementData.agreementType)
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        {agrType !== 'notes' && (
          <Grid container md={12}>
            <Grid item md={4}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Reinstatement
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.reinstatement
                  ? agreementData.reinstatement
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        {agrType !== 'notes' && (
          <Grid container md={12}>
            <Grid item md={4}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Coverage
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.coverage ? agreementData.coverage : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        {agrType === 'notes' && (
          <Grid container md={12}>
            <Grid item md={4}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Agreement
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData?.status ? agreementData?.status : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container md={12}>
        <Grid item md={6}>
          <Typography variant="h4" className={`${classes.mt3}`}>
            Documents
          </Typography>
          <Typography
            className={`${classes.mt3} ${classes.textBlue} ${classes.Bold} ${classes.cursorStyle}`}
            variant="body2"
          >
            <a onClick={() => setdoc(true)}>View Documents</a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const statusContent = () => (
    <Grid item md={3}>
      <Typography variant="h4" className={`${classes.mb0}`}>
        Status
      </Typography>
      <Grid container md={12}>
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Status
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData?.status ? agreementData?.status : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Date opened
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.dateOpened
                ? formatDate(agreementData.dateOpened)
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Current Due Date
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.currentDueDate
                ? formatDate(agreementData.currentDueDate)
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Current Days Late
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.currentDaysLate
                ? agreementData.currentDaysLate
                : '0'}
            </Typography>
          </Grid>
        </Grid>
        {agrType !== 'notes' && (
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Enrolled in Auto Pay
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.autoPay ? agreementData.autoPay : 'No'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Date Closed
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.dateClosed ? agreementData.dateClosed : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt3} ${classes.Bold}`}
              variant="body2"
            >
              Closed Reason
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography className={`${classes.mt3}`} variant="body2">
              {agreementData.closedReason ? agreementData.closedReason : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const termContent = () => (
    <Grid container md={5}>
      <Grid container md={12}>
        <Grid item md={7}>
          <Typography
            className={`${classes.mt3} ${classes.Bold}`}
            variant="body2"
          >
            Term
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Typography className={`${classes.mt3}`} variant="body2">
            {agreementData.term ? agreementData.term : '0'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={12}>
        <Grid item md={7}>
          <Typography
            className={`${classes.mt3} ${classes.Bold}`}
            variant="body2"
          >
            Remaining Term
          </Typography>
        </Grid>
        <Grid item md={5}>
          <Typography className={`${classes.mt3}`} variant="body2">
            {agreementData.remainingTerm ? agreementData.remainingTerm : '0'}
          </Typography>
        </Grid>
      </Grid>
      {agrType !== 'notes' && (
        <>
          <Grid container md={12}>
            <Grid item md={7}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Total rent to own
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.totalRent
                  ? formatePayment(agreementData.totalRent)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={7}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Cash Price
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.cashPrice
                  ? formatePayment(agreementData.cashPrice)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={7}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Agreement RV
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.agreementRv
                  ? formatePayment(agreementData.agreementRv)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {agrType === 'notes' && (
        <>
          <Grid container md={12}>
            <Grid item md={7}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Total Cost
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.totalCost
                  ? formatePayment(agreementData.totalCost)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={7}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Remaining Notes
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.remainingNotes
                  ? formatePayment(agreementData.remainingNotes)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  const rateContent = () => (
    <Grid item md={5}>
      <Typography variant="h4" className={`${classes.mb0}`}>
        Rate
      </Typography>
      <Grid container md={12}>
        <Grid container md={7}>
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Rate
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.rate
                  ? formatePayment(agreementData.rate)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
          {agreementData.promotionApplied && (
            <Grid container md={12}>
              <Grid item md={6}>
                <Typography
                  className={`${classes.mt3} ${classes.Bold}`}
                  variant="body2"
                >
                  Promotional Applied
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography className={`${classes.mt3}`} variant="body2">
                  {agreementData.promotionApplied
                    ? agreementData.promotionApplied
                    : '-'}
                  <RACTooltip
                    placement={'top-start'}
                    className={`${classes.tooltipcss} ${classes.cursorStyle}`}
                    title={
                      <>
                        <Grid>
                          <Typography>Promotional Description:</Typography>
                          <Typography>{agreementData.promoDesc}</Typography>
                        </Grid>
                      </>
                    }
                  >
                    <Info className={`${classes.width8}`} />
                  </RACTooltip>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Schedule
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.schedule ? agreementData.schedule : '-'}
              </Typography>
            </Grid>
          </Grid>
          {agrType !== 'notes' && (
            <Grid container md={12}>
              <Grid item md={6}>
                <Typography
                  className={`${classes.mt3} ${classes.Bold}`}
                  variant="body2"
                >
                  Rent paid
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography className={`${classes.mt3}`} variant="body2">
                  {agreementData.rentPaid
                    ? formatePayment(agreementData.rentPaid)
                    : '$0.00'}
                </Typography>
              </Grid>
            </Grid>
          )}
          {agrType === 'notes' && (
            <Grid container md={12}>
              <Grid item md={6}>
                <Typography
                  className={`${classes.mt3} ${classes.Bold}`}
                  variant="body2"
                >
                  Note paid
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography className={`${classes.mt3}`} variant="body2">
                  {agreementData.notePaid
                    ? formatePayment(agreementData.notePaid)
                    : '$0.00'}
                </Typography>
              </Grid>
            </Grid>
          )}
          {agrType !== 'notes' && (
            <Grid container md={12}>
              <Grid item md={6}>
                <Typography
                  className={`${classes.mt3} ${classes.Bold}`}
                  variant="body2"
                >
                  EPO/SAC
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography className={`${classes.mt3}`} variant="body2">
                  {agreementData.epo
                    ? formatePayment(agreementData.epo)
                    : '$0.00'}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container md={12}>
            <Grid item md={6}>
              <Typography
                className={`${classes.mt3} ${classes.Bold}`}
                variant="body2"
              >
                Back Rent
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className={`${classes.mt3}`} variant="body2">
                {agreementData.backRent
                  ? formatePayment(agreementData.backRent)
                  : '$0.00'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {termContent()}
      </Grid>
      <Typography variant="h4" className={`${classes.mt3}`}>
        Free Time
      </Typography>
      <Grid container md={12}>
        <Grid container md={7}>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt2} ${classes.Bold}`}
              variant="body2"
            >
              Free Time days
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              className={`${classes.mt2} ${classes.Bold}`}
              variant="body2"
            >
              {agreementData.freeTimeDays ? agreementData.freeTimeDays : '0'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={5}>
          <Grid item md={7}>
            <Typography
              className={`${classes.mt2} ${classes.Bold}`}
              variant="body2"
            >
              Free Time Amount
            </Typography>
          </Grid>
          <Grid item md={5}>
            <Typography className={`${classes.mt2}`} variant="body2">
              {agreementData.freeTimeAmount
                ? formatePayment(agreementData.freeTimeAmount)
                : '$0.00'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const redirect = (path: any) => {
    history.push({
      pathname: path,
    });
  };

  return (
    <Grid
      container
      md={12}
      className={`${classes.globalBackgroundColor} ${classes.pagePadding}`}
    >
      {contentHead()}
      <Grid container md={12}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={2} md={12}>
              {agreementContent()}
              {statusContent()}
              {rateContent()}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
