import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  RACButton,
  RACModalCard,
  RACCard,
  Typography,
  CircularProgress,
  RACCOLOR,
  Dialog,
  DialogContent,
  LinearProgress,
  Box,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import {
  useAleCustomer,
  useAleCustomerActions,
} from '../../context/AleCustomer/AleCustomerProvider';

const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1100,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  textlabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.typography.pxToRem(10),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
    padding: '5rem',
  },
  formLabel: {
    color: '#111111',
    fontSize: '14px',
    paddingLeft: theme.typography.pxToRem(45),
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-bold',
    marginTop: '8px',
  },
  formLabelValue: {
    color: '#6e6e6e',
    fontSize: '16px',
    fontFamily: 'OpenSans-bold',
    marginTop: '8px',
    paddingRight: theme.typography.pxToRem(45),
  },
  progress: {
    background: RACCOLOR.GRAY,
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
  leftbutton: {
    height: theme.typography.pxToRem(43),
    width: theme.typography.pxToRem(150),
    display: 'flex',
    justifyContent: 'flex-start !important',
  },
}));

export const AleFooter = () => {
  const classes = useStyles();
  const {
    aleCount,
    aleAgmLeftCount,
    aleDollarTotal,
    processing,
    statprogress,
  } = useAleCustomer();
  const { makePaymentForCustomer, saveToPdf } = useAleCustomerActions();
  const [areYouSureModal, setAreYouSureModal] = useState(false);

  const modalCardRadius = '25px !important';

  const rYouSurePopup = () => {
    return (
      <>
        <Typography
          style={{ fontSize: '16px', textAlign: 'center', marginTop: '-17px' }}
        >
          Are you sure you would like to make all these payments?{' '}
        </Typography>
        <RACButton
          style={{
            marginTop: '25px',
            marginLeft: '100px',
            padding: '10px 30px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setAreYouSureModal(false);
          }}
        >
          Cancel
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '25px',
            padding: '10px 30px',
            marginLeft: '18px',
          }}
          onClick={async () => {
            setAreYouSureModal(false);
            await makePaymentForCustomer();
          }}
        >
          Ok
        </RACButton>
      </>
    );
  };

  return (
    <div className={clsx(classes.footerRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <RACButton
                  className={clsx(classes.leftbutton)}
                  variant="contained"
                  size="small"
                  color="primary"
                  key="footerRefreshCallList"
                  onClick={() => {
                    saveToPdf();
                  }}
                >
                  Save to Excel
                </RACButton>
              </Grid>
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                classes={{ root: classes.buttonContainer }}
              >
                <div className={clsx(classes.textlabel)}>
                  <Typography className={classes.formLabel}>
                    $ Amount Selected For Payment:&nbsp;
                  </Typography>
                  <Typography className={classes.formLabelValue}>
                    $
                    {aleDollarTotal.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography className={classes.formLabel}>
                    Num of Agreements Selected:&nbsp;
                  </Typography>
                  <Typography className={classes.formLabelValue}>
                    {aleCount.toString()}
                  </Typography>
                  <Typography className={classes.formLabel}>
                    Agreements Left to Process:&nbsp;
                  </Typography>
                  <Typography className={classes.formLabelValue}>
                    {aleAgmLeftCount.toString()}
                  </Typography>
                </div>
                <RACButton
                  className={clsx(classes.button)}
                  variant="contained"
                  size="small"
                  color="primary"
                  key="footerRefreshCallList"
                  onClick={() => {
                    setAreYouSureModal(true);
                  }}
                >
                  Make Payment
                </RACButton>
              </Grid>
              <RACModalCard
                isOpen={areYouSureModal}
                borderRadius={modalCardRadius}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => {
                  setAreYouSureModal(false);
                }}
              >
                {rYouSurePopup()}
              </RACModalCard>
              <Dialog open={processing ? processing : false} fullWidth={false}>
                <DialogContent className={classes.progress}>
                  <CircularProgress size={30} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography variant="body2">{`${statprogress}%`}</Typography>
                    <Box width="100%" mt={1}>
                      <LinearProgress
                        variant="determinate"
                        value={statprogress}
                      />
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </Grid>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
