import { Grid, Typography } from '@rentacenter/racstrap';

import React from 'react';
import { getCstFormateDate, getFormatedDate } from './../../../utils/utils';
import { formatePhoneNumber } from './CustomerDetailsHelperFunc';
import { getLegalHoldReasons } from '../../Agreement/commonFunctions';

export function vehicleModal(
  classes: any,
  vehicleTableBody: any,
  viewDetailsIndex: any
) {
  return (
    <Grid container md={12}>
      {formPopupMainGrid(
        formPopupDetailsData(
          'Utilities in Whose Name',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.utilities || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        formPopupDetailsData(
          'Phone in Whose Name',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.phoneInWhose || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        6,
        6
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Auto Make and Model',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.vehicleMake
            ? vehicleTableBody?.[viewDetailsIndex]?.vehicleModal
              ? vehicleTableBody?.[viewDetailsIndex]?.vehicleMake +
                ' ' +
                vehicleTableBody?.[viewDetailsIndex]?.vehicleModal
              : vehicleTableBody?.[viewDetailsIndex]?.vehicleMake
            : '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        formPopupDetailsData(
          'Auto Financed By',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.vehFinancedFrom || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        6,
        6
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Auto Color',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.vehicleColor || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        formPopupDetailsData(
          'Monthly Auto Payment',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.autoPayment || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        6,
        6
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Auto Tag #',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.vehicleTagNumber || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        '',
        6,
        6
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'State',
          `${classes.semiBold}  ${classes.p2}`,
          vehicleTableBody?.[viewDetailsIndex]?.vehRegState || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p2}`,
          7,
          5
        ),
        '',
        6,
        6
      )}
    </Grid>
  );
}

export function employmentModal(
  classes: any,
  employmentDetails: any,
  viewDetailsIndex: any
) {
  return (
    <Grid container md={12}>
      <Grid container md={12}>
        {formPopupMainGrid(
          formPopupDetailsData(
            'Company',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.employerName || '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Renter`s Job Title',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.jobTitle || '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Supervisor',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.supervisorFirstName || '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Hours',
            `${classes.semiBold}  ${classes.p10px0px}`,
            formateEmpWorkingHours(employmentDetails, viewDetailsIndex),
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Do Not Call',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.doNotCall === 'Y'
              ? 'No'
              : 'Yes',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Payment Schedule',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.employerPayscheduleDesc ||
              '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Phone #',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.employerPhoneNumber
              ? formatePhoneNumber(
                  employmentDetails?.[viewDetailsIndex]?.employerPhoneNumber
                )
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Day Paid',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.daysPaidDesc || '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Do Not Visit',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.doNotVisit === 'Y'
              ? 'No'
              : 'Yes',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Take Home Pay',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.takeHomePay
              ? formateAmount(
                  employmentDetails?.[viewDetailsIndex]?.takeHomePay
                )
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Address',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.addressLine1 || '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Length of Employment',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.lengthOfEmploymentYears
              ? `${employmentDetails?.[viewDetailsIndex]?.lengthOfEmploymentYears} years`
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            8,
            4
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Status',
            `${classes.semiBold}  ${classes.p10px0px}`,
            employmentDetails?.[viewDetailsIndex]?.active === 'Y'
              ? 'Active'
              : 'In Active',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          // Add an empty grid item for the last column
          <Grid item md={4}></Grid>,
          6,
          6
        )}
      </Grid>
    </Grid>
  );
}

function formateEmpWorkingHours(employmentDetails: any, viewDetailsIndex: any) {
  return employmentDetails?.[viewDetailsIndex]?.workStartTime
    ? employmentDetails?.[viewDetailsIndex]?.workStartTime +
        ' & ' +
        employmentDetails?.[viewDetailsIndex]?.workEndTime
    : '-';
}

export function showResidenceDetailsPopup(
  residenceTableBody: any,
  viewDetailsIndex: any,
  customerDetails: any,
  classes: any
) {
  return (
    <Grid container md={12}>
      {formPopupMainGrid(
        formPopupDetailsData(
          'Lease Company',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.leaseCompanyName || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        formPopupDetailsData(
          'Status',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.active === 'Y'
            ? 'Active'
            : 'In Active',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          8,
          4
        ),
        7,
        5
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Rent/Own',
          `${classes.semiBold}  ${classes.p10px0px}`,
          customerDetails?.residenceType || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        formPopupDetailsData(
          'Name of Lease',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.leaseHolderName || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          8,
          4
        ),
        7,
        5
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Landlord`s Name',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.landlordFirstName
            ? residenceTableBody?.[viewDetailsIndex]?.landlordLastName
              ? residenceTableBody?.[viewDetailsIndex]?.landlordFirstName +
                ' ' +
                residenceTableBody?.[viewDetailsIndex]?.landlordLastName
              : '-'
            : '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        formPopupDetailsData(
          'Length of Lease',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.lengthOfLease || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          8,
          4
        ),
        7,
        5
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Phone #',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.phoneNumber
            ? formatePhoneNumber(
                residenceTableBody?.[viewDetailsIndex]?.phoneNumber
              )
            : '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        formPopupDetailsData(
          'Monthly Rent',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.monthlyRent
            ? formateAmount(residenceTableBody?.[viewDetailsIndex]?.monthlyRent)
            : '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          8,
          4
        ),
        7,
        5
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Time to Call',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.bestTimeToCallDesc || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        formPopupDetailsData(
          'Length of Residency',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.lengthOfResidency || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          8,
          4
        ),
        7,
        5
      )}
      {formPopupMainGrid(
        formPopupDetailsData(
          'Previous Address',
          `${classes.semiBold}  ${classes.p10px0px}`,
          residenceTableBody?.[viewDetailsIndex]?.addressLine1 || '-',
          `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
          6,
          6
        ),
        '',
        7,
        5
      )}
    </Grid>
  );
}

//model popup legal
export function showLegalDetailsPopup(
  classes: any,
  legalTableBody: any,
  viewDetailsIndex: any
) {
  return (
    <Grid container md={12} style={{ width: '100%' }}>
      <Grid container md={12}>
        {formPopupMainGrid(
          formPopupDetailsData(
            'Effective Date',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.effectiveDate
              ? getFormatedDate(
                  legalTableBody?.[viewDetailsIndex]?.effectiveDate
                ).slice(0, 24)
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Closed Date',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.closedDate
              ? getFormatedDate(
                  legalTableBody?.[viewDetailsIndex]?.closedDate
                ).slice(0, 24)
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Reason',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.holdReason
              ? getLegalHoldReasons(
                  legalTableBody?.[viewDetailsIndex]?.holdReason
                )
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Closed Reason',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.closedReason
              ? legalTableBody?.[viewDetailsIndex]?.closedReason
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Created By',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.createdBy
              ? legalTableBody?.[viewDetailsIndex]?.createdBy
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          formPopupDetailsData(
            'Closed By',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.closedBy
              ? legalTableBody?.[viewDetailsIndex]?.closedBy
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Created Date',
            `${classes.semiBold}  ${classes.p10px0px}`,
            legalTableBody?.[viewDetailsIndex]?.createdDate
              ? getCstFormateDate(
                  legalTableBody?.[viewDetailsIndex]?.createdDate
                ).slice(0, 18) + ' CST'
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            5,
            7
          ),
          '',
          6,
          6
        )}
      </Grid>
    </Grid>
  );
}

function formateWithLength(string: any, flag?: any) {
  if (flag) {
    return string.length > 19 ? `${string.slice(0, 17)}....` : string;
  }
  return string.length > 24 ? `${string.slice(0, 21)}....` : string;
}

//model for payment history
export function showPaymentHistoryPopup(
  classes: any,
  paymentHistoryTableBody: any,
  viewDetailsIndex: any
) {
  return (
    <Grid container md={12} style={{ width: '96%' }}>
      <Grid container md={12}>
        {cardFirstHalf(paymentHistoryTableBody[viewDetailsIndex])}
        {cardSecondHalf(paymentHistoryTableBody[viewDetailsIndex])}
      </Grid>
    </Grid>
  );
}

const cardFirstHalf = (paymentInfo: any) => (
  <Grid item md={6} xs={12} sm={6}>
    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Receipt #</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.receiptId ? (
            <Typography>{paymentInfo.receiptId}</Typography>
          ) : (
            '-'
          )}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Store</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.store ? (
            <Typography>{paymentInfo.store}</Typography>
          ) : (
            '-'
          )}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Payment Origin</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.paymentOrigin ? paymentInfo.paymentOrigin : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Tender Type</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.tenderType ? paymentInfo.tenderType : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Days Extended</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.daysExt ? paymentInfo.daysExt : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Days Late</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.daysLate ? paymentInfo.daysLate : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>NSF</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>{paymentInfo?.nsf ? paymentInfo.nsf : '-'}</Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Old Due Date</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.pastDueDate
            ? getFormatedDate(paymentInfo.pastDueDate)
            : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Next Due Date</Typography>
      </Grid>
      <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
        <Typography>
          {paymentInfo?.nextDueDate
            ? getFormatedDate(paymentInfo.nextDueDate)
            : '-'}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const cardSecondHalf = (paymentInfo: any) => (
  <Grid item md={6} xs={12} sm={6}>
    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={4} xs={6} sm={6}>
        <Typography style={{}}>Date</Typography>
      </Grid>
      <Grid
        item
        md={8}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.datePaid
            ? paymentInfo.datePaid.slice(0, 20) + ' CST'
            : '-'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={5} xs={6} sm={6}>
        <Typography style={{}}>Net Rent</Typography>
      </Grid>
      <Grid
        item
        md={7}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.netRent ? formateAmount(paymentInfo.netRent) : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Club</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.clubAmount
            ? formateAmount(paymentInfo.clubAmount)
            : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>LDW</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.ldw ? formateAmount(paymentInfo.ldw) : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Late Fee</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.lateFee ? formateAmount(paymentInfo.lateFee) : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Discount</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.discount
            ? formateAmount(paymentInfo.discount)
            : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Other</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.other ? formateAmount(paymentInfo.other) : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Tax</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.tax ? formateAmount(paymentInfo.tax) : '$0.00'}
        </Typography>
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 20px 13px 10px 0px' }}>
      <Grid
        md={12}
        style={{
          display: 'flex',
          paddingLeft: '10px',
        }}
      >
        <Typography
          component="span"
          style={{
            backgroundColor: '#E3E3E3',
            height: '2px',
            width: '100%',
            borderRadius: '5px',
          }}
        />
      </Grid>
    </Grid>

    <Grid container style={{ padding: ' 10px 13px' }}>
      <Grid item md={6} xs={6} sm={6}>
        <Typography style={{}}>Total Payment</Typography>
      </Grid>
      <Grid
        item
        md={6}
        xs={6}
        sm={6}
        style={{ color: '#4A5174', textAlign: 'end' }}
      >
        <Typography>
          {paymentInfo?.totalPayment ? paymentInfo.totalPayment : '$0.00'}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

function formPopupMainGrid(GridOne: any, GridTwo: any, md1: any, md2: any) {
  return (
    <Grid container md={12}>
      <Grid item md={md1}>
        {GridOne}
      </Grid>
      <Grid item md={md2}>
        {GridTwo}
      </Grid>
    </Grid>
  );
}

function formateAmount(amount: any) {
  const amountNumber = Number(amount.replace(',', ''));
  if (!isNaN(amountNumber)) {
    return `$${amountNumber.toFixed(2)}`;
  } else {
    return `$0.00`;
  }
}

//model for work history
export function showWorkHistoryPopup(
  classes: any,
  workHistoryTableBody: any,
  viewDetailsIndex: any
) {
  return (
    <Grid container md={12} className={`${classes.p2}`}>
      <Grid container md={12}>
        {formPopupMainGrid(
          formPopupDetailsData(
            'Store #',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.storeNumber,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          formPopupDetailsData(
            'Date',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.activityDate
              ? workHistoryTableBody[viewDetailsIndex]?.activityDate.slice(
                  0,
                  20
                ) + ' CST'
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px} ${classes.ml26}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Phone # Dialed',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.phoneNumberDialed,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          formPopupDetailsData(
            'Due Date',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.pastDueDate
              ? getFormatedDate(
                  workHistoryTableBody?.[viewDetailsIndex]?.pastDueDate
                )
              : '-',
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px} ${classes.ml26}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Relation',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.personalReference,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          formPopupDetailsData(
            'Days Late',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.daysLate,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px} ${classes.ml26}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Worked Result',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.callResultType,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          formPopupDetailsData(
            'Coworker',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.coWorker,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px} ${classes.ml26}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Commitment Status',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.commitmentStatus,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          formPopupDetailsData(
            'Times Called',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.callsToday,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px} ${classes.ml26}`,
            5,
            7
          ),
          6,
          6
        )}
        {formPopupMainGrid(
          formPopupDetailsData(
            'Notes',
            `${classes.semiBold}  ${classes.p10px0px}`,
            workHistoryTableBody[viewDetailsIndex]?.notes,
            `${classes.semiBold} ${classes.textDarkSlateBlue} ${classes.p10px0px}`,
            6,
            6
          ),
          '',
          6,
          6
        )}
      </Grid>
    </Grid>
  );
}

function getTitleForPopup(title: any, titleClass: any) {
  return <Typography className={titleClass}>{title}</Typography>;
}

function getValueForPopup(value: any, valueClass: any, flag: any) {
  return (
    <Typography
      className={valueClass}
      title={value?.length > 19 ? value : null}
    >
      {value ? formateWithLength(value, flag) : '-'}
    </Typography>
  );
}

function formPopupDetailsData(
  title: any,
  titleClass: any,
  value: any,
  valueClass: any,
  titleSize: any,
  valueSize: any
) {
  const flagArray = ['Relation', 'Notes', 'Worked Result', 'Commitment Status'];
  const flag = flagArray.includes(title);
  return (
    <Grid container md={12}>
      <Grid item md={titleSize}>
        {getTitleForPopup(title, titleClass)}
      </Grid>
      <Grid item md={valueSize}>
        {getValueForPopup(value, valueClass, flag)}
      </Grid>
    </Grid>
  );
}
