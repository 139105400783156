import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatCurrency } from '../../../../utils/InventoryUtils';

export function GaapDepreciation() {
  const { classes, gaapDepreciation } = useContext(InventoryDetailsContext);

  return (
    <>
      <Grid style={{ marginBottom: '10px' }}>
        <Grid item md={12} style={{ marginBottom: '8px' }}>
          <Typography variant="h5">GAAP Depreciation</Typography>
        </Grid>

        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <Grid container md={12} className={`${classes.cardLastRow}`}>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Depreciation Method
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {gaapDepreciation?.depreciationMethod
                    ? gaapDepreciation?.depreciationMethod
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Depreciation Percentage
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {gaapDepreciation?.depreciationPercentage
                    ? gaapDepreciation?.depreciationPercentage + ' %'
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Gross Profit Per Payment
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {gaapDepreciation?.grossProfitPerPayment
                    ? formatCurrency(gaapDepreciation?.grossProfitPerPayment)
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Turns
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {gaapDepreciation?.turns ? gaapDepreciation?.turns : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
