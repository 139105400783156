export const handleMenuConfig = (
  menuConfig: any,
  menuSearchData: any,
  setMenuSearchContent: any
) => {
  const groupedMenu: any = {};
  let menu: any = menuConfig;

  menuSearchData.employeeId &&
  !menuSearchData.hierarchyValue &&
  !menuSearchData.userStore
    ? (menu = menuConfig.filter((item: any) => item?.hierarchy === 'USER'))
    : null;
  menu.forEach((value: any) => {
    const {
      appName,
      appComponentName,
      appSubComponentName,
      appComponentFunctionName,
      hierarchy,
      hierarchyValue,
      hierarchyRefCode,
      edit,
      view,
      approve,
    } = value;
    groupedMenu[appName] = groupedMenu[appName] || [];
    groupedMenu[appName].push({
      appName,
      appComponentName,
      appSubComponentName,
      appComponentFunctionName,
      hierarchy,
      hierarchyValue,
      hierarchyRefCode,
      edit,
      view,
      approve,
    });
  });
  const sortedMenuGrouping = Object.values(groupedMenu)
    .map((menuGroup: any) => ({
      menuGrouping: menuGroup,
    }))
    .sort((a, b) =>
      a.menuGrouping[0].appName.localeCompare(b.menuGrouping[0].appName)
    );
  setMenuSearchContent(sortedMenuGrouping);
};

export const booleanToString = (value: any) => {
  if (value) {
    return '1';
  } else {
    return '0';
  }
};

export function formatString(inputString: string) {
  if (inputString === 'LOB') {
    return inputString;
  } else {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
}
