import { handlePaymentHistoryDetails } from './CustomerDetailsHelperFunc';

export async function getPaymentHistoryData(
  paymentHistory: any,
  setPaymentHistoryTableBody: any,
  setResponseStatus: any,
  setLoader: any,
  setWorkHistoryTableBody: any
) {
  if (paymentHistory) {
    if (paymentHistory.status == 200 || paymentHistory.status == 204) {
      await handlePaymentHistoryDetails(
        paymentHistory,
        setResponseStatus,
        setPaymentHistoryTableBody,
        setWorkHistoryTableBody
      );
    } else {
      setLoader(false);
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        paymentHistoryTabStatus: paymentHistory.status,
      }));
      setPaymentHistoryTableBody([]);
    }
  } else {
    setLoader(false);
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      paymentHistoryTabStatus: 500,
    }));
    setPaymentHistoryTableBody([]);
  }
}
