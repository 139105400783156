import {
  CircularProgress,
  Grid,
  RACButton,
  RACCOLOR,
  RACModalCard,
  Typography,
  makeStyles,
} from '@rentacenter/racstrap';
import React, { useState } from 'react';
import PinField from 'react-pin-input';
import clsx from 'clsx';
import { getPinDetails, getUserID } from '../../../api/user';

const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px',
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonMargin: {
    marginRight: '50px',
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  spinnerModal: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204 204 204 / 0%)',
    background: 'rgb(255 255 255 / 0%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  loaderContainer: {
    position: 'fixed',
    background: '#f7f5f5',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 2000,
    textAlign: 'center',
    margin: '0px 0px',
  },
  loaderContent: {
    display: 'block',
    position: 'fixed',
    zIndex: 9999999,
    top: '40%',
    right: '50%',
  },
}));

export function PinPopup(props: any) {
  const classes = useClasses();
  const {
    setEmployeeDetails,
    setTwoFactorCompleted,
    onCompleteFunction,
    closePopupFunction,
  } = props;
  const [InvalidPin, setInvalidPin] = useState<number>();
  const [openPinModal, setOpenPinModal] = useState<boolean>(true);
  const [loader, setloader] = useState<boolean>(false);
  const [otp, setotp] = useState<string>('');

  //OnChange Functionalities
  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && otp.length == 4) {
      event.preventDefault();
      continueClick();
    }
  };

  const pinValue = (event: any) => {
    setotp(event);
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    let id = '';
    const pin = otp;

    //Fetching User Information
    if (props.employeeID == undefined) {
      const userResponse = await getUserID();
      id = userResponse.data.employeeId
        ? userResponse.data.employeeId
        : userResponse.data.email.toLowerCase();
      setEmployeeDetails !== undefined
        ? setEmployeeDetails(userResponse.data)
        : null;
    } else {
      id = props.employeeID;
    }

    //Validating Pin
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setOpenPinModal(true);
    if (result.data.statusCode == 200) {
      setOpenPinModal(false);
      onCompleteFunction ? onCompleteFunction() : null;
      closePopupFunction ? closePopupFunction(false) : null;
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
    setInvalidPin(result.status);
    setloader(false);
  };

  const onPinCloseClick = () => {
    setOpenPinModal(false);
    closePopupFunction(false);
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onComplete={(event: any) => pinValue(event)}
              onChange={(event: any) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={onPinCloseClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={otp.length != 4}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {loader === true ? (
        <Grid className={classes.loaderContainer}>
          <Grid className={classes.loaderContent}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}

      <RACModalCard
        isOpen={openPinModal}
        borderRadius="20px"
        className="racPinPopup"
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </>
  );
}
