import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as AlertIcon } from '../../Icons/no-records-found.svg';
import { ReactComponent as SuccessIcon } from '../../Icons/Done.svg';
import React, { useContext } from 'react';
import { ResetPinContext } from './ResetPinContext';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';

export default function SuccessPopUp() {
  const classes = GlobalAdminStyles();
  const { successDisplay, popUp, setPopUp } = useContext(ResetPinContext);
  const popUpClose = () => {
    setPopUp({
      ...popUp,
      successPopUp: false,
    });
  };
  return (
    <RACModalCard
      isOpen={popUp.successPopUp}
      className={classes.employeePopUp}
      closeIcon={true}
      maxWidth="xs"
      borderRadius="25px !important"
      onClose={() => {
        popUpClose();
      }}
    >
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.centerAlign}>
          {successDisplay == 200 ? <SuccessIcon /> : <AlertIcon></AlertIcon>}
          <Typography className={`${classes.formLabel} ${classes.mt2}`}>
            {successDisplay == 200 ? 'PIN Reset Successfully' : 'Bad request'}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.centerAlign}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              popUpClose();
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    </RACModalCard>
  );
}
