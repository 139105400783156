import React, { useState, createContext } from 'react';
import { AppConfigurationStyles } from '../../JsStyles/AppConfigurationStyles';

export const AppConfigurationContext = createContext<any>('');

export function AppConfigurationContextProvider(props: any) {
  const classes = AppConfigurationStyles();
  const [franchiseCompany, setFranchiseCompany] = useState<Array<string>>([]);
  const [franchiseeUser, setFranchiseeUser] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(true);
  const [coreUser, setCoreUser] = useState<boolean>(false);
  const [store, setStore] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [state, setState] = useState<any[]>([]);
  const [districtDropdown, setDistrictDropdown] = useState<any[]>();
  const [regionDropdown, setRegionDropdown] = useState<any[]>();
  const [countryDropdown, setCountryDropdown] = useState<any[]>();
  const [lobDropdown, setLobDropdown] = useState<any[]>();
  const [rule, setRule] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [group, setGroup] = useState<any[]>([]);
  const [rulesDropdownLoader, setRulesDropdownLoader] = useState<boolean>(true);
  const [resultWidget, setResultWidget] = useState<number>(0);
  const [organizationRules, setOrganizationRules] = useState<any>([]);
  const [request, setRequest] = useState<any>({});
  const [paramDatatype, setParamDatatype] = useState<any[]>([]);
  const [paramValueTemplate, setParamValueTemplate] = useState<any[]>([]);
  const [paramNameDetails, setParamNameDetails] = useState<any[]>([]);
  const [excelLoader, setExcelLoader] = useState<boolean>(false);
  const [accordionCount, setAccordionCount] = useState<number>(1);
  const [ruleConstraints, setRuleConstraints] = useState<any>({});
  const [assignButtonLoader, setAssignButtonLoader] = useState<boolean>(true);
  return (
    <div>
      <AppConfigurationContext.Provider
        value={{
          classes,
          franchiseCompany,
          setFranchiseCompany,
          franchiseeUser,
          setFranchiseeUser,
          viewMode,
          setViewMode,
          coreUser,
          setCoreUser,
          store,
          setStore,
          company,
          setCompany,
          state,
          setState,
          rule,
          setRule,
          category,
          setCategory,
          group,
          setGroup,
          districtDropdown,
          setDistrictDropdown,
          regionDropdown,
          setRegionDropdown,
          countryDropdown,
          setCountryDropdown,
          lobDropdown,
          setLobDropdown,
          rulesDropdownLoader,
          setRulesDropdownLoader,
          resultWidget,
          setResultWidget,
          organizationRules,
          setOrganizationRules,
          request,
          setRequest,
          paramDatatype,
          setParamDatatype,
          paramValueTemplate,
          setParamValueTemplate,
          paramNameDetails,
          setParamNameDetails,
          excelLoader,
          setExcelLoader,
          accordionCount,
          setAccordionCount,
          ruleConstraints,
          setRuleConstraints,
          assignButtonLoader,
          setAssignButtonLoader,
        }}
      >
        {props.children}
      </AppConfigurationContext.Provider>
    </div>
  );
}
