/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { InventorySearchContext } from '../../context/Inventory/InventorySearchContext';
import { ResultWidget } from './SearchComponents/ResultWidget';
import { LoadingScreen } from './GlobalComponents/LoadingScreen';
import { SearchHeader } from './SearchComponents/SearchHeader';
import { FilterWidget } from './SearchComponents/FilterWidget';
import { NoRecordsCard } from './GlobalComponents/NoRecordsCard';
import { Grid } from '@rentacenter/racstrap';
import ErrorPopup from './GlobalComponents/ErrorPopup';
import { getSearchParams } from '../../api/user';
import { conditionType, statusTypes } from '../../utils/InventoryUtils';
import { DynamicCard } from '../AppConfigurations/MicroComponents/DynamicCard';

export function InventorySearch() {
  const {
    showError,
    setShowError,
    showFilter,
    showResult,
    noRecords,
    setConditionsOptions,
    setDepartmentOptions,
    setSubDepartmentOptions,
    setStatusOptions,
    setDropdownLoader,
  } = useContext(InventorySearchContext);

  const getDropdownData = async () => {
    try {
      const dropdownResponse = await getSearchParams();

      setDropdownLoader(false);

      if (dropdownResponse && dropdownResponse.status == 200) {
        const dropdownValues = dropdownResponse.data;
        const departmentDropdown = [{ label: 'Select', value: '' }];
        const subDepartmentDropdown = [{ label: 'Select', value: '' }];
        const statusDropdown = [{ label: 'Select', value: '' }];
        const conditionsDropdown = [{ label: 'Select', value: '0' }];

        //Department
        dropdownValues.departmentList.forEach((obj: any) => {
          departmentDropdown.push({
            label: obj.description,
            value: obj.description,
          });
        });
        setDepartmentOptions(departmentDropdown);

        //SubDepartment
        dropdownValues.subDepartmentList.forEach((obj: any) => {
          subDepartmentDropdown.push({
            label: obj.description,
            value: obj.description,
          });
        });
        setSubDepartmentOptions(subDepartmentDropdown);

        //Status
        dropdownValues.statusList.forEach((obj: any) => {
          const status: any = statusTypes(obj.referenceCode);

          statusDropdown.push({
            label: status,
            value: obj.id.toString(),
          });
        });
        setStatusOptions(statusDropdown);

        //Condition
        dropdownValues.conditionList.forEach((obj: any) => {
          const condition: any = conditionType(obj.referenceCode);
          conditionsDropdown.push({
            label: condition,
            value: obj.id.toString(),
          });
        });
        setConditionsOptions(conditionsDropdown);
      }
    } catch (error: any) {
      setDropdownLoader(false);
    }
  };

  useEffect(() => {
    getDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: '0.8rem' }}>
      {/* {gridLoader ? <LoadingScreen /> : <></>} */}

      <SearchHeader />

      {showFilter ? <FilterWidget /> : <></>}

      {noRecords ? (
        <Grid style={{ margin: '1rem 0rem' }}>
          <NoRecordsCard />
        </Grid>
      ) : (
        <></>
      )}

      {!noRecords && showResult ? showError ? <DynamicCard value={{origin : 'Inventory'}}/> : <ResultWidget /> : <></>}
    </div>
  );
}
