import React, { useState, useEffect, useContext } from 'react';

import {
  CardContent,
  Card,
  Grid,
  RACButton,
  Typography,
  RACTableCell,
  RACTable,
  RACTableRow,
  CircularProgress,
  RACModalCard,
  RACTextbox,
  RACSelect,
  RACDatePicker,
} from '@rentacenter/racstrap';

import { ReactComponent as SortDownIcon } from './../../emilyIcons/Emily Icons/Sort.svg';
import { ReactComponent as SortUpIcon } from './../../assets/images/sort-up-icon.svg';
import { ReactComponent as AlertIcon } from './../../emilyIcons/no-records-found.svg';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import { ReactComponent as FilterIcon } from '../../Icons/Filter.svg';
import { ReactComponent as UpArrow } from '../../assets/images/up-arrow-filter.svg';
import { ReactComponent as DownArrow } from '../../assets/images/down-arrow-filter.svg';

import {
  GetCustomerDetails,
  getDropDownData,
  getSearchResults,
} from '../../api/user';
import {
  SearchPayload,
  dropdown,
} from '../../constants/customerSearchInterface';
import {
  borderStyle,
  customerFormData,
  customerSearchHeading,
  inputErrorMessage,
  mail,
  onlyLetters,
  onlyNumber,
} from '../../constants/CustomerConstants';
import { CustomerContext } from './CustomerDependencies/CustomerContext';
import { getCustomerId } from './CustomerDependencies/MakeApiCall';
import {
  formateSearchPayload,
  getFormatedGovtIdtypeDD,
  getFormatedStatesDropDown,
  sortObject,
  getValidationResponse,
} from './CustomerDependencies/CustomerSearchDependencies';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { convertDateFormat } from './CustomerDependencies/CustomerDetailsHelperFunc';

export default function CustomerSearch() {
  const {
    render,
    setRender,
    loader,
    setLoader,
    classes,
    errorMessagePopup,
    setErrorMessagePopup,
    errorMessage,
    setErrorMessage,
    customerRecords,
    setCustomerRecords,
    showFilter,
    setFilter,
    customerDetials,
    setCustomerData,
    filterOptions,
    setFilterOptions,
  } = useContext(CustomerContext);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [firstNameSortOrder, setFirstNameSortOrder] = useState(false);
  const [lastNameSortOrder, setLastNameSortOrder] = useState(false);
  const limit = 20;
  const [offset, setOffset] = useState<number>(20);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetchDataLoader, setFetchDataLoader] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>('');
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(false);
  let validationResponse = getValidationResponse(
    filterOptions,
    validEmail,
    validPhone
  );
  const [cardLoader, setCardLoader] = useState<boolean>(false);
  const navigate = useHistory();
  const primaryFieldDisable = !(
    filterOptions.firstName.trim() != '' ||
    filterOptions.lastName.trim() != '' ||
    filterOptions.ssn1.trim() +
      filterOptions.ssn2.trim() +
      filterOptions.ssn3.trim() !=
      '' ||
    filterOptions.email.trim() != '' ||
    filterOptions.phone.trim() != '' ||
    filterOptions.addressLine1.trim() != '' ||
    filterOptions.dateOfBirth.trim() != ''
  );

  const secondaryFieldDisable = !(
    filterOptions.firstName.trim().length >= 3 &&
    filterOptions.lastName.trim().length >= 3
  );

  const clearButtonEnable =
    filterOptions.firstName != '' ||
    filterOptions.lastName != '' ||
    filterOptions.dateOfBirth != '' ||
    filterOptions.email != '' ||
    filterOptions.phone != '' ||
    filterOptions.addressLine1 != '' ||
    filterOptions.addressLine2 != '' ||
    filterOptions.state != '0' ||
    filterOptions.zip != '' ||
    filterOptions.city != '' ||
    filterOptions.govIdType != '0' ||
    filterOptions.govtId != '' ||
    filterOptions.customerId != '' ||
    filterOptions.ssn1 != '' ||
    filterOptions.ssn2 != '' ||
    filterOptions.ssn3 != '';

  // //DropDowns
  const [states, setStates] = useState<dropdown[]>([
    { label: 'Select', value: '0' },
  ]);

  const [govtIdTypes, setGovtTypes] = useState<dropdown[]>([
    { label: 'Select', value: '0' },
  ]);

  useEffect(() => {
    fetchDropDowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDropDowns = async () => {
    try {
      setDropDownLoading(true);
      let statesDropDown: any = '';
      const dropDownResponse = await getDropDownData('0');
      statesDropDown = dropDownResponse?.data?.stateList?.getStateResponse;
      if (statesDropDown?.length > 0) {
        const states: dropdown[] = getFormatedStatesDropDown(statesDropDown);
        setStates(states);
      }
      if (dropDownResponse?.data?.governmentIdTypeOptions?.length > 0) {
        const govtIdTypes: dropdown[] =
          getFormatedGovtIdtypeDD(dropDownResponse);

        setGovtTypes(govtIdTypes);
      }
      setDropDownLoading(false);
    } catch (e: any) {
      setDropDownLoading(false);
      setErrorMessage('Error in Dropdown');
      setErrorMessagePopup(true);
    }
  };

  const searchResultHeading = () => (
    <>
      {customerSearchHeading.map((obj: any, index: any) => {
        if (obj.heading == 'Last Name') {
          return (
            <RACTableCell key={index} className={classes.width10}>
              {obj.heading}{' '}
              {!lastNameSortOrder ? (
                <SortDownIcon
                  className={`${classes.pointer}`}
                  onClick={() =>
                    sortObject(
                      'asc',
                      'lastName',
                      customerDetials,
                      setLastNameSortOrder,
                      setFirstNameSortOrder,
                      setCustomerData
                    )
                  }
                />
              ) : (
                <SortUpIcon
                  className={`${classes.pointer}`}
                  onClick={() =>
                    sortObject(
                      'desc',
                      'lastName',
                      customerDetials,
                      setLastNameSortOrder,
                      setFirstNameSortOrder,
                      setCustomerData
                    )
                  }
                />
              )}
            </RACTableCell>
          );
        }
        if (obj.heading == 'First Name') {
          return (
            <RACTableCell key={index} className={classes.width10}>
              {obj.heading}{' '}
              {!firstNameSortOrder ? (
                <SortDownIcon
                  className={`${classes.pointer}`}
                  onClick={() =>
                    sortObject(
                      'asc',
                      'firstName',
                      customerDetials,
                      setLastNameSortOrder,
                      setFirstNameSortOrder,
                      setCustomerData
                    )
                  }
                />
              ) : (
                <SortUpIcon
                  className={`${classes.pointer}`}
                  onClick={() =>
                    sortObject(
                      'desc',
                      'firstName',
                      customerDetials,
                      setLastNameSortOrder,
                      setFirstNameSortOrder,
                      setCustomerData
                    )
                  }
                />
              )}
            </RACTableCell>
          );
        }
        if (obj.heading == 'Email Address') {
          return (
            <RACTableCell key={index} className={classes.width15}>
              {obj.heading}
            </RACTableCell>
          );
        }
        if (obj.heading == 'Address') {
          return (
            <RACTableCell key={index} className={classes.width15}>
              {obj.heading}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj.heading}</RACTableCell>;
      })}
    </>
  );

  async function navigateToDetailsPage(obj: any) {
    setLoader(true);
    const customerId = await getCustomerId(
      obj?.globalCustomerId,
      setErrorMessage,
      setErrorMessagePopup
    );
    if (customerId) {
      setLoader(false);
      navigate.push(`/operations/customer/details/${customerId}`);
    } else {
      setLoader(false);
    }
  }

  const renderCustomerRow = (obj: any, index: any) => (
    <RACTableRow
      className={`${classes.fixTableHeadAgrTrsRec} ${classes.pointer}`}
      backgroundColor="white"
      key={index}
      onClick={() => {
        navigateToDetailsPage(obj);
      }}
    >
      <RACTableCell
        className={`${classes.linkColor} ${classes.fontSizeSearchTable}`}
      >
        {obj?.lastName ? obj?.lastName : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.linkColor} ${classes.fontSizeSearchTable}`}
      >
        {obj?.firstName ? obj?.firstName : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.taxId ? obj?.taxId.slice(-4) : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
        title={obj?.emailAddress?.length > 25 ? obj?.emailAddress : ''}
      >
        {obj?.emailAddress
          ? obj?.emailAdress?.length > 20
            ? obj?.emailAddress.slice(0, 18) + '...'
            : obj?.emailAddress
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.dateOfBirth ? getFormatedDate(obj.dateOfBirth) : '-'}
      </RACTableCell>
      {getCustomerAddressRow(obj)}
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.addresses ? obj?.addresses?.city : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {getAddress(obj)}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.addresses ? obj?.addresses?.postalCode : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.phones
          ? obj?.phones[0]?.phoneNumber
            ? formatePhoneNumber(obj?.phones[0]?.phoneNumber)
            : '-'
          : '-'}
      </RACTableCell>
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
      >
        {obj?.agreementCounts ? obj?.agreementCounts[0]?.active : '-'}
      </RACTableCell>
    </RACTableRow>
  );

  function getFormatedDate(originalDate: any) {
    const dateComponents = originalDate.split('-');

    const year = dateComponents[0];
    const month = dateComponents[1];
    const day = dateComponents[2];

    return `${month}/${day}/${year}`;
  }

  function getAddress(obj: any) {
    return obj?.addresses
      ? obj?.addresses?.stateCode
        ? obj?.addresses?.stateCode
        : obj?.addresses?.state
        ? obj?.addresses?.state
        : '-'
      : '-';
  }

  function getCustomerAddressRow(obj: any) {
    let primaryAddress = obj?.addresses;
    if (isArrayofObjects(primaryAddress)) {
      primaryAddress = primaryAddress.find(
        (ele: any) => ele?.addressType == 'PRIM'
      );
    }
    obj.addresses = primaryAddress;
    const actualAddress = obj?.addresses?.addressLine1
      ? obj?.addresses?.addressLine2
        ? obj?.addresses?.addressLine1 + ' ' + obj?.addresses?.addressLine2
        : obj?.addresses?.addressLine1
      : null;
    return (
      <RACTableCell
        className={`${classes.fontSizeSearchTable} ${classes.textDarkSlateBlue}`}
        title={actualAddress?.length > 25 ? actualAddress : ''}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 100 }}
      >
        {actualAddress
          ? actualAddress.length > 25
            ? actualAddress.slice(0, 22) + '...'
            : actualAddress
          : '-'}
      </RACTableCell>
    );
  }

  function isArrayofObjects(variable: any) {
    if (Array.isArray(variable)) {
      return variable.every(
        (item) => typeof item === 'object' && item !== null
      );
    }
    return false;
  }

  const searchResultBody = () => (
    <>
      {cardLoader ? (
        <RACTableRow>
          <RACTableCell colSpan={12}>
            <Grid className={`${classes.textCenter}`}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </RACTableRow>
      ) : (
        customerDetials.map((obj: any, index: any) =>
          renderCustomerRow(obj, index)
        )
      )}
    </>
  );

  function formatePhoneNumber(number: any) {
    if (number.length == 10) {
      number = number.replace(/(\d{1,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      number = number.replace(/\D/g, '');
    }
    return number;
  }

  function formateZipNumber(value: any) {
    if (value.length > 5) {
      const firstFiveDigits = value.slice(0, 5);
      const remainingDigits = value.slice(5);
      value = `${firstFiveDigits}-${remainingDigits}`;
    } else {
      value = value.replace(/\D/g, '');
    }
    return value;
  }

  function handleInputData(key: string, value: any) {
    if (key == 'ssn2' && filterOptions.ssn1.length == 3) {
      setFilterOptions({ ...filterOptions, [key]: value });
    }
    if (key == 'email') {
      const validEmail = mail.test(value);
      setValidEmail(!validEmail);
    }
    if (key == 'phone') {
      const regex = /^\d{10}$/;
      const isValid = regex.test(value);
      setValidPhone(!isValid);
      value = formatePhoneNumber(value);
    }

    if (key == 'zip') {
      value = formateZipNumber(value);
    }

    if (key != 'ssn2') {
      setFilterOptions({ ...filterOptions, [key]: value });
    }

    ssnHandler(value, key);

    if (key == 'customerId' && value.length > 0) {
      setFieldName('customerId');
      validationResponse = true;
      setFilterOptions({
        lastName: '',
        firstName: '',
        dateOfBirth: '',
        ssn1: '',
        ssn2: '',
        ssn3: '',
        email: '',
        phone: '',
        addressLine1: '',
        addressLine2: '',
        zip: '',
        city: '',
        state: '0',
        govIdType: '0',
        govtId: '',
        customerId: value,
      });
      validationResponse = true;
    } else {
      setFieldName('');
    }

    setRender(!render);
  }

  async function getFilterResults() {
    setCardLoader(true);
    setHasMore(true);
    setOffset(20);
    setFilter(false);
    setCustomerRecords(true);
    setCustomerData([]);
    const payload: SearchPayload = formateSearchPayload(
      filterOptions,
      limit,
      0
    );
    let customerResponse: any;
    if (filterOptions.customerId != '') {
      customerResponse = await GetCustomerDetails(filterOptions.customerId);
    } else {
      customerResponse = await getSearchResults(payload);
    }
    if (customerResponse?.status == 200) {
      setCardLoader(false);
      if (filterOptions.customerId === '') {
        customerResponse = customerResponse?.data?.GetCustomers;
        setCustomerData(customerResponse?.customers);
        customerResponse.customers = [...customerResponse?.customers].sort(
          (a, b) => b['lastName'].localeCompare(a['lastName'])
        );
        if (customerResponse.customers.length !== 20) {
          setHasMore(false);
          setOffset(20);
        }
      } else {
        customerResponse = customerResponse?.data?.GetCustomer?.value;
        const response = [
          {
            globalCustomerId: customerResponse?.globalCustomerId,
            customerType: customerResponse?.customerId,
            firstName: customerResponse?.firstName,
            lastName: customerResponse?.lastName,
            middleName: customerResponse?.middleInitial,
            suffix: customerResponse?.suffix,
            taxId: customerResponse?.taxId,
            taxIdType: customerResponse?.taxIdType,
            governmentId: customerResponse?.governmentId,
            governmentIdType: customerResponse?.governmentIdType,
            dateOfBirth: convertDateFormat(customerResponse?.dateOfBirth),
            emailAddress: customerResponse?.emailAddress,
            previousEmailAddresses: [customerResponse?.emailAddress],
            source: customerResponse?.createdSource,
            originStore: customerResponse?.customerId,
            skip: customerResponse?.skip,
            skipDate: customerResponse?.skipDate,
            stolen: customerResponse?.stolen,
            hard: customerResponse?.hard,
            approvalId: customerResponse?.approvalId,
            approvalStatus: customerResponse?.approvalStatus,
            externalCustomers: [],
            agreementCounts: [
              {
                active: customerResponse?.activeAgreements,
                lob: 'RTO',
                inactive: customerResponse?.inActiveAgreements,
              },
            ],
            agreements: [],
            phones: customerResponse?.phones,
            addresses: customerResponse?.addresses,
            verifiedAddresses: [],
            customerSinceDate: customerResponse?.customerSinceDate,
            createdBy: customerResponse?.createdBy,
            createdDate: customerResponse?.createdDate,
            lastModifiedBy: customerResponse?.lastModifiedBy,
            lastModifiedDate: customerResponse?.lastModifiedDate,
            taxIdEnc: customerResponse?.taxIdEnc,
            dateOfBirthEnc: customerResponse?.dateOfBirthEnc,
            governmentIdEnc: customerResponse?.governmentIdEnc,
          },
        ];
        setCustomerData(response);
      }
    } else {
      setHasMore(false);
      setOffset(20);
      setFetchDataLoader(false);
      setCardLoader(false);
      // setErrorMessage(somethingWentWrong);
      // setErrorMessagePopup(true);
    }
  }

  function showErrorMessgePopup() {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <AlertIcon></AlertIcon>

          <Typography className={`${classes.formLabel} ${classes.mt3}`}>
            {errorMessage}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.mt3} ${classes.textCenter}`}>
          <RACButton
            style={{ padding: '7px 10px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMessagePopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  }

  function ssnHandler(value: any, ssn: string) {
    if (ssn == 'ssn1') {
      const ssn2El: any = document.getElementById('a11y_ssntwo');
      const ssn1El: any = document.getElementById('a11y_ssnone');
      if (value.length === 3 && ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    } else if (ssn == 'ssn2') {
      const ssn3El: any = document.getElementById('a11y_ssnthree');
      const ssn1El: any = document.getElementById('a11y_ssnone');
      const ssn2El: any = document.getElementById('a11y_ssntwo');
      if (value.length === 2 && ssn2El == document.activeElement) {
        ssn3El.focus();
      }
      if (
        value.length === 0 &&
        filterOptions.ssn1?.length > 0 &&
        ssn2El == document.activeElement
      ) {
        ssn1El.focus();
      }
    } else if (ssn == 'ssn3') {
      const ssn3El: any = document.getElementById('a11y_ssnthree');
      const ssn2El: any = document.getElementById('a11y_ssntwo');
      if (
        value.length === 0 &&
        filterOptions.ssn3?.length > 0 &&
        ssn3El == document.activeElement
      ) {
        ssn2El.focus();
      }
    }
  }

  function TriggerApplyButton(e: any) {
    if (e.key == 'Enter') {
      validationResponse ? getFilterResults() : '';
    }
  }

  function validateNumbersAndSet(e: any) {
    if (!onlyNumber.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    handleInputData(e.target.name, e.target.value);
  }

  function validateCharactersAndSet(e: any) {
    if (!onlyLetters.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^A-Za-z\s]+/g, '');
    }
    handleInputData(e.target.name, e.target.value);
  }

  function renderCustomerSearchCard() {
    return (
      <Grid container md={12} className={`${classes.mb3} ${classes.p01}`}>
        <Grid item md={11} lg={11} sm={4} xs={6}>
          <Typography
            variant="h6"
            className={`${classes.floatLeft} ${classes.mtt2} ${classes.title}`}
          >
            Customer Search
          </Typography>
        </Grid>

        <Grid item md={1}>
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            className={`${classes.floatRight}`}
            disabled={!customerRecords}
            onClick={() => {
              setFilter(!showFilter);
            }}
          >
            {' '}
            <FilterIcon
              className={`${classes.mR1} ${classes.h1}`}
            /> Filter{' '}
            {showFilter ? (
              <UpArrow className={`${classes.mL1} ${classes.pointer}`} />
            ) : (
              <DownArrow className={`${classes.mL1} ${classes.pointer}`} />
            )}{' '}
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  function renderCustomerSearchTable() {
    return (
      <Grid container md={12} className={`${classes.mb3} ${classes.p01}`}>
        <Card className={`${classes.card}`}>
          <CardContent>
            {/* Row1 */}
            {renderSearchCardPrimaryDetails()}
            {/* Row2 */}
            {renderSearchCardSecondaryDetails()}
            {/* Row3 */}
            {renderSearchCardCustomerIdRow()}
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderSearchCardCustomerIdRow() {
    return (
      <Grid container md={12} spacing={2}>
        <Grid item md={2}>
          <RACTextbox
            disabled={fieldName != 'customerId' && fieldName != ''}
            type="text"
            name="customerId"
            inputlabel="Customer #"
            placeHolder="Customer #"
            maxlength={36}
            value={filterOptions.customerId}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              validateNumbersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>
        <Grid item md={10} className={`${classes.mt2}`}>
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.floatRight} ${classes.ms2}`}
            disabled={validationResponse ? false : true}
            onClick={() => {
              getFilterResults();
            }}
          >
            Apply
          </RACButton>

          <RACButton
            variant="outlined"
            color="primary"
            disabled={!clearButtonEnable}
            className={`${classes.floatRight} ${classes.p10p25}`}
            onClick={() => {
              setFilterOptions(customerFormData);
              setFieldName('');
            }}
          >
            Clear
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  function secondRowFirstDependency() {
    return (
      <Grid container spacing={2} md={4}>
        <Grid item md={6}>
          <RACTextbox
            disabled={fieldName != 'addressLine1' && fieldName != ''}
            type="text"
            name="addressLine1"
            placeHolder={'Address Line 1'}
            inputlabel="Address Line 1"
            maxlength={30}
            value={filterOptions.addressLine1}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              handleInputData(e.target.name, e.target.value);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <RACTextbox
            disabled={
              (fieldName != 'addresLine2' && fieldName != '') ||
              primaryFieldDisable
            }
            type="text"
            name="addressLine2"
            placeHolder={'Address 2, APT #, Unit #, P.O. Box'}
            inputlabel="Address Line 2"
            value={filterOptions.addressLine2}
            inputLabelClassname={classes.mb1}
            maxlength={30}
            OnChange={(e: any) => {
              handleInputData(e.target.name, e.target.value);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function secondRowSecondDependency() {
    return (
      <Grid container spacing={2} className={`${classes.ms2}`} md={4}>
        <Grid item md={4}>
          <RACTextbox
            disabled={
              (fieldName != 'zip' && fieldName != '') || primaryFieldDisable
            }
            type="text"
            name="zip"
            inputlabel="Zip"
            placeHolder={'Zip code'}
            maxlength={10}
            minlength={5}
            value={filterOptions.zip}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              validateNumbersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>

        <Grid item md={4}>
          <RACTextbox
            disabled={
              (fieldName != 'city' && fieldName != '') || primaryFieldDisable
            }
            type="text"
            name="city"
            inputlabel="City"
            value={filterOptions.city}
            placeHolder={'City'}
            maxlength={30}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              validateCharactersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              if (e.key == 'Enter') {
                getFilterResults();
              }
            }}
          />
        </Grid>
        <Grid item md={4}>
          <RACSelect
            loading={dropDownLoading}
            isDisabled={
              (fieldName != 'state' && fieldName != '') || primaryFieldDisable
            }
            inputLabel={'State'}
            options={states}
            inputLabelClassName={classes.mb1}
            name="state"
            defaultValue={filterOptions.state}
            onChange={(e: any) => {
              handleInputData('state', e.target.value);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function secondRowThirdDependency() {
    return (
      <Grid container spacing={2} className={`${classes.ms2}`} md={4}>
        <Grid item md={6}>
          <RACSelect
            loading={dropDownLoading}
            isDisabled={
              (fieldName != 'govtIdType' && fieldName != '') ||
              secondaryFieldDisable
            }
            inputLabel={'Government ID Type'}
            defaultValue={filterOptions.govIdType}
            inputLabelClassName={classes.mb1}
            options={govtIdTypes}
            onChange={(e: any) => {
              handleInputData('govIdType', e.target.value);
            }}
          />
        </Grid>
        <Grid item md={6}>
          <RACTextbox
            disabled={
              (fieldName != 'govtId' && fieldName != '') ||
              filterOptions.govIdType == 'NONE' ||
              secondaryFieldDisable ||
              filterOptions.govIdType == '0'
            }
            type="text"
            name="govtId"
            inputlabel="Government ID #"
            value={filterOptions.govtId}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              handleInputData(e.target.name, e.target.value);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderSearchCardSecondaryDetails() {
    return (
      <Grid container md={12} className={`${classes.mb3}`}>
        {secondRowFirstDependency()}
        {secondRowSecondDependency()}
        {secondRowThirdDependency()}
      </Grid>
    );
  }
  const fetchNextData = async () => {
    try {
      setHasMore(true);
      setFetchDataLoader(true);
      getNextDatas();
    } catch (e: any) {
      setFetchDataLoader(false);
      setHasMore(false);
      setOffset(20);
    }
  };

  async function getNextDatas() {
    try {
      setOffset(offset + 20);
      setFetchDataLoader(true);
      setHasMore(true);
      const payload: SearchPayload = formateSearchPayload(
        filterOptions,
        limit,
        offset
      );
      let customerResponse = await getSearchResults(payload);
      if (customerResponse?.status == 200) {
        setFetchDataLoader(false);
        customerResponse = customerResponse?.data?.GetCustomers;
        setCustomerData((pre: any) => [...pre, ...customerResponse?.customers]);
        if (customerResponse.customers.length !== 20) {
          setOffset(20);
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        setOffset(20);
        setFetchDataLoader(false);
        setLoader(false);
      }
    } catch (e: any) {
      setHasMore(false);
      setOffset(20);
      setFetchDataLoader(false);
      setLoader(false);
    }
  }
  function renderCustomerSearchDetails() {
    return (
      <Grid container md={12} className={`${classes.p01}`}>
        <Card className={classes.searchCard}>
          <CardContent>
            <Grid>
              {customerDetials.length > 19 ? (
                <InfiniteScroll
                  next={fetchNextData}
                  dataLength={customerDetials?.length}
                  hasMore={hasMore}
                  height={
                    showFilter
                      ? '55vh'
                      : customerDetials.length > 19
                      ? '83vh'
                      : 'auto'
                  }
                  loader={
                    fetchDataLoader ? (
                      <Grid className={classes.GridLoader}>
                        <CircularProgress></CircularProgress>
                      </Grid>
                    ) : null
                  }
                >
                  <RACTable
                    className={classes.fixTableHeadAgrTrsRec}
                    renderTableHead={searchResultHeading}
                    renderTableContent={searchResultBody}
                  />
                </InfiniteScroll>
              ) : // ) : (
              customerDetials.length > 0 || cardLoader ? (
                <RACTable
                  className={classes.customerResultsGrid}
                  renderTableHead={searchResultHeading}
                  renderTableContent={searchResultBody}
                />
              ) : (
                <RACTableRow style={{ backgroundColor: 'white' }}>
                  <RACTableCell style={{ width: '82.5%' }}></RACTableCell>
                  <RACTableCell>
                    <Grid className={`${classes.textCenter}`}>
                      <NoRecordsFound />
                      <Typography
                        className={`${classes.subTitle} ${classes.mb3}`}
                      >
                        No Records Found
                      </Typography>
                    </Grid>
                  </RACTableCell>
                </RACTableRow>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function renderSearchCardPrimaryDetails() {
    return (
      <Grid container md={12} spacing={2} className={`${classes.mb3}`}>
        <Grid item md={2}>
          <RACTextbox
            disabled={fieldName != 'lastName' && fieldName != ''}
            type="text"
            name="lastName"
            inputlabel="Last Name"
            maxlength={30}
            value={filterOptions.lastName}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              validateCharactersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>

        <Grid item md={2}>
          <RACTextbox
            disabled={fieldName != 'firstName' && fieldName != ''}
            type="text"
            name="firstName"
            inputlabel="First Name"
            maxlength={30}
            value={filterOptions.firstName}
            inputLabelClassname={classes.mb1}
            OnChange={(e: any) => {
              validateCharactersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>

        <Grid item md={2}>
          <RACDatePicker
            disabled={fieldName != 'dob' && fieldName != ''}
            id="dob"
            label="Date of Birth"
            value={filterOptions.dateOfBirth}
            classes={{
              label: classes.mb1,
            }}
            name="dateOfBirth"
            onChange={(e: any) => {
              handleInputData('dateOfBirth', e);
            }}
          />
        </Grid>
        <Grid item md={2} className={`${classes.mt1}`}>
          <label
            htmlFor="a11y_ssn"
            className={`${classes.semiBold} ${classes.mb5}`}
          >
            Social Security# / ITIN#
          </label>
          <Grid container spacing={2}>
            <Grid item md={4} className={`${classes.mt05}`}>
              <RACTextbox
                disabled={fieldName != 'ssn' && fieldName != ''}
                type="password"
                name="Ssn1"
                value={filterOptions.ssn1}
                id={'a11y_ssnone'}
                placeHolder={'***'}
                className="cust-text-aign"
                inputLabelClassname={`${classes.mb1}`}
                maxlength={3}
                OnChange={(e: any) => {
                  handleSSNInputValues(e, 'ssn1');
                }}
                isCurrency={false}
                OnKeydown={(e: any) => {
                  TriggerApplyButton(e);
                }}
              />
            </Grid>
            <Grid item md={3} className={`${classes.mt05}`}>
              <RACTextbox
                disabled={fieldName != 'ssn' && fieldName != ''}
                type="password"
                name="Ssn2"
                value={filterOptions.ssn2}
                id={'a11y_ssntwo'}
                className="cust-text-aign"
                placeHolder={'**'}
                maxlength={2}
                inputLabelClassname={classes.mb1}
                OnChange={(e: any) => {
                  handleSSNInputValues(e, 'ssn2');
                }}
                isCurrency={false}
                OnKeydown={(e: any) => {
                  TriggerApplyButton(e);
                }}
              />
            </Grid>
            <Grid item md={5} className={`${classes.mt05}`}>
              <RACTextbox
                disabled={fieldName != 'ssn' && fieldName != ''}
                type="text"
                name="Ssn3"
                value={filterOptions.ssn3}
                id={'a11y_ssnthree'}
                className="cust-text-aign"
                placeHolder={'****'}
                maxlength={4}
                inputLabelClassname={classes.mb1}
                OnChange={(e: any) => {
                  handleSSNInputValues(e, 'ssn3');
                }}
                isCurrency={false}
                OnKeydown={(e: any) => {
                  TriggerApplyButton(e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2}>
          <RACTextbox
            disabled={fieldName != 'email' && fieldName != ''}
            type="text"
            name="email"
            inputlabel="Email Address"
            value={filterOptions.email}
            maxlength={50}
            inputLabelClassname={classes.mb1}
            errorMessage={
              validEmail && filterOptions.email != ''
                ? inputErrorMessage.email
                : ''
            }
            OnChange={(e: any) => {
              handleInputData(e.target.name, e.target.value);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>

        <Grid item md={2}>
          <RACTextbox
            disabled={fieldName != 'phone' && fieldName != ''}
            type="text"
            name="phone"
            inputlabel="Phone #"
            value={filterOptions.phone}
            maxlength={10}
            inputLabelClassname={classes.mb1}
            errorMessage={
              validPhone && filterOptions.phone != ''
                ? inputErrorMessage.phone
                : ''
            }
            OnChange={(e: any) => {
              validateNumbersAndSet(e);
            }}
            OnKeydown={(e: any) => {
              TriggerApplyButton(e);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function handleSSNInputValues(e: any, key: string) {
    if (!onlyNumber.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }

    handleInputData(key, e.target.value);
  }

  return (
    <Grid container md={12} className={`${classes.pt4} `}>
      {renderCustomerSearchCard()}
      {showFilter ? renderCustomerSearchTable() : <></>}

      <RACModalCard
        isOpen={loader}
        closeIcon={false}
        maxWidth={'xs'}
        borderRadius={borderStyle}
        TitleClassName={`${classes.textCenter}`}
      >
        {
          <Grid className={` ${classes.textCenter}`}>
            <CircularProgress />
            <Typography className={`${classes.formLabel} ${classes.mt3}`}>
              Validating Customer
            </Typography>
            <Typography
              className={`${classes.formLabel} ${classes.mt3} ${classes.colorGray}`}
            >
              Please wait...
            </Typography>
          </Grid>
        }
      </RACModalCard>
      <RACModalCard
        isOpen={errorMessagePopup}
        closeIcon={false}
        maxWidth={'xs'}
        borderRadius={borderStyle}
      >
        {showErrorMessgePopup()}
      </RACModalCard>

      {customerRecords ? renderCustomerSearchDetails() : <></>}
    </Grid>
  );
}
