import React, { useContext } from 'react';
import { ChargeOff } from '../MicroComponents/ChargeOff';
import { CurrentAgreements } from '../MicroComponents/CurrentAgreements';
import { InventoryInformation } from '../MicroComponents/InventoryInformation';
import { RecoveryDetails } from '../MicroComponents/RecoveryDetails';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { Grid } from '@rentacenter/racstrap';

export function InformationTab() {
  const { displayElements } = useContext(InventoryDetailsContext);
  return (
    <>
      <InventoryInformation />
      {displayElements.currentAgr ? <CurrentAgreements /> : <></>}
      {displayElements.chargeOff ? <ChargeOff /> : <></>}
      <Grid style={{ marginBottom: '80px' }}>
        {displayElements.recovery ? <RecoveryDetails /> : <></>}
      </Grid>
    </>
  );
}
