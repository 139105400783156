/* eslint-disable prettier/prettier */
/* eslint-disable */
export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}

export const FULL_API_ERROR_MESSAGE =
  'Unable to fetch the data. Please try again later.';
export const INITIAL_OFFSET = 0;
export const ALE_LIST_API_LIMIT = 500;

export const AgreementType = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Club',
    value: 'CLUB',
  },
  {
    label: 'Loaner',
    value: 'LOAN',
  },
  {
    label: 'Installment Notes',
    value: 'NOTES',
  },
  {
    label: 'Promo',
    value: 'PRO',
  },
  {
    label: 'Rental',
    value: 'RTO',
  },
];

export const Status = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const inventoryHeadings = [
  { heading: 'Item #' },
  { heading: 'Description' },
  { heading: 'Department' },
  { heading: 'Sub Department' },
  { heading: 'Remaining Value' },
];

export const rmsHeadings = [
  { heading: 'RMS #' },
  { heading: 'Description' },
  { heading: 'Department' },
  { heading: 'Sub Department' },
  { heading: 'RMS STATUS' },
];

export const paymentHeadings = [
  { heading: 'Date' },
  { heading: 'Reciept #' },
  { heading: 'Store' },
  { heading: 'Origin' },
  { heading: 'Tender' },
  { heading: 'CC Last 4 Digits' },
  { heading: 'EXT' },
  { heading: 'Late' },
  { heading: 'NSF' },
  { heading: 'Total' },
  { heading: 'Action' },
];

export const activityHeadings = [
  { heading: 'Date' },
  { heading: 'Activity type' },
  { heading: 'Description' },
  { heading: 'Coworker' },
  { heading: 'Created Source' },
];

export const agreementSearchHeadings = [
  { heading: 'Store' },
  { heading: 'Agreement #' },
  { heading: 'Last Name' },
  { heading: 'First Name' },
  { heading: 'Description' },
  { heading: 'Agreement Type' },
  { heading: 'Status' },
  { heading: 'Sub Status' },
  { heading: 'Rate' },
  { heading: 'Term' },
  { heading: 'Rent Paid' },
  { heading: 'Due Date' },
];

export const legalHoldGridHeadings = [
  { heading: 'Last Name' },
  { heading: 'First Name' },
  { heading: 'Reason' },
  { heading: 'Date of Birth' },
  { heading: 'SSN' },
  { heading: 'Email' },
  { heading: 'Address' },
  { heading: 'City' },
  { heading: 'State' },
  { heading: 'Zip Code' },
];

export const ON_RENT = 'On Rent';
export const RENT_READY = 'Rent Ready';
export const SERVICE_ON_RENT = 'Service On Rent';
export const LOANER = 'Loaner';
export const NOT_RENT_READY = 'Not Rent Ready';
export const PENDING_CHARGE_OFF = 'Pending Charge-off';
export const CHARGED_OFF = 'Charged Off';
export const HOLD = 'Hold';
export const SERVICE_CHARGED_OFF = 'Service Charged Off';
export const SERVICE_IDLE = 'Service Idle';
export const RESERVED = 'Reserved';
export const TRANSFER_IN_PROGRESS = 'Transfer In-Progress';
export const PENDING_RENTAL_AGREEMENT = 'Pending Rental Agreement';
export const PENDING_LOANER_AGREEMENT = 'Pending Loaner Agreement';
export const API_ERROR_MESSAGE = 'unable to fetch the data';

export const HierarchyType = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'Role',
    value: 'ROLE',
  },
  {
    label: 'Store',
    value: 'STORE',
  },
  {
    label: 'District',
    value: 'DISTRICT',
  },
  {
    label: 'Region',
    value: 'REGION',
  },
  {
    label: 'State',
    value: 'STATE',
  },
  {
    label: 'Company',
    value: 'COMPANY',
  },
  {
    label: 'Country',
    value: 'COUNTRY',
  },
  {
    label: 'LOB',
    value: 'LOB',
  },
];

export const getParentHierarchyOptions = (hierarchyType: any) => {
  switch (hierarchyType) {
    case 'STATE':
      return [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Company',
          value: 'COMPANY',
        },
      ];
    case 'COUNTRY':
      return [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'LOB',
          value: 'LOB',
        },
      ];
    default:
      return [
        {
          label: 'Select',
          value: '',
        },
      ];
  }
};

export const getHierarchyValueName = (hierarchyType: any) => {
  switch (hierarchyType) {
    case 'ROLE':
      return 'Roles';
    case 'DISTRICT':
      return 'District';
    case 'REGION':
      return 'Region';
    case 'DISTRICT':
      return 'District';
    case 'COMPANY':
      return 'Company';
    case 'COUNTRY':
      return 'Country';
    case 'LOB':
      return 'Lob';
    default:
      return 'Hierarchy value';
  }
};
export const MenuPermissionsHeading = [
  { heading: 'Menu Grouping' },
  { heading: 'Sub Menu' },
  { heading: 'Feature Name' },
  { heading: 'Hierarchy' },
  { heading: 'Hierarchy Value' },
  { heading: 'View' },
  { heading: 'Edit' },
  { heading: 'Approve' },
  { heading: 'Access' },
];

export const AssignHierarchyType = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'User',
    value: 'USER',
  },
  {
    label: 'Role',
    value: 'ROLE',
  },
  {
    label: 'Store',
    value: 'STORE',
  },
  {
    label: 'District',
    value: 'DISTRICT',
  },
  {
    label: 'Region',
    value: 'REGION',
  },
  {
    label: 'State',
    value: 'STATE',
  },
  {
    label: 'Company',
    value: 'COMPANY',
  },
  {
    label: 'Country',
    value: 'COUNTRY',
  },
  {
    label: 'LOB',
    value: 'LOB',
  },
];

export const ResetPinHeading = [
  { heading: 'Employee ID' },
  { heading: 'First Name' },
  { heading: 'Last Name' },
  { heading: 'Email ID' },
  { heading: 'Action' }
]

export const DEFAULT_RULE_LIMIT=10;
export const DEFAULT_RULE_OFFSET=0;

export const booleanValues=['Y','y','N','n','1','0','TRUE','FALSE','true','false'];