import React, { useContext, useEffect, useState } from 'react';
import {
  CardContent,
  Card,
  Grid,
  RACButton,
  Typography,
  CircularProgress,
} from '@rentacenter/racstrap';
import { customerDetailsStyle } from './../Customer/customerStyles';
import { useHistory, useParams } from 'react-router-dom';
import { getStoreDetails } from '../../api/user';
import {
  formStoreDetails,
  formatDate,
  navigateToStoreLocation,
} from './LocationDependencies/LocationCommonFunc';
import MessagePopup from './LocationDependencies/MessagePopup';
import { LocationContext } from './LocationDependencies/LocationContext';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
export default function StoreDetails() {
  const classes = customerDetailsStyle();
  const history = useHistory();
  const { errorMessagePopup, setErrorMessagePopup, setErrorMessage } =
    useContext(LocationContext);
  const params: any = useParams();
  const storeNumber = params.storeId;
  const [loader, setLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(200);
  const [storeDetails, setStoreDetails] = useState<any>({
    general: {
      storeId: '-',
      storeName: '-',
      status: '-',
      openedDate: '-',
      lineOfBusiness: '-',
      posSystem: '-',
      simsConversion: '-',
    },
    location: {
      address: '-',
      coOrdinates: '-',
    },
    contact: {
      phone: '-',
    },
    hierarchy: {
      region: '-',
      regionDirector: '-',
      reginalDirectorEmail: '-',
      district: '-',
      districtManager: '-',
      storeManager: '-',
    },
    storeHours: {
      monday: '-',
      tuesday: '-',
      wednesday: '-',
      thursday: '-',
      friday: '-',
      saturday: '-',
      sunday: '-',
    },
  });

  useEffect(() => {
    fetchStoreDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchStoreDetails() {
    try {
      setLoader(true);
      const payload = {
        storeNumbers: [storeNumber],
      };
      const storeDetailsResponse = await getStoreDetails(payload);
      if (
        storeDetailsResponse?.data?.response[0] &&
        (storeDetailsResponse?.status == 200 ||
          storeDetailsResponse?.status == 204)
      ) {
        const storeDetails: any = formStoreDetails(storeDetailsResponse?.data);
        setStoreDetails(storeDetails);
        setStatus(200);
        setLoader(false);
      } else if (storeDetailsResponse?.status == 400) {
        setLoader(false);
        setStatus(400);
        setErrorMessagePopup(true);
        setErrorMessage('Bad Request');
      } else {
        setLoader(false);
        setStatus(500);
        setErrorMessagePopup(true);
        setErrorMessage('Something Went Wrong');
        setStoreDetails([]);
      }
    } catch (e: any) {
      setLoader(false);
      setStatus(500);
      setErrorMessagePopup(true);
      setErrorMessage('Something Went Wrong');
    }
  }

  function renderGeneralContent() {
    return (
      <Grid item md={5}>
        <Grid container md={12}>
          <Typography
            className={`${classes.bold}  ${classes.p10px0px} ${classes.fontSize18}`}
          >
            General
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Store #
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Store Name
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Status
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Opened Date
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Line of Business
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              POS System
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              SIMS Conversion
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.storeId
                ? storeDetails.general.storeId
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.storeName
                ? storeDetails.general.storeName
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.status ? storeDetails.general.status : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.openedDate
                ? formatDate(storeDetails.general.openedDate)
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.lineOfBusiness
                ? storeDetails.general.lineOfBusiness
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.posSystem
                ? storeDetails.general.posSystem
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.general.simsConversion
                ? formatDate(storeDetails.general.simsConversion)
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Typography
            className={`${classes.bold}  ${classes.p10px0px} ${classes.fontSize18}`}
          >
            Location
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Address
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.location.address
                ? storeDetails.location.address
                : '-'}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Coordinates
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.location.coOrdinates
                ? storeDetails.location.coOrdinates
                : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderContactAndHierarchy() {
    return (
      <Grid item md={5}>
        <Grid container md={12}>
          <Typography
            className={`${classes.bold}  ${classes.p10px0px} ${classes.fontSize18}`}
          >
            Contact
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              Phone
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.contact.phone ? storeDetails.contact.phone : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Typography
            className={`${classes.bold}  ${classes.p10px0px} ${classes.fontSize18}`}
          >
            Hierarchy
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Grid item md={4}>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Region
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Region Director
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Region Director Email
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              District
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              District Manager
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Store Manager
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.region
                ? storeDetails.hierarchy.region
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.regionDirector
                ? storeDetails.hierarchy.regionDirector
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.reginalDirectorEmail
                ? storeDetails.hierarchy.reginalDirectorEmail
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.district
                ? storeDetails.hierarchy.district
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.districtManager
                ? storeDetails.hierarchy.districtManager
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.hierarchy.storeManager
                ? storeDetails.hierarchy.storeManager
                : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderStoreHours() {
    return (
      <Grid item md={2}>
        <Grid container md={12}>
          <Typography
            className={`${classes.bold}  ${classes.p10px0px} ${classes.fontSize18}`}
          >
            Store Hours
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Grid item md={3}>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Mon
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Tue
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Wed
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Thu
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Fri
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Sat
            </Typography>
            <Typography className={`${classes.semiBold}  ${classes.p10px0px}`}>
              Sun
            </Typography>
          </Grid>
          <Grid item md={9}>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.monday
                ? storeDetails.storeHours.monday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.tuesday
                ? storeDetails.storeHours.tuesday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.wednesday
                ? storeDetails.storeHours.wednesday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.thursday
                ? storeDetails.storeHours.thursday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.friday
                ? storeDetails.storeHours.friday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.saturday
                ? storeDetails.storeHours.saturday
                : '-'}
            </Typography>
            <Typography className={`${classes.regular}  ${classes.p10px0px}`}>
              {storeDetails.storeHours.sunday
                ? storeDetails.storeHours.sunday
                : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12}>
          <Grid item md={10}>
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.floatRight} ${classes.mt5} ${classes.pointer}`}
              onClick={() => {
                navigateToStoreLocation(storeDetails.location.coOrdinates);
              }}
            >
              Store Locator
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderLocationDetails() {
    return status == 200 ? (
      <Grid container md={12}>
        {renderGeneralContent()}
        {renderContactAndHierarchy()}
        {renderStoreHours()}
      </Grid>
    ) : (
      <Grid className={`${classes.textCenter} ${classes.mt5}`}>
        <NoRecordsFound />
        <Typography className={`${classes.subTitle} ${classes.mb3}`}>
          No Records Found
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {loader ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={`${classes.height2}`}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container md={12} className={`${classes.p01}`}>
            <Grid item md={12}>
              <Typography
                variant="h6"
                className={`${classes.floatLeft} ${classes.mt43} ${classes.title}`}
              >
                Store Info
              </Typography>
            </Grid>
            <Grid container>
              <Card className={`${classes.card} ${classes.mt3}`}>
                <CardContent>{renderLocationDetails()}</CardContent>
              </Card>
            </Grid>
            {errorMessagePopup ? <MessagePopup /> : null}
          </Grid>
          <Grid item md={12} className={classes.fixedBottom}>
            <RACButton
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </>
      )}
    </>
  );
}
