import {
  CustomerData,
  dropdown,
} from '../../../constants/customerSearchInterface';

export function getFormatedStatesDropDown(statesDropDown: any, location?: any) {
  const statesResponse = [...statesDropDown];
  let states: dropdown[] = [];
  statesResponse.map((data: any, index: number) => {
    index === 0
      ? states.push(
          { label: 'Select', value: '0' },
          {
            label: data.abbreviation,
            value: location ? data.stateProvinceId : data.abbreviation,
          }
        )
      : states.push({
          label: data.abbreviation,
          value: location ? data.stateProvinceId : data.abbreviation,
        });
  });

  const sortedStates = states
    .slice(1)
    .sort((a: any, b: any) => a['label'].localeCompare(b['label']));

  states = [states[0], ...sortedStates];

  return states;
}

export function getFormatedGovtIdtypeDD(govtIdTypeDropDown: any) {
  const govtIdResponse = govtIdTypeDropDown?.data?.governmentIdTypeOptions;

  const govtIDTypes: dropdown[] = [];

  govtIdResponse.map((data: any, index: number) => {
    index === 0
      ? govtIDTypes.push(
          { label: 'Select', value: '0' },
          { label: data.description, value: data.referenceCode }
        )
      : govtIDTypes.push({
          label: data.description,
          value: data.referenceCode,
        });
  });

  return govtIDTypes;
}

export function formateSearchPayload(
  filterOptions: any,
  limit: any,
  offset: any
) {
  return {
    firstName: filterOptions.firstName,
    lastName: filterOptions.lastName,
    taxId: filterOptions.ssn1 + filterOptions.ssn2 + filterOptions.ssn3,
    dateOfBirth: filterOptions.dateOfBirth,
    governmentId: filterOptions.govtId,
    governmentIdType:
      filterOptions.govIdType == '0' ? '' : filterOptions.govIdType,
    emailAddress: filterOptions.email,
    phones: [
      {
        phoneNumber: filterOptions.phone.replace(/\D/g, ''),
      },
    ],
    addresses: [
      {
        city: filterOptions.city,
        state: filterOptions.state == '0' ? '' : filterOptions.state,
        postalCode: filterOptions.zip,
        addressLine1: filterOptions.addressLine1,
        addressLine2: filterOptions.addressLine2,
      },
    ],
    globalCustomerId: filterOptions.customerId,
    limit: limit,
    offset: offset,
    lob: ['RTO'],
  };
}

export function sortObject(
  order: string,
  key: string,
  customerDetials: any,
  setLastNameSortOrder: any,
  setFirstNameSortOrder: any,
  setCustomerDetails: any
) {
  let sortedArray: CustomerData[] = [...customerDetials];
  if (order === 'asc') {
    key == 'lastName'
      ? setLastNameSortOrder(true)
      : setFirstNameSortOrder(true);
    sortedArray = [...sortedArray].sort((a: any, b: any) =>
      a[key].localeCompare(b[key])
    );
  } else {
    key == 'lastName'
      ? setLastNameSortOrder(false)
      : setFirstNameSortOrder(false);
    sortedArray = [...sortedArray].sort((a: any, b: any) =>
      b[key].localeCompare(a[key])
    );
  }
  setCustomerDetails(sortedArray);
}

export function getValidationResponse(
  filterOptions: any,
  validEmail: any,
  validPhone: any
) {
  return (
    ((filterOptions.firstName.trim().length >= 3 ||
      filterOptions.lastName.trim().length >= 3 ||
      filterOptions.ssn1.trim().length +
        filterOptions.ssn2.trim().length +
        filterOptions.ssn3.trim().length ===
        9 ||
      (!validEmail && filterOptions.email.trim().length !== 0) ||
      (!validPhone && filterOptions.phone.trim().length !== 0) ||
      filterOptions.addressLine1.trim().length > 1 ||
      filterOptions.dateOfBirth.trim().length > 0) &&
      !(
        (filterOptions.email.trim().length > 0 && validEmail) ||
        (filterOptions.phone.trim().length > 0 && validPhone) ||
        (filterOptions.zip.trim().length !== 0 &&
          filterOptions.zip.trim().length < 5)
      )) ||
    filterOptions.customerId.trim().length > 0
  );
}
