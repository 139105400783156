import { handleWorkHistoryDetails } from './CustomerDetailsHelperFunc';

export function getWorkHistoryDetails(
  workHistory: any,
  setWorkHistoryTableBody: any,
  setLoader: any,
  setResponseStatus: any,
  workHistoryTableBody: any
) {
  if (workHistory && workHistory.status == 200) {
    handleWorkHistoryDetails(
      workHistory,
      setResponseStatus,
      setWorkHistoryTableBody,
      workHistoryTableBody
    );
  }
}
