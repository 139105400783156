/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  Grid,
  RACButton,
  Typography,
  RACTableRow,
  RACTableCell,
  RACCheckBox,
  Divider,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightAngle } from '../../Icons/angleRight.svg';
import { ReactComponent as Search } from '../../Icons/search-btn.svg';
import { ReactComponent as UpArrow } from '../../Icons/up-arrow-filter.svg';
import { ReactComponent as DownArrow } from '../../Icons/down-arrow-filter.svg';
import { MenuPermissionsHeading } from '../../constants/constants';
import {
  fetchConfigReference,
  fetchOrganizationDetails,
  getStoreDetails,
  getmenuandstore,
} from '../../api/user';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import AssignMenu from './AssignMenu';
import { AdminContext } from './AdminContext';
import MenuPermissionsFilter from './MenuPermissionsFilter';
import { MenuPermissionsGrid } from './MenuPermissionsGrid';
import { handleMenuConfig } from './commonFunction';

export default function MenuPermissions() {
  const {
    setLoader,
    menuSearchData, setMenuSearchData,
    displaySearch, setDisplaySearch,
    displaySearchResults, setDisplaySearchResults,
    displayAssignMenu, setDisplayAssignMenu, setErrorMessage,
    filterDisable, setFilterDisable, setHierarchyFieldDisable, disable, setAssignMenuSearchData,
    setParentHierarchyFieldDisable, setAssignMenupageDisplay, setDropDownLoading, dropDownLoading,
    setPageDisplay, pageDisplay, assignMenuContent, setAssignMenuContent, setRoleData,
    menuSearchContent, setMenuSearchContent, confirmationPopUp, setConfirmationPopUp, setDropDown,
    validationData, checkedRows, setCheckedRows, setDisplayContent, loader, setAssignMenuPageDisplay,
    userStore, setUserStore, setDisable, referenceDetails, setReferenceDetails, assignMenuPageDisplay,
    assignLoader, setAssignLoader,stateDetails, setStateDetails, assignMenuCopy, setAssignMenuCopy,
    setAsHierarchyValue, setAssignHierarchy, deletedRows, setDeletedRows
  } = useContext(AdminContext);
  const classes = GlobalAdminStyles();
  const history = useHistory();
  useEffect(() => {
    fetchReferenceData(null, 'Y')
    fetchDropDownData();
  }, [])

  async function fetchMenuAndStore(payload: any, boolean: boolean) {
    try {
      setDisplaySearchResults(true);
      setDisplaySearch(false);
      setFilterDisable(false);
      setMenuSearchContent([]);
      setCheckedRows([]);
      setPageDisplay(0)
      boolean ? payload.userStore = '' : payload.userStore = payload.userStore;
      let fetchedData: any = {};
      let storeData: any = {};
      const body = {
        coworkerId: payload.employeeId || null,
        emailId: payload.emailId || null,
        role: payload.hierarchy == 'ROLE' ? payload.hierarchyValue : null,
        menuRequest: ((payload.hierarchyValue || payload.userStore) && payload.hierarchy != 'ROLE') ? {
          association: {
            associationCode: payload.hierarchyValue || payload.userStore,
            associationType: payload.hierarchy || (payload.userStore ? 'STORE' : ''),
            parentAssociationCode: payload.parentHierarchyValue || null,
            parentAssociationType: payload.parentHierarchy || null
          }
        } : null,
        menuRequired: true,
        storesRequired: false,
        isAdmin: 'Y'
      }
      setLoader(true);
      ((payload.employeeId || payload.emailId) && (!payload.hierarchyValue && !payload.userStore)) ? [fetchedData, storeData] = await Promise.all([
        getmenuandstore(body),
        getmenuandstore({
          coworkerId: payload.employeeId,
          emailId: payload.emailId,
          storesRequired: true
        })
      ]) : fetchedData = await getmenuandstore(body);
      (!payload.employeeId && !payload.emailId && payload.hierarchy != 'COUNTRY' && payload.hierarchy != 'LOB' && payload.hierarchy != 'ROLE')
        ? await fetchReferenceData(payload, 'N') : null;
      setLoader(false);

      if (fetchedData?.status === 200 && fetchedData?.data?.coworkerProfile) {
        const userData = fetchedData?.data?.coworkerProfile;
        setReferenceDetails({
          ...referenceDetails,
          firstName: userData.firstName || '-',
          lastName: userData.lastName || '-',
          employeeId: userData.employeeId || '-',
          role: userData.role || '-',
          emailId: userData.emailAddress || '-',
        })
        let data = menuSearchData;
        data.employeeId = userData.employeeId;
        setMenuSearchData(data);
      }
      if((payload.employeeId || payload.emailId) && !fetchedData?.data?.coworkerProfile ){
        setReferenceDetails({
          ...referenceDetails,
          firstName: '-',
          lastName:'-',
          employeeId: '-',
          role: '-',
          emailId: '-',
        })
      }
      if (fetchedData?.status === 200 && storeData?.data?.storeAssignment) {
        fetchUserStores(storeData?.data?.storeAssignment)
      }
      if((payload.employeeId || payload.emailId) && (!payload.userStore) && !storeData?.data?.storeAssignment ){
        fetchUserStores([])
      }
      if (fetchedData?.status === 200 && fetchedData?.data?.menuConfig.length !== 0 &&
        fetchedData?.data?.menuConfig?.code != 400) {
        let menu: any = fetchedData?.data?.menuConfig;
        menuSearchData.employeeId &&
          !menuSearchData.hierarchyValue &&
          !menuSearchData.userStore
          ? (menu = fetchedData?.data?.menuConfig.filter((item: any) => item?.hierarchy === 'USER'))
          : null;
        if (menu?.length == 0 || !menu) {
          setPageDisplay(204)
        } else {
          setPageDisplay(200)
          handleMenuConfig(fetchedData?.data?.menuConfig, menuSearchData, setMenuSearchContent)
        }
      }
      else if ((fetchedData?.status === 204) || (fetchedData?.status === 200 && fetchedData?.data?.menuConfig.length === 0)) {
        setPageDisplay(204)
      }
      else if (fetchedData?.data?.menuConfig?.code == 400) {
        setPageDisplay(204);
      }
      else {
        setPageDisplay(500)
      }
    } catch (error) {
      setLoader(false);
    }
  }

  async function fetchUserStores(stores: any) {
    try {
      const dataArray: any[] = []
      stores.map((item: any) => {
        dataArray.push({
          label: item?.accountingUnitNumber,
          value: item?.accountingUnitNumber,
        })
      });
      dataArray.sort((a: any, b: any) => a.label.localeCompare(b.label));
      dataArray.unshift({ label: 'No Stores', value: '' });
      setUserStore(dataArray)
    } catch (error: any) {
      setUserStore([{ label: 'No Stores', value: '' }])
    }
  }

  function formatPhoneNumber(input: any) {
    const match = input.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return input;
  }

  async function fetchReferenceData(payload: any, menuRequired: string) {
    try {
      setAssignMenuPageDisplay(0)
      const body = {
        menuRequired: menuRequired,
        association: payload ? {
          associationCode: payload.hierarchyValue,
          associationType: payload.hierarchy
        } : null
      }
      if (payload?.hierarchy == 'STORE') {
        const storeData: any = await getStoreDetails({
          storeNumbers: [payload.hierarchyValue]
        })
        if (storeData?.status == 200 && storeData?.data?.response?.length > 0) {
          const storeResponse = storeData?.data?.response[0];
          setReferenceDetails({
            ...referenceDetails,
            associationCode: storeResponse.storeNumber || '-',
            address: (storeResponse.addressLine1) + (storeResponse.addressLine2 || ''),
            city: storeResponse.city || '-',
            zipCode: storeResponse.fullZip || '-',
            phone: storeResponse.workPhoneNumber ? formatPhoneNumber(storeResponse.workPhoneNumber) : '-',
            stateName: storeResponse.stateName || '-'
          })
        }
      }
      else {
        if (body.menuRequired === 'Y') {
          setDisable((prevDisable: any) => ({
            ...prevDisable,
            assignMenu: true
          }));
          setAssignLoader(true)
        }
        if (!payload?.parentHierarchyValue) {
          const headerData: any = await fetchConfigReference(body)

          if (headerData?.status == 200 && headerData?.data?.configReference?.adminAssociation) {
            const referenceData = headerData?.data?.configReference?.adminAssociation;
            setReferenceDetails({
              ...referenceDetails,
              associationCode: (referenceData.associationCode || body?.association?.associationCode),
              managerName: referenceData.managerName ? referenceData.managerName[0] : '-',
              noOfStores: (referenceData.noOfStores || '-'),
              parentAssociationCode: (referenceData.parentAssociationCode || '-'),
              countryRefCode: (referenceData.countryRefCode || '-'),
              stateName: (referenceData.stateName || '-'),
              companyType: (referenceData.companyType == '1' ? 'Franchise' : 'Core'),
              companyName: (referenceData.companyName),
              lobCode: (referenceData.lobCode || '-')
            })
          }
          if (body.menuRequired === 'Y' && headerData?.status == 200 && headerData?.data?.configReference?.menuList.length > 0) {
            const referenceData = headerData?.data?.configReference?.menuList;
            setAssignMenuCopy(referenceData)
          } else {
            setAssignMenuPageDisplay(500)
          }
        }
        if (body.menuRequired === 'Y') {
          setDisable((prevDisable: any) => ({
            ...prevDisable,
            assignMenu: false
          }));
          setAssignLoader(false)
        }
      }
    } catch (e) {
      setLoader(false)
    }
  }

  function clearSearch() {
    setMenuSearchData((prevMenuSearchData: any) => ({
      ...prevMenuSearchData,
      employeeId: '',
      emailId: '',
      hierarchy: '',
      hierarchyValue: '',
      parentHierarchy: '',
      parentHierarchyValue: ''
    }));
    setDisable((prevDisable: any) => ({
      ...prevDisable,
      employeeId: false,
      emailId: false,
      hierarchy: false,
      hierarchyValue: true,
      parentHierarchy: true,
      parentHierarchyValue: true
    }));
    setErrorMessage('')
  }

  function handleOnChange(e: any) {
    setMenuSearchData({
      ...menuSearchData,
      [e?.target?.name]: e?.target?.value,
    });
  }

  const renderMenuTableHeadFn = () => (
    <>
      {MenuPermissionsHeading.map((obj: any, index: any) => {
        return (
          <>
            <RACTableCell key={index}>{obj?.heading}</RACTableCell>
          </>
        )
      })}
    </>
  );

  const handleAssignMenu = (menu: any) => {

    const groupedMenu: any = {};

    menu.forEach((value: any) => {
      const { appName, appComponentName, appSubComponentName, appComponentFunctionName } = value;
      groupedMenu[appName] = groupedMenu[appName] || [];
      groupedMenu[appName].push({
        appName,
        appComponentName,
        appSubComponentName,
        appComponentFunctionName,
        view: false,
        edit: false,
        approve: false,
        active: false
      });
    });
    const sortedMenuGrouping = Object.values(groupedMenu)
      .map((menuGroup: any) => ({
        menuGrouping: menuGroup,
      }))
      .sort((a, b) => a.menuGrouping[0].appName.localeCompare(b.menuGrouping[0].appName));
    setAssignMenuContent(sortedMenuGrouping);
  };

  const handleCheckBox = (e: any, i: any, index: any, key: any) => {
    const updatedMenuSearchContent = [...menuSearchContent];
    updatedMenuSearchContent[i].menuGrouping[index][key] = e.target.checked;

    let updatedRow: any = {}
    let deleteRow: any = {}
    const row = updatedMenuSearchContent[i].menuGrouping[index];

    if (key === 'view') {
      if (!e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].edit = false;
        updatedMenuSearchContent[i].menuGrouping[index].approve = false;
      }
    }
    if (key === 'edit') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].view = true;
      }
      else {
        updatedMenuSearchContent[i].menuGrouping[index].approve = false;
      }
    }
    if (key === 'approve') {
      if (e.target.checked) {
        updatedMenuSearchContent[i].menuGrouping[index].view = true;
        updatedMenuSearchContent[i].menuGrouping[index].edit = true;
      }
    }
    const associationCode = row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? [row.hierarchyRefCode.split(' + ')[1].split('-')[1]]
      : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? [row.hierarchyRefCode.split(' + ')[0]]
        : row.hierarchy?.toUpperCase() == 'STATE' ? [row.hierarchyRefCode.split('-')[1]] : [row.hierarchyRefCode];
    if (key === 'active') {
      deleteRow = {
        appComponentFunction: row.appComponentFunctionName,
        employeeId: row.hierarchy == 'USER' ? [row.hierarchyValue] : null,
        role: row.hierarchy == 'ROLE' ? [row.hierarchyValue] : null,
        association: row.hierarchy !== 'USER' && row.hierarchy !== 'ROLE' ? {
          associationCode: associationCode,
          associationType: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? 'STATE' : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? 'COUNTRY' : (row.hierarchy).toUpperCase(),
          parentAssociationCode: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? row.hierarchyRefCode.split(' + ')[0]
            : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? row.hierarchyRefCode.split(' + ')[1] : null,
          parentAssociationType: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? 'COMPANY' : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? 'LOB' : null
        } : null,
        view: '0',
        edit: '0',
        active: '0',
        approve: '0',
      };
      deletedRows.push(deleteRow)
    } else {
      updatedRow = {
        appComponentFunction: row.appComponentFunctionName,
        employeeId: row.hierarchy == 'USER' ? [row.hierarchyValue] : null,
        role: row.hierarchy == 'ROLE' ? [row.hierarchyValue] : null,
        association: row.hierarchy !== 'USER' && row.hierarchy !== 'ROLE' ? {
          associationCode: associationCode,
          associationType: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? 'STATE' : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? 'COUNTRY' : (row.hierarchy).toUpperCase(),
          parentAssociationCode: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? row.hierarchyRefCode.split(' + ')[0]
            : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? row.hierarchyRefCode.split(' + ')[1] : null,
          parentAssociationType: row.hierarchy?.toUpperCase() == 'COMPANY + STATE' ? 'COMPANY' : row.hierarchy?.toUpperCase() == 'COUNTRY + LOB' ? 'LOB' : null
        } : null,
        view: row.view ? '1' : '0',
        edit: row.edit ? '1' : '0',
        active: '1',
        approve: row.approve ? '1' : '0',
      };
      const existingIndex = checkedRows.findIndex((row: any) => row.appComponentFunction === updatedRow.appComponentFunction && row.hierarchy === updatedRow.hierarchy);
      if (existingIndex !== -1) {
        checkedRows[existingIndex] = updatedRow;
      } else {
        checkedRows.push(updatedRow);
      }
    }

    setMenuSearchContent(updatedMenuSearchContent);
    setCheckedRows([...checkedRows]);
    setDeletedRows([...deletedRows])
  };

  const renderMenuTableContentFn = () => (
    <>
      <RACTableRow>
        <RACTableCell colSpan={12}>
          <Divider orientation='horizontal' />
        </RACTableCell>
      </RACTableRow>
      {menuSearchContent.map((menuItem: any, i: number) => (
        <React.Fragment key={i}>
          <RACTableRow>
            <RACTableCell className={`${classes.title}`}>
              {menuItem.menuGrouping[0].appName}
            </RACTableCell>
          </RACTableRow>
          {menuItem.menuGrouping.map((menu: any, index: number) => (
            <React.Fragment key={`${i}-${index}`}>
              <RACTableRow>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appComponentName || '-'}
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appSubComponentName || '-'}
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.appComponentFunctionName || '-'}
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  {menu.hierarchy || '-'}
                </RACTableCell>
                <RACTableCell title={(menu.hierarchy?.toUpperCase() == 'STATE' || menu.hierarchy?.toUpperCase() == 'COMPANY'
                  || menu.hierarchy?.toUpperCase() == 'COMPANY + STATE') ?
                  menu.hierarchyValue : menu.hierarchyRefCode} className={`${classes.textDarkSlateBlue}`}>
                  {(menu.hierarchy?.toUpperCase() == 'STATE' || menu.hierarchy?.toUpperCase() == 'COMPANY' ||
                    menu.hierarchy?.toUpperCase() == 'COMPANY + STATE') ?
                    menu.hierarchyValue ? menu.hierarchyValue.length > 40 ? `${menu.hierarchyValue.substring(0, 40)}...` :
                      menu.hierarchyValue : menu.hierarchyRefCode : menu.hierarchyRefCode}
                </RACTableCell>
                <RACTableCell>
                  <RACCheckBox
                    checked={menu.view}
                    onChange={(e: any) => handleCheckBox(e, i, index, 'view')}
                    size='medium'></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACCheckBox
                    checked={menu.edit}
                    size='medium'
                    onChange={(e: any) => handleCheckBox(e, i, index, 'edit')}
                  ></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACCheckBox
                    checked={menu.approve}
                    onChange={(e: any) => handleCheckBox(e, i, index, 'approve')}
                    size='medium'></RACCheckBox>
                </RACTableCell>
                <RACTableCell className={`${classes.textDarkSlateBlue}`}>
                  <RACButton
                    variant="text"
                    size="small"
                    className={`${classes.removeButtonStyle}`}
                    onClick={(e: any) => {
                      handleCheckBox(e, i, index, 'active');
                      setConfirmationPopUp({
                        ...confirmationPopUp,
                        isOpen: true,
                        isUser: (deletedRows && deletedRows[0]?.employeeId) ? true : false,
                        isAssign: false,
                        isRemove: true,
                        isSave: false
                      })
                    }
                    }
                  >
                    Remove
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            </React.Fragment>
          ))}
          <RACTableRow>
            <RACTableCell colSpan={12}>
              <Divider orientation='horizontal' />
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      ))}
    </>
  );

  function onKeyPress(e: any) {
    if (validationData && e.key == 'Enter') {
      if (menuSearchData.emailId && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(menuSearchData.emailId)) {
        setErrorMessage('Invalid Email Id');
      } else {
        setErrorMessage('');
        (menuSearchData.employeeId || menuSearchData.emailId) ? setDisplayContent("EMPLOYEE")
          : setDisplayContent(menuSearchData.hierarchy);
        fetchMenuAndStore(menuSearchData, true);
      }
    }
  }

  async function fetchDropDownData() {
    try {
      let roleData: any = [];
      let storeData: any = [];
      let districtData: any = [];
      let regionData: any = [];
      let stateData: any = [];
      let companyData: any = [];
      let countryData: any = [];
      let lobData: any = [];
      setDisable((prevDisable: any) => ({
        ...prevDisable,
        hierarchy: true,
      }));
      setDropDownLoading({ ...dropDownLoading, hierarchyLoading: true });
      [roleData, storeData, districtData, regionData, stateData, companyData, countryData, lobData] = await Promise.all([
        fetchConfigReference({ roleRequired: true }),
        fetchOrganizationDetails({ type: 'STORE' }),
        fetchOrganizationDetails({ type: 'DISTRICT' }),
        fetchOrganizationDetails({ type: 'REGION' }),
        fetchOrganizationDetails({ type: 'STATE' }),
        fetchOrganizationDetails({ type: 'COMPANY' }),
        fetchOrganizationDetails({ type: 'COUNTRY' }),
        fetchOrganizationDetails({ type: 'LOB' }),
      ])

      dropDownSetter(roleData, 'role')
      dropDownSetter(storeData, 'store')
      dropDownSetter(districtData, 'district')
      dropDownSetter(regionData, 'region')
      dropDownSetter(stateData, 'state')
      dropDownSetter(companyData, 'company')
      dropDownSetter(countryData, 'country')
      dropDownSetter(lobData, 'lob')
      setDropDownLoading({ ...dropDownLoading, hierarchyLoading: false });
      setDisable((prevDisable: any) => ({
        ...prevDisable,
        hierarchy: false,
      }));
    } catch (error: any) {
      setDropDownLoading({ ...dropDownLoading, hierarchyLoading: false });
      setDisable((prevDisable: any) => ({
        ...prevDisable,
        hierarchy: false,
      }));
    }
  }

  function dropDownSetter(response: any, key: any) {
    if (response?.status == 200) {
      let data: any = [];
      const today = new Date();
      key === 'role' ? data = response?.data?.tableData?.referenceDetails?.filter((val: any) => {
        const endDate = new Date(val.endDate);
        return endDate >= today
      }) :
        data = response?.data?.result;
      const dataArray: any = [];
      if (key == 'state') {
        setStateDetails(data)
      }
      data.map((item: any) => {
        if (key === 'role') {
          dataArray.push({
            label: item?.appRoleCode,
            value: (item?.appRoleCode).toString(),
          })
          setRoleData(data)
        }
        else {
          dataArray.push({
            label: key === 'state' ? item?.referenceValue : item?.referenceName,
            value: key === 'store' ? (item?.referenceName).toString() : (item?.referenceValue).toString(),
          })
        }
      })
      dataArray
        .sort((a: any, b: any) => a.label.localeCompare(b.label))
      dataArray.unshift({ label: 'Select', value: '' });

      setDropDown((prevDisable: any) => ({
        ...prevDisable,
        [key]: dataArray
      }));
    }
  }

  return (
    <Grid container md={12} className={`${classes.pagePadding}`}>
      <Grid container md={12} className={`${classes.mt20} ${classes.ml10}`}>
        <Typography variant='body1' className={`${classes.greyColor} ${classes.fs12} ${classes.cursorStyle}`}>
          <span onClick={() => {
            history.push({
              pathname: `/dashboard/homepage`,
            });
          }}> Dashboard </span>
          <span className={`${classes.ml10}`}><RightAngle className={`${classes.greyColor} ${classes.h10}`} /></span>
          <span className={`${classes.ml10} ${classes.cursorStyle} ${!displayAssignMenu ? classes.textBlue : null}`}
            onClick={() => {
              setDisplayAssignMenu(false)
              setDisplaySearch(true)
              clearSearch()
            }}>Menu Permissions</span>
          {displayAssignMenu ?
            <> <span className={`${classes.ml10}`}><RightAngle className={`${classes.greyColor} ${classes.h10}`} /></span>
              <span className={`${classes.textBlue} ${classes.cursorStyle} ${classes.ml10}`}>Assign Menu</span> </> : null}
        </Typography>
      </Grid>

      {displayAssignMenu == false ? <Grid container md={12} className={`${classes.mb3}`}>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt2} ${classes.title} ${classes.mL1}`}
          >
            Menu Permissions
          </Typography>
        </Grid>
        <Grid item md={6}>
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.w15} ${classes.mR1} ${classes.floatRight} ${classes.padding0}`}
            disabled={filterDisable}
            onClick={() => {
              displaySearchResults == true
                ? setDisplaySearch(!displaySearch)
                : setDisplaySearch(displaySearch);
            }}
          >
            <Search className={`${classes.mR1}`} />
            Search
            {displaySearch ? (
              <UpArrow className={`${classes.mL1}`} />
            ) : (
              <DownArrow className={`${classes.mL1}`} />
            )}
          </RACButton>
        </Grid>
      </Grid> : <></>}

      {displaySearch ? (
        <MenuPermissionsFilter
          onKeyPress={onKeyPress}
          fetchMenuAndStore={fetchMenuAndStore}
          handleOnChange={handleOnChange}
          clearSearch={clearSearch}
        />
      ) : (
        <></>
      )}

      {displaySearchResults ? (
        <MenuPermissionsGrid
          renderMenuTableHeadFn={renderMenuTableHeadFn}
          renderMenuTableContentFn={renderMenuTableContentFn}
          fetchMenuAndStore={fetchMenuAndStore}
        />
      ) : (
        <></>
      )
      }

      <Grid item md={12} className={classes.fixedBottom}>
        <RACButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            setDisplayAssignMenu(false)
            history.push({
              pathname: `/dashboard/homepage`,
            });
          }}
        >
          Cancel
        </RACButton>
        {displaySearchResults ?
          <RACButton
            color="primary"
            variant="contained"
            size="small"
            className={`${classes.floatRight} ${classes.padding0}`}
            disabled={checkedRows.length ? false : true}
            onClick={() => setConfirmationPopUp({
              ...confirmationPopUp,
              isOpen: true,
              isRemove: false,
              isSave: true,
              isUser: (checkedRows && checkedRows?.every((val: any) => val?.employeeId)) ? true : false,
            })}
          >
            Save
          </RACButton> : <></>}
        <RACButton
          loading={assignLoader}
          disabled={disable.assignMenu}
          color="primary"
          variant="contained"
          size="small"
          className={`${classes.floatRight} ${classes.padding0} ${classes.mR1} `}
          onClick={() => {
            handleAssignMenu(assignMenuCopy);
            setAsHierarchyValue([])
            setAssignHierarchy([])
            setAssignMenuSearchData((prevMenuSearchData: any) => ({
              ...prevMenuSearchData,
              employeeId: '',
              hierarchy: '',
              hierarchyValue: '',
              parentHierarchy: '',
              parentHierarchyValue: '',
            }));
            setAssignMenuPageDisplay(200);
            setDisplayAssignMenu(true)
            setDisplaySearch(false)
            setDisplaySearchResults(false)
            setDisable((prevDisable: any) => ({
              ...prevDisable,
              assignParentHierarchy: true
            }));
          }}
        >
          Assign Menu
        </RACButton>
      </Grid>

      {displayAssignMenu ?
        <AssignMenu /> : <></>
      }
    </Grid>
  );
}
