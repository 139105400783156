import { Card, Grid, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatDate } from '../../../../utils/InventoryUtils';
import { useHistory } from 'react-router-dom';

export function ChargeOff() {
  const history = useHistory();
  const { classes, chargeOffDetails } = useContext(InventoryDetailsContext);
  return (
    <>
      <Grid style={{ marginBottom: '10px' }}>
        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Typography variant="h5">Charge Off</Typography>
        </Grid>

        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <Grid container md={12} className={`${classes.cardLastRow}`}>
              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Agreement #
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  <a
                    className={classes.racpadLink}
                    onClick={() => {
                      if (chargeOffDetails?.agreementId) {
                        history.push(
                          `/operations/agreement/details/0000/${chargeOffDetails?.agreementId}`
                        );
                      }
                    }}
                  >
                    {chargeOffDetails.agreement
                      ? chargeOffDetails.agreement
                      : '-'}
                  </a>
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Receipt #
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {chargeOffDetails.receipt ? chargeOffDetails.receipt : '-'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Customer
                </Typography>
                <Typography
                  className={`${classes.formValue}`}
                  noWrap
                  title={
                    chargeOffDetails.customer ? chargeOffDetails.customer : ''
                  }
                >
                  {chargeOffDetails.customer ? chargeOffDetails.customer : '-'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>
                  Reason
                </Typography>
                <Typography
                  className={`${classes.formValue}`}
                  noWrap
                  title={chargeOffDetails.reason ? chargeOffDetails.reason : ''}
                >
                  {chargeOffDetails.reason ? chargeOffDetails.reason : '-'}
                </Typography>
              </Grid>

              <Grid item className={`${classes.w20}`}>
                <Typography className={`${classes.formLabel}`}>Date</Typography>
                <Typography className={`${classes.formValue}`}>
                  {chargeOffDetails.date
                    ? formatDate(chargeOffDetails.date)
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
