import {
  Grid,
  Card,
  CardContent,
  RACSelect,
  RACTextbox,
  RACButton,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { locationTypes } from '../../../utils/InventoryUtils';
import { InventorySearchContext } from '../../../context/Inventory/InventorySearchContext';
import { getInventorySearch, getRadiusStore } from '../../../api/user';

export function FilterWidget() {
  const {
    locationType,
    classes,
    searchParams,
    setSearchParams,
    setLocationType,
    setFilteredInventories,
    setSearchRequest,
    statusOptions,
    conditionsOptions,
    departmentOptions,
    subDepartmentOptions,
    setShowError,
    setNoRecords,
    dropdownLoader,
    setShowResult,
    setShowFilter,
    arrayFileds,
    setArrayFields,
    setGridLoader,
  } = useContext(InventorySearchContext);

  const validateFields = () => {
    return (arrayFileds.storeNumber !== '' &&
      arrayFileds.storeNumber.length == 5) ||
      arrayFileds.itemNumber !== '' ||
      searchParams.serialNumber !== '' ||
      searchParams.statusId !== '' ||
      searchParams.conditionId !== '0' ||
      searchParams.department !== '' ||
      searchParams.subDepartment !== '' ||
      searchParams.bracket !== '' ||
      searchParams.brand !== '' ||
      searchParams.modelNumber !== '' ||
      searchParams.poNumber !== ''
      ? true
      : false;
  };

  function handleOnChange(e: any, fieldType: string) {
    if (
      fieldType == 'textbox' &&
      (e.target.name == 'storeNumber' || e.target.name == 'itemNumber')
    ) {
      setArrayFields({ ...arrayFileds, [e.target.name]: e.target.value });
    } else {
      setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    }
  }

  const handleOnkeyDown = (e: any) => {
    if (e.key == 'Enter') {
      searchInventories();
    }
  };

  const formPayload = async () => {
    try {
      const radiusStores: string[] = [];

      if (locationType == 'Radius') {
        const radiusStoresResponse = await getRadiusStore(
          arrayFileds.storeNumber
        );
        if (
          radiusStoresResponse &&
          radiusStoresResponse?.data?.response.length > 0
        ) {
          radiusStoresResponse.data.response.forEach((obj: any) => {
            radiusStores.push(obj.neighborStoreNumber);
          });
        }
      } else {
        radiusStores.push(arrayFileds.storeNumber);
      }
      return {
        storeNumbers: arrayFileds.storeNumber != '' ? radiusStores : null,
        itemNumber:
          arrayFileds.itemNumber !== '' ? [arrayFileds.itemNumber] : null,
        serialNumber: searchParams.serialNumber,
        bracket: searchParams.bracket,
        brand: searchParams.brand,
        modelNumber: searchParams.modelNumber,
        poNumber: searchParams.poNumber != '' ? searchParams.poNumber : null,
        statusId: searchParams.statusId ? Number(searchParams.statusId) : null,
        subStatusId: locationType == 'Radius' ? [1, 2] : null,
        conditionId:
          searchParams.conditionId !== '0'
            ? Number(searchParams.conditionId)
            : null,
        department: searchParams.department,
        subDepartment: searchParams.subDepartment,
        setNumber: 0,
        isActivePriceTag: false,
        limit: 25,
      };
    } catch (error: any) {
      setGridLoader(false);
      setShowError(true);
    }
  };

  async function searchInventories() {
    try {
      const validSearch = validateFields();
      if (validSearch) {
        setGridLoader(true);
        setShowResult(true);
        setShowFilter(false);
        setShowError(false);
        setNoRecords(false);
        const payload: any = await formPayload();
        const inventorySearchResponse = await getInventorySearch(payload);

        if (inventorySearchResponse && inventorySearchResponse?.status == 200) {
          if (inventorySearchResponse.data.response.length == 0) {
            setFilteredInventories([]);
            setNoRecords(true);
          } else {
            setNoRecords(false);
            // setShowFilter(false);
            setFilteredInventories(inventorySearchResponse.data.response);
            setSearchRequest({
              storeNumbers: payload.storeNumbers,
              itemNumber: payload.itemNumber,
              serialNumber: payload.serialNumber,
              bracket: payload.bracket,
              brand: payload.brand,
              modelNumber: payload.modelNumber,
              poNumber: payload.poNumber,
              statusId: payload.statusId,
              subStatusId: payload.subStatusId,
              conditionId: payload.conditionId,
              department: payload.department,
              subDepartment: payload.subDepartment,
              setNumber: 1,
              isActivePriceTag: payload.isActivePriceTag,
              limit: 25,
            });
          }
        } else {
          setShowError(true);
        }
        setGridLoader(false);
      }
    } catch (error: any) {
      setGridLoader(false);
      setShowError(true);
    }
  }

  return (
    <>
      <Grid>
        <Card className={`${classes.cardBorder}`}>
          <CardContent style={{ padding: '5px' }}>
            <Grid container md={12} spacing={2} style={{ margin: '5px 0px' }}>
              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel="Location Type"
                  inputLabelClassName={`${classes.formLabel}`}
                  name="locationType"
                  options={locationTypes}
                  defaultValue={locationType}
                  isDisabled={arrayFileds.storeNumber.length < 5 ? true : false}
                  onChange={(e: any) => {
                    setLocationType(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Location"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="number"
                  id="location"
                  name="storeNumber"
                  value={arrayFileds.storeNumber}
                  maxlength={5}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Item #"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="number"
                  id="itemNo"
                  name="itemNumber"
                  value={arrayFileds.itemNumber}
                  maxlength={13}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Serial #"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="text"
                  id="serialNo"
                  name="serialNumber"
                  value={searchParams.serialNumber}
                  maxlength={20}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel="Status"
                  inputLabelClassName={`${classes.formLabel}`}
                  name="statusId"
                  loading={dropdownLoader}
                  isDisabled={statusOptions.length == 1 ? true : false}
                  defaultValue={searchParams.statusId}
                  options={statusOptions}
                  onChange={(e: any) => {
                    e.target.name = 'statusId';
                    handleOnChange(e, 'dropdown');
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel="Condition"
                  inputLabelClassName={`${classes.formLabel}`}
                  name="conditionId"
                  loading={dropdownLoader}
                  isDisabled={conditionsOptions.length == 1 ? true : false}
                  defaultValue={searchParams.conditionId}
                  options={conditionsOptions}
                  onChange={(e: any) => {
                    e.target.name = 'conditionId';
                    handleOnChange(e, 'dropdown');
                  }}
                />
              </Grid>
            </Grid>

            <Grid container md={12} spacing={2} style={{ margin: '5px 0px' }}>
              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel="Department"
                  inputLabelClassName={`${classes.formLabel}`}
                  name="department"
                  loading={dropdownLoader}
                  isDisabled={departmentOptions.length == 1 ? true : false}
                  defaultValue={searchParams.department}
                  options={departmentOptions}
                  onChange={(e: any) => {
                    e.target.name = 'department';
                    handleOnChange(e, 'dropdown');
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACSelect
                  inputLabel="Sub - Department"
                  inputLabelClassName={`${classes.formLabel}`}
                  name="subDepartment"
                  loading={dropdownLoader}
                  isDisabled={subDepartmentOptions.length == 1 ? true : false}
                  defaultValue={searchParams.subDepartment}
                  options={subDepartmentOptions}
                  onChange={(e: any) => {
                    e.target.name = 'subDepartment';
                    handleOnChange(e, 'dropdown');
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Bracket"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="text"
                  id="bracket"
                  name="bracket"
                  value={searchParams.bracket}
                  maxlength={50}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Brand"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="text"
                  id="brand"
                  name="brand"
                  value={searchParams.brand}
                  maxlength={50}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="Model #"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="text"
                  id="model"
                  name="modelNumber"
                  value={searchParams.modelNumber}
                  maxlength={50}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid item md={2} lg={2} sm={3} xs={6}>
                <RACTextbox
                  inputlabel="PO #"
                  inputLabelClassname={`${classes.formLabel}`}
                  type="text"
                  id="poNumber"
                  name="poNumber"
                  value={searchParams.poNumber}
                  maxlength={50}
                  OnChange={(e: any) => {
                    handleOnChange(e, 'textbox');
                  }}
                  OnKeydown={(e: any) => {
                    handleOnkeyDown(e);
                  }}
                />
              </Grid>

              <Grid
                item
                md={12}
                lg={12}
                xs={12}
                sm={12}
                style={{ marginTop: '10px' }}
              >
                <RACButton
                  variant="contained"
                  color="primary"
                  style={{ float: 'right', margin: '0px 15px' }}
                  disabled={!validateFields()}
                  onClick={() => {
                    searchInventories();
                  }}
                >
                  Apply
                </RACButton>

                <RACButton
                  variant="outlined"
                  color="primary"
                  style={{ float: 'right', padding: '10px 25px' }}
                  disabled={!validateFields()}
                  onClick={() => {
                    setArrayFields({
                      storeNumber: '',
                      itemNumber: '',
                    });
                    setSearchParams({
                      serialNumber: '',
                      bracket: '',
                      brand: '',
                      modelNumber: '',
                      poNumber: '',
                      statusId: '',
                      conditionId: '0',
                      department: '',
                      subDepartment: '',
                    });
                    setLocationType('Store');
                  }}
                >
                  Clear
                </RACButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
