/* eslint-disable prettier/prettier */
/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const AppConfigurationStyles = () => {
    const useClasses = makeStyles((theme) => ({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '10px',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(189, 189, 196)',
                borderRadius: '20px',
                border: '3px solid rgb(206, 204, 201)',
            },
            '*::-webkit-scrollbar-track': {
                background: 'rgb(245, 243, 239)',
            },
        },

        accodrionStyle:{
            maxWidth: '18px', display: 'inline' 
        },
        actionButton: {
            backgroundColor: "#D7E7FE",
        },
        alignCenter:{
            display: 'flex', alignItems: 'center'
        },
        businessValue: {
            zIndex: 3,
            '& input':{
                borderTopLeftRadius:'0px !important',
                borderBottomLeftRadius:'0px !important',
                textOverflow:'ellipsis'
            },
        },
        cardStyle:{ borderRadius: "15px", overflow: "visible" },
        clearButton:{
            float: "right",
            padding: "11px 25px",
            marginRight: "16px",
        },
        excelStyle:{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
        },
        floatLeft:{
            float:'left'
        },
        floatRight:{
            float: "right" 
        },
        fixedFooter: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '1rem',
        },
        fixPopup:{
            position:'fixed'
        },
        modificationCardSize: {
            '& div': {
                '& div': {
                    maxWidth: '775px',
                },
            }
        },
        mb1: {
            marginBottom: '0.2rem'
        },
        mb125:{
            marginBottom:'0.125rem'
        },
        mt1:{
            marginTop:'1rem'
        },
        multiSelectDisabled: {
            '& .dropdown-container': {
                height: '2rem',
                backgroundColor:'#EBEBE4'
            },
            '& .dropdown-heading': {
                height: '2rem'
            },
            "& .dropdown-content": {
                borderRadius: "8px",
                zIndex: 15
            }
        },
        multiSelectEnabled: {
            '& .dropdown-container': {
                height: '2rem'
            },
            '& .dropdown-heading': {
                height: '2rem'
            },
            "& .dropdown-content": {
                borderRadius: "8px",
                zIndex: 15
            }
        },
        p1:{
            padding:'1rem'
        },
        pb75:{
            paddingBottom:'0.75rem'
        },
        toooltipStyle:{
            
            "& MuiTooltip-popper":{
                minWidth:'600px',
                "& MuiTooltip-tooltip":{
                    minWidth:'600px'
                }
            }
        },
        tableStyle: {
           
            "& th": {
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                fontFamily: "OpenSans-bold",
                whiteSpace: "nowrap",
                zIndex: 10
            },

        },
        
        indexStyle: {
            // zIndex: 3,
            '& input':{
                borderTopLeftRadius:'0px !important',
                borderBottomLeftRadius:'0px !important'
            },
            width:'220px'
           
        },
        viewValue: {
            // zIndex: 3,
            '& input':{
                borderTopLeftRadius:'0px !important',
                borderBottomLeftRadius:'0px !important'
            },
            width:'166%'
           
        },
        infiniteLoader: {
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '16px',
            color: 'black',
            margin: '30px 0px',
        },
        width100:{
            width:'100%'
        },
        whiteBackground:{
            backgroundColor:'white'
        }
       
    }));
    return useClasses();
};
