import { Grid, RACButton } from '@rentacenter/racstrap';
import { InventoryStyles } from '../../../../JsStyles/InventoryStyles';
import { useHistory } from 'react-router-dom';
import React from 'react';

export function Footer() {
  const styles = InventoryStyles();
  const history = useHistory();
  return (
    <>
      <Grid container style={{ zIndex: '0' }} className={styles.fixedFooter}>
        <Grid className={styles.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            className={styles.ms2}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
}
