import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { customerDetailsStyle } from './../customerStyles';
import CollapseArrow from '../../../assets/images/Accordion-close-updated.png';
import ExpandArrow from '../../../assets/images/Accordion-down-updated.png';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import { ReactComponent as AlertIcon } from '../../../emilyIcons/no-records-found.svg';
import { ReactComponent as SortDownIcon } from './../../../emilyIcons/Sort.svg';
import { ReactComponent as SortUpIcon } from '../../../assets/images/sort-up-icon.svg';
import React, { useEffect, useState } from 'react';
import { sortObject } from './../../../utils/utils';
import { AgreementTableBodyRender } from '../CustomerDependencies/AgreementsHelper';
import { Loader } from '../CommonCompo/Loader';

export default function AgreementsTab(props: any) {
  const classes: any = customerDetailsStyle();
  const sortOrder = 'customers[0].priority';

  useEffect(() => {
    calculatePriorityOfCustomer();
  });

  const aggrementHeading: tableHeadings[] = [
    { heading: ' ' },
    { heading: 'Agreement #' },
    { heading: 'Store #' },
    { heading: 'Route' },
    { heading: 'Renter' },
    { heading: 'Description' },
    { heading: 'Opened' },
    { heading: 'Due Date' },
    { heading: 'Days Late' },
    { heading: 'Rate' },
    { heading: 'Term' },
    { heading: 'Rent Paid' },
    { heading: 'Closed Date' },
    { heading: 'Closed Reason' },
    { heading: 'Action' },
  ];

  const [toggleObject, setToggleObj] = useState<any>({
    agreementRenter: false,
    referenceStatus: false,
    employmentStatus: false,
    residenceStatus: false,
    paymentHistoryDate: false,
    workHistoryDate: false,
  });

  const [showActiveAgr, setShowActiveAgr] = useState(true);
  const [showInactiveAgr, setShowInactiveAgr] = useState(false);

  let activeAgreementsBody = props.value.tableBodyOne;
  let inActiveAgreementsBody = props.value.tableBodyTwo;
  const activeAgreementsPriority: any = [];
  function calculatePriorityOfCustomer() {
    activeAgreementsBody?.forEach((element: any) => {
      const array = element?.customers?.find(
        (arr: any) => props?.value?.customerId == arr?.customerId
      );
      if (array) {
        array?.length == 0
          ? activeAgreementsPriority.push(null)
          : activeAgreementsPriority.push(array);
      } else {
        activeAgreementsPriority.push(null);
      }
    });

    const inactivePriorityArray: any = [];
    inActiveAgreementsBody?.forEach((element: any) => {
      const array = element?.customers?.find(
        (arr: any) => props?.value?.customerId == arr?.customerId
      );
      if (array) {
        array?.length == 0
          ? inactivePriorityArray.push(null)
          : inactivePriorityArray.push(array);
      } else {
        inactivePriorityArray.push(null);
      }
    });

    activeAgreementsBody?.forEach((element: any, index: any) => {
      element.customers[0] = activeAgreementsPriority[index];
    });

    inActiveAgreementsBody?.forEach((element: any, index: any) => {
      element.customers[0] = inactivePriorityArray[index];
    });
  }

  const renderActiveAgr = () => (
    <>
      {props.value.activeAgrStatus == 200 &&
      activeAgreementsBody?.length > 0 ? (
        activeAgreementsBody.map((obj: any, index: any) => {
          return AgreementTableBodyRender(
            obj,
            index,
            props.value.customerId,
            1
          );
        })
      ) : (
        <RACTableRow>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell colSpan={6}>
            <Grid container md={12}>
              <Grid
                item
                md={12}
                className={`${classes.textCenter} ${classes.mt1}`}
              >
                {props.value.activeAgrStatus == 204 ? (
                  <NoRecordsFound />
                ) : (
                  <AlertIcon />
                )}
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  {props.value.activeAgrStatus == 204
                    ? 'No Records Found'
                    : props.value.activeAgrStatus == 400
                    ? 'Bad Request'
                    : 'Something Went Wrong'}
                </Typography>
              </Grid>
            </Grid>
          </RACTableCell>
        </RACTableRow>
      )}
    </>
  );

  const renderInactiveAgr = () => (
    <>
      {props.value.inActiveAgrStatus == 200 &&
      inActiveAgreementsBody?.length > 0 ? (
        inActiveAgreementsBody.map((obj: any, index: any) => {
          return AgreementTableBodyRender(
            obj,
            index,
            props.value.customerId,
            2
          );
        })
      ) : (
        <RACTableRow>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell colSpan={6}>
            <Grid container md={12}>
              <Grid
                item
                md={12}
                className={`${classes.textCenter} ${classes.mt1}`}
              >
                {props.value.inActiveAgrStatus == 204 ? (
                  <NoRecordsFound />
                ) : (
                  <AlertIcon />
                )}
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  {props.value.inActiveAgrStatus == 204
                    ? 'No Records Found'
                    : props.value.inActiveAgrStatus == 400
                    ? 'Bad Request'
                    : 'Something Went Wrong'}
                </Typography>
              </Grid>
            </Grid>
          </RACTableCell>
        </RACTableRow>
      )}
    </>
  );

  const agreementTabHeading = () => (
    <>
      {aggrementHeading.map((obj: any, index: any) => {
        {
          if (
            obj.heading == 'Agreement #' ||
            obj.heading == 'Opened' ||
            obj.heading == 'Due Date'
          ) {
            return (
              <RACTableCell
                key={index}
                className={`${classes.bold} ${classes.width8}`}
              >
                {obj.heading}
              </RACTableCell>
            );
          } else if (obj.heading == 'Route') {
            return (
              <RACTableCell
                key={index}
                className={`${classes.bold} ${classes.width5}`}
              >
                {obj.heading}
              </RACTableCell>
            );
          } else if (obj.heading == 'Rent Paid') {
            return (
              <RACTableCell
                key={index}
                className={`${classes.bold} ${classes.width8} ${classes.mr2} ${classes.textRight}`}
              >
                {obj.heading}
              </RACTableCell>
            );
          } else if (
            obj.heading == 'Rate' ||
            obj.heading == 'Rent Paid' ||
            obj.heading == 'Term'
          ) {
            return (
              <RACTableCell
                key={index}
                className={`${classes.bold} ${classes.width5} ${classes.textRight}`}
              >
                {obj.heading}
              </RACTableCell>
            );
          } else if (obj.heading == 'Renter') {
            return (
              <RACTableCell key={index} className={classes.bold}>
                {obj.heading}{' '}
                {!toggleObject.agreementRenter ? (
                  <SortDownIcon
                    className={classes.sortIconPosition}
                    onClick={() => {
                      activeAgreementsBody = sortObject(
                        'asc',
                        sortOrder,
                        activeAgreementsBody,
                        'agreementRenter',
                        setToggleObj,
                        props.value.setActiveAgreements
                      );
                      inActiveAgreementsBody = sortObject(
                        'asc',
                        sortOrder,
                        inActiveAgreementsBody,
                        'agreementRenter',
                        setToggleObj,
                        props.value.setInactiveAgreements
                      );
                    }}
                  />
                ) : (
                  <SortUpIcon
                    className={classes.sortIconPosition}
                    onClick={() => {
                      activeAgreementsBody = sortObject(
                        'desc',
                        sortOrder,
                        activeAgreementsBody,
                        'agreementRenter',
                        setToggleObj,
                        props.value.setActiveAgreements
                      );
                      inActiveAgreementsBody = sortObject(
                        'desc',
                        sortOrder,
                        inActiveAgreementsBody,
                        'agreementRenter',
                        setToggleObj,
                        props.value.setInactiveAgreements
                      );
                    }}
                  />
                )}
              </RACTableCell>
            );
          } else if (obj.heading == 'Action') {
            return (
              <RACTableCell
                key={index}
                className={`${classes.textCenter} ${classes.bold}`}
              >
                {obj.heading}
              </RACTableCell>
            );
          }
          return (
            <RACTableCell key={index} className={`${classes.bold}`}>
              {obj.heading}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const agreementTabContent = () => (
    <>
      <RACTableRow
        style={{ backgroundColor: 'white' }}
        className={`${classes.fixTableHeadAgrTrsRec} ${classes.semiBold}`}
      >
        <RACTableCell style={{ width: '3%' }}>
          {showActiveAgr ? (
            <img
              src={ExpandArrow}
              className={`${classes.arrowIcon} ${classes.pointer}`}
              onClick={() => {
                setShowActiveAgr(false);
              }}
            />
          ) : (
            <img
              src={CollapseArrow}
              className={`${classes.arrowIcon} ${classes.pointer}`}
              onClick={() => {
                setShowActiveAgr(true);
              }}
            />
          )}
        </RACTableCell>
        <RACTableCell>Active</RACTableCell>
      </RACTableRow>
      {showActiveAgr ? renderActiveAgr() : ''}

      <RACTableRow
        style={{ backgroundColor: 'white' }}
        className={`${classes.fixTableHeadAgrTrsRec} ${classes.semiBold}`}
      >
        <RACTableCell style={{ width: '3%' }}>
          {showInactiveAgr ? (
            <img
              src={ExpandArrow}
              className={`${classes.arrowIcon} ${classes.pointer}`}
              onClick={() => {
                setShowInactiveAgr(false);
              }}
            />
          ) : (
            <img
              src={CollapseArrow}
              className={`${classes.arrowIcon} ${classes.pointer}`}
              onClick={() => {
                setShowInactiveAgr(true);
                setShowActiveAgr(false);
              }}
            />
          )}
        </RACTableCell>
        <RACTableCell>InActive</RACTableCell>
      </RACTableRow>
      {showInactiveAgr ? renderInactiveAgr() : ''}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01} ${classes.fixTableHeadAgrTrsRec} ${classes.semiBold}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid container md={12}>
              <RACTable
                className={classes.fixTableHeadAgrTrsRec}
                renderTableHead={agreementTabHeading}
                renderTableContent={agreementTabContent}
                stickyHeader={true}
              />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
