/* eslint-disable */
import * as XLSX from "xlsx";

import { DropdownValue } from "../Interfaces/Inventory/AppConfigInterface";
import moment from "moment";
import { getOrgRulesSummary } from "../api/user";
import {
  DEFAULT_RULE_LIMIT,
  DEFAULT_RULE_OFFSET,
  booleanValues,
} from "../constants/constants";
import { ReactComponent as Text } from "../assets/images/string.svg";
import { ReactComponent as Int } from "../assets/images/numbers.svg";
import { ReactComponent as Percentage } from "../assets/images/percentage.svg";
import { ReactComponent as Booleans } from "../assets/images/boolean.svg";
import { ReactComponent as Dollar } from "../assets/images/dollar.svg";
import { ReactComponent as Arrays } from "../assets/images/array.svg";
import { ReactComponent as Time } from "../assets/images/clock.svg";
import { ReactComponent as Json } from "../assets/images/json.svg";
// import { AppConfigurationContext } from '../context/AppConfigurations/AppConfigurationContext';

function sortByProperty(arr: any[], property: string):any[] {
  try{
  const index=arr.findIndex((item:any)=>item[property]=='Select');
  if(index>=0){
    arr.splice(index,1);
  }
  const sortedItem= arr.sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });

  if(index>=0){
    sortedItem.splice(0,0,{label:'Select',value:''})
  }

  return sortedItem
  }
  catch(err:any){
    return arr
  }

}
function getUniqueDataByProperty(arr:any[],property:string) {
  try{
  const uniqueData:string[]=[];
  const uniqueObject:any[]=[];
  arr?.forEach((data:any)=>{
    if(!uniqueData.includes(data[property])){
      uniqueData.push(data[property]);
      uniqueObject.push(data)
    }
  });
  return uniqueObject;
}
catch(err:any){
  return arr;
}
}

function formDropdownValues(
  dropdownData: any[],
  labelName: string,
  valueName: string,
  dropdownType: string,
  isfranchiseeUser?: boolean
): DropdownValue[] {
  try{
  let dropdownFormed: DropdownValue[] = [];
  if (dropdownType == "single") {
    dropdownFormed.push({ label: "Select", value: "" });
  }

  dropdownData.forEach((dropdownvalue: any) => {
    dropdownFormed.push({
      label: dropdownvalue[labelName],
      value: dropdownvalue[valueName],
    });
  });

  const filterArr = ['Legal Engine', 'Legal Engine Inv Condition', 'Legal Limit']
  if (isfranchiseeUser) {
   //Filter the category  based on the group applied for franchisee user...
    dropdownFormed = dropdownFormed.filter(param => {
      const filterdCategory = !filterArr.includes(param.value);
      return filterdCategory; 
    });
  }
  return dropdownFormed;
}
catch(err:any){
  return [{ label: "Select", value: "" }];
}
}

function parseJSONObject(value: string) {
  try {
    const parsedValue = JSON.parse(value);
    return parsedValue;
  } catch (e) {
    return null;
  }
}

function parseObjectLiteral(value: string) {
  try {
    const parsedValue = eval("(" + value + ")");
    return parsedValue;
  } catch (e) {
    return null;
  }
}

function convertStringToObject(value: string) {
  try{
    const data: any = parseJSONObject(value);
    if (data) {
      return data;
    } else {
      return parseObjectLiteral(value);
    }
  }
  catch(err:any){
    return {}
  }
}

function isMatchingObject(template: any, request: any): boolean {
  try {
    if (
      typeof template === "object" &&
      !Array.isArray(template) &&
      typeof request === "object" &&
      !Array.isArray(request)
    ) {
      const templateKeys = Object.keys(template);
      const requestKeys = Object.keys(request);

      if (requestKeys.length !== templateKeys.length) {
        return false;
      }

      return templateKeys.every((key) => {
        if (!(key in request)) {
          return false;
        }

        const templateValue = template[key];
        const requestValue = request[key];

        if (Array.isArray(templateValue)) {
          if (!Array.isArray(requestValue)) {
            return false;
          }

          return requestValue.every((request) => {
            return isMatchingObject(templateValue[0], request);
          });
        } else if (
          Object.prototype.toString.call(templateValue) == "[object Object]"
        ) {
          return isMatchingObject(templateValue, requestValue);
        } else {
          return (
            Object.prototype.toString.call(templateValue) ===
            Object.prototype.toString.call(requestValue)
          );
        }
      });
    }
    return false;
  } catch (e) {
    return false;
  }
}

const getExportData = async (coreUser: boolean, request: any) => {
  try {
    let i = 0;
    let response: any[] = [];
    const payload = { ...request };
    payload.limit = DEFAULT_RULE_LIMIT;
    payload.offset = DEFAULT_RULE_OFFSET;
    const existingRule: any[] = [];
    const upcomingRule: any[] = [];
    for (i; true; i++) {
      if (i == 5) {
        break;
      }
      const summaryResponse = await getOrgRulesSummary(payload);
      payload.limit = payload.limit + DEFAULT_RULE_LIMIT;
      payload.offset = payload.offset + DEFAULT_RULE_LIMIT;

      if (
        summaryResponse?.status == 200 &&
        summaryResponse?.data?.organizationRule?.length
      ) {
        response = response.concat(summaryResponse?.data?.organizationRule);
        if (summaryResponse.data.organizationRule.length < DEFAULT_RULE_LIMIT) {
          break;
        }
      } else {
        break;
      }
    }
    response?.forEach((masterCategories: any) => {
      const masterCategoryName = {
        Category_Master_Display_Name:
          masterCategories.categoryMasterDisplayName,
        Param_Category_Master_Name: masterCategories.paramCategoryMasterName,
      };

      masterCategories.paramCategoryDetails.forEach((categories: any) => {
        const categoryName: any = { ...masterCategoryName };
        (categoryName.Category_Display_Name = categories.categoryDisplayName),
          (categoryName.Param_Category_Name = categories.paramCategoryName);

        categories.ruleDetails.forEach((ruleData: any) => {
          const organizationRule: any = { ...categoryName };
          organizationRule.Category_Master_Display_Name =
            ruleData.paramKeyType == "FEATURE_FLAG"
              ? "Feature"
              : ruleData.paramKeyType == "CONFIGURATION"
              ? "Configurations"
              : organizationRule.Category_Master_Display_Name;
          organizationRule.Category_Display_Name =
            ruleData.paramKeyType == "FEATURE_FLAG"
              ? "Feature"
              : ruleData.paramKeyType == "CONFIGURATION"
              ? "Configurations"
              : organizationRule.Category_Display_Name;
          organizationRule.Group_Display_Name = ruleData.groupDisplayName;
          organizationRule.Param_Group_Name = ruleData.paramGroupName;
          organizationRule.Rule_Display_Name = ruleData.keyDisplayName;
          organizationRule.Param_Key_Name = ruleData.paramKeyName;
          organizationRule.Param_Key_Type = ruleData.paramKeyType;
          organizationRule.Param_Name = ruleData.paramName;
          organizationRule.Business_Value = ruleData.paramValue;
          organizationRule.Business_Value_Multiplier =
            ruleData.paramValueMultiplier;
          organizationRule.Business_Value_Datatype =
            ruleData.paramValueDatatype;
          organizationRule.Hierarchy_Type = ruleData.hierarchyType;
          organizationRule.Hierarchy_Value = ruleData.hierarchyValue;
          organizationRule.Start_Date = ruleData.startDate;
          organizationRule.End_Date = ruleData.endDate;
          organizationRule.Rule_Description = ruleData.paramKeyDesc;
          organizationRule.Created_By = ruleData.createdBy;
          organizationRule.Created_DateTime = ruleData.createdDate;
          organizationRule.Last_Modified_By = ruleData.lastModifiedBy;
          organizationRule.Last_Modified_DateTime = ruleData.lastModifiedDate;

          if (
            (!coreUser &&
              ruleData.paramKeyType != "FEATURE_FLAG" &&
              ruleData.paramKeyType != "CONFIGURATION") ||
            coreUser
          ) {
            if (ruleData.startDate > moment().format("YYYY-MM-DD")) {
              upcomingRule.push(organizationRule);
            } else {
              existingRule.push(organizationRule);
            }
          }
        });
      });
    });

    return { upcomingRule: upcomingRule, existingRule: existingRule };
  } catch (err: any) {
    return { upcomingRule: [], existingRule: [] };
  }
};

async function exportToExcel(coreUser: boolean, request: any) {
  try{
    const ruleDetails: any = await getExportData(coreUser, request);
    console.log('Excel rules',ruleDetails);
    const ws = XLSX.utils.json_to_sheet(ruleDetails.existingRule);
    const ws1 = XLSX.utils.json_to_sheet(ruleDetails.upcomingRule);
    const wb = XLSX.utils.book_new();
    const ws1Cols = Object.keys(ruleDetails.existingRule[0]);
    ws["!cols"] = ws1Cols.map((col) => ({ width: 30 }));
    ws1["!cols"] = ws1Cols.map((col) => ({ width: 30 }));
    XLSX.utils.book_append_sheet(wb, ws, "Existing Rules");
    XLSX.utils.book_append_sheet(wb, ws1, "Upcoming Rules");
  
    // Write to file
    XLSX.writeFile(
      wb,
      `AppConfigurations_${moment().format("YYYY-MM-DD hh_mm_ss")}.xlsx`
    );
  }
  catch(err:any){
    return ''
  }
}

const getBusinessValueIcon = (datatype?: string) => {
  switch (datatype?.toUpperCase()) {
    case "STRING":
      return <Text />;
    case "PERCENTAGE":
      return <Percentage />;
    case "AMOUNT":
      return <Dollar />;
    case "ARRAY":
      return <Arrays />;
    case "BOOLEAN":
      return <Booleans />;
    case "TIME":
      return <Time />;
    case "NUMBER":
      return <Int />;
    case "JSON":
      return <Json />;
    default:
      return <Text />;
  }
};

const validDatatype = (
  businessValueType: string,
  value: string
): string | undefined | null => {
  switch (businessValueType) {
    case "NUMBER":
    case "PERCENTAGE":
    case "AMOUNT": {
      const regex = /^[-+]?\d*\.?\d*$/;
      if (regex.test(value)||value=='') {
        return value;
      }
      break;
    }

    case "TIME":
    case "BOOLEAN":
    case "JSON":
    case "OBJECT":
    case "STRING":
    case "ARRAY": {
      return value;
    }
  }
};

export {
  sortByProperty,
  formDropdownValues,
  isMatchingObject,
  exportToExcel,
  getBusinessValueIcon,
  validDatatype,
  convertStringToObject,
  getUniqueDataByProperty
};
