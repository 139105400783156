/* eslint-disable prettier/prettier */
/* eslint-disable */
import {
  CardContent,
  Grid,
  Card,
  CircularProgress,
  Typography,
  RACSelect,
  RACTable,
} from '@rentacenter/racstrap';
import { useContext } from 'react';
import { AdminContext } from './AdminContext';
import ErrorContent from '../Agreement/ErrorMessageContent';
import { fetchConfigReference } from '../../api/user';
import { ContentHeaders } from './ContentHeaders';
import { PinPopUp } from './PinPopUp';
import ConfirmationPopUp from './ConfirmationPopUp';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import SuccessPopUp from './SuccessPopUp';

export function MenuPermissionsGrid(props: any) {

  const {
      history,
      loader, setLoader,
      menuSearchData, setMenuSearchData,
      parentHierarchyType, setParentHierarchyType,
      hierarchyValue, setHierarchyValue,
      parentHierarchyValue, setParentHierarchyValue,
      displaySearch, setDisplaySearch,
      displaySearchResults, setDisplaySearchResults,
      displayAssignMenu, setDisplayAssignMenu,
      filterDisable, setFilterDisable,
      buttonDisable,
      hierarchyFieldDisable, setHierarchyFieldDisable,
      parentHierarchyFieldDisable, setParentHierarchyFieldDisable,
      pageDisplay, setPageDisplay, displayContent,
      menuSearchContent, setMenuSearchContent, dropDownLoading, setDropDownLoading,
      insertUpdateData, setInsertUpdateData, validationData, userStore, confirmationPopUp, setConfirmationPopUp
  } = useContext(AdminContext);
  
  const classes = GlobalAdminStyles();
  
  const renderErrorContent = (content: any, status: any) => (
      <ErrorContent content={content} status={status} style={classes.mt40} />
  );



  return (
      <>
          <Grid
              container
              md={12}
              className={displaySearch === true ? `${classes.mt35}` : ''}
          >
              <Card className={classes.card} style={{ padding: '1%' }}>
                  {pageDisplay === 200 || pageDisplay === 204 ?
                      <>
                          <ContentHeaders />
                          {displayContent == 'EMPLOYEE' ?
                              <Grid container md={12} style={{ margin: '1%' }}>
                                  <Grid item style={{ width: '4%' }}>
                                      <Typography className={`${classes.title}`}> Store: </Typography>
                                  </Grid>
                                  <Grid item md={1}>
                                      <RACSelect
                                          onChange={(e) => {
                                              let data = menuSearchData;
                                              data.userStore = e?.target?.value
                                              setMenuSearchData(data);
                                              props.fetchMenuAndStore(data, false)
                                          }}
                                          options={userStore}
                                          defaultValue={menuSearchData?.userStore}
                                      />
                                  </Grid>
                              </Grid> : <></>}
                      </> : null}
                  <CardContent className={pageDisplay != 200 ? classes.cardContentDefault : classes.cardContent}>
                      <Grid className={pageDisplay != 200 ? classes.fixTableHeadAgrTrsRec1 : classes.fixTableHeadAgrTrsRec}>
                          {loader ? (
                              <Grid className={`${classes.cardLoader}`}>
                                  <CircularProgress />
                              </Grid>
                          ) : null}

                          {pageDisplay === 204 ? (
                              renderErrorContent('No Records Found', 204)
                          ) :
                              pageDisplay === 200 ? (
                                  <RACTable
                                      renderTableHead={props.renderMenuTableHeadFn}
                                      renderTableContent={props.renderMenuTableContentFn}
                                      stickyHeader
                                  />
                              ) : pageDisplay == 400 ? (
                                  renderErrorContent('Bad Request', 400)
                              ) : pageDisplay == 500 ? (
                                  renderErrorContent('Something Went Wrong', 500)
                              ) : (
                                  <></>
                              )}

                      </Grid>
                  </CardContent>
              </Card>
              {confirmationPopUp.isOpen ? <ConfirmationPopUp isOpen={confirmationPopUp.isOpen} isAssign={confirmationPopUp.isAssign} isUser={confirmationPopUp.isUser} isRemove={confirmationPopUp.isRemove} isSave={confirmationPopUp.isSave} /> : null}
              {confirmationPopUp.isPinOpen ? <PinPopUp isPinOpen={confirmationPopUp.isPinOpen} isRemove={confirmationPopUp.isRemove} value={'MenuConfig'} /> : null}
              {confirmationPopUp.successPopUp ? <SuccessPopUp isOpen={confirmationPopUp.successPopUp} value={'MenuConfig'} /> : null}
          </Grid></>
  )
}
