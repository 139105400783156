import { Card, Grid, CardContent, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatDate } from '../../../../utils/InventoryUtils';

export function RecoveryDetails() {
  const { classes, recoveryDetails } = useContext(InventoryDetailsContext);
  return (
    <>
      <Grid>
        <Grid container md={12} style={{ marginBottom: '8px' }}>
          <Typography variant="h5">Recovery</Typography>
        </Grid>
        <Card className={`${classes.informationCard}`}>
          <CardContent>
            <Grid container md={12} className={`${classes.cardLastRow}`}>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  Recovery Details
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {recoveryDetails.recoveryDate
                    ? formatDate(recoveryDetails.recoveryDate)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Typography className={`${classes.formLabel}`}>
                  RV at Recovery
                </Typography>
                <Typography className={`${classes.formValue}`}>
                  {recoveryDetails.rvAtRecovery
                    ? recoveryDetails.rvAtRecovery
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
