import React, { createContext, useState } from 'react';
import { customerDetailsStyle } from '../customerStyles';
import {
  EmployerReference,
  LandlordReference,
  LegalDetails,
  PaymentHistory,
  PersonalReference,
  TabStatus,
  Vehicle,
  WorkHistory,
  customerDetails,
} from '../../../constants/customerDetailsInterface';
import { Agreement } from '../../../constants/customerDetailsInterface';
import {
  customerFormData,
  paymentOptionTableBodies,
  taxExamptDetails,
  taxExamptEditIndexDetails,
} from '../../../constants/CustomerConstants';
import {
  CustomerData,
  dropdown,
} from '../../../constants/customerSearchInterface';

export const CustomerContext = createContext<any>('');

export function CustomerContextProvider(props: any) {
  const [render, setRender] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const classes = customerDetailsStyle();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessagePopup, setErrorMessagePopup] = useState<boolean>(false);

  const [paymentOffset, setPaymentOffet] = useState<number>(10);
  const [paymentHasMore, setPaymentHasMore] = useState<boolean>(true);
  const [paymentFetchDataLoader, setPaymentFetchDataLoader] =
    useState<boolean>(false);
  const [workHistoryOffset, setWorkHistoryOffet] = useState<number>(10);
  const [workHistoryHasMore, setWorkHistoryHasMore] = useState<boolean>(true);
  const [workHistoryFetchDataLoader, setWorkHistoryFetchDataLoader] =
    useState<boolean>(false);
  const [customerRecords, setCustomerRecords] = useState(false);
  const [showFilter, setFilter] = useState(true);

  const [customerDetails, setCustomerDetails] = useState<customerDetails>({
    status: 200,
    name: '-',
    coCustomerName: '-',
    coCustomerId: 0,
    creationDate: '-',
    contact: {
      primaryAddress: '-',
      mailingAddress: '-',
      phone: '-',
      emailAddress: '-',
      category: '',
    },
    personal: {
      dateOfBirth: '-',
      socialSecurity: '-',
      govIdType: '-',
      govId: '-',
      issuedState: '-',
    },
    preferences: {
      preferredLanguage: '-',
      paymentRemainderCalls: '-',
      preferredMethodOfContact: '-',
      phoneSolicitation: '-',
      mailSolicitation: '-',
    },
  });

  //taxExampt
  const [taxExamptTableBody, setTaxExamptTableBody] = useState<any>([]);
  const [taxExamptData, setTaxExamptData] = useState<any>(taxExamptDetails);
  const [taxExamptionEditId, setTaxExamptEditId] = useState<number>(-1);
  const [taxExamptEditDetails, setTaxExamptEditDetails] = useState<any>(
    taxExamptEditIndexDetails
  );

  // legal
  const [legalTableBody, setLegalTableBody] = useState<LegalDetails[]>([
    {
      effectiveDate: '-',
      holdReason: '-',
      bankruptacyChapter: '-',
      closedDate: '-',
      closedReason: '-',
      createdBy: '-',
      createdDate: '-',
      closedBy: '-',
      lastModifiedDate: '-',
    },
  ]);

  // paymentHis
  const [paymentHistoryTableBody, setPaymentHistoryTableBody] = useState<
    PaymentHistory[]
  >([
    {
      receiptId: '-',
      store: '-',
      paymentOrigin: '-',
      tenderType: '-',
      daysExt: '-',
      daysLate: '-',
      datePaid: '-',
      nextDueDate: '-',
      actualTerm: '-',
      rentPaid: '-',
      closeDate: '-',
      agreementStatusReason: '-',
      agreementId: '-',
      oldDueDate: '-',
      netRent: '-',
      clubAmount: '-',
      ldw: '-',
      lateFee: '-',
      discount: '-',
      other: '-',
      tax: '-',
      totalPayment: '-',
      nsf: '-',
      cc4Digit: '-',
    },
  ]);
  const [paymentHistoryModel, setPaymentHistoryModel] =
    useState<boolean>(false);

  //paymentOpt
  const [paymentOptionTableBody, setPaymentOptionTableBody] = useState<any>(
    paymentOptionTableBodies
  );

  // workHistory
  const [workHistoryTableBody, setWorkHistoryTableBody] = useState<
    WorkHistory[]
  >([]);
  const [workHistoryModel, setWorkHistoryModel] = useState<boolean>(false);

  // vehicle
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [vehicleTableBody, setVehicleTableBody] = useState<Vehicle[]>([
    {
      utilities: '-',
      vehicleMake: '-',
      vehicleModal: '-',
      vehicleColor: '-',
      vehicleTagNumber: '-',
      vehRegState: '-',
      phoneInWhose: '-',
      vehFinancedFrom: '-',
      autoPayment: '-',
    },
  ]);

  const [customerDetials, setCustomerData] = useState<CustomerData[]>([]);

  const [filterOptions, setFilterOptions] = useState(customerFormData);

  // agr
  const [activeAgreementsBody, setActiveAgreements] = useState<any>([
    {
      agreementId: '-',
      isNoteAgreement: '-',
      agreementRefCode: '-',
      agreementNumber: '-',
      agreementDescription: '-',
      agreementStatus: '-',
      agreementSubstatus: '-',
      agreementType: '-',
      agreementTypeRefCode: '-',
      storeNumber: '-',
      switchout: '0',
      isDeliveryConfirmed: '-',
      deliveredDate: '-',
      isTransferInitiated: '0',
      storeCity: '-',
      stateProvinceAbbreviation: '-',
      totalCost: '4126.71',
      remainingPaymentAmount: '246.78',
      paymentSchedule: '-',
      nextDueDate: null,
      numberOfPayments: 122,
      autoPay: true,
      pastDueRent: '-',
      rentPaid: '-',
      amountDue: '-',
      currentDueDate: '-',
      lateLast10: 0,
      reInstated: '0',
      customers: [
        {
          customerId: '5859068',
          firstName: 'John',
          lastName: 'Sims',
          phoneNumber: '2542739338',
          emailId: 'johnbaxtersims@xxxxx.com',
          isClubApplied: '1',
        },
        {
          customerId: '5859068',
          firstName: 'John',
          lastName: 'Sims',
          phoneNumber: '4438355027',
          emailId: 'johnbaxtersims@xxxxx.com',
          isClubApplied: '1',
        },
      ],
      suspenseAmount: '0.00',
      isLDWActive: '1',
      agreementOpenDate: '-',
      customerPaymentMethodId: '4042678',
    },
  ]);
  const [inActiveAgreementsBody, setInactiveAgreements] = useState<Agreement[]>(
    [
      {
        agreementNumber: '-',
        storeNumber: '-',
        route: '-',
        customers: [],
        agreementDescription: '-',
        agreementOpenDate: '-',
        currentDueDate: '-',
        currentDayslate: '-',
        actualRate: '-',
        actualTerm: '-',
        rentPaid: '-',
        closeDate: '-',
        agreementStatusReason: '-',
        agreementId: '-',
        autoPay: false,
      },
    ]
  );

  const [phoneModal, setPhoneModalPopup] = useState<boolean>(false);
  const [phonePopupDetailsBody, setPhonePopupDetailsBody] = useState<any>([
    {
      phoneId: '-',
      phoneNumber: '-',
      phoneType: '-',
      phoneTypeDesc: '-',
      extension: null,
      primary: '-',
      callTimeType: '-',
      callTimeTypeDesc: '-',
      active: '-',
      note: null,
    },
  ]);
  const [unHoldPopup, setUnHoldPopup] = useState<boolean>(false);

  // reference & residence
  const [referenceTableBody, setReferenceTableBody] = useState<
    PersonalReference[]
  >([
    {
      active: '-',
      relationshipTypeDesc: '-',
      firstName: '-',
      lastName: '-',
      doNotCall: '-',
      phoneNumber: '-',
      bestTimeToCallDesc: '-',
    },
  ]);
  const [residenceTableBody, setResidenceTableBody] = useState<
    LandlordReference[]
  >([
    {
      leaseCompanyName: '-',
      monthlyRent: '-',
      landlordFirstName: '-',
      landlordLastName: '-',
      phoneNumber: '-',
      bestTimeToCallDesc: '-',
      addressLine1: '-',
      addressLine2: '-',
      active: '-',
      leaseHolderName: '-',
      lengthOfLease: '-',
      lengthOfResidency: '-',
    },
  ]);

  const [employmentDetailsModal, setShowEmployementDetails] = useState(false);
  //emp
  const [employmentDetails, setEmployementDetails] = useState<
    EmployerReference[]
  >([]);
  const [residenceInfoModal, setShowResidencPopup] = useState(false);
  const [viewDetailsIndex, setViewDetailsIndex] = useState<number>(0);

  const [responseStatus, setResponseStatus] = useState<TabStatus>({
    activeAgreementTabStatus: 200,
    referenceTabStatus: 200,
    residenceTabStatus: 200,
    vehicleTabStatus: 200,
    employmentTabStatus: 200,
    taxExemptTabStatus: 200,
    inactiveAgreementTabStatus: 200,
    paymentHistoryTabStatus: 200,
    legalHoldTabStatus: 200,
    workHistoryTabStatus: 200,
  });

  const [toggleObject, setToggleObj] = useState<any>({
    agreementRenter: false,
    referenceStatus: false,
    employmentStatus: false,
    residenceStatus: false,
    paymentHistoryDate: false,
    workHistoryDate: false,
  });

  const [showLegalDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const [taxExamptStatus, setTaxExamptStatus] = useState<string>('');
  const [user, setUser] = useState<any>([]);

  const [secondaryLoader, setSecondaryLoader] = useState<boolean>(false);

  const [legalHoldStatusReason, setlegalHoldStatusReason] = useState<
    dropdown[]
  >([
    {
      label: 'Select',
      value: '0',
    },
  ]);
  const [legalUnHoldStatusReason, setlegalUnHoldStatusReason] = useState<
    dropdown[]
  >([
    {
      label: 'Select',
      value: '0',
    },
  ]);

  const [taxExemptDenialDropDown, setTaxExemptDenialDropDown] = useState<
    dropdown[]
  >([
    {
      label: 'Select',
      value: '0',
    },
  ]);

  const [legalCustomerStatus, setLegalCustomerStatus] = useState<string>('');
  const [pageLoader, setPageLoader] = useState<boolean>(false);

  return (
    <CustomerContext.Provider
      value={{
        loader,
        setLoader,
        render,
        setRender,
        classes,
        errorMessage,
        setErrorMessage,
        errorMessagePopup,
        setErrorMessagePopup,
        paymentOffset,
        setPaymentOffet,
        viewDetailsIndex,
        setViewDetailsIndex,
        responseStatus,
        setResponseStatus,
        residenceInfoModal,
        setShowResidencPopup,
        employmentDetails,
        setEmployementDetails,
        employmentDetailsModal,
        setShowEmployementDetails,
        residenceTableBody,
        setResidenceTableBody,
        referenceTableBody,
        setReferenceTableBody,
        unHoldPopup,
        setUnHoldPopup,
        phonePopupDetailsBody,
        setPhonePopupDetailsBody,
        phoneModal,
        setPhoneModalPopup,
        inActiveAgreementsBody,
        setInactiveAgreements,
        activeAgreementsBody,
        setActiveAgreements,
        showVehicleModal,
        setShowVehicleModal,
        vehicleTableBody,
        setVehicleTableBody,
        workHistoryModel,
        setWorkHistoryModel,
        workHistoryTableBody,
        setWorkHistoryTableBody,
        paymentOptionTableBody,
        setPaymentOptionTableBody,
        paymentHasMore,
        setPaymentHasMore,
        paymentFetchDataLoader,
        setPaymentFetchDataLoader,
        paymentHistoryModel,
        setPaymentHistoryModel,
        paymentHistoryTableBody,
        setPaymentHistoryTableBody,
        workHistoryOffset,
        setWorkHistoryOffet,
        workHistoryHasMore,
        setWorkHistoryHasMore,
        workHistoryFetchDataLoader,
        setWorkHistoryFetchDataLoader,
        customerDetails,
        setCustomerDetails,
        legalTableBody,
        setLegalTableBody,
        taxExamptTableBody,
        setTaxExamptTableBody,
        taxExamptData,
        setTaxExamptData,
        taxExamptionEditId,
        setTaxExamptEditId,
        taxExamptEditDetails,
        setTaxExamptEditDetails,
        showLegalDetailsModal,
        setShowDetailsModal,
        toggleObject,
        setToggleObj,
        taxExamptStatus,
        setTaxExamptStatus,
        user,
        setUser,
        customerRecords,
        setCustomerRecords,
        showFilter,
        setFilter,
        customerDetials,
        setCustomerData,
        filterOptions,
        setFilterOptions,
        secondaryLoader,
        setSecondaryLoader,
        legalHoldStatusReason,
        setlegalHoldStatusReason,
        legalUnHoldStatusReason,
        setlegalUnHoldStatusReason,
        taxExemptDenialDropDown,
        setTaxExemptDenialDropDown,
        legalCustomerStatus,
        setLegalCustomerStatus,
        pageLoader,
        setPageLoader,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
}
