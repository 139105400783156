import {
  Grid,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  Typography,
  RACTableRow,
} from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { ReactComponent as SortDesc } from '../../Icons/SortDown.svg';
import { ReactComponent as SortAsc } from '../../Icons/SortUp.svg';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import { formatDate, sortNumber } from '../../../../utils/InventoryUtils';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

export function PromoAgrGrid() {
  const { classes, promoAgreements, setPromoAgreements } = useContext(
    InventoryDetailsContext
  );
  const [sort, setSort] = useState<boolean>(true);
  const history = useHistory();
  const agreementHistoryheadings = [
    'Promo Agreement #',
    'Customer',
    'Open Date',
    'Close Date',
    'Status',
  ];

  const agrStatusIndicator = (val: any) => {
    if (val === 'Active') {
      return `${classes.ahActive} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Inactive') {
      return `${classes.ahInactive} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Pastdue') {
      return `${classes.ahDue} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else if (val === 'Pending') {
      return `${classes.ahPending} ${classes.racpadLink} ${classes.racpadAgrLink}`;
    } else {
      return `${classes.racpadLink} ${classes.racpadAgrLink}`;
    }
  };
  const renderAgreementHeadings = () => (
    <>
      {agreementHistoryheadings.map((obj: any, index: number) => {
        if (obj == 'Promo Agreement #') {
          return (
            <RACTableCell key={index}>
              {obj}{' '}
              {promoAgreements?.length <= 1 ? (
                <></>
              ) : sort ? (
                <SortDesc
                  onClick={() => {
                    sortNumber(
                      'asc',
                      'agreementNumber',
                      promoAgreements,
                      setPromoAgreements
                    );
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              ) : (
                <SortAsc
                  onClick={() => {
                    sortNumber(
                      'desc',
                      'agreementNumber',
                      promoAgreements,
                      setPromoAgreements
                    );
                    setSort(!sort);
                  }}
                  className={`${classes.cursorStyle}`}
                />
              )}
            </RACTableCell>
          );
        }
        return <RACTableCell key={index}>{obj}</RACTableCell>;
      })}
    </>
  );

  const renderAgreementHistory = () => (
    <>
      {promoAgreements.map((obj: any, index: any) => {
        return (
          <RACTableRow className={`${classes.whitebackground}`} key={index}>
            <RACTableCell key={index}>
              {obj.promoAgreementNumber ? (
                <a
                  className={agrStatusIndicator(obj.status)}
                  onClick={() => {
                    history.push({
                      pathname: `/operations/agreement/details/0000/${obj?.agreementId}`,
                      search: `?agrType=pro`,
                    });
                  }}
                >
                  {obj.promoAgreementNumber}
                </a>
              ) : (
                '-'
              )}
            </RACTableCell>

            <RACTableCell key={index * 1 + 1}>
              <Typography className={`${classes.formValue}`}>
                {obj.customer ? obj.customer : '-'}
              </Typography>
            </RACTableCell>

            <RACTableCell key={index * 1 + 2}>
              <Typography className={`${classes.formValue}`}>
                {obj.openDate ? formatDate(obj.openDate) : '-'}
              </Typography>
            </RACTableCell>

            <RACTableCell key={index * 1 + 2}>
              <Typography className={`${classes.formValue}`}>
                {obj.closeDate ? formatDate(obj.closeDate) : '-'}
              </Typography>
            </RACTableCell>

            <RACTableCell key={index}>
              <Typography className={`${classes.formValue}`}>
                {obj.status ? obj.status : '-'}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  return (
    <Grid style={{ marginBottom: '10px' }}>
      <Grid container md={12} style={{ marginBottom: '6px' }}>
        <Grid item md={8}>
          <Typography variant="h5">Promo Agreements</Typography>
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item md={12} style={{ maxHeight: '20%' }}>
          <Card className={`${classes.informationCard}`}>
            <CardContent>
              <InfiniteScroll
                next={() => {
                  return null;
                }}
                dataLength={promoAgreements?.length}
                hasMore={false}
                height={promoAgreements?.length > 3 ? 150 : ''}
                loader={''}
              >
                <RACTable
                  renderTableHead={renderAgreementHeadings}
                  renderTableContent={renderAgreementHistory}
                  stickyHeader
                  className={classes.fixTableHeadAgrTrsRec}
                />
              </InfiniteScroll>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
