import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

// import { customerDetailsStyle } from './../customerStyles';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import React, { useContext } from 'react';
import { Loader } from '../CommonCompo/Loader';
import { CustomerContext } from '../CustomerDependencies/CustomerContext';

export default function PaymentOptionTab(props: any) {
  // const classes: any = customerDetailsStyle();
  const { classes } = useContext(CustomerContext);

  const paymentOptionTableHeading: tableHeadings[] = [
    { heading: 'Remote Payment' },
    { heading: 'ACH' },
    { heading: 'Checks' },
    { heading: 'Enrolled in AutoPay' },
  ];

  const paymentOptionTableBody = props.value.tableBodyOne;

  const activeAgreementsBody = props.value.activeAgreementsBody;
  const inActiveAgreementsBody = props.value.inActiveAgreementsBody;

  function getEligibleForEpay() {
    const allAgreements = [...activeAgreementsBody, ...inActiveAgreementsBody];
    const foundAutoPay = allAgreements.some(
      (agreement: any) => agreement.autoPay === true
    );
    if (foundAutoPay) {
      return 'Yes';
    }
    return 'No';
  }

  const paymentOptionTabHead = () => (
    <>
      {paymentOptionTableHeading?.map((heading: any, index: any) => {
        return (
          <RACTableCell
            className={`${classes.bold} ${classes.width25}`}
            key={index}
          >
            {heading.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  //payment option table body part
  const paymentOptionTabContent = () => (
    <>
      <RACTableRow style={{ backgroundColor: 'white' }}>
        <RACTableCell
          className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
        >
          {paymentOptionTableBody?.remotePaymentAllowed == 'Y' ? 'Yes' : 'No'}
        </RACTableCell>
        <RACTableCell
          className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
        >
          {paymentOptionTableBody?.achAllowed == 'Y' ? 'Yes' : 'No'}
        </RACTableCell>
        {/* <RACTableCell
          className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
        >
          {paymentOptionTableBody?.eligibleForPay
            ? paymentOptionTableBody.eligibleForPay
            : '-'}
        </RACTableCell> */}
        <RACTableCell
          className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
        >
          {paymentOptionTableBody?.acceptCheck == 'Y' ? 'Yes' : 'No'}
        </RACTableCell>
        <RACTableCell
          className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
        >
          {getEligibleForEpay()}
        </RACTableCell>
      </RACTableRow>
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          {props.value.cardLoader ? (
            <Grid className={classes.cardLoader}>
              <Loader />
            </Grid>
          ) : (
            <Grid>
              {props.value.status == 200 ? (
                <RACTable
                  renderTableHead={paymentOptionTabHead}
                  renderTableContent={paymentOptionTabContent}
                  stickyHeader
                  className={classes.fixTableHeadAgrTrsRec}
                />
              ) : props.value.status == 204 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <NoRecordsFound />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    No Records Found
                  </Typography>
                </Grid>
              ) : props.value.status == 400 ? (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Bad Request
                  </Typography>
                </Grid>
              ) : (
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <AlertIcon />
                  <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                    Something Went Wrong
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
