/* eslint-disable */
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  RACButton,
  RACSelect,
  RACTooltip,
  Typography,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AppConfigurationContext } from "../../../context/AppConfigurations/AppConfigurationContext";
import { AppConfigurationStyles } from "../../../JsStyles/AppConfigurationStyles";
import { ReactComponent as ExcelIcon } from "../../../assets/images/excelIcon.svg";
import { ReactComponent as InfoIcon } from "../../../Icons/info-tooltip.svg";
import {
  DropdownValue,
  OrganizationFilter,
} from "../../../Interfaces/Inventory/AppConfigInterface";
import {
  fetchConfigReference,
  fetchOrganizationDetails,
  getMenuConfigurationDetails,
  getOrgRulesSummary,
  getUserID,
} from "../../../api/user";
import {
  exportToExcel,
  formDropdownValues,
  getUniqueDataByProperty,
  sortByProperty,
} from "../../../utils/AppconfigurationUtils";

import {
  DEFAULT_RULE_LIMIT,
  DEFAULT_RULE_OFFSET,
} from "../../../constants/constants";
import ErrorPopup from "../../Inventory/GlobalComponents/ErrorPopup";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from "../../..";

export function FilterWidget() {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = AppConfigurationStyles();
  const {
    store,
    setStore,
    company,
    setCompany,
    state,
    setState,
    rule,
    setRule,
    category,
    setCategory,
    group,
    setGroup,
    request,
    coreUser,
    franchiseCompany,
    rulesDropdownLoader,
    setRulesDropdownLoader,
    resultWidget,
    setResultWidget,
    setOrganizationRules,
    setRequest,
    excelLoader,
    setExcelLoader,
    setRuleConstraints,
    setViewMode,
    setCoreUser,
    setDistrictDropdown,
    setRegionDropdown,
    setCountryDropdown,
    setLobDropdown,
    setAssignButtonLoader,
    franchiseeUser,
    setFranchiseeUser
  } = useContext(AppConfigurationContext);

  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  const [categoryDropdown, setCategoryDropdown] = useState<DropdownValue[]>([]);
  const [groupDropdown, setGroupDropdown] = useState<DropdownValue[]>([]);
  const [ruleDropdown, setRuleDropdown] = useState<DropdownValue[]>([]);
  const [OrgdropdownLoader, setOrgDropdownLoader] = useState<boolean>(true);
  const [categorySelected, setCategorySelected] = useState<DropdownValue[]>([]);
  const [groupSelected, setGroupSelected] = useState<DropdownValue[]>([]);
  const [ruleSelected, setRuleSelected] = useState<DropdownValue[]>([]);
  const [companyDropdown, setCompanyDropdown] = useState<DropdownValue[]>([
    { label: "Select", value: "" },
  ]);
  const [stateDropdown, setStateDropdown] = useState<DropdownValue[]>([
    { label: "Select", value: "" },
  ]);
  const [storeDropdown, setStoreDropdown] = useState<DropdownValue[]>([
    { label: "Select", value: "" },
  ]);

  const defaultFilterState: OrganizationFilter = {
    companyCode: "",
    stateCode: "",
    storeNumber: "",
  };
  const [filterSelected, setFilterSelected] =
    useState<OrganizationFilter>(defaultFilterState);

  const validSearch: boolean =
    filterSelected.companyCode != "" ||
    filterSelected.stateCode != "" ||
    filterSelected.storeNumber != "";

  const filterApplied: boolean = !(
    validSearch ||
    categorySelected.length ||
    groupSelected.length ||
    ruleSelected.length
  );
  const [enableExcel, setEnableExcel] = useState<boolean>(
    !filterApplied && !excelLoader
  );
  /**
   * Storing the user selected company and handling the dependent dropdowns(state and store)
   * Company based states and stores dropdowns are binded
   * If the state and stores are already selected checking their presence in current dropdown else deselected
   */
  const handleCompanyDropdown = (e: any) => {
    try {
      if (filterSelected.companyCode != e.target.value) {
        const filters: OrganizationFilter = {
          companyCode: e.target.value,
          stateCode: "",
          storeNumber: "",
        };

        if (e.target.value == "") {
          buildDefaultCompanyDropdown();
          buildDefaultStateDropdown();
          buildDefaultStoreDropdown();
        } else {
          const currentStates: any = [];
          const storeFormed: DropdownValue[] = [{ label: "Select", value: "" }];
          const stateFormed: DropdownValue[] = [{ label: "Select", value: "" }];

          //Handling store dropdown
          store.forEach((storeDetails: any) => {
            if (storeDetails.companyCode == e.target.value) {
              storeFormed.push({
                label: storeDetails.referenceName,
                value: storeDetails.referenceName,
              });
              currentStates.push(storeDetails.stateCode);
              if (filterSelected.storeNumber == storeDetails.referenceName) {
                filters.storeNumber = storeDetails.referenceName;
              }
            }
          });
          setStoreDropdown(storeFormed);

          //Handling state dropdown
          state.forEach((stateDetails: any) => {
            if (currentStates.includes(stateDetails.referenceValue)) {
              stateFormed.push({
                label: stateDetails.referenceValue,
                value: stateDetails.referenceValue,
              });
              if (filterSelected.stateCode == stateDetails.referenceValue) {
                filters.stateCode = stateDetails.referenceValue;
              }
            }
          });
          setStateDropdown(stateFormed);
        }

        setFilterSelected(filters);
      }
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  /**
   * Storing the state sleceted and handling the store dropdown
   * Stores present in the state are binded in the dropdown
   * If store are already selected checking its presence in current dropdown else deselected
   * Reason for not populating company is different Companies can present in a particular state
   */
  const handleStateDropdown = (e: any) => {
    try {
      if (filterSelected.stateCode != e.target.value) {
        const filters: OrganizationFilter = {
          companyCode: "",
          stateCode: e.target.value,
          storeNumber: "",
        };
        const storeFormed: DropdownValue[] = [{ label: "Select", value: "" }];

        //Handling Store Dropdown
        if (e.target.value == "" && filterSelected.companyCode == "") {
          buildDefaultStoreDropdown();
          buildDefaultCompanyDropdown();
        } else {
          const companyAvailable: string[] = [];
          store?.forEach((storeDetails: any) => {
            const dropdownValue: DropdownValue = {
              label: storeDetails.referenceName,
              value: storeDetails.referenceName,
            };

            /**
             * To populate the companies available under the selected state
             */
            if (
              (storeDetails.stateCode == e.target.value||e.target.value=='') &&
              !companyAvailable.includes(storeDetails.companyCode)
            ) {
              companyAvailable.push(storeDetails.companyCode);
            }

            if (
              (storeDetails.stateCode == e.target.value||e.target.value=='') &&
              (filterSelected.companyCode == "" ||
                (filterSelected.companyCode != "" &&
                  storeDetails.companyCode == filterSelected.companyCode))
            ) {
              storeFormed.push(dropdownValue);
              if (filterSelected.storeNumber == dropdownValue.value) {
                filters.storeNumber = dropdownValue.value;
              }
            }
          });

          const companies: DropdownValue[] = [{ label: "Select", value: "" }];
          //Handling Company Dropdown
          if (companyAvailable.length) {
            company?.forEach((companyDetails: any) => {
              if (companyAvailable.includes(companyDetails.referenceValue)) {
                companies.push({
                  label: companyDetails.referenceName,
                  value: companyDetails.referenceValue,
                });

                if (
                  companyDetails.referenceValue == filterSelected.companyCode
                ) {
                  filters.companyCode = companyDetails.referenceValue;
                }
              }
            });
          }
          setCompanyDropdown(companies);
          setStoreDropdown(storeFormed);
        }
        setFilterSelected(filters);
      }
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  /**
   * Storing the store selected
   * Populating the state and company on which the store exists
   */
  const handleStoreDropdown = (e: any) => {
    try {
      const options = { ...filterSelected };
      options.storeNumber = e.target.value;
      if (e.target.value != "") {
        const chosenStore = store.find(
          (storeDetails: any) => storeDetails.referenceName == e.target.value
        );

        options.companyCode = chosenStore.companyCode
          ? chosenStore.companyCode
          : "";
        options.stateCode = chosenStore.stateCode ? chosenStore.stateCode : "";
      }

      setFilterSelected(options);
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  /**
   * Storing the Categories selected
   * Binding the group and rule dropdown based on the category selected
   * If values in group and rule already selected then their presence is checked else deselected
   */
  const handleCategoryDropdown = (e: DropdownValue[]) => {
    try {
      setCategorySelected(e);
      if (!e.length) {
        buildDefaultParamGroup();
        buildDefaultParamKey();
      } else {
        const chosenCategory: string[] = [];
        const chosenCategoryId: string[] = [];
        const chosenGroup: string[] = [];
        let currentGroupDropdown: DropdownValue[] = [];
        let ruleDropdown: DropdownValue[] = [];
        let prevSelectedGroup: DropdownValue[] = [];
        let prevSelectedRule: DropdownValue[] = [];

        //Category Dropdown binding flow
        e.forEach((optedCategory: DropdownValue) => {
          chosenCategory.push(optedCategory.value);
        });

        category?.forEach((categories: any) => {
          if (chosenCategory.includes(categories.displayName)) {
            chosenCategoryId.push(categories.paramCategoryId);
          }
        });

        //Group Dropdown binding flow
        group.forEach((groupDetails: any) => {
          if (chosenCategoryId.includes(groupDetails.paramCategoryId)) {
            const dropdownValue: DropdownValue = {
              label: groupDetails.displayName,
              value: groupDetails.displayName,
            };

            chosenGroup.push(groupDetails.paramGroupId);
            currentGroupDropdown.push(dropdownValue);

            groupSelected.forEach((groupOpted: any) => {
              if (groupOpted.value == groupDetails.displayName) {
                prevSelectedGroup.push(dropdownValue);
              }
            });
          }
        });
        currentGroupDropdown = getUniqueDataByProperty(
          currentGroupDropdown,
          "label"
        );
        prevSelectedGroup = getUniqueDataByProperty(prevSelectedGroup, "label");
        currentGroupDropdown = sortByProperty(currentGroupDropdown, "label");
        setGroupSelected(prevSelectedGroup);
        setGroupDropdown(currentGroupDropdown);

        //Rule Dropdown binding flow
        rule.forEach((ruleDetails: any) => {
          if (chosenGroup.includes(ruleDetails.paramGroupId)) {
            const dropdownValue: DropdownValue = {
              label: ruleDetails.displayName,
              value: ruleDetails.displayName,
            };
            ruleDropdown.push(dropdownValue);

            ruleSelected.forEach((ruleData: any) => {
              if (ruleData.value == ruleDetails.displayName) {
                prevSelectedRule.push(dropdownValue);
              }
            });
          }
        });

        ruleDropdown = getUniqueDataByProperty(ruleDropdown, "label");
        prevSelectedRule = getUniqueDataByProperty(prevSelectedRule, "label");
        ruleDropdown = sortByProperty(ruleDropdown, "label");

        setRuleSelected(prevSelectedRule);
        setRuleDropdown(ruleDropdown);
      }
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const handleGroupDropdown = (e: DropdownValue[]) => {
    try {
      setGroupSelected(e);

      if (!e.length && categorySelected.length) {
        const chosenGroupId: string[] = [];
        const chosenCategoryId: string[] = [];
        let currentGroupDropdown: any[] = [];
        let currentRuleDropdown: DropdownValue[] = [];

        category?.forEach((categoryDetails: any) => {
          categorySelected.forEach((selectedCategory: any) => {
            if (selectedCategory.label == categoryDetails.displayName) {
              chosenCategoryId.push(categoryDetails.paramCategoryId);
            }
          });
        });

        group?.forEach((groupDetails: any) => {
          if (chosenCategoryId.includes(groupDetails.paramCategoryId)) {
            currentGroupDropdown.push({
              displayName: groupDetails.displayName,
            });
            chosenGroupId.push(groupDetails.paramGroupId);
          }
        });

        rule.forEach((ruleData: any) => {
          if (chosenGroupId.includes(ruleData.paramGroupId)) {
            currentRuleDropdown.push({
              label: ruleData.displayName,
              value: ruleData.displayName,
            });
          }
        });
        let groupDropdownFormed: DropdownValue[] = formDropdownValues(
          currentGroupDropdown,
          "displayName",
          "displayName",
          "multi"
        );

        groupDropdownFormed = getUniqueDataByProperty(
          groupDropdownFormed,
          "label"
        );
        currentRuleDropdown = getUniqueDataByProperty(
          currentRuleDropdown,
          "label"
        );
        groupDropdownFormed = sortByProperty(groupDropdownFormed, "label");
        currentRuleDropdown = sortByProperty(currentRuleDropdown, "label");

        setGroupDropdown(groupDropdownFormed);
        setRuleDropdown(currentRuleDropdown);
        setRuleSelected([]);
      } else {
        const chosenCategory: string[] = [];
        let ruleDropdown: DropdownValue[] = [];
        let prevSelectedCategory: DropdownValue[] = [];
        const chosenGroup: string[] = [];
        let prevSelectedRule: DropdownValue[] = [];
        const groupSelectionDetails: any = {};

        group.forEach((groupDetails: any) => {
          e.forEach((groupOpted: DropdownValue) => {
            if (groupDetails.displayName == groupOpted.value) {
              chosenGroup.push(groupDetails.paramGroupId);

              const iteratedGroup: any[] =
                groupSelectionDetails[groupDetails.displayName] || [];
              const groupsCategory = category?.filter(
                (categoryInfo: any) =>
                  groupDetails.paramCategoryId == categoryInfo.paramCategoryId
              );
              iteratedGroup.push(groupsCategory[0]?.displayName);
              chosenCategory.push(groupDetails.paramCategoryId);
              groupSelectionDetails[groupDetails.displayName] = iteratedGroup;
            }
          });
        });

        category.forEach((categoryDetails: any) => {
          if (chosenCategory.includes(categoryDetails.paramCategoryId)) {
            if (!categorySelected.length) {
              prevSelectedCategory.push(categoryDetails.displayName);
            } else {
              categorySelected?.forEach((selected: DropdownValue) => {
                if (selected.label == categoryDetails.displayName) {
                  prevSelectedCategory.push(categoryDetails.displayName);
                }
              });
            }
          }
        });

        Object.keys(groupSelectionDetails).forEach((key: any) => {
          const hasMatch = groupSelectionDetails[key].some((item: any) =>
            prevSelectedCategory.includes(item)
          );
          if (!hasMatch) {
            prevSelectedCategory.push(...groupSelectionDetails[key]);
          }
        });

        let prevCategoryFormed: DropdownValue[] = [];
        prevSelectedCategory?.forEach((prefilledCategories: any) => {
          prevCategoryFormed.push({
            label: prefilledCategories,
            value: prefilledCategories,
          });
        });
        prevCategoryFormed = getUniqueDataByProperty(
          prevCategoryFormed,
          "label"
        );
        setCategorySelected(prevCategoryFormed);

        //Handling Rule Dropdown
        rule.forEach((ruleDetails: any) => {
          if (chosenGroup.includes(ruleDetails.paramGroupId)) {
            const dropdownValue = {
              label: ruleDetails.displayName,
              value: ruleDetails.displayName,
            };
            const groupIterated= group?.filter((item:any)=>item.paramGroupId==ruleDetails.paramGroupId);
            const categoryIterated= category?.filter((item:any)=>item.paramCategoryId==groupIterated[0].paramCategoryId);

            if(!categorySelected.length||(categorySelected.length && (categorySelected.filter((item:any)=>categoryIterated[0].displayName==(item.label))).length)){
              ruleDropdown.push(dropdownValue);
            }
            

            ruleSelected.forEach((ruleData: any) => {
              if (ruleData.value == ruleDetails.displayName) {
                prevSelectedRule.push(dropdownValue);
              }
            });
          }
        });
        prevSelectedRule = getUniqueDataByProperty(prevSelectedRule, "label");
        setRuleSelected(prevSelectedRule);

        ruleDropdown = getUniqueDataByProperty(ruleDropdown, "label");
        setRuleDropdown(ruleDropdown);

        //Handling Category Dropdown
      }
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const handleRuleDropdown = (e: any) => {
    try {
      setRuleSelected(e);
      if (e.length) {
        const prevSelectedGroup: string[] = [];
        const prevSelectedCategory: string[] = [];

        const groupHash: any = {};
        const categoryHash: any = {};

        rule.forEach((ruleData: any) => {
          const categoryId: string[] = [];
          e.forEach((ruleOpted: DropdownValue) => {
            if (ruleData.displayName == ruleOpted.value) {
              let iteratedGroup = groupHash[ruleData.displayName] || [];

              group.forEach((groupData: any) => {
                if (groupData.paramGroupId == ruleData.paramGroupId) {
                  if (!categoryId.includes(groupData.paramCategoryId)) {
                    categoryId.push(groupData.paramCategoryId);
                  }

                  iteratedGroup.push(groupData.displayName);
                  groupSelected?.forEach((selected: DropdownValue) => {
                    if (
                      selected.value == groupData.displayName &&
                      !prevSelectedGroup.includes(selected.value)
                    ) {
                      prevSelectedGroup.push(selected.value);
                    }
                  });

                  groupHash[ruleData.displayName] = iteratedGroup;
                }
              });

              category.forEach((categoryData: any) => {
                if (categoryId.includes(categoryData.paramCategoryId)) {
                  const iteratedCategory =
                    categoryHash[ruleData.displayName] || [];
                  if (!iteratedCategory.includes(categoryData.displayName)) {
                    iteratedCategory.push(categoryData.displayName);
                    categoryHash[ruleData.displayName] = iteratedCategory;
                  }

                  categorySelected?.forEach((selected: DropdownValue) => {
                    if (
                      selected.value == categoryData.displayName &&
                      !prevSelectedCategory.includes(categoryData.displayName)
                    ) {
                      prevSelectedCategory.push(categoryData.displayName);
                    }
                  });
                }
              });
            }
          });
        });

        Object.keys(groupHash).forEach((key: any) => {
          const hasMatch = groupHash[key].some((item: any) =>
            prevSelectedGroup.includes(item)
          );
          if (!hasMatch) {
            prevSelectedGroup.push(...groupHash[key]);
          }
        });

        Object.keys(categoryHash).forEach((key: any) => {
          const hasMatch = categoryHash[key].some((item: any) =>
            prevSelectedCategory.includes(item)
          );
          if (!hasMatch) {
            prevSelectedCategory.push(...categoryHash[key]);
          }
        });

        const prevGroup: DropdownValue[] = prevSelectedGroup.map(
          (item: string) => ({ label: item, value: item })
        );
        setGroupSelected(prevGroup);

        const prevCategory: DropdownValue[] = prevSelectedCategory.map(
          (item: string) => ({ label: item, value: item })
        );
        setCategorySelected(prevCategory);
      }
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  //Dropdown binding functions
  const buildDefaultCompanyDropdown = (companyOnload?: any) => {
    try {
      const companyData: any[] = companyOnload ? companyOnload : company;
      const companyFormed: DropdownValue[] = formDropdownValues(
        companyData,
        "referenceName",
        "referenceValue",
        "single"
      );
      setCompanyDropdown(companyFormed);
      setFilterSelected({ ...filterSelected, companyCode: "" });
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const buildDefaultStoreDropdown = (storesOnload?: any) => {
    try {
      const storesData: any[] = storesOnload ? storesOnload : store;
      const storeFormed: DropdownValue[] = formDropdownValues(
        storesData,
        "referenceName",
        "referenceName",
        "single"
      );
      setStoreDropdown(storeFormed);
      setFilterSelected({ ...filterSelected, storeNumber: "" });
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const buildDefaultStateDropdown = (stateOnload?: any) => {
    try {
      let stateData: any[] = stateOnload ? stateOnload : state;

      let stateFormed: DropdownValue[] = formDropdownValues(
        stateData,
        "referenceValue",
        "referenceValue",
        "single"
      );
      setStateDropdown(stateFormed);
      setFilterSelected({ ...filterSelected, stateCode: "" });
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const buildDefaultParamCategory = (paramCategory?: any) => {
    try {
      const categoryData: any[] = paramCategory ? paramCategory : category;
      let categoryFormed: DropdownValue[] = formDropdownValues(
        categoryData,
        "displayName",
        "displayName",
        "multi"
      );
      categoryFormed = getUniqueDataByProperty(categoryFormed, "label");
      setCategoryDropdown(categoryFormed);
      setCategorySelected([]);
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const buildDefaultParamGroup = (paramGroup?: any) => {
    try {
      const groupData: any[] = paramGroup ? paramGroup : group;
      let groupFormed: DropdownValue[] = formDropdownValues(
        groupData,
        "displayName",
        "displayName",
        "multi"
      );
      groupFormed = getUniqueDataByProperty(groupFormed, "label");
      setGroupDropdown(groupFormed);
      setGroupSelected([]);
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const buildDefaultParamKey = (paramKey?: any) => {
    try {
      const keyData: any[] = paramKey ? paramKey : rule;
      let keyFormed: DropdownValue[] = formDropdownValues(
        keyData,
        "displayName",
        "displayName",
        "multi"
      );
      keyFormed = getUniqueDataByProperty(keyFormed, "label");
      setRuleDropdown(keyFormed);
      setRuleSelected([]);
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  //Apply Functionality
  const formPayload = () => {
    try {
      const filtersOpted: any = {
        isAdmin: "Y",
        limit: DEFAULT_RULE_LIMIT,
        offset: DEFAULT_RULE_OFFSET,
      };

      filtersOpted.storeNumbers = filterSelected.storeNumber
        ? [filterSelected.storeNumber]
        : [];
      filtersOpted.stateCode = filterSelected.stateCode
        ? [filterSelected.stateCode]
        : [];
      filtersOpted.companyCode = filterSelected.companyCode
        ? [filterSelected.companyCode]
        : [];

      let categoryData: any[] = [];
      let selectedCategory: string[] = [];
      let groupData: any[] = [];
      let selectedGroup: string[] = [];
      let ruleData: any[] = [];
      let selectedRule: string[] = [];

      if (categorySelected.length) {
        const categoryDisplayName = categorySelected.map(
          (category: any) => category.label
        );

        categoryData = category?.filter((categoryDetails: any) =>
          categoryDisplayName.includes(categoryDetails.displayName)
        );
        selectedCategory = categoryData.map(
          (item: any) => item.paramCategoryName
        );
      }

      if (groupSelected.length) {
        const groupDisplayName = groupSelected.map((item: any) => item.label);

        groupData = group?.filter((item: any) =>
          groupDisplayName.includes(item.displayName)
        );
        const actualCategory: string[] = [];
        categoryData.forEach((item: any) => {
          groupData.forEach((groupItem: any) => {
            if (groupItem.paramCategoryId == item.paramCategoryId) {
              actualCategory.push(item.paramCategoryName);
              selectedGroup.push(groupItem.paramGroupName);
            }
          });
        });
        selectedCategory = actualCategory;
      }

      if (ruleSelected.length) {
        const ruleDisplayName = ruleSelected.map(
          (rule: DropdownValue) => rule.label
        );
        ruleData = rule.filter((item: any) =>
          ruleDisplayName.includes(item.displayName)
        );

        const actualGroup: string[] = [];
        const categoryId: any[] = [];

        groupData.forEach((groupItem: any) => {
          ruleData.forEach((ruleItem: any) => {
            if (ruleItem.paramGroupId == groupItem.paramGroupId) {
              actualGroup.push(groupItem.paramGroupName);
              selectedRule.push(ruleItem.paramKeyName);
              categoryId.push(groupItem.paramCategoryId);
            }
          });
        });

        const filteredCategory = category.filter((categoryItem: any) =>
          categoryId.includes(categoryItem.paramCategoryId)
        );
        const finaizedCategory = filteredCategory.map(
          (item: any) => item.paramCategoryName
        );
        selectedCategory = finaizedCategory;
        selectedGroup = actualGroup;
      }

      if (selectedRule.length) {
        filtersOpted.paramKeyNames = [...new Set(selectedRule)];
      }
      if (selectedGroup.length) {
        filtersOpted.paramGroupNames = [...new Set(selectedGroup)];
      }
      if (selectedCategory.length) {
        filtersOpted.paramCategoryNames = [...new Set(selectedCategory)];
      }

      return filtersOpted;
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  const getFilterResult = async () => {
    try {
      if (validSearch) {
        const payload = formPayload();

        setRequest(payload);
        setOrganizationRules([]);
        setResultWidget(1);
        const summaryResponse: any = await getOrgRulesSummary(payload);

        if (
          summaryResponse?.status == 200 &&
          summaryResponse?.data?.organizationRule?.length
        ) {
          setOrganizationRules(summaryResponse.data.organizationRule);
          setResultWidget(200);
          setEnableExcel(true);
        } else if (
          summaryResponse?.status == 204 ||
          summaryResponse?.data == "" ||
          summaryResponse?.data?.organizationRule?.length == 0
        ) {
          setResultWidget(204);
        } else {
          setResultWidget(204);
        }
      }
    } catch (err: any) {
      setResultWidget(500);
    }
  };

  /**
   * During the onload of the FilterWidget
   * Fetching the Company,State,Store, ParamCategory,ParamGroup and ParamKey data from service
   * Filtering the data based on the user either a franchise/core company user
   */
  const getDropdownsData = async () => {
    try {
      let employeeId = "";
      let franchiseUserCompany: string[] = [];
      let groupData: any[] = [];
      let categoryData: any[] = [];
      let franchiseeUser = false;

      if (containerData?.GetEmployeeId != undefined) {
        employeeId = containerData.GetEmployeeId();
      } else {
        const fetchUserInfoResponse = await getUserID();

        if (
          fetchUserInfoResponse &&
          fetchUserInfoResponse.status == 200 &&
          fetchUserInfoResponse.data?.employeeId
        ) {
          employeeId = fetchUserInfoResponse.data.employeeId;
        }
      }

      if (employeeId != "") {
        const menuAndCoworkerInfoResponse = await getMenuConfigurationDetails({
          coworkerId: employeeId,
          menuRequired: true,
          companyCodeRequired: true,
        });

        if (
          menuAndCoworkerInfoResponse &&
          menuAndCoworkerInfoResponse.status == 200 &&
          menuAndCoworkerInfoResponse.data
        ) {
          if (menuAndCoworkerInfoResponse.data?.franchiseeUser) {
            franchiseeUser = true;
            franchiseUserCompany = menuAndCoworkerInfoResponse.data.companyCode;
            if (menuAndCoworkerInfoResponse.data?.menuConfig) {
              const menuAssigned = menuAndCoworkerInfoResponse.data?.menuConfig;
              menuAssigned?.forEach((menuInfo: any) => {
                setFranchiseeUser(true)
                if (
                  menuInfo.appRefCode == "Admin" &&
                  menuInfo.appComponentFunctionRefCode ==
                  "Application Configurations" &&
                  menuInfo.edit == 1
                ) {
                  setViewMode(false);
                }
              });
            }
          } else if (menuAndCoworkerInfoResponse.data?.menuConfig) {
            const menuAssigned = menuAndCoworkerInfoResponse.data?.menuConfig;
            menuAssigned?.forEach((menuInfo: any) => {
              setCoreUser(true);
              if (
                menuInfo.appRefCode == "Admin" &&
                menuInfo.appComponentFunctionRefCode ==
                  "Application Configurations" &&
                menuInfo.edit == 1
              ) {
                setViewMode(false);
              }
            });
          }
        }
      }

      const [
        storeResponse,
        stateResponse,
        companyResponse,
        ruleDetailResponse,
      ] = await Promise.all<any>([
        fetchOrganizationDetails({ type: "STORE" }),
        fetchOrganizationDetails({ type: "STATE" }),
        fetchOrganizationDetails({ type: "COMPANY" }),
        fetchConfigReference({
          referenceTable: [
            "param_key",
            "param_group",
            "param_category",
            "param_key_datatype",
            "param_name",
            "param_value_template",
            "param_key_type",
          ],
        }),
      ]);
      const stateAbbreviation: string[] = [];

      if (
        storeResponse &&
        storeResponse?.status == 200 &&
        storeResponse?.data?.result
      ) {
        let storeDetails: any[] = [];

        if (franchiseUserCompany.length) {
          storeResponse.data.result.forEach((store: any) => {
            if (franchiseUserCompany.includes(store.companyCode)) {
              storeDetails.push(store);
              stateAbbreviation.push(store.stateCode);
            }
          });
        } else {
          storeDetails = storeResponse.data.result;
        }
        setStore(storeDetails);
        buildDefaultStoreDropdown(storeDetails);
      }

      if (
        stateResponse &&
        stateResponse?.status == 200 &&
        stateResponse?.data?.result
      ) {
        let stateDetails: any[] = [];

        if (franchiseUserCompany.length) {
          stateResponse.data.result.forEach((state: any) => {
            if (stateAbbreviation.includes(state.referenceValue)) {
              stateDetails.push(state);
            }
          });
        } else {
          stateDetails = stateResponse.data.result;
        }
        stateDetails = sortByProperty(stateDetails, "referenceValue");
        setState(stateDetails);
        buildDefaultStateDropdown(stateDetails);
      }

      if (
        companyResponse &&
        companyResponse?.status == 200 &&
        companyResponse?.data?.result
      ) {
        let companyDetails: any[] = [];
        if (franchiseUserCompany.length) {
          companyResponse.data.result.forEach((company: any) => {
            if (franchiseUserCompany.includes(company.referenceValue)) {
              companyDetails.push(company);
            }
          });
        } else {
          companyDetails = companyResponse.data.result;
        }

        setCompany(companyDetails);
        buildDefaultCompanyDropdown(companyDetails);
        if (companyDetails.length == 1) {
          setFilterSelected({
            ...filterSelected,
            companyCode: companyDetails[0].referenceValue,
          });
        }
      }
      setOrgDropdownLoader(false);

      if (
        ruleDetailResponse &&
        ruleDetailResponse?.status == 200 &&
        ruleDetailResponse?.data?.configReference?.referenceTable
      ) {
        let tempRuleContraint: any = {};
        let datatypeInfo: any = {};
        let keyTypeInfo: any = {};
        let paramKeyDatatype: any[] = [];
        let paramKeytype: any[] = [];
        let ruleData: any[] = [];
        let paramKeyTemplate: any[] = [];
        let paramName: any[] = [];

        ruleDetailResponse.data.configReference.referenceTable?.forEach(
          (paramDetails: any) => {
            if (paramDetails.referenceKey == "param_key_datatype") {
              paramKeyDatatype = paramDetails.referenceDetails;
            }

            if (paramDetails.referenceKey == "param_key_type") {
              paramKeytype = paramDetails.referenceDetails;
            }

            if (paramDetails.referenceKey == "param_key") {
              ruleData = sortByProperty(
                paramDetails.referenceDetails,
                "displayName"
              );
            }

            if (paramDetails.referenceKey == "param_group") {
              groupData = sortByProperty(
                paramDetails.referenceDetails,
                "displayName"
              );

              buildDefaultParamGroup(groupData);
              setGroup(groupData);
            }

            if (paramDetails.referenceKey == "param_category") {
              categoryData = sortByProperty(
                paramDetails.referenceDetails,
                "displayName"
              );

              buildDefaultParamCategory(categoryData);
              setCategory(categoryData);
            }

            if (paramDetails.referenceKey == "param_value_template") {
              paramKeyTemplate = paramDetails.referenceDetails;
            }

            if (paramDetails.referenceKey == "param_name") {
              paramName = paramDetails.referenceDetails;
            }
          }
        );

        paramKeyDatatype?.forEach((row: any) => {
          datatypeInfo[row.paramKeyDatatypeId] = row.paramKeyDatatypeName;
        });

        paramKeytype?.forEach((row: any) => {
          keyTypeInfo[row.paramKeyTypeId] = row.paramKeyTypeName;
        });

        let paramKey: any[] = [];
        let paramGroupData: any[] = [];
        let groupCategoryData: any[] = [];
        const arr = ["FEATURE_FLAG", "CONFIGURATION"]
        if (franchiseeUser) {
          paramKey = ruleData.filter(
            (rule: any) => !(arr.includes(keyTypeInfo[rule.paramKeyTypeId]))
          );
          //Filter the param group based on the rule applied for franchisee user...
          paramGroupData = groupData.filter(param => {
            const matchingGroup = paramKey.find(group => group.paramGroupId === param.paramGroupId);
            return matchingGroup; 
          });
         //Filter the category  based on the group applied for franchisee user...
          groupCategoryData = categoryData.filter(param => {
            const matchingGroup = paramGroupData.find(group => group.paramCategoryId === param.paramCategoryId);
            return matchingGroup; 
          });

          buildDefaultParamCategory(groupCategoryData);
          setCategory(groupCategoryData);

          buildDefaultParamGroup(paramGroupData);
          setGroup(paramGroupData);
        } else {
          paramKey = ruleData;
        }
        buildDefaultParamKey(paramKey);
        setRule(paramKey);
        paramKey.forEach((row: any) => {
          const rule = tempRuleContraint[row.paramKeyId] || {};
          rule.datatype = datatypeInfo[row.paramKeyDatatypeId];
          rule.keyType = keyTypeInfo[row.paramKeyTypeId];
          rule.desc = row.paramKeyDesc;
          tempRuleContraint = { ...tempRuleContraint, [row.paramKeyId]: rule };
        });

        paramKeyTemplate.forEach((row: any) => {
          const templateInfo = tempRuleContraint[row.paramKeyId] || {};
          templateInfo.template = row.paramValueTemplateName;
          tempRuleContraint = {
            ...tempRuleContraint,
            [row.paramKeyId]: templateInfo,
          };
        });

        paramName.forEach((row: any) => {
          const paramNameInfo = tempRuleContraint[row.paramKeyId] || {};
          paramNameInfo.paramName = paramNameInfo?.paramName
            ? paramNameInfo?.paramName
            : [];
          if (!paramNameInfo.paramName.includes(row.paramNameValue)) {
            paramNameInfo.paramName.push(row.paramNameValue);
          }
          tempRuleContraint = {
            ...tempRuleContraint,
            [row.paramKeyId]: paramNameInfo,
          };
        });

        setRuleConstraints(tempRuleContraint);
      }
      if (franchiseUserCompany.length == 1) {
        setFilterSelected({
          ...filterSelected,
          companyCode: franchiseUserCompany[0],
        });
      }
      setRulesDropdownLoader(false);
      const [districtResponse, regionResponse, countryResponse, lobResponse] =
        await Promise.all<any>([
          fetchOrganizationDetails({ type: "DISTRICT" }),
          fetchOrganizationDetails({ type: "REGION" }),
          fetchOrganizationDetails({ type: "COUNTRY" }),
          fetchOrganizationDetails({ type: "LOB" }),
        ]);

      if (
        districtResponse &&
        districtResponse?.status == 200 &&
        districtResponse?.data?.result
      ) {
        const districts=districtResponse.data.result;
        let districtOptions:DropdownValue[] = []; 
        districts.forEach((districtDetails:any)=>{
          districtOptions.push({
            label:districtDetails.referenceName,
            value:districtDetails.referenceValue
          })
          
        });
        districtOptions=getUniqueDataByProperty(districtOptions,'label');
          setDistrictDropdown(districtOptions);
      }
      if (
        regionResponse &&
        regionResponse?.status == 200 &&
        regionResponse?.data?.result
      ) {
        const regions=regionResponse.data.result;
        let regionOptions:DropdownValue[] = []; 
        regions.forEach((regionDetails:any)=>{
          regionOptions.push({
            label:regionDetails.referenceName,
            value:regionDetails.referenceValue
          })
          
        });
        regionOptions=getUniqueDataByProperty(regionOptions,'label');
        setRegionDropdown(regionOptions);
        
      }
      
      if (
        countryResponse &&
        countryResponse?.status == 200 &&
        countryResponse?.data?.result
      ) {
        const country = countryResponse.data.result;
        let countryOptions:DropdownValue[] = []; 
        country.forEach((countryDetails:any)=>{
          countryOptions.push({
            label:countryDetails.referenceName,
            value:countryDetails.referenceValue
          })
          
        });
        countryOptions=getUniqueDataByProperty(countryOptions,'label');
        setCountryDropdown(countryOptions);
      }
      
      if (
        lobResponse &&
        lobResponse?.status == 200 &&
        lobResponse?.data?.result
      ) {
        const lob = lobResponse.data.result;
        let lobOptions:DropdownValue[] = []; 
        lob.forEach((lobDetails:any)=>{
          lobOptions.push({
            label:lobDetails.referenceName,
            value:lobDetails.referenceValue
          })
        });
        lobOptions=getUniqueDataByProperty(lobOptions,'label');
        setLobDropdown(lobOptions);
       
      }
      setAssignButtonLoader(false);
              
    } catch (err: any) {
      setShowErrorPopup(true);
    }
  };

  useEffect(() => {
    if (!categoryDropdown.length) {
      getDropdownsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showErrorPopup ? <ErrorPopup setFunction={setShowErrorPopup} /> : <></>}

      <Grid container md={12} className={classes.pb75}>
        <Grid item md={10}>
          <Typography variant="h5">
            Application Configurations &ensp;
            <RACTooltip
              placement={"top-start"}
              className={classes.toooltipStyle}
              title={
                <>
                  <Grid>
                    <Typography>
                      Hovering over the Category, Group, Rule Name will show the
                      name in the DB column
                    </Typography>
                  </Grid>
                </>
              }
            >
              <InfoIcon />
            </RACTooltip>
          </Typography>
        </Grid>

        <Grid item md={1}></Grid>
        <Grid
          item
          className={classes.excelStyle}
          style={
            enableExcel && !excelLoader && resultWidget==200
              ? {}
              : { opacity: "50%", pointerEvents: "none" }
          }
          md={1}
          lg={1}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <ExcelIcon
              onClick={async () => {
                setExcelLoader(true);
                await exportToExcel(coreUser, request);
                setExcelLoader(false);
              }}
            />
            <Typography
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginLeft: "7px",
              }}
            >
              {excelLoader ? (
                <CircularProgress
                  style={{ width: "25px", height: "0px !important" }}
                />
              ) : (
                <div />
              )}
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <Grid>
        <Card className={classes.cardStyle}>
          <CardContent>
            <Grid container md={12} spacing={2}>
              <Grid item md={2}>
                <RACSelect
                  inputLabel="Company Name"
                  options={companyDropdown}
                  loading={OrgdropdownLoader}
                  inputLabelClassName={classes.mb1}
                  defaultValue={filterSelected.companyCode}
                  onChange={handleCompanyDropdown}
                />
              </Grid>

              <Grid item md={2}>
                <RACSelect
                  inputLabel="State"
                  options={stateDropdown}
                  loading={OrgdropdownLoader}
                  inputLabelClassName={classes.mb1}
                  defaultValue={filterSelected.stateCode}
                  onChange={(e: any) => {
                    handleStateDropdown(e);
                  }}
                />
              </Grid>

              <Grid item md={2}>
                <RACSelect
                  inputLabel="Store #"
                  options={storeDropdown}
                  loading={OrgdropdownLoader}
                  inputLabelClassName={classes.mb1}
                  defaultValue={filterSelected.storeNumber}
                  onChange={handleStoreDropdown}
                />
              </Grid>

              <Grid item md={2}>
                <Grid item md={12} className={classes.mb125}>
                  <Typography>Category</Typography>
                </Grid>
                <Grid md={12}>
                  <MultiSelect
                    options={categoryDropdown}
                    value={categorySelected}
                    isLoading={rulesDropdownLoader}
                    labelledBy="Select"
                    disabled={!validSearch}
                    className={
                      validSearch
                        ? classes.multiSelectEnabled
                        : classes.multiSelectDisabled
                    }
                    onChange={handleCategoryDropdown}
                  ></MultiSelect>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <Grid item md={12} className={classes.mb125}>
                  <Typography>Group</Typography>
                </Grid>
                <Grid md={12}>
                  <MultiSelect
                    options={groupDropdown}
                    value={groupSelected}
                    disabled={!validSearch}
                    isLoading={rulesDropdownLoader}
                    labelledBy="Select"
                    className={
                      validSearch
                        ? classes.multiSelectEnabled
                        : classes.multiSelectDisabled
                    }
                    onChange={handleGroupDropdown}
                  ></MultiSelect>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <Grid item md={12} className={classes.mb125}>
                  <Typography>Rule Name</Typography>
                </Grid>
                <Grid md={12}>
                  <MultiSelect
                    options={ruleDropdown}
                    value={ruleSelected}
                    isLoading={rulesDropdownLoader}
                    labelledBy="Select"
                    disabled={!validSearch}
                    className={
                      validSearch
                        ? classes.multiSelectEnabled
                        : classes.multiSelectDisabled
                    }
                    onChange={handleRuleDropdown}
                  ></MultiSelect>
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={12} spacing={2} className={classes.mt1}>
              <Grid item md={12}>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.floatRight}
                  disabled={!validSearch || OrgdropdownLoader}
                  onClick={() => {
                    getFilterResult();
                  }}
                >
                  Apply
                </RACButton>

                <RACButton
                  variant="outlined"
                  color="primary"
                  disabled={OrgdropdownLoader || filterApplied}
                  className={classes.clearButton}
                  onClick={() => {
                    buildDefaultCompanyDropdown();
                    buildDefaultStateDropdown();
                    buildDefaultStoreDropdown();
                    buildDefaultParamCategory();
                    buildDefaultParamGroup();
                    buildDefaultParamKey();
                    setEnableExcel(false);
                    setFilterSelected(defaultFilterState);
                  }}
                >
                  Clear
                </RACButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
