import {
  Grid,
  RACButton,
  Typography,
  RACTableRow,
  RACTableCell,
  CircularProgress,
  TableRow,
} from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { ResetPinHeading } from '../../constants/constants';
import { getmenuandstore } from '../../api/user';
import { ResetPinContext } from './ResetPinContext';
import ResetPinFilter from './ResetPinFilter';
import ResetPinGrid from './ResetPinGrid';
import { GlobalAdminStyles } from '../../JsStyles/globalAdminStyles';
import { useHistory } from 'react-router-dom';

export default function ResetPin() {
  const classes = GlobalAdminStyles();
  const {
    displaySearchResults,
    setDisplaySearchResults,
    setPageDisplay,
    loader,
    setLoader,
    popUp,
    setPopUp,
    validationData,
    resetPinSearchObj,
    setUserId,
    setResetPinSearchObj,
    resetPinGridContent,
    setResetPinGridContent,
    setErrorMessage,
  } = useContext(ResetPinContext);

  const history = useHistory();

  async function getResetPinSearchData(payload: any) {
    try {
      setLoader(true);
      setPageDisplay(200);
      setResetPinGridContent([]);
      setDisplaySearchResults(true);
      const body = {
        coworkerId: payload.employeeId || null,
        emailId: payload.emailId || null,
      };
      const fetchedData = await getmenuandstore(body);
      setLoader(false);
      if (fetchedData?.status === 200 && fetchedData?.data?.coworkerProfile) {
        const userData = [fetchedData?.data?.coworkerProfile];
        setUserId(userData[0]?.employeeId);
        setResetPinGridContent(userData);
        setPageDisplay(200);
      } else {
        setPageDisplay(204);
      }
    } catch (e: any) {
      setLoader(false);
      setPageDisplay(500);
    }
  }

  const renderResetPinTableHeadFn = () => (
    <>
      {ResetPinHeading.map((obj: any, index: any) => {
        return <RACTableCell key={index}>{obj?.heading}</RACTableCell>;
      })}
    </>
  );

  const renderResetPinTableContentFn = () => (
    <>
      {loader ? (
        <TableRow>
          <RACTableCell colSpan={12}>
            <Grid className={`${classes.centerAlign}`}>
              <CircularProgress />
            </Grid>
          </RACTableCell>
        </TableRow>
      ) : null}
      {resetPinGridContent?.map((obj: any, index: any) => {
        return (
          <RACTableRow
            key={index}
            className={classes.whitebackground}
            style={{ width: '100%' }}
          >
            <RACTableCell
              style={{ width: '20%' }}
              className={`${classes.textDarkSlateBlue}`}
            >
              {obj?.employeeId ? obj?.employeeId : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '20%' }}
              className={`${classes.textDarkSlateBlue}`}
            >
              {obj?.firstName ? obj?.firstName : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '20%' }}
              className={`${classes.textDarkSlateBlue}`}
            >
              {obj?.lastName ? obj?.lastName : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '30%' }}
              className={`${classes.textDarkSlateBlue}`}
            >
              {obj?.emailAddress ? obj?.emailAddress : '-'}
            </RACTableCell>
            <RACTableCell style={{ width: '10%' }}>
              <RACButton
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  setPopUp({ ...popUp, confirmationPopUp: true });
                }}
              >
                {' '}
                Reset{' '}
              </RACButton>
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  function keyPress(e: any) {
    if (validationData && e.key == 'Enter') {
      if (
        resetPinSearchObj.emailId &&
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
          resetPinSearchObj.emailId
        )
      ) {
        setErrorMessage('Invalid Email Id');
      } else {
        setErrorMessage('');
        getResetPinSearchData(resetPinSearchObj);
      }
    }
  }

  function clearSearch() {
    setResetPinSearchObj({
      ...resetPinSearchObj,
      employeeId: '',
      emailId: '',
    });
  }

  return (
    <Grid container md={12} className={`${classes.pagePadding1}`}>
      <Grid container className={`${classes.mb5}`}>
        <Grid item md={6}>
          <Typography
            className={`${classes.floatLeft} ${classes.mt9} ${classes.title4} ${classes.mL1}`}
          >
            Reset PIN
          </Typography>
        </Grid>
      </Grid>

      <ResetPinFilter
        keyPress={keyPress}
        getResetPinSearchData={getResetPinSearchData}
        clearSearch={clearSearch}
      />

      {displaySearchResults ? (
        <ResetPinGrid
          renderResetPinTableHeadFn={renderResetPinTableHeadFn}
          renderResetPinTableContentFn={renderResetPinTableContentFn}
        />
      ) : null}

      <Grid item md={12} className={classes.fixedBottom}>
        <RACButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            history.push({
              pathname: `/dashboard/homepage`,
            });
          }}
        >
          Cancel
        </RACButton>
      </Grid>
    </Grid>
  );
}
