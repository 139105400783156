/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
  makeStyles,
} from '@rentacenter/racstrap';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ErrorIcon } from '../../../assets/images/Error.svg';
import { ReactComponent as NoRecordsIcon } from '../../../assets/images/No records.svg';
import { ReactComponent as SavedIcon } from '../../../assets/images/Done.svg';

const useClasses = makeStyles((theme: any) => ({
  centerAlign: {
    textAlign: 'center',
  },
  mt2: {
    marginTop: theme.typography.pxToRem(10),
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  buttonContainer: {
    marginTop: theme.typography.pxToRem(25),
    paddingBottom: theme.typography.pxToRem(24),
  },
}));

/**
 * props used so far: popupType, closePopupFunction, onCloseFunction, needCloseIcon
 * Default Popup: Something Went Wrong
 * Date: 20-May-2024
 * Developer: Mohan Kumar.D
 */
export default function DynamicPopup(props?: any) {
  const classes = useClasses();
  const [icon, setIcon] = useState<React.ReactNode>(<ErrorIcon />);
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const [closeIcon, setCloseIcon] = useState<boolean>(false);
  const { type, needCloseIcon, closePopupFunction, onCloseFunction } = props;
  const [message, setMessage] = useState<string>('Something went wrong');

  useEffect(() => {
    needCloseIcon ? setCloseIcon(true) : null;
    switch (type?.toUpperCase()) {
      case 'SAVED': {
        setIcon(<SavedIcon />);
        setMessage('Saved Successfully');
        break;
      }
      case 'NORECORD': {
        setIcon(<NoRecordsIcon />);
        setMessage('No Records Found');
        break;
      }
      case 'BADREQUEST': {
        setIcon(<ErrorIcon />);
        setMessage('Bad Request');
        break;
      }
      case 'ERROR': {
        setIcon(<ErrorIcon />);
        setMessage('Something went wrong');
        break;
      }
      default: {
        setIcon(<ErrorIcon />);
        setMessage('Something went wrong');
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = () => {
    closePopupFunction ? closePopupFunction(false) : null;
    onCloseFunction ? onCloseFunction() : null;
    setShowPopup(false);
  };

  const popupContent = () => (
    <div
      id="initialpayment"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <Grid className={classes.centerAlign}>
        {icon}
        <Typography variant="h6" className={`  ${classes.mt2}`}>
          {message}
        </Typography>
      </Grid>

      <Grid
        item
        md={12}
        className={`${classes.buttonContainer} ${classes.centerAlign}`}
      >
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          onClick={handleOnClose}
        >
          OK
        </RACButton>
      </Grid>
    </div>
  );

  return (
    <RACModalCard
      isOpen={showPopup}
      closeIcon={closeIcon}
      onClose={handleOnClose}
      maxWidth="xs"
      borderRadius="25px !important"
      //eslint-disable-next-line react/no-children-prop
      children={popupContent()}
    />
  );
}
