import { Grid, Typography } from '@rentacenter/racstrap';
import { InventoryStyles } from '../../../JsStyles/InventoryStyles';
import React from 'react';

export function AgreementsLegend() {
  const classes = InventoryStyles();
  return (
    <Grid className={`${classes.legendGrid}`}>
      <Typography
        component="span"
        className={`${classes.mr2} ${classes.legendSqure} ${classes.legendPending}`}
      />
      <Typography component="span" className={classes.mr5}>
        Pending
      </Typography>
      <Typography
        component="span"
        className={`${classes.mr2} ${classes.legendSqure} ${classes.legendActive}`}
      />
      <Typography component="span" className={classes.mr5}>
        Active
      </Typography>
      <Typography
        component="span"
        className={`${classes.mr2} ${classes.legendSqure} ${classes.legendPastDue}`}
      />
      <Typography component="span" className={classes.mr5}>
        Past Due
      </Typography>
      <Typography
        component="span"
        className={`${classes.mr2} ${classes.legendSqure} ${classes.legendInactive}`}
      />
      <Typography component="span" className={classes.mr2}>
        Inactive
      </Typography>
    </Grid>
  );
}
