import { Grid, RACModalCard, Typography } from '@rentacenter/racstrap';
import { InventoryStyles } from '../../../JsStyles/InventoryStyles';
import { formatCurrency, formatDate } from '../../../utils/InventoryUtils';
import React from 'react';

export function PaymentDetailsCard(props?: any) {
  const paymentInfo = props.paymentDetails;
  const classes = InventoryStyles();

  const cardFirstHalf = () => (
    <Grid item md={6} xs={12} sm={6}>
      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Receipt</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.receiptId ? (
              <Typography>{paymentInfo.receiptId}</Typography>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Store</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.store ? (
              <Typography>{paymentInfo.store}</Typography>
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Payment Origin</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.paymentOrigin ? paymentInfo.paymentOrigin : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Tender Type</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.tenderType ? paymentInfo.tenderType : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Days Extended</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.daysExt ? paymentInfo.daysExt : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Days Late</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.daysLate ? paymentInfo.daysLate : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>NSF</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>{paymentInfo?.nsf ? paymentInfo.nsf : '-'}</Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Old Due Date</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.oldDueDate ? formatDate(paymentInfo.oldDueDate) : '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Next Due Date</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} style={{ color: '#4A5174' }}>
          <Typography>
            {paymentInfo?.nextDueDate
              ? formatDate(paymentInfo.nextDueDate)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const cardSecondHalf = () => (
    <Grid item md={6} xs={12} sm={6}>
      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={4} xs={6} sm={6}>
          <Typography style={{}}>Date</Typography>
        </Grid>
        <Grid
          item
          md={8}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.datePaid ? formatDate(paymentInfo.datePaid) : '-'}{' '}
            {paymentInfo?.datePaid && paymentInfo?.timePaid
              ? ' ' + paymentInfo.timePaid + ' CST'
              : ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={5} xs={6} sm={6}>
          <Typography style={{}}>Net Rent</Typography>
        </Grid>
        <Grid
          item
          md={7}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.netRent
              ? formatCurrency(paymentInfo.netRent)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Club</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.clubAmount
              ? formatCurrency(paymentInfo.clubAmount)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>LDW</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.ldw ? formatCurrency(paymentInfo.ldw) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Late Fee</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.lateFee
              ? formatCurrency(paymentInfo.lateFee)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Discount</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.discount
              ? formatCurrency(paymentInfo.discount)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Other</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.other ? formatCurrency(paymentInfo.other) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Tax</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.tax ? formatCurrency(paymentInfo.tax) : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 20px 13px 10px 0px' }}>
        <Grid
          md={12}
          style={{
            display: 'flex',
            paddingLeft: '10px',
          }}
        >
          <Typography
            component="span"
            style={{
              backgroundColor: '#E3E3E3',
              height: '2px',
              width: '100%',
              borderRadius: '5px',
            }}
          />
        </Grid>
      </Grid>

      <Grid container style={{ padding: ' 10px 13px' }}>
        <Grid item md={6} xs={6} sm={6}>
          <Typography style={{}}>Total Payment</Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sm={6}
          style={{ color: '#4A5174', textAlign: 'end' }}
        >
          <Typography>
            {paymentInfo?.totalPayment
              ? formatCurrency(paymentInfo?.totalPayment)
              : '$ 0.00'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      <RACModalCard
        isOpen={true}
        title="Payment Details"
        className={`${classes.paymentPopup}`}
        closeIcon={true}
        onClose={() => {
          props.setFunction(false);
        }}
        maxWidth={'sm'}
      >
        <Grid container md={12} style={{ paddingTop: '0px' }}>
          {cardFirstHalf()}
          {cardSecondHalf()}
        </Grid>
      </RACModalCard>
    </>
  );
}
