/* eslint-disable */

import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';
import { getAgreementReceiptById } from '../api/user';

import { isEmpty } from 'lodash';

export type ListStatus =
  | 'initial'
  | 'loading'
  | 'apiError'
  | 'empty'
  | 'success';
export interface ComponentStateProps {
  loading?: boolean;
  hasApiError?: boolean;
  response?: any;
}


export const getComponentState = ({
  loading,
  hasApiError,
  response,
}: ComponentStateProps): ListStatus => {
  if (loading) return 'loading';
  if (hasApiError) return 'apiError';

  if (response) {
    if (Array.isArray(response)) {
      return response.length > 0 ? 'success' : 'empty';
    } else if (isEmpty(response)) {
      return 'empty';
    }
    return 'success';
  } else {
    return 'initial';
  }
};

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export function sortObject(
  order: any,
  key: any,
  array: any,
  toggleName: any,
  toggleFunction: any,
  setFunction: any
) {
  const sortedArray = array.slice();
  if (order === 'asc') {
    handleAscSort(toggleFunction, toggleName, setFunction, key, sortedArray);
  } else {
    handleDscSort(toggleFunction, toggleName, setFunction, key, sortedArray);
  }
}

function handleDscSort(
  toggleFunction: any,
  toggleName: any,
  setFunction: any,
  key: any,
  sortedArray: any
) {
  toggleFunction((pre: any) => ({ ...pre, [toggleName]: false }));
  if (key == 'customers[0].priority') {
    setFunction(sortedArray.sort((a: any, b: any) => {
      const priorityA = a.customers[0]?.priority ?? '';
      const priorityB = b.customers[0]?.priority ?? '';
      return priorityB.localeCompare(priorityA);
    }))
  } else {
    setFunction(
      sortedArray.sort((a: any, b: any) =>
        (b[key] ?? '').localeCompare(a[key] ?? '')
      )
    );
  }
}

function handleAscSort(
  toggleFunction: any,
  toggleName: any,
  setFunction: any,
  key: any,
  sortedArray: any
) {
  toggleFunction((pre: any) => ({ ...pre, [toggleName]: true }));
  if (key == 'customers[0].priority') {
    setFunction(sortedArray.sort((a: any, b: any) => {
      const priorityA = a.customers[0]?.priority ?? '';
      const priorityB = b.customers[0]?.priority ?? '';
      return priorityA.localeCompare(priorityB);
    }))
  } else {
    setFunction(
      sortedArray.sort((a: any, b: any) =>
        (a[key] ?? '').localeCompare(b[key] ?? '')
      )
    );
  }
}


export async function getAgreementReceipt(
  id: any,
  setLoader: any,
  setErrorMessage: any,
  setErrorMessagePopup: any,
  customerId: any,
  desc: any
) {
  setLoader(true);
  const payLoad: any = {
    identifier: desc == 'Club' ? customerId : id,
    documentStatus: 'signed',
    actionType: 'print',
    identifierType: desc == 'Club' ? 'CUST' : 'AGR',
  };
  const printDocumentResult = await getAgreementReceiptById(payLoad);
  setLoader(false);
  if (
    printDocumentResult?.status == 200 &&
    Object.entries(printDocumentResult?.data?.documents).length !== 0
  ) {
    const allDocument = printDocumentResult?.data?.documents;
    const refCode = desc == 'Club' ? 'CLUB' : 'RNT';
    let document = allDocument.find((obj: any) => obj['refCode'] === refCode);
    viewPDFclick(document.file, document.documentUrl);
  } else {
    setLoader(false);
    setErrorMessage('Not able to Get Agreement Document');
    setErrorMessagePopup(true);
  }
}

export const viewPDFclick = (b64File: any, docuURL: any) => {
  const urlArray = docuURL.split('.');
  const docuType = urlArray[urlArray.length - 1];
  // const docuType = 'pdf';

  let contentType = `application/${docuType}`;
  if (docuType === 'html') {
    contentType = 'text/html';
  }
  const blobResult = b64toBlob(b64File, contentType);

  const fileURL = URL.createObjectURL(blobResult);

  window.open(fileURL);

  // for download
  // const link = document.createElement('a');
  // link.href = URL.createObjectURL(blobResult);
  // link.download = `document.${docuType}`;
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
};

const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export function getFormatedDate(date: any) {
  if (date) {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }
  return '';
}

export function getTimestampToDate(date: any) {
  const datePart = date.split('T')[0];
  const [year, month, day] = datePart.split('-');
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}


export function getCstFormateDate(date: string) {
  const dateObject = new Date(date);

    const options = {
      year: 'numeric' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      hour12: true,
      timeZoneName: 'short' as const,
    };
    return dateObject.toLocaleString('en-US', options);
}


export function sortNumber(
  order: any,
  key: any,
  array: any,
  setFunction: any
) {
  const sortedArray = array.slice();
  if (order === 'asc') {
    handleAscSortNumber(setFunction, key, sortedArray);
  } else {
    handleDscSortNumber(setFunction, key, sortedArray);
  }

}

function handleDscSortNumber(
  setFunction: any,
  key: any,
  sortedArray: any
) {
  setFunction(
    sortedArray.sort((a: any, b: any) =>
      (b[key] ?? '').localeCompare(a[key] ?? '')
    )
  );
}

function handleAscSortNumber(
  setFunction: any,
  key: any,
  sortedArray: any
) {
  setFunction(
    sortedArray.sort((a: any, b: any) =>
      (a[key] ?? '').localeCompare(b[key] ?? '')
    )
  );
}

export function sortByDate(objectsArray: any, datePropertyName: any, ascending = true) {
  const sortedArray = objectsArray.slice().sort((a: any, b: any) => {
    const dateA: any = new Date(a[datePropertyName]);
    const dateB: any = new Date(b[datePropertyName]);

    return ascending ? dateA - dateB : dateB - dateA;
  });

  return sortedArray;
}

export function sortActivityDate(Array: any, order: string) {
  const dateSort = (a:any, b:any) => {
    const dateA: any = new Date(a.activityDate);
    const dateB: any = new Date(b.activityDate);
    if (order == 'asc') {
      return dateA - dateB
    }
    else {
      return dateB - dateA
    }
  };
  Array.sort(dateSort);
}

export function formatCurrency(amount: string | number) {
  const sanitizedAmount = typeof amount === 'string' ? amount.replace(/,/g, '') : amount;
  const parsedAmount = Number(sanitizedAmount);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(parsedAmount);
};


export function formatDate(dateString:any) {
  const date = new Date(dateString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const locationTypes = [{ label: 'Store', value: 'STR' }, { label: 'Radius', value: 'RDS' }];

export function formateDateFromDateAndTime(date: any) {
  const originalDate = new Date(date)
  const options = {
    year: 'numeric' as const,
    month: '2-digit' as const,
    day: '2-digit' as const,
    hour: '2-digit' as const,
    minute: '2-digit' as const,
    hour12: true,
    timeZoneName: 'short' as const,
  };

  return originalDate.toLocaleString('en-US', options);
}

export function formateDateFromTimestamp(dateFromTimestamp: any){
  dateFromTimestamp.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  });
}

export function sortDates(
  order: any,
  key: any,
  array: any,
  toggleName: any,
  toggleFunction: any,
  setFunction: any) {
  let sortedArray = array.slice();
  if (order === 'asc') {
    toggleFunction((pre: any) => ({ ...pre, [toggleName]: true }));
    sortedArray = [...sortedArray].sort(
      (a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()
    );
    setFunction(sortedArray.sort((a: any, b: any) => dateComparator(a[key], b[key], order)));
  } else {
    toggleFunction((pre: any) => ({ ...pre, [toggleName]: false }));
    sortedArray = [...sortedArray].sort(
      (a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()
    );
  }
  setFunction(sortedArray);
}

function dateComparator(dateA: string, dateB: string, order: string) {
  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();
  
  if (order === 'asc') {
    return timestampA - timestampB;
  } else {
    return timestampB - timestampA;
  }
}

export function yearToDateFormater(inputDate: any) {
  const dateArray = inputDate.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function getFormattedDate (): string  {
  const now = new Date();

  // Extract month, day, and year
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
  const day = now.getDate().toString().padStart(2, '0'); // Day of the month
  const year = now.getFullYear().toString(); // Full year

  // Format as MMDDYYYY
  return `${month}${day}${year}`;
};
