import { Typography, RACTableCell, RACTableRow } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { ReactComponent as PdfIcon } from './../../../emilyIcons/View PDF.svg';
import { getAgreementReceipt, getFormatedDate } from '../../../utils/utils';
import { CustomerContext } from './CustomerContext';
import { useHistory } from 'react-router';

export function AgreementTableBodyRender(
  obj: any,
  index: any,
  customerId: any,
  type: number
) {
  const history = useHistory();
  const { setPageLoader, setErrorMessage, setErrorMessagePopup, classes } =
    useContext(CustomerContext);
  const renderTableCell = (value: any, className?: string, title?: string) => (
    <RACTableCell
      className={`${classes.semiBold} ${classes.textDarkSlateBlue} ${className}`}
      title={title}
    >
      {value ? <Typography className={className}>{value}</Typography> : '-'}
    </RACTableCell>
  );

  function getRoute(obj: any) {
    return obj?.route
      ? obj?.route.length > 9
        ? `${obj?.route.slice(0, 7)}...`
        : obj?.route
      : '-';
  }

  function renderStoreCell(obj: any, classes: any) {
    return (
      <RACTableCell
        key={index}
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
      >
        {obj?.storeNumber ? (
          <Typography
            className={classes.racpadLink}
            onClick={() => {
              history.push({
                pathname: `/operations/store/${obj?.storeNumber}`,
              });
            }}
          >
            {obj.storeNumber}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
    );
  }

  function renderAgreementIdCell(
    obj: any,
    classes: any,
    customerId: any,
    type: any
  ) {
    return (
      <RACTableCell
        key={index}
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
      >
        {obj?.agreementNumber ? (
          <Typography
            className={classes.racpadLink}
            onClick={() => {
              history.push({
                pathname: `/operations/agreement/details/${customerId}/${obj?.agreementId}`,
                search: `?agrType=${obj?.agreementType?.toLowerCase()}&storeNumber=${
                  obj?.storeNumber
                }`,
              });
            }}
          >
            <span
              className={
                type == 1 ? `${classes.AgrLeftColor}` : `${classes.redColor}`
              }
            ></span>{' '}
            {obj.agreementNumber}
          </Typography>
        ) : (
          '-'
        )}
      </RACTableCell>
    );
  }
  return (
    <RACTableRow
      key={index}
      className={classes.fixTableHeadAgrTrsRec}
      backgroundColor="white"
    >
      <RACTableCell
        key={index}
        className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
      >
        {''}
      </RACTableCell>
      {renderAgreementIdCell(obj, classes, customerId, type)}
      {renderStoreCell(obj, classes)}
      {renderTableCell(getRoute(obj), '', obj?.route)}
      {renderTableCell(
        obj.customers[0]?.priority
          ? obj.customers[0]?.priority == 1
            ? 'Renter'
            : 'Co-Renter'
          : '-',
        ''
      )}
      {renderTableCell(
        obj?.agreementDescription
          ? obj.agreementDescription.length > 15
            ? obj.agreementDescription.slice(0, 13) + '...'
            : obj.agreementDescription
          : '-',
        '',
        obj?.agreementDescription
      )}
      {renderTableCell(getFormatedDate(obj?.agreementOpenDate), '')}
      {renderTableCell(getFormatedDate(obj?.currentDueDate), '')}
      {renderTableCell(obj?.currentDayslate ? obj?.currentDayslate : '0', '')}
      {renderTableCell(
        obj?.actualRate ? `$${obj?.actualRate}` : `$0.00`,
        `${classes.textRight}`
      )}
      {renderTableCell(obj?.actualTerm, `${classes.textRight}`)}
      {renderTableCell(
        obj?.rentPaid ? `$${obj?.rentPaid}` : obj?.rentPaid,
        `${classes.textRight}`
      )}
      {renderTableCell(getFormatedDate(obj?.closeDate), '')}
      {renderTableCell(
        obj?.closeDate
          ? obj?.agreementStatusReason
            ? obj?.agreementStatusReason?.length > 15
              ? obj?.agreementStatusReason.slice(0, 12) + '...'
              : obj?.agreementStatusReason
            : '-'
          : '-',
        '',
        obj?.agreementStatusReason
      )}
      <RACTableCell
        className={`${classes.semiBold} ${classes.textCenter} ${classes.actionIconWidth}`}
      >
        <PdfIcon
          className={`${classes.iconWidth} ${classes.textCenter}`}
          onClick={() => {
            getAgreementReceipt(
              obj.agreementId,
              setPageLoader,
              setErrorMessage,
              setErrorMessagePopup,
              customerId,
              obj.agreementDescription
            );
          }}
        />
      </RACTableCell>
    </RACTableRow>
  );
}
