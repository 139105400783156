import { Card, CardContent, Grid, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { InventoryDetailsContext } from '../../../../context/Inventory/InventoryDetailsContext';
import {
  CHARGED_OFF,
  HOLD,
  LOANER,
  NOT_RENT_READY,
  ON_RENT,
  SERVICE_CHARGED_OFF,
  PENDING_CHARGE_OFF,
  PENDING_LOANER_AGREEMENT,
  PENDING_RENTAL_AGREEMENT,
  RENT_READY,
  RESERVED,
  SERVICE_IDLE,
  SERVICE_ON_RENT,
  TRANSFER_IN_PROGRESS,
} from '../../../../constants/constants';

export function Header() {
  const { classes, headerInformation } = useContext(InventoryDetailsContext);
  const dynamicStatusColor = (val: any) => {
    switch (val) {
      case ON_RENT:
      case SERVICE_ON_RENT:
      case LOANER: {
        return `${classes.statusBadge} ${classes.statusGreen}`;
      }
      case RENT_READY:
      case NOT_RENT_READY:
      case PENDING_CHARGE_OFF:
      case HOLD:
      case SERVICE_IDLE:
      case RESERVED:
      case TRANSFER_IN_PROGRESS: {
        return `${classes.statusBadge} ${classes.statusWarning}`;
      }
      case PENDING_RENTAL_AGREEMENT:
      case PENDING_LOANER_AGREEMENT: {
        return `${classes.statusBadge} ${classes.statusInfo}`;
      }
      case CHARGED_OFF:
      case SERVICE_CHARGED_OFF: {
        return `${classes.statusBadge} ${classes.bgAsh} `;
      }
    }
  };
  return (
    <>
      <Card className={`${classes.headerCard}`}>
        <CardContent style={{ padding: '10px 0px 8px' }}>
          <Grid className={`${classes.dflex} ${classes.p0}`}>
            <Grid
              container
              className={`${classes.pl4} ${classes.pt2} ${classes.floatLeft} ${classes.widthAuto}`}
            >
              <Typography className={`${classes.fontSemiBold}`}>
                Item #
              </Typography>
              <Typography
                className={`${classes.textMildGrey} ${classes.ml2} ${classes.formValue} `}
              >
                {headerInformation.itemNumber
                  ? headerInformation.itemNumber
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              container
              className={`${classes.pl5} ${classes.pt2}  ${classes.widthAuto}`}
            >
              <Typography className={`${classes.fontSemiBold}`}>
                Description
              </Typography>
              <Typography
                className={`${classes.textMildGrey} ${classes.ml2} ${classes.formValue} `}
                title={
                  headerInformation.description &&
                  headerInformation.description.length > 40
                    ? headerInformation.description
                    : ''
                }
              >
                {headerInformation.description
                  ? headerInformation.description.length > 40
                    ? headerInformation.description.slice(0, 37) + '...'
                    : headerInformation.description
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              container
              className={`${classes.pl5} ${classes.pt2}  ${classes.widthAuto}`}
            >
              <Typography className={`${classes.fontSemiBold}`}>
                Status
              </Typography>
              <Typography
                className={
                  headerInformation.status
                    ? dynamicStatusColor(headerInformation.status)
                    : '-'
                }
              >
                {headerInformation.status !== undefined
                  ? headerInformation.status
                  : ' -'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
