import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalStyles } from '../../JsStyles/globalStyles';
import { validateLegalData } from '../Agreement/commonFunctions';
export const LegalHoldContext = createContext<any>('');

export function LegalHoldProvider(props: any) {
  const history = useHistory();
  const classes = GlobalStyles();
  const [displaySearch, setDisplaySearch] = useState<boolean>(false);
  const [displaySearchResults, setDisplaySearchResults] =
    useState<boolean>(false);
  const [pageDisplay, setPageDisplay] = useState(200);
  const [dropDownloading, setDropDownloading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [legalHoldSearchObj, setLegalHoldSearchObj] = useState({
    firstName: '',
    lastName: '',
    holdRefCode: '',
    limit: 20,
    offset: 0,
  });
  const [offset, setOffset] = useState<number>(20);
  const [loaders, setLoaders] = useState<any>({
    pageLoader: false,
    fetchDataLoader: false,
  });
  const [reasonOptions, setReasonOptions] = useState<any>([
    { label: 'All', value: '' },
  ]);
  const [lastNameSortOrder, setLastNameSortOrder] = useState(true);
  const [firstNameSortOrder, setFirstNameSortOrder] = useState(true);
  const [legalHoldGridContent, setLegalHoldGridContent] = useState<any>([]);
  const validationData: boolean = validateLegalData(legalHoldSearchObj);
  const clearButtonDisable: boolean =
    legalHoldSearchObj?.holdRefCode?.trim() != '' ||
    legalHoldSearchObj?.firstName?.trim() != '' ||
    legalHoldSearchObj?.lastName?.trim() != '';

  return (
    <div>
      <LegalHoldContext.Provider
        value={{
          history,
          classes,
          displaySearch,
          setDisplaySearch,
          displaySearchResults,
          setDisplaySearchResults,
          pageDisplay,
          setPageDisplay,
          dropDownloading,
          setDropDownloading,
          hasMore,
          setHasMore,
          legalHoldSearchObj,
          setLegalHoldSearchObj,
          offset,
          setOffset,
          loaders,
          setLoaders,
          reasonOptions,
          setReasonOptions,
          lastNameSortOrder,
          setLastNameSortOrder,
          firstNameSortOrder,
          setFirstNameSortOrder,
          legalHoldGridContent,
          setLegalHoldGridContent,
          validationData,
          clearButtonDisable,
        }}
      >
        {props.children}
      </LegalHoldContext.Provider>
    </div>
  );
}
