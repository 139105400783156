/* eslint-disable prettier/prettier */
/* eslint-disable */
import React, { useContext } from 'react';
import {
  Grid,
  RACButton,
  RACCOLOR,
  RACModalCard,
  Typography,
  makeStyles,
} from '@rentacenter/racstrap';
import PinField from 'react-pin-input';
import { getCurrentUser, getPinDetails, resetPin } from '../../api/user';
import { ResetPinContext } from './ResetPinContext';
import clsx from 'clsx';

const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px',
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonMargin: {
    marginRight: '50px',
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  spinnerModal: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204 204 204 / 0%)',
    background: 'rgb(255 255 255 / 0%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  loaderContainer: {
    position: 'fixed',
    background: '#f7f5f5',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 2000,
    textAlign: 'center',
    margin: '0px 0px',
  },
  loaderContent: {
    display: 'block',
    position: 'fixed',
    zIndex: 9999999,
    top: '40%',
    right: '50%',
  },
}));

export function PinPopUp() {
  const {
    setLoader,
    popUp,
    setPopUp,
    checkPin,
    setCheckPin,
    disable,
    setDisable,
    pinDisplay,
    setPinDisplay,
    setResetPinGridContent,
    resetPinGridContent,
    userId,
    setSuccessDisplay,
  } = useContext(ResetPinContext);

  const classes = useClasses();

  async function validatePin() {
    const array = resetPinGridContent;
    setPopUp({ ...popUp, pinPopUp: false });
    setLoader(true);
    setResetPinGridContent([]);
    const userDetails: any = await getCurrentUser();
    const employeeId = userDetails?.data?.employeeId;
    const response: any = await getPinDetails({
      userId: employeeId,
      encryptedPin: checkPin,
    });
    if (response?.data?.statusCode == 200) {
      setPopUp({ ...popUp, pinPopUp: false });
      const response = await resetPin({
        userId: userId,
        isReset: 'Y',
      });
      setLoader(false);
      setPinDisplay(200);
      if (response?.data?.statusCode == 200) {
        setPopUp({ ...popUp, successPopUp: true, pinPopUp: false });
        setSuccessDisplay(200);
      } else {
        setPopUp({ ...popUp, successPopUp: true, pinPopUp: false });
        setSuccessDisplay(400);
      }
    } else {
      setPopUp({ ...popUp, pinPopUp: true });
      setLoader(false);
      setPinDisplay(400);
    }
    setDisable(true);
    setResetPinGridContent(array);
  }

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={(e) => e.key == 'Enter' && !disable ? validatePin() : null}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onChange={(e) => {
                setPinDisplay(0);
                if (e.length == 4) {
                  setDisable(false);
                } else {
                  setDisable(true);
                }
              }}
              onComplete={(e) => {
                setCheckPin(e)
              }}
            />
          </Grid>
          {pinDisplay == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={() => {
              setPopUp({ ...popUp, pinPopUp: false });
              setPinDisplay(0);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={disable}
            onClick={() => {
              validatePin();
            }}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={popUp.pinPopUp}
        borderRadius="20px"
        closeIcon={true}
        onClose={() => {
          setPopUp({ ...popUp, pinPopUp: false });
          setPinDisplay(0);
        }}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </>
  );
}
