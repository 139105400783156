import { Grid } from '@rentacenter/racstrap';
import { RevenueAndService } from '../MicroComponents/RevenueAndService';
import { ActivitiesGrid } from '../MicroComponents/ActivitiesGrid';
import React from 'react';

export function ActivitiesTab(props?: any) {
  return (
    <>
      <Grid>
        <RevenueAndService />
        <Grid style={{ marginBottom: '30px' }}>
          <ActivitiesGrid
            offset={props?.offset}
            offsetFunc={props?.offsetFunc}
          />
        </Grid>
      </Grid>
    </>
  );
}
