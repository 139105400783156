import {
  handleActiveAgreements,
  handleInActiveAgreements,
} from './CustomerDetailsHelperFunc';

export function inActiveAgreementData(
  inactiveAgreementDetails: any,
  setLoader: any,
  setInactiveAgreements: any,
  setResponseStatus: any
) {
  if (inactiveAgreementDetails) {
    if (inactiveAgreementDetails?.status == 200) {
      if (inactiveAgreementDetails?.data !== '') {
        handleInActiveAgreements(
          inactiveAgreementDetails,
          setResponseStatus,
          setInactiveAgreements
        );
      } else {
        setLoader(false);
        setInactiveAgreements([]);
        setResponseStatus((prevStatus: any) => ({
          ...prevStatus,
          inactiveAgreementTabStatus: 204,
        }));
      }
    } else {
      setLoader(false);
      setInactiveAgreements([]);
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        inactiveAgreementTabStatus: 204,
      }));
    }
  } else {
    setLoader(false);
    setInactiveAgreements([]);
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      inactiveAgreementTabStatus: 500,
    }));
  }
}

export function activeAgreementData(
  activeAgreementDetails: any,
  setLoader: any,
  setActiveAgreements: any,
  setResponseStatus: any,
  setCoCustomerId: any,
  setCoCustomerName: any
) {
  if (activeAgreementDetails) {
    if (activeAgreementDetails?.data == '') {
      setLoader(false);
      setActiveAgreements([]);
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        activeAgreementTabStatus: 204,
      }));
    } else {
      if (activeAgreementDetails?.status == 200) {
        setCustomerDetails(
          activeAgreementDetails,
          setCoCustomerId,
          setCoCustomerName
        );
        handleActiveAgreements(
          activeAgreementDetails,
          setResponseStatus,
          setActiveAgreements
        );
      } else {
        setLoader(false);
        setActiveAgreements([]);
        setResponseStatus((prevStatus: any) => ({
          ...prevStatus,
          activeAgreementTabStatus: activeAgreementDetails?.status,
        }));
      }
    }
  } else {
    setLoader(false);
    setActiveAgreements([]);
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      activeAgreementTabStatus: 500,
    }));
  }
}

function setCustomerDetails(
  activeAgreementDetails: any,
  setCoCustomerId: any,
  setCoCustomerName: any
) {
  setCoCustomerId(
    activeAgreementDetails?.data?.coCustomerId
      ? parseInt(activeAgreementDetails?.data?.coCustomerId)
      : 0
  );
  setCoCustomerName(
    activeAgreementDetails?.data?.coCustomerLastName
      ? activeAgreementDetails?.data?.coCustomerFirstName +
          ' ' +
          activeAgreementDetails?.data?.coCustomerLastName
      : ''
  );
}
