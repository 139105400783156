import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';

import { customerDetailsStyle } from './../customerStyles';
import { tableHeadings } from '../../../constants/customerDetailsInterface';
import { ReactComponent as AlertIcon } from './../../../emilyIcons/no-records-found.svg';
import { ReactComponent as DetailsIcon } from '../../../emilyIcons/More info.svg';
import { ReactComponent as NoRecordsFound } from '../../../assets/images/No-records.svg';
import React from 'react';
import { Loader } from '../CommonCompo/Loader';

export default function EmploymentTab(props: any) {
  const classes: any = customerDetailsStyle();

  const employmentHeading: tableHeadings[] = [
    { heading: 'Status' },
    { heading: 'Company' },
    { heading: 'Supervisor' },
    { heading: 'Do Not Call' },
    { heading: 'Phone #' },
    { heading: 'Action' },
  ];

  const employmentDetails = props.value.tableBodyOne;

  const employmentTabHeading = () => (
    <>
      {employmentHeading.map((obj: any, index: any) => {
        if (obj.heading == 'Action') {
          return (
            <RACTableCell
              key={index}
              className={`${classes.textCenter} ${classes.bold}`}
            >
              {obj.heading}
            </RACTableCell>
          );
        }
        return (
          <RACTableCell key={index} className={`${classes.bold}`}>
            {obj.heading}
          </RACTableCell>
        );
      })}
    </>
  );

  const employmentTabContent = () => (
    <>
      {employmentDetails.map((obj: any, index: any) => {
        return (
          <RACTableRow className={classes.backgroundColorWhite} key={index}>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.active == 'Y' ? 'Active' : 'In Active'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.employerName ? obj.employerName : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.supervisorFirstName
                ? obj.supervisorLastName
                  ? obj.supervisorFirstName + ' ' + obj.supervisorLastName
                  : obj.supervisorFirstName
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.doNotCall == 'Y' ? 'Yes' : 'No'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '18%' }}
              className={`${classes.semiBold}  ${classes.textDarkSlateBlue}`}
            >
              {obj.employerPhoneNumber
                ? `(${obj.employerPhoneNumber.slice(
                    0,
                    3
                  )}) ${obj.employerPhoneNumber.slice(
                    3,
                    6
                  )}-${obj.employerPhoneNumber.slice(6)}`
                : '-'}
            </RACTableCell>
            <RACTableCell
              style={{ width: '10%' }}
              className={`${classes.semiBold} ${classes.textCenter}`}
            >
              <DetailsIcon
                className={classes.iconWidth}
                onClick={() => {
                  props.value.setShowEmployementDetails(true);
                }}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  return (
    <Grid
      container
      md={12}
      className={`${classes.lightGrayBgColor} ${classes.p01} ${classes.fixTableHeadAgrTrsRec} ${classes.semiBold}`}
    >
      <Card className={`${classes.tabsCard} ${classes.mt3}`}>
        <CardContent>
          <Grid container md={12}>
            {props.value.cardLoader ? (
              <Grid className={classes.cardLoader}>
                <Loader />
              </Grid>
            ) : props.value.status == 200 ? (
              <RACTable
                renderTableHead={employmentTabHeading}
                renderTableContent={employmentTabContent}
                className={classes.backgroundColorWhite}
                stickyHeader
              />
            ) : props.value.status == 204 ? (
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <NoRecordsFound />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  No Records Found
                </Typography>
              </Grid>
            ) : props.value.status == 400 ? (
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <AlertIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  Bad Request
                </Typography>
              </Grid>
            ) : (
              <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                <AlertIcon />
                <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                  Something Went Wrong
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
